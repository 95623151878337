import React from "react";
import title1 from "../../../../../../assets/games/money_Wheel/mw_title.gif";
import userTag from "../../../../../../assets/games/money_Wheel/mw_username_icon.png";
import winning_bg from "../../../../../../assets/games/money_Wheel/win.png";
import balance_bg from "../../../../../../assets/games/money_Wheel/Bala.png";
import betAmountbg from "../../../../../../assets/games/money_Wheel/mw_bet_bg.png";
import winBg from "../../../../../../assets/games/money_Wheel/mw_bet_bg.png"
import chips from '../../../chips';

import "./moneyWheel.css"

import { connect } from "react-redux";

class LeftButtonPanel extends React.Component {
    constructor(props) {
        super(props)

        this.chipVal = [
            { label: "1k", val: 1000 },
            { label: "5k", val: 5000 },
            { label: "10k", val: 10000 },
            { label: "25k", val: 25000 },
            { label: "50k", val: 50000 },
            { label: "100k", val: 100000 },
            { label: "5M", val: 5000000 },
            { label: "1B", val: 100000000 },
            { label: "", val: 0 }
        ];


        // this.chipVal = [
        //     { src: chip_1, val: 1 },
        //     { src: chip_10, val: 10 },
        //     { src: chip_25, val: 25 },
        //     { src: chip_50, val: 50 },
        //     { src: chip_100, val: 100 },
        //     { src: chip_500, val: 500 },
        //     { src: chip_1000, val: 1000 },
        //     { src: chip_5000, val: 5000 },
        //     { src: Trash_icon, label: "", val: 0 }
        // ];

        this.state = { selectedBtn: 1 }

    }
    componentDidMount() {
        const { childRef } = this.props;
        childRef(this);
        console.log(this.props.mwt_chipValues) //1,5,10,25,50,100,500,1000,5000
        if (this.props.mwt_chipValues) {
            let newChips = this.props.mwt_chipValues.split(",");
            console.log(newChips);
            console.log(newChips.length)
            let lblTxt = "";
            for (let i = 0; i < newChips.length; i++) {
                if (Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999) {
                    lblTxt = "K"
                } else if (Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999) {
                    lblTxt = "M"
                } else if (Number(newChips[i]) > 999999999) {
                    lblTxt = "B"
                }
                this.chipVal[i].val = newChips[i]
                console.log(this.chipVal[i].val, lblTxt);
                this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);

            }
        }
    }

    checkChipValue = (chipValue, chpTxt, i) => {
        if (chipValue >= 1000) {
            chipValue = chipValue / 1000;
            this.checkChipValue(chipValue, chpTxt, i)
        } else {
            console.log(chpTxt);
            this.showResultChipValue(chipValue, chpTxt, i);
            return chipValue
        }
    }


    showResultChipValue = (chVal, chipTxt, index) => {
        console.log(chVal);
        console.log(chVal + "" + chipTxt)
        this.chipVal[index].label = chVal + "" + chipTxt;
        console.log(this.chipVal)
    }

    // topLable_fn(labelValue) {
    //     // Nine Zeroes for Billions
    //     return Math.abs(Number(labelValue)) >= 1.0e+9

    //         ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
    //         // Six Zeroes for Millions 
    //         : Math.abs(Number(labelValue)) >= 1.0e+6

    //             ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    //             // Three Zeroes for Thousands
    //             : Math.abs(Number(labelValue)) >= 1.0e+3

    //                 ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

    //                 : Math.abs(Number(labelValue));
    // }
    topLable_fn(labelValue) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9
    
        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6
    
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3
    
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
    
        : Math.abs(Number(labelValue.toFixed(2)));
    }

    render() {
        // let updatebal = this.topLable_fn(Number(this.props.balance)).toFixed(2);
        let updatebal = this.topLable_fn(Number(this.props.balance))
        let updateBet = this.topLable_fn(this.props.totalBet)
        return (
            <React.Fragment>

                <div className="mw_left_container">

                    <div className="moneywheelidcontainer">
                        {this.props.language.Hand_Id}: {this.props.handId} <br />
                        {this.props.language.Player_Id}: {this.props.playerId}
                    </div>

                    <div className="mw_title row">
                        <img className="title_image" src={title1} alt="" />

                    </div>

                    <div className="fun_id_2 row">
                        <img className="fun_balance_bg_label" src={balance_bg} alt="" />
                        <span className="balance">{updatebal}</span>
                    </div>

                    <div className="fun_id">
                        <img className="fun_id_bg_label" src={winning_bg} alt="" />
                        <div className="mw_winner centered">{this.props.winAmount}</div>
                    </div>


                    <div className="money_wheel_chips">

                        {/* {chips.map((data, i) => (
                            <div id={"chipImg_" + this.chipVal[i].val} className={this.state.selectedBtn == this.chipVal[i].val ? "money_wheel_chipImg active_chip" : "money_wheel_chipImg"} key={data.src}

                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ selectedBtn: this.chipVal[i].val })
                                    this.props.selectedBetChip(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`);
                                }}
                            >
                                <img className="chip_size" src={data.src} alt={data.src} />
                            </div>
                        ))} */}

                        {chips.map((data, i) => (
                            <div id={"chipImg_" + data.val} className="col-4 money_wheel_chipImg" key={data.src}>
                                <div  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.selectedBetChip(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`);
                                    this.setState({ selectedBtn: data.val })
                                }}>
                               
                                    <img src={data.src} alt={data.src}
                                        className={this.state.selectedBtn === data.val ? "activeChip" : "inactive"} />
                                    <div className="mwt_chip_text">{this.chipVal[i].label}</div>
                                    </div>
                                {/* <p className="mwt_chipImg"
                                >
                                </p> */}
                            </div>))}
                    </div>


                    <div className="bet_button_container">

                        <div className="bet_amount_bg">
                            <img className="win_bg" src={betAmountbg} alt="" />
                            <span className="bet_amount_heading">{this.props.language.TotalBet}: {updateBet}</span>
                        </div>

                        <div className="win_bg_container">
                            <img className="win_bg" src={winBg} alt="" />
                            <img className="fun_id_tag" src={userTag} alt="" />
                            <span className="mw_user">{this.props.user}</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStatesToProps = (state) => {
    return {
        language: state.languageObjs.languageObj
    }
}

export default connect(mapStatesToProps)(LeftButtonPanel)