import React from "react";
import chips from '../../../chips';
import { connect } from "react-redux";

class BetChips extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            chipSelect: 1,
        }

        this.chipVal = [
            { label: "1k", val: 3000 },
            { label: "5k", val: 5000 },
            { label: "10k", val: 10000 },
            { label: "10k", val: 10000 },
            { label: "50k", val: 50000 },
            { label: "100k", val: 100000 },
            { label: "5M", val: 5000000 },
            { label: "1B", val: 100000000 },
            { label: "", val: "" }
        ];

        //console.log("Selected Chip Value: "+this.props.betAmount);
    }

    componentDidMount() {
        const { childRef } = this.props;
        childRef(this);

        if (this.props.art_chipValues) {
            let newChips = this.props.art_chipValues.split(",");
            console.log(newChips);
            console.log(newChips.length)
            let lblTxt = "";
            for (let i = 0; i < newChips.length; i++) {
                if (Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999) {
                    lblTxt = "K"
                } else if (Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999) {
                    lblTxt = "M"
                } else if (Number(newChips[i]) > 999999999) {
                    lblTxt = "B"
                }
                this.chipVal[i].val = newChips[i]
                console.log(this.chipVal[i].val, lblTxt);
                this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);

            }
        }


    }

    checkChipValue(chipValue, chpTxt, i) {
        if (chipValue >= 1000) {
            chipValue = chipValue / 1000;
            this.checkChipValue(chipValue, chpTxt, i)
        } else {
            console.log(chpTxt);
            this.showResultChipValue(chipValue, chpTxt, i);
            return chipValue
        }
    }

    showResultChipValue(chVal, chipTxt, index) {
        console.log(chVal);
        console.log(chVal + "" + chipTxt)
        this.chipVal[index].label = chVal + "" + chipTxt;
        console.log(this.chipVal)
    }


    // topLable_fn(betAmount){
    //     console.log(betAmount);
    //     console.log(typeof(betAmount));
    //     let updatedBalance = betAmount;



    //     if(updatedBalance >= 1000 && updatedBalance <= 999999){
    //        // alert("1");
    //         updatedBalance = (updatedBalance / 1000) +"K";      
    //         console.log(updatedBalance)
    //     }else if(updatedBalance >= 100000 && updatedBalance <=999999999){
    //        // alert("2");
    //       updatedBalance = (updatedBalance / 1000)+"M";    
    //     }else if(updatedBalance >999999999){
    //       //  alert("3");
    //       updatedBalance = (updatedBalance / 1000)+"B";      
    //     }

    //     return updatedBalance;
    //   }
    //   topLable_fn2(betAmount){
    //    // console.log(betAmount);
    //     if(betAmount >= 1000 && betAmount <=999999){
    //         betAmount = (betAmount / 1000) +"K";      
    //        // console.log(betAmount)
    //     }else if(betAmount >= 1000000 && betAmount <=999999999){
    //       betAmount = betAmount / 1000+"M";    
    //     }else if(betAmount >999999999){
    //       betAmount = betAmount / 1000+"B";      
    //     }

    //     return betAmount;
    //   }

    topLable_fn(labelValue) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9
    
        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6
    
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3
    
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
    
        : Math.abs(Number(labelValue.toFixed(2)));
    }
    render() {
        const roundNumber = this.props.balance - this.props.TotalBetAmouont
        const betAmount = (roundNumber)
        let updatebal = this.topLable_fn(Number(betAmount))
        //console.log("Balance Amount: "+betAmount);
        // let updatebal = this.topLable_fn(betAmount);
        let updateBet = this.topLable_fn(this.props.TotalBetAmouont)
        //console.log(updatebal);
        return (
            <React.Fragment>
                <div className="fd p_5">
                    <div className="">
                        <div className="funBal fd">
                            <div className=""> {updatebal} </div>
                            <div className=""> {this.props.language.Balance} </div>
                        </div>
                        <div className="funBal fd">

                            <div className="">{updateBet}</div>
                            <div className=""> {this.props.language.TotalBet}</div>
                        </div>
                        {/* <div className="funBal fd">
                        <div className=""> {this.props.winAmount} </div>
                        <div className=""> WINNING </div>
                    </div> */}
                    </div>


                    <div className="fd chips wid_110prsnt">
                        <div className="row">
                            {chips.map((data, i) => (
                                <div id={"chipImg_" + data.val} className="col-4 text-center amChipCard am_chip" key={data.src}>

                                    <p className="chipImg" onClick={(e) => { e.preventDefault(); this.props.betAmount(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`); this.setState({ chipSelect: data.val }) }} >
                                        <img src={data.src} alt={data.src}
                                            className={this.state.chipSelect === data.val ? "active" : "inactive"} />
                                        <div className="art_overlay-text">{this.chipVal[i].label}</div>
                                    </p>
                                </div>))}
                        </div>
                    </div>

                    {/* <div className="fd ">
            <button type="button" className="fd bt_1_bdr p_2">
            <button type="button" className="fd ar_bt_1"> Cleare </button>
            </button>
            </div> */}
                </div>
            </React.Fragment>
        );
    }
}


const mapStatesToProps = (state) => {
    return {
        language: state.languageObjs.languageObj
    }
}
export default connect(mapStatesToProps)(BetChips)
