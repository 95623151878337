import React, { Component } from "react";
import "./funSoratTable.css";
import tableData from "./table";
import goldCoin from "../../../../../../assets/gold_coin.svg";
import { connect } from "react-redux";
class FunSoratTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            betNumbers: [],
            nmArray: [],
            betAmt: 0,
            dataNum:0,
            betAmout:0,
            winAmount:0,
        };
        for (var b = 0; b < 63; b++) {
            this.state.nmArray.push({ id: b, amount: 0 });
        }
    }
    componentDidMount(){    
        const { childRef } = this.props;
        childRef(this);

        console.log(this.props.language);
    }


    betData(dataList) {
        if (this.props.chipMoeny !== 0) {
            this.state.dataNum = parseInt(dataList);
            console.log("hello " +this.state.dataNum);
            // eslint-disable-next-line eqeqeq
            var objIndex = this.state.nmArray.findIndex((obj) => obj.id == this.state.dataNum);
            console.log("objIndex " +objIndex);
            // this.state.nmArray.push({ id: dataNum, amount: 15 });
            // eslint-disable-next-line react/no-direct-mutation-state
            var chipMoney = parseInt(this.props.chipMoeny);
            // eslint-disable-next-line react/no-direct-mutation-state
            console.log("this.state.betAmout 1--> : "+this.state.betAmout);
            this.state.nmArray[objIndex].amount += chipMoney;
            this.state.betAmout = this.state.nmArray[objIndex].amount;
            var betValue = this.state.nmArray[objIndex].amount;

          //  this.props.betString = this.state.nmArray;

            console.log("betValue--> : "+betValue);
            console.log("chipMoeny--> : "+this.props.chipMoeny);
            console.log(this.state.nmArray);

            var divContain;
            var divs;
            if(!divContain){
                divContain = document.getElementById("funSoratTable");
            }
            if(!divs){
                divs = divContain.getElementsByTagName("div");
            }

            this.setState(
                {
                    betNumbers: this.state.betNumbers + dataList + ",",
                },
                () => {console.log(this.state.betNumbers)

                }
            );
        } else {
            alert('Please Select Bet Amount.')
        }
    }

    mouseDownSelectCard(data){ 
        this.props.action(data)
        // this.betoncard = new Audio(betoncard)
        // this.betoncard.play();
        this.startInt = setInterval( () => {
        //   this.betoncard2 = new Audio(betoncard)
        //   this.betoncard2.play();
          this.props.action(data)
        }, 150); 
      } 
      mouseUpStopCards(){
        clearInterval(this.startInt);
      }

    //   topLable_fn(betAmount){
    //     if(betAmount >= 1000 && betAmount <=999999){
    //         betAmount = betAmount / 1000+"K";      
    //     }else if(betAmount >= 1000000 && betAmount <=999999999){
    //       betAmount = betAmount / 1000+"M";    
    //     }else if(betAmount >999999999){
    //       betAmount = betAmount / 1000+"B";      
    //     }

    //     return betAmount;
    //   }

    // topLable_fn(labelValue) 
    // {
    //     // Nine Zeroes for Billions
    //     return Math.abs(Number(labelValue)) >= 1.0e+9
    
    //     ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
    //     // Six Zeroes for Millions 
    //     : Math.abs(Number(labelValue)) >= 1.0e+6
    
    //     ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    //     // Three Zeroes for Thousands
    //     : Math.abs(Number(labelValue)) >= 1.0e+3
    
    //     ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
    
    //     : Math.abs(Number(labelValue));
    // }
    topLable_fn(labelValue) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9
    
        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6
    
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3
    
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
    
        : Math.abs(Number(labelValue.toFixed(2)));
    }

  render() {
    const roundNumber = this.props.balance - this.props.TotalBetAmouont
    let betAmount = (roundNumber)
    // let updatebal = this.topLable_fn(Number(betAmount)).toFixed(2);
    let updatebal = this.topLable_fn(Number(betAmount))
    let updateBet = this.topLable_fn(this.props.TotalBetAmouont)

    // if(betAmount >= 1000 && betAmount <=99999){
    //     betAmount = betAmount / 1000+"K";      
    // }else if(betAmount >= 1000000 && betAmount <=999999999){
    //   betAmount = betAmount / 1000+"M";    
    // }else if(betAmount >999999999){
    //   betAmount = betAmount / 1000+"B";      
    // }

    return (
        <React.Fragment>
            <div className="indSoratBln fd">
                <div className="funBal">
                    <div className=""> {this.props.language.Balance} </div>
                    <div className="" id="winSorat"> {updatebal } </div>
                </div>
                <div className="funBal">
                    <div className="value">
                    {this.props.language.TotalBet}
                   
                    </div>
                    <div className=""> {updateBet} </div>
                </div>
                {/* <div className="funBal">
                    <div className=""> WINNING </div>
                    <div className=""> {this.props.winAmount} </div>
                </div> */}
            </div> 
            <table className="funSoratTable" id="funSoratTable">
                <tbody>
                <tr>
                    {tableData
                        .filter((data) => data.TR === "tr0")
                        .map((data) => (
                            <td key={data.src} rowSpan="3">
                                <img width="140px" src={data.src} alt={data.src} />
                                <div className="boxCntr" id={"id_"+data.betCntr} 
                                onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betCntr}`)}}
                                onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                            </td>
                        ))}
                    {tableData
                        .filter((data) => data.TR === "tr1")
                        .map((data) => (
                            <td key={data.src}>
                                <img className="tabImg" src={data.src} alt={data.src} />
                                <div className="boxCntr" id={"id_"+data.betCntr} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betCntr}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                                <div className="boxBtm" id={"id_"+data.betBtm} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betBtm}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}> 
                                </div>
                                <div className="boxLeft" id={"id_"+data.betLeft} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betLeft}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}> 
                                </div>
                                <div className="boxCrnr" id={"id_"+data.betCrnr} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betCrnr}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}> 
                                </div>
                                <div className={(data.betRight !== null)? "boxTop d_block" : "boxTop d_none" } id={"id_"+data.betTop} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betTop}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>  
                                </div>
                                <div className={(data.betRight !== null)? "boxRight d_block" : "boxRight d_none" } id={"id_"+data.betRight} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betRight}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}> 
                                </div>
                            </td>
                        ))}
                </tr>
                <tr>
                    {tableData
                        .filter((data) => data.TR === "tr2")
                        .map((data) => (
                            <td key={data.src}>
                                <img className="tabImg" src={data.src} alt={data.src} />
                                <div className="boxCntr" id={"id_"+data.betCntr} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betCntr}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                                <div className="boxBtm" id={"id_"+data.betBtm} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betBtm}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                                <div className="boxLeft" id={"id_"+data.betLeft} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betLeft}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                                <div className="boxCrnr" id={"id_"+data.betCrnr} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betCrnr}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                                <div className={(data.betRight !== null)? "boxRight d_block" : "boxRight d_none" } id={"id_"+data.betRight} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betRight}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>   
                                </div>
                            </td>
                        ))}
                </tr>
                <tr>
                    {tableData
                        .filter((data) => data.TR === "tr3")
                        .map((data) => (
                            <td key={data.src}>
                                <img className="tabImg" src={data.src} alt={data.src} />
                                <div className="boxCntr" id={"id_"+data.betCntr} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betCntr}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                                <div className="boxBtm" id={"id_"+data.betBtm} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betBtm}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                                <div className="boxLeft" id={"id_"+data.betLeft} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betLeft}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                                <div className="boxCrnr" id={"id_"+data.betCrnr} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betCrnr}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                                <div className={(data.betRight !== null)? "boxRight d_block" : "boxRight d_none" } id={"id_"+data.betRight} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betRight}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
                                    onMouseOut={(e)=>{this.mouseUpStopCards()}}>
                                </div>
                            </td>
                        ))}
                </tr>
                </tbody>
            </table>
           {/* <div className="F_Sorat_ins">
                <div className="ins"> {this.props.infoTxt} </div>
                        </div>*/}
        </React.Fragment>
    );
  }
}

const mapStatesToProps = (state)=>{
    return {
      language : state.languageObjs.languageObj
    }
  }
  export default connect(mapStatesToProps)(FunSoratTable)
  
