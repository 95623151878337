import React from "react";
import "./UI/funSoratTable.css";
import Close from "./UI/close";
import Title from "./UI/title";
import Wheel from "./UI/wheel";
import RightButtonPanel from "./UI/rightButtonPanel";
import FunSoratTable from "./UI/funSoratTable";
import ButtonsList from './UI/buttonsList';
import ChipSelection from './UI/chipSelection';
import goldCoin from "../../../../../assets/gold_coin.svg";
import coin from "../../../../../assets/gold_coin.svg";
import artBg from "../../../../../assets/games/funSoratTimer/sorat-bg.png";

import clearSound from "../../../../../assets/sounds/clear.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../assets/sounds/take.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";

import tickSound from "../../../../../assets/sounds/tick.mp3";
import wheelSound from "../../../../../assets/sounds/wheelStart.mp3";

import { connect } from "react-redux";

 class FunSoratTimer extends React.Component {
  constructor(props) {
    super(props);
    this.soratWheelRef = React.createRef();
    this.soratWheel = React.createRef();
    this.RightButtonPanel = React.createRef();
    this.soratTableRef = React.createRef();
    // this.wheelWidth = this.props.vw / 2;
    // this.wheelHeight = this.props.vh * 0.4;
    this.wheelWidth = 0;
    this.wheelHeight = 0;
    this.state = {
      gameId: this.props.gameId,
      playerId: this.props.playerId,
      totalBet: 0,
      betButtonText: this.props.language.Prev,
      betButtonValue: 0,
      zoomButtonText:this.props.language.Zoom_ON,
      zoomButtonValue:0,
      showBetButton: false,
      wheelZoom: 1,
      infoText: this.props.language.Play_Njoy,
      betstr: "",
      bets: "",
      stop: "NA",
      placeBetState: false,
      targetResult: props.targetResult,
      selectedChipVal: 1,
      // selectedChipLbl:"",
      betString: [],
      nmArray: [],
      Total_Bet_Amount: 0,
      winAmount: 0,
      wheelZoomClick: true,
      x_img: 0,
      disDoubleBtn: 0,
      disBetBtn: 0,
      disClearBtn: 0,
      disZoomBtn: 0,
      glowTakeBtn: 0,
      btnZindex: 0,
      soratLoader: true,
      bgLoader: false,
    };
    this.resultNumber = 0;
    this.betArray = [];
    this.previousPosition = undefined;
    this.betAmount = 0;
    this.isReady = false;
    this.removeGlow = false;
    this.bonusValue = 0;
    this.handId = 0;
    this.takeAmount = 0;
    this.isloadImg = false;
    this.gameLoad = true;

    this.clearSound = new Audio(clearSound);
    this.exitSound = new Audio(exitSound);
    this.betSound = new Audio(betSound);
    this.takeSound = new Audio(takeSound);
    this.winSound = new Audio(winSound);
    this.duploseSound = new Audio(duploseSound);
    this.tickSound = new Audio(tickSound);
    this.wheelSound = new Audio(wheelSound);
    this.loadBg();

    this.dvHt = null;
    this.dvWid = null;
    this.gameBox = null;
    this.newLimits = [];

    console.log(this.props.gameState)
  }

  componentDidMount() {

    const { childRef } = this.props;
    childRef(this);

    console.log(typeof(this.props.language))

    console.log("Language: "+this.props.language);

    const langObj = this.props.language;
    console.log(typeof(langObj));
    // langObj = JSON.parse(langObj);
    // console.log(this.props.language.Balance);  

    // console.log(this.props.language.Balance);

    if(this.props.isIphone){
      document.getElementById('iospopup').style.display = "block";  
    }else{
      document.getElementById('iospopup').style.display = "none";  
    }
    console.log(this.props.gameState)
    //this.tickSound.play();
    document.getElementById('winPop').style.display = 'none';

    // this.props.gamstateHandle();
    var soratWheelContainer = document.getElementById('soratWheelContainer');
    this.wheelHeight = soratWheelContainer.clientHeight;
    this.wheelWidth = soratWheelContainer.clientWidth;
    this.setState({ wheelZoom: 1, zindex: "0", placeBetState: false });
    this.resetBetList();

    console.log("Max Bet Amount is: "+this.props.maxBetAmt)

    if(this.props.fst_chipValues){
      let newChips = this.props.fst_chipValues.split(",");
      console.log(newChips[0]);  
      let initChipVal = newChips[0];
      let lblTxt;
      if(initChipVal >= 1000 && initChipVal <= 999999){
          lblTxt = "K"
      }else if(initChipVal >= 1000000 && initChipVal <= 999999999){
          lblTxt = "M"                        
      }else if(initChipVal > 999999999){            
          lblTxt = "B"
      } 
      
      this.state.selectedChipVal = initChipVal;
      // this.state.selectedChipLbl = initChipVal+""+lblTxt;
    }

    if(this.props.fst_innerLimits){
      this.newLimits = this.props.fst_innerLimits.split("|");
      console.log(this.newLimits);
      for(let t=0; t<this.newLimits.length; t++){
        this.newLimits[t] = this.newLimits[t].split("-");
        console.log(" *******   "+this.newLimits[t]);
      }
    }


    if ((this.props.gameState.handId == this.props.gameState.oldHandId && this.props.gameState.betRequest)) {

      let newStr = this.props.gameState.betRequest.betString.replace(/,/g, "|");
      let newStr0 = newStr.slice(0, -1);
      let newStr1 = newStr0.replace(/ /g, "0");
      let newStr2 = newStr1.split("|");

      let prevBetString = [];
      for (var i = 0; i < newStr2.length; i++) {
        prevBetString.push({
          id: i,
          amount: parseInt(newStr2[i]),
        });
      }
      this.state.nmArray = prevBetString.slice();

      for (var i = 0; i < this.state.nmArray.length; i++) {
        let preBetValue = this.state.nmArray[i].amount;
        if (preBetValue > 0) {
          if(preBetValue >= 1000 && preBetValue <=999999){
            preBetValue = preBetValue / 1000+"K";
            console.log(preBetValue);
          }else if(preBetValue >= 1000000 && preBetValue <=999999999){
            preBetValue = preBetValue / 1000+"M";
            console.log(preBetValue);
          }else if(preBetValue >999999999){
            preBetValue = preBetValue / 1000+"B";
            console.log(preBetValue);
          }
          document.getElementById("id_" + i).innerHTML = `<p class="goldCoin_sorat"> <img src=${coin} /> <span> ${preBetValue} </span> </p>`;
        }
      }

      console.log("----- betAmount  " + this.props.gameState.betRequest.betAmount);

      let totBetNum = this.changeAmtlabl(this.props.gameState.betRequest.betAmount);
    
      this.setState({
        betButtonText: this.props.language.BetOK,
        btnActive: true,
        betButtonValue:1,
        disBetBtn: 1,
        disClearBtn: 1,
        disDoubleBtn: 1,
        winAmount: 0,
        Total_Bet_Amount: this.props.gameState.betRequest.betAmount,
        infoText:this.props.language.BetAccepted  + totBetNum,
      });

      console.log('Cover block ----  this.props.gameState.handId != undefined ' + this.props.gameState.handId)
      console.log("funSoratCover  172");
      document.getElementById("funSoratCover").style.display = "block";

        if (this.state.zoomButtonValue == 0) { 
        //  this.setState({ wheelZoom: 1.4, zindex: "600", x_img: 1});
        }
        //this.showPrevBtn();
      
    } else {      
      document.getElementById("funSoratCover").style.display = "none";
      document.getElementById("bet_take").classList.remove("active");
      this.setState({ betButtonText: this.props.language.Prev,betButtonValue:0, disDoubleBtn: 1, disBetBtn: 0, disClearBtn: 1, disZoomBtn: 0 });
      if (this.props.gameState.history && this.props.gameState.history.length > 0) {
        this.bonusValue = this.props.gameState.history[this.props.gameState.history.length - 1].extraInfo;
      }

      this.soratWheelRef.current.setJackpotNumber(this.bonusValue);
      if (!this.props.gameState.betRequest) {
        this.setState({ disBetBtn: 1 });
      }
    }
    if (this.props.gameState.handId) {
      this.handId = this.props.gameState.handId;
    }
    document.addEventListener("keyup", this.fst_handleKeyboardEvent); 
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.fst_handleKeyboardEvent);
  }

  changeAmtlabl(chngAmt){
    let newAmt = chngAmt;
    if(newAmt >= 1000 && newAmt <=999999){
      newAmt = newAmt / 1000+"K";      
    }else if(newAmt >= 1000000 && newAmt <=999999999){
      newAmt = newAmt / 1000+"M";    
    }else if(newAmt >999999999){
      newAmt = newAmt / 1000+"B";      
    }
    return newAmt;
  }


  fst_handleKeyboardEvent = (evt) => {
    console.log(evt.code);
    switch(evt.code){
      case "Enter":
      case "NumpadEnter":
        console.log(this.state.betButtonValue)
        console.log(this.state.disBetBtn)
       // if (this.state.betButtonText == "PREV" && this.state.disBetBtn == 0) {
         if (this.state.betButtonValue == 0 && this.state.disBetBtn == 0) {
          this.rightButtonPanelHandler("PREV");
       // }else if (this.state.betButtonText == "BET OK" && this.state.disBetBtn == 0) {
         }else if (this.state.betButtonValue == 1 && this.state.disBetBtn == 0) {
          this.rightButtonPanelHandler("BET OK");
        }
        break;
      case "Space":
      //  if(this.state.betButtonText == "TAKE" && this.state.disBetBtn == 0){
         if(this.state.betButtonValue == 2 && this.state.disBetBtn == 0){
          this.rightButtonPanelHandler("TAKE");
        }
        break;
      case "Escape":
        //this.rightButtonPanelHandler("exit");
        break;
      case "KeyC":      
       // if(this.state.betButtonText == "BET OK" && this.state.disClearBtn == 0){
         if(this.state.betButtonValue == 1 && this.state.disClearBtn == 0){ 
          this.rightButtonPanelHandler("clear");
        }
        break;      
    }

  }



  resize = () => {
    console.log( this.dvWid )
    console.log( this.dvHt )
    let h;
    let w;
    
    w = window.innerWidth;
    h = window.innerHeight;
    
    let marg_left = (h * this.dvWid / this.dvHt - w) / 2;
    let marg_top = (w * this.dvHt / this.dvWid - h) / 2;
    let checkWid = h * this.dvWid / this.dvHt;
    console.log('-------------SORAT --------------')
    console.log(window.innerWidth ,  window.innerHeight)
    console.log(h , this.dvWid , this.dvHt )
    console.log( checkWid )
    console.log('-------------SORAT --------------')
    if (w > h && w < this.gameBox.clientWidth && h < this.gameBox.clientHeight && w > checkWid) {
      //alert('sorat one')
      this.gameBox.style.transform = "scale(" + h / this.dvHt + ")";
      this.gameBox.style.marginLeft = marg_left * -1 + "px";
    } else if (w < h && w < this.gameBox.clientWidth && h < this.gameBox.clientHeight) {
      //alert('sorat two')
      this.gameBox.style.transform = "scale(" + w / this.dvWid + ")";
      this.gameBox.style.marginLeft = marg_left * 1 + "px";
      
        this.gameBox.style.marginTop = marg_top * -1 + "px";
       
    } else {
      //alert('sorat three')
      this.gameBox.style.transformOrigin = "left top";
      this.gameBox.style.transform = "scale(" + w / this.dvWid + ")";
      
      this.gameBox.style.marginTop = marg_top * -1 + "px";
    }
  }

  resetBetList() {
    this.state.nmArray = [];
    for (var b = 0; b < 64; b++) {
      this.state.nmArray.push({
        id: b,
        amount: 0,
      });
    }
  }

  updateMessage() {
    console.log("--------- " + this.props.infoText);
    this.setState({ infoText: this.props.infoText });
    
    console.log(this.state.totalBet)

    console.log("error_code: " + this.props.error_code);
    if (this.props.error_code == "game.bet.invalid") {
      this.setState({
        betButtonText: this.props.language.Prev,
        betButtonValue:0,
        btnActive: false,
        zoomButtonText: this.props.language.Zoom_ON,
        zoomButtonValue:0,
        wheelZoom: 1,
        zindex: "0",
        disBetBtn: 0,
        disZoomBtn: 0,
      });
      this.updateWinsArray(0);
      console.log("funSoratCover  220");
      document.getElementById("funSoratCover").style.display = "none";
      console.log('Cover none ----  2')
    }
  }

  gameCloseHandler(game) {
    this.exitSound.play();
    this.soratWheelRef.current.stopWheelSound();
    this.clearSound.pause();
    this.betSound.pause();
    this.takeSound.pause();
    this.winSound.pause();
    this.duploseSound.pause();

    this.RightButtonPanel.current.clearTimer(this.props.timesend);
    this.props.action(game);
  }

  wheelHandler(position) {
    this.glowImage();

    if (this.props.targetResult.winAmount > 0) {
      // alert('win')
      document.getElementById('winPop').style.display = 'block';
      document.getElementById('winSorat').classList.add('winSorat');
      setTimeout(() => {
        document.getElementById('winSorat').classList.remove('winSorat');
        document.getElementById('winPop').style.display = 'none';
        this.rightButtonPanelHandler("TAKE")
      }, 3000);

      this.setState({
        disDoubleBtn: 1,
        disClearBtn: 1,
        disZoomBtn: 1,
        winAmount: this.props.targetResult.winAmount,
        infoText: this.props.language.WonHand,
        Total_Bet_Amount: this.props.targetResult.betAmount,
      });
      setTimeout(() => {
        this.declareWin(this.props.targetResult.winAmount);
      }, 1000)
      document.getElementById("funSoratCover").style.display = "block";
    } else {
     
      //if (this.takeAmount == 0) {
        // this.duploseSound.play(); 
        this.updateWinsArray(0);
        document.getElementById("funSoratCover").style.display = "none";
        console.log('Cover none ----  3')
        var valId = document.getElementById("bet_take");
        valId.classList.remove("active");
        console.log("----- winAmount 4 " + 0);
        this.setState({ winAmount: 0, betButtonText:this.props.language.Prev, betButtonValue:0, infoText: this.props.language.Play_Njoy });
        if (this.props.gameState.betRequest) {
          this.setState({ disBetBtn: 0 })
        } else {
          this.setState({ disBetBtn: 1 })
        }
     // }

    }
    document.getElementById("funSoratCover").style.zIndex = "999";
    if (this.props.gameState.handId) {
      this.handId = this.props.gameState.handId;
    }
  }

  updateGameState(){
    console.log("Sorat Timer Update GameState: ")
    if(this.props.gameState.betRequest){
        this.setState({
          disBetBtn : 0,
        })
    }else{
        this.setState({
          disBetBtn : 1,
        })
    }
}

  showPrevBtn() {
    this.setState({
      showBetButton: true,
      betButtonText: this.props.language.Prev,
      betButtonValue:0,
    });
    var valId = document.getElementById("bet_take");
    valId.classList.remove("active");
  }

  declareWin(value) {
    // this.getPlayerInfo();
    console.log("Funsorat: DeclareWIN: value: " + value);
    if (value > 0) {
      var valId = document.getElementById("bet_take");
      valId.classList.add("active");
    } else {
      this.betAmount = 0;
      this.setState({
        showBetButton: false,
        infoText:  this.props.language.Play_Njoy ,
        balance: this.props.balance,
        totalBet: this.betAmount,
        disBetBtn: 0,
      });
      if (document.getElementById("funSoratCover")) {
        document.getElementById("funSoratCover").style.display = "none";
      }
      this.rightButtonPanelHandler("clear");
    }
    this.isReady = false;

  }

  getPlayerInfo(){
    const body2 = {
        CN: "GET_PLAYER_INFO",
        object: {
            gameId: Number(this.state.gameId),
            playerId: Number(this.props.playerId),
        },
        sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 ={};
  }

  rightButtonPanelHandler(action, value) {
    switch (action) {
      case "BET OK":
        this.state.totalBet = this.state.nmArray.reduce((prev, cur) => prev + cur.amount, 0);
        console.log(this.state.totalBet);
        console.log(this.props.minBetAmt);
        if(this.state.totalBet != 0 && this.state.totalBet < this.props.minBetAmt){
          this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
        }else{ 
          document.getElementById("bet_take").classList.remove("active");
         
          if (this.state.zoomButtonValue == 0) {
            this.setState({ zindex: "600" });
          }
          //document.getElementById("zoomBtn").disabled = true; 
          console.log("Bet String");
          console.log(this.state.nmArray);
          this.state.totalBet = this.state.nmArray.reduce((prev, cur) => prev + cur.amount, 0);

          if (this.isReady) {            
            
            let betValid = [];
            this.state.betstr = "";
            this.state.bets = "";
            console.log("Total Bets: " + this.state.totalBet);
            if (this.props.balance >= this.state.totalBet) {
              let innerMin_Limit = 1;
              for (let i = 0; i < this.state.nmArray.length; i++) {

                // let innerMin_Limit = 1;
                if(i<=12){                  
                  innerMin_Limit = this.newLimits[0][0]; 
                }else if(i >= 13 && i <= 32){
                  innerMin_Limit = this.newLimits[1][0];                      
                }else if(i >= 33 && i <= 38){
                  innerMin_Limit = this.newLimits[2][0];                      
                }else if(i >= 39 && i <= 48){
                  innerMin_Limit = this.newLimits[3][0];            
                }
                
                if(this.state.nmArray[i].amount > 0){
                  console.log(this.state.nmArray[i].amount)
                  console.log(innerMin_Limit)
                  if(this.state.nmArray[i].amount >= innerMin_Limit){
                     console.log("OK")
                    betValid[i] = true;
                  }else{
                    betValid[i] = false;
                     console.log("dont place the bet.")
                  }
                }else{
                  betValid[i] = true;
                }

                if (this.state.nmArray[i].id == 48 || this.state.nmArray[i].id == 53) {
                  this.state.bets += this.state.nmArray[i].amount + ",";
                } else if (this.state.nmArray[i].id == 49 || this.state.nmArray[i].id == 50 || this.state.nmArray[i].id == 51 || this.state.nmArray[i].id == 54 || this.state.nmArray[i].id == 55 || this.state.nmArray[i].id == 56 || this.state.nmArray[i].id == 57 || this.state.nmArray[i].id == 58 || this.state.nmArray[i].id == 59 || this.state.nmArray[i].id == 60 || this.state.nmArray[i].id == 61 || this.state.nmArray[i].id == 62 || this.state.nmArray[i].id == 64) {
                  this.state.bets += " " + ",";
                } else if (this.state.nmArray[i].id == 13 || this.state.nmArray[i].id == 33 || this.state.nmArray[i].id == 39) {
                  this.state.bets = this.state.bets.slice(0, -1);
                  this.state.bets += "," + this.state.nmArray[i].amount + "|";
                } else  if (this.state.nmArray[i].id < 63) {
                  this.state.bets += this.state.nmArray[i].amount + "|";
                }
                // else{
                //   this.state.bets += this.state.nmArray[i].amount + " ";
                // }
              }
              console.info("betValid: "+betValid);
              let sendBet = true;;
              for(let u=0; u<betValid.length;u++){
                if(betValid[u] ==  false){
                  sendBet = false;
                  // console.log("Dont send bet reques");
                  break;
                }
              }
              
              console.log(this.state.nmArray);
              console.info(this.state.bets);
              if(sendBet){
                this.setState({
                  disDoubleBtn: 1,
                  disBetBtn: 1,
                  disClearBtn: 1,
                  disZoomBtn: 1,
                });
                this.isReady = false;
                this.placebet();
                document.getElementById("funSoratCover").style.display = "block";
              }else{
                console.log("Dont Send Bet Request.")
                const minIntLmt = this.changeAmtlabl(innerMin_Limit)
                this.setState({
                   infoText: this.props.language.InerLmt + minIntLmt });                
              }
              // this.props.updatePlayerBalance();
            } else {
              //alert('amount reduces to your balance');
            }
          }

          console.log("Bet OK ");
          console.log(this.state.totalBet);
          console.log(this.takeAmount);

          if (this.state.totalBet > 0) {
            if (this.takeAmount > 0) {
              // this.setState({ infoText: this.props.infoText });
            }
          } else {
            //this.props.spectatorCount(this.state.Total_Bet_Amount, this.handId); //Check Spectator
            if (this.takeAmount == 0) {
              this.setState({ infoText:  this.props.language.Play_Njoy  });
            }
          }
        }

        break;
      case "ZoomON":
        if(document.getElementById('iospopup').style.display == "none"){
          this.betSound.play();
        }
        //this.setState({ zoomButtonText: "Wheel Zoom: OFF", wheelZoom: 1, btnZindex:"1500", zindex: "600", x_img: 1});
        this.setState({ zoomButtonText:this.props.language.ZoomO_FF,zoomButtonValue:1 });

        break;
      case "ZoomOFF":
        if(document.getElementById('iospopup').style.display == "none"){
          this.betSound.play();
        }
        //this.setState({ zoomButtonText: "Wheel Zoom: ON", wheelZoom: 1, btnZindex:"0", zindex: "0"});
        this.setState({ zoomButtonText:this.props.language.Zoom_ON,zoomButtonValue:0 });
        break;
      case "TAKE":
        if(document.getElementById('iospopup').style.display == "none"){
          this.takeSound.play();
        }
        console.log("take amount action");
        var valId = document.getElementById("bet_take");
        valId.classList.remove("active");

        for (var i = 0; i < this.state.nmArray.length; i++) {
          var valId = document.getElementById("id_" + i);
          if (valId) {
            valId.classList.remove("active");
          }
        }
        this.takeAmountResult();
        break;
      case "double":
        this.betSound.play();
        this.updateWinsArray(2);
        break;
      case "clear":
        if (this.clearSound) {
          console.log(this.clearSound);
          document.getElementById("bet_take").classList.remove("active");
          if(document.getElementById('iospopup').style.display == "none"){
            this.clearSound.play();
          }
        }
        this.updateWinsArray(0);
        this.setState({ betButtonText: this.props.language.Prev,betButtonValue:0, disClearBtn: 1, disDoubleBtn: 1 });
        console.log("funSoratCover  416");
        document.getElementById("funSoratCover").style.display = "none";
        if (this.props.gameState.betRequest) {
          this.setState({ disBetBtn: 0 });
        } else {
          this.setState({ disBetBtn: 1 });
        }
        break;
      case "PREV":
        this.betSound.play();
        this.removeImgGlow();
        this.showPrevBet();
        break;
      case "exit":
        this.gameCloseHandler("target");
        const body = {
          sessionId: sessionStorage.getItem('sessionId'),
          CN: "EXIT_GAME",
          object: {
            gameId: this.props.gameId,
            playerId: Number(this.props.playerId),
          },
        };
        this.props.network.sendof(body);
        break;
      case "disableBtns":
        //this.soratTableRef.mouseUpStopCards();//current
        this.removeImgGlow();
        this.setState({
          disDoubleBtn: 1,
          disBetBtn: 1,
          disClearBtn: 1,
          disZoomBtn: 1,
          btnActive: false,
        });
        console.log("disableBtns");
        console.log(this.takeAmount)
        if (this.props.infoText) {
          if (this.takeAmount > 0) {
            // this.setState({ infoText: this.props.infoText });
          }
        } else {
          if (this.takeAmount == 0) {
            this.setState({ infoText:  this.props.language.Play_Njoy  });
          }
        }
        // console.log('Cover block ---- disableBtns' )
        document.getElementById("funSoratCover").style.display = "block";
        break;
      case "enableBtns":
        this.setState({
          disDoubleBtn: 0,
          disBetBtn: 0,
          disClearBtn: 0,
          disZoomBtn: 0,
        });
        break;
        case "PlayTickSound":
          if(document.getElementById('iospopup').style.display == "none"){
            this.tickSound.play();
          }
        break;
        case "StopTickSound":
          if(document.getElementById('iospopup').style.display == "none"){
            this.tickSound.pause();
          }
        break;
      default:
        break;
    }
  }

  removeImgGlow() {
    for (var i = 0; i < this.state.nmArray.length; i++) {
      var valId = document.getElementById("id_" + i);
      if (valId) {
        valId.classList.remove("active");
      }
    }
    this.removeGlow = false;
  }

  takeAmountResult() {
    console.log("Take Amount Function");
    console.log("Remove Img Glow: " + this.resultNumber);
    console.log("----- winAmount 6 " + 0);
    this.setState({
      disDoubleBtn: 0,
      disBetBtn: 0,
      disClearBtn: 0,
      disZoomBtn: 0,
      glowTakeBtn: 0,
      btnActive: false,
      betButtonText: this.props.language.Prev,
      betButtonValue:0,
      totalBet: 0,
      winAmount: 0,
      placeBetState: false
    });
    this.takeAmount = 0;
    var valId = document.getElementById("bet_take");
    valId.classList.remove("active");
    document.getElementById("funSoratCover").style.display = "none";
    console.log('Cover none ----  6')

    this.setState({ infoText:  this.props.language.Play_Njoy , showBetButton: false });
    this.updateWinsArray(0);

    const body = {
      sessionId: sessionStorage.getItem('sessionId'),
      CN: "TAKE_AMOUNT",
      object: {
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
      },
    };
    // this.props.network.sendof(body);
    this.body = {};

    console.log("isPlayerBanned " + this.props.isPlayerBanned);
    if (this.props.isPlayerBanned) {
      this.gameCloseHandler("target");      
      this.props.player_banned();
    }
    console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
    if (this.props.gameUnderMaintanance) {
      this.gameCloseHandler("target");
      this.props.game_maintanance("Game under maintenance, reach to admin.");
    }

    console.log("deviceBlocked " + this.props.isdeviceBlocked);
    if (this.props.isdeviceBlocked) {
      this.gameCloseHandler("target");
      this.props.device_blocked();
    }
  }
  updateWinsArray(num) {
    console.info("Update Bet:::::::::::::::::::::: " + num);
    // console.log(this.state.nmArray);
    let totBet = 0;
    if (num == 2) {
      if(this.state.Total_Bet_Amount*2 > this.props.maxBetAmt){
        this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});

      }else if(this.state.Total_Bet_Amount*2 <= this.props.balance){
        let innerMaxLimit = 1;
        
        for (let k = 0; k < this.state.nmArray.length; k++) {
          // console.log(totBet);
          
          if(k<=12){
            console.log(this.newLimits[0][1]);
            innerMaxLimit = this.newLimits[0][1];
          }else if(k >= 13 && k <= 32){
            innerMaxLimit = this.newLimits[1][1];                      
          }else if(k >= 33 && k <= 38){
            innerMaxLimit = this.newLimits[2][1];                      
          }else if(k >= 39 && k <= 48){
            innerMaxLimit = this.newLimits[3][1];            
          }
          // console.log(this.state.nmArray[k].amount)
        //  console.log(innerMaxLimit)
          if (this.state.nmArray[k].amount > 0 && this.state.nmArray[k].amount <= Number(innerMaxLimit) && totBet <= this.props.balance) {
            // console.log("***************************")
            if (this.state.nmArray[k].amount * num <= innerMaxLimit) {
              if (this.state.Total_Bet_Amount + this.state.nmArray[k].amount <= this.props.balance) {
                this.state.Total_Bet_Amount = this.state.Total_Bet_Amount + this.state.nmArray[k].amount;
                this.state.nmArray[k].amount = this.state.nmArray[k].amount * num;
                let doubleBetValue = this.state.nmArray[k].amount;
                if(doubleBetValue >= 1000 && doubleBetValue <=999999){
                  doubleBetValue = doubleBetValue / 1000+"K";
                  console.log(doubleBetValue);
                }else if(doubleBetValue >= 1000000 && doubleBetValue <=999999999){
                  doubleBetValue = doubleBetValue / 1000+"M";
                  console.log(doubleBetValue);
                }else if(doubleBetValue >999999999){
                  doubleBetValue = doubleBetValue / 1000+"B";
                  console.log(doubleBetValue);
                }                
                document.getElementById("id_" + k).innerHTML = `<p class="goldCoin_sorat"> <img src=${coin} /> <span> ${doubleBetValue} </span> </p>`;
              }
            }else{
              console.log(innerMaxLimit);
              this.setState({infoText: this.props.language.MaxInrBetAmt+this.changeAmtlabl(innerMaxLimit)});
            }
            totBet += this.state.nmArray[k].amount;
            this.setState({
              Total_Bet_Amount: totBet,
            });
          }else{
           
          }
        }  
        if (totBet <= this.props.balance) { 
          this.setState({
            disDoubleBtn: 0,
            disClearBtn: 0,
            disZoomBtn: 0,
            //infoText:  this.props.language.Play_Njoy ,
          });  
        } else {
          this.isReady = false;
          this.setState({
            disDoubleBtn: 1,
            disClearBtn: 0,
            disZoomBtn: 0,
            infoText:this.props.language.LowBalance,
          });  
        }
      }else{
        //this.isReady = false;
        this.setState({
          disClearBtn: 0,
          disZoomBtn: 0,
          infoText: this.props.language.LowBalance ,
        });
      }
    }else{
      for (let a = 0; a <= 47; a++) {
        if (this.state.nmArray[a].amount > 0) {
          this.state.nmArray[a].amount = 0;
          document.getElementById("id_" + a).innerHTML = "";
        }
        totBet += this.state.nmArray[a].amount;
      }

      this.setState({
        nmArray: this.state.nmArray,
        disDoubleBtn: 1,
        disClearBtn: 1,
        disZoomBtn: 0,
        infoText: this.props.language.Play_Njoy,
        Total_Bet_Amount: totBet,
      });
    }    
  }

  showPrevBet() {
    // console.log("Prev Bet : "+this.state.nmArray);
    console.log("Funsorat Prev Bet Amount  " + this.props.gameState.betRequest.betAmount);
    console.log("Player Balance: " + this.props.balance);
    if (this.props.gameState.betRequest.betAmount <= this.props.balance) {
      if(this.props.gameState.betRequest.betAmount > this.props.maxBetAmt){
        this.setState({ infoText:this.props.language.PreBet_MaxLmt });
      }else{      
        console.log(this.state.Total_Bet_Amount);
        if (this.state.Total_Bet_Amount === 0) {
          let prevBetString = [];
          
          document.getElementById("bet_take").classList.add("active");

          console.log("Prev Bet String Length: ");
          console.log(this.props.gameState);
          console.log(this.props.gameState.betRequest);
          console.log(this.props.gameState.betRequest.betString.length);
          let myArr = this.props.gameState.betRequest.betString.split(",");
          //let  myArr2 = myArr.split(",")
          console.log(this.props.gameState.betRequest.betString);
          var newStr = this.props.gameState.betRequest.betString.replace(/,/g, "|");
          var newStr0 = newStr.slice(0, -1);
          var newStr1 = newStr0.replace(/ /g, "0");
          var newStr2 = newStr1.split("|");

          for (var i = 0; i < newStr2.length; i++) {
            prevBetString.push({
              id: i,
              amount: parseInt(newStr2[i]),
            });
          }

          this.state.nmArray = prevBetString.slice();
          console.log("this.state.nmArray");
          console.log(this.state.nmArray);

        }

        const totalbal = this.state.nmArray.reduce((total, m) => m.amount + total, 0);
        console.log("totalbal "+totalbal);
        console.log("----- winAmount 7 " + 0);
        this.setState({
          Total_Bet_Amount: totalbal,
          winAmount: 0,
          disClearBtn: 0,
          disDoubleBtn: 0,
        });

        for (let i = 0; i < this.state.nmArray.length; i++) {
          let preBetValue = this.state.nmArray[i].amount;
          if (preBetValue > 0) {
            //console.log("Bet Value: 488: " + value);
            if(preBetValue >= 1000 && preBetValue <=999999){
              preBetValue = preBetValue / 1000+"K";
              console.log(preBetValue);
            }else if(preBetValue >= 1000000 && preBetValue <=999999999){
              preBetValue = preBetValue / 1000+"M";
              console.log(preBetValue);
            }else if(preBetValue >999999999){
              preBetValue = preBetValue / 1000+"B";
              console.log(preBetValue);
            }
            document.getElementById("id_" + i).innerHTML = `<p class="goldCoin_sorat"> <img src=${coin} /> <span> ${preBetValue} </span> </p>`;
          }
        }
        if (this.state.betButtonValue == 0) {
          this.setState({
            showBetButton: true,
            betButtonText: this.props.language.BetOK,
            btnActive: true,
            betButtonValue:1,
          });

          this.isReady = true;
        }
        this.setState({
          nmArray: this.state.nmArray,
        });
        if (this.props.gameState.handId) {
          this.handId = this.props.gameState.handId;
        }

        console.log(this.state.nmArray);
      }
    } else {
      this.setState({ infoText:this.props.language.Bet_Amt_MaxBal });
    }
  }

  placebet() {
    this.removeImgGlow();
    const body = {
      sessionId: sessionStorage.getItem('sessionId'),
      CN: "FUN_SORAT_BET",
      object: {
        betString: this.state.bets,
        playerId: Number(this.props.playerId),
        gameId: this.props.gameId,
      },
    };
    console.log(body)
    this.props.network.sendof(body);
    this.body = {};
    this.setState({ placeBetState: true })
  }


  funSoratTimerGetwheelresp() {
    //console.log('Cover block ---- funSoratTimerGetwheelresp' ) 
    document.getElementById("funSoratCover").style.display = "block";

    if (this.props.targetResult) {
      this.bonusValue = this.props.targetResult.bonusX;
      console.log("Funsorat Result winAmount: " + this.props.targetResult.winAmount);
      console.log("Funsorat Timer Bonus Value: " + this.bonusValue);
      console.log("Funsorat Result Number: " + this.props.targetResult.resultNum);
      this.resultNumber = this.props.targetResult.resultNum;
      this.setState({ x_img: 1 });
      this.soratWheelRef.current.sendimg(this.state.x_img);
      // this.rightButtonPanelHandler("ZoomON");
      if (this.state.zoomButtonValue == 0) {
        this.setState({ wheelZoom: 1, zindex: "600" });

        setTimeout(() => {
          this.setState({ wheelZoom: 1, zindex: "0", placeBetState: false });
        }, 7500);
      }
      this.soratWheelRef.current.spinTheWheel(this.resultNumber);
      if(document.getElementById('iospopup').style.display == "none"){
        //alert(this.wheelSound.duration);
        //7.053061        
        if(this.isMobile || this.isIphone){
          this.wheelSound.currentTime = 2;          
        }
        this.wheelSound.play();
      }
    }
  }

  glowImage() {
    console.log("Glow Win Image: " + this.props.gameState);
    //this.declareWin(this.props.targetResult.winAmount);
    this.removeImgGlow();
    if (this.props.targetResult.winAmount > 0) {
      this.takeAmount = this.props.targetResult.winAmount;
      if(document.getElementById('iospopup').style.display == "none"){
        this.winSound.play();
      }

      this.getPlayerInfo();
    } else {
      if(document.getElementById('iospopup').style.display == "none"){
        this.duploseSound.play();
      }
      if (this.state.winAmount == 0) {
        setTimeout(() => {
          this.clearBetList();
          this.getPlayerInfo();
        }, 1500)
      }
    }

    var valId = document.getElementById("id_" + this.resultNumber);
    if (valId) {
      var getId = valId.id.replace("id_", "");
      // console.info( getId )
      if (getId == this.resultNumber) {
        valId.classList.add("active");
        this.removeGlow = true;
      }
    }

   
    this.props.gamstateHandle();
  }


  clearBetList() {
    //console.log("clearBetList");
    for (var i = 0; i < this.state.betString.length; i++) {
      if (document.getElementById("id_" + i)) {
        document.getElementById("id_" + i).innerHTML = ``;
        this.state.betString[i].amount = 0;
      }
    }
    this.setState({ Total_Bet_Amount: 0 });
    this.setState({ disDoubleBtn: 1, disClearBtn: 1 });
  }

  funSoratTimerGetTime() {
    //console.log("Funsorat Timer: " + this.props.timesend);
    if (this.props.timesend) {
      this.RightButtonPanel.current.sendSoratTimer(this.props.timesend);
    }
  }
  chipVal(val, label) {
    this.takeSound.play();
    console.log("Selected Chip lable: "+label);
    this.state.selectedChipVal = val;
    // this.state.selectedChipLbl = label;
    this.setState({
      betAmount: val,
      //infoText: `Your Bet Amount Is: ${val} & You Are Ready To Go`
    });
  }


  benohandle(id) {
    this.removeImgGlow();

    this.betSoundOne = new Audio(betSound);
    this.betSoundOne.play();
    console.info("Selected Chip Value: " + this.state.selectedChipVal);
    console.info("Selected ID Value: " + id);
    if (this.state.selectedChipVal == 0) {
      this.state.nmArray[id].amount = 0;
      document.getElementById("id_" + id).innerHTML = ``;
      this.setState({infoText:this.props.language.MinBetAmt+this.changeAmtlabl(Number(this.props.gameState.betRequest.betAmount))})
    }
    console.log(this.state.nmArray);
    const totalbet = this.state.nmArray.reduce((total, m) => m.amount + total, parseInt(this.state.selectedChipVal));
    console.log("totalbet " + totalbet);
    console.log("this.props.balance " + this.props.balance);
    console.log("Max Bet Amount: "+this.props.maxBetAmt);

    if(totalbet > this.props.maxBetAmt){
      this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
      // this.setState({infoText: "Min Bet "+this.props.minBetAmt+" and Max Bet "+this.props.maxBetAmt});
    }else if (totalbet <= this.props.balance) {
        if (this.state.nmArray[id] != null && this.state.nmArray[id].amount != null) {
          console.log("______________________________________________");
          console.log(this.state.nmArray[id].amount + parseInt(this.state.selectedChipVal));
          console.log("______________________________________________");
          
          let innerLimit = 1;
          console.log(this.newLimits);
          try 
          {
            if(id<=12){
              console.log(this.newLimits[0][1]);
              innerLimit = this.newLimits[0][1];
            }else if(id >= 13 && id <= 32){
              innerLimit = this.newLimits[1][1];                      
            }else if(id >= 33 && id <= 38){
              innerLimit = this.newLimits[2][1];                      
            }else if(id >= 39 && id <= 48){
              innerLimit = this.newLimits[3][1];            
            }
          } catch (err)
          {
            console.log("error while trying to fetch inner limits: ArayOutOfBoundsException "+ err.message);
          }
          console.log("FST Inner Limit:  "+innerLimit)

          if ((this.state.nmArray[id].amount + parseInt(this.state.selectedChipVal)) <= innerLimit) {
            this.isReady = true;
            this.setState({
              Total_Bet_Amount: totalbet,
              disClearBtn: 0,
              disDoubleBtn: 0,
            });
            if (totalbet > 0) {
              this.setState({ placeBetState: true, betButtonText:this.props.language.BetOK,betButtonValue:1, disBetBtn: 0 });
              document.getElementById("bet_take").classList.add("active");              
            } else {
              document.getElementById("bet_take").classList.remove("active");
              this.setState({ disClearBtn: 1, disDoubleBtn: 1 });
              this.setState({ betButtonText: this.props.language.Prev,betButtonValue:0 })
              console.log(this.props.gameState)
              if (this.props.gameState.betRequest) {
                this.setState({ disBetBtn: 0});
              } else {
                this.setState({ disBetBtn: 1})
              }
            }
            for (let i = 0; i < this.state.nmArray.length; i++) {
              if (this.state.nmArray[i].id == id) {
                let chipBetValue = (this.state.nmArray[i].amount += parseInt(this.state.selectedChipVal));
                console.log("Bet Value: 699: " + chipBetValue);
                console.log("Bet Value: 699: " + typeof(chipBetValue));
                if(Number(chipBetValue) >= 1000 && Number(chipBetValue) <=999999){
                  chipBetValue = chipBetValue / 1000+"K";
                  console.log(chipBetValue);
                }else if(chipBetValue >= 1000000 && chipBetValue <=999999999){
                  chipBetValue = chipBetValue / 1000+"M";
                  console.log(chipBetValue);
                }else if(chipBetValue >999999999){
                  chipBetValue = chipBetValue / 1000+"B";
                  console.log(chipBetValue);
                }

                if (this.state.selectedChipVal != 0) {
                  document.getElementById("id_" + i).innerHTML = `<p class="goldCoin_sorat"> <img src=${coin} /> <span> ${chipBetValue} </span> </p>`;
                } else {
                  document.getElementById("id_" + i).innerHTML = ``;
                }
              }
              //console.log("this.removeGlow "+this.removeGlow);
              if (this.removeGlow == true) {
                var valId = document.getElementById("id_" + i);
                if (valId) {
                  valId.classList.remove("active");
                  this.removeGlow = false;
                }
              }
            }
          }else{            
            this.setState({infoText: this.props.language.MaxInrBetAmt+this.changeAmtlabl(innerLimit)});
          }
          if(this.state.selectedChipVal == 0 && this.state.Total_Bet_Amount ==0){
            this.setState({infoText:this.props.language.MinBetAmt+this.changeAmtlabl(this.props.minBetAmt) })
            setTimeout(()=>{
                this.setState({ infoText:  this.props.language.Play_Njoy });
              },1500)
          }else{
              // this.setState({ infoText:  this.props.language.Play_Njoy });
          }
        
      } else {
        this.setState({ infoText: this.props.language.LowBalance});
        
        console.log("amount reduces to your balance");
        //alert('amount reduces to your balance');
      }
    }else {
      this.setState({ infoText: this.props.language.LowBalance  });
      console.log("amount reduces to your balance");
    }

    this.removeGlow = false;


    this.setState({
      nmArray: this.state.nmArray,
    });
    // var valId = document.getElementById("bet_take");
    // valId.classList.remove("active");

    // console.log(this.state.nmArray);
  }

  historyUpdate() {
    console.log('hello history')
    this.RightButtonPanel.current.historyData();
  }
  rightButtonPanelImageHandler() { 
      this.setState({ x_img: 0 });
      this.soratWheelRef.current.sendimg(0);
  }

  loadBg() {
    this.LoadImg = new window.Image()
    this.LoadImg.src = artBg;
    this.LoadImg.addEventListener('load', this.soratLoded)
  }

  soratLoded = () => {
    if (!this.isloadImg) {
      this.isloadImg = true;
    } else {
      this.setState({
        soratLoader: false
      })
    }
  }

  closeWin() {
    document.getElementById('iospopup').style.display="none";
    this.takeSound = new Audio(takeSound);
    this.winSound = new Audio(winSound);
    this.duploseSound = new Audio(duploseSound);
    this.tickSound = new Audio(tickSound);
    this.wheelSound = new Audio(wheelSound);
  }
  render() {
    //console.log("Render Sorat Game")
    let winAmount = this.changeAmtlabl(this.state.winAmount)
    return (
      <React.Fragment>
        <div className="gameBox" id="gameBox">
          <div className="gamePage"
            style={{
              background: `linear-gradient(#002960, #004987, #022053)`
            }}
          >
            <div className="iosFetchSounds" id="iospopup" onClick={(e) => this.closeWin()}>
              <div className="popupTooLong">
                <div className=""> {this.props.language.Wel_ST} </div>
                <br></br>
                {this.props.language.OK_Btn}
               
                <br></br>
                <br></br>
                <div className="fd " >                    
                  <button className="btn_iosPopup" > 
               {this.props.language.Ok}</button>
                </div>
              </div>
            </div>
            <div className="FGAfunSoratTimer">
              <div className="fd sorat_ht_1 p_30">
                <div className="row">
                  <div className="fd">
                    <div className="fd">
                      <div className="fd">
                      </div>
                      <div className="fd">
                        <div className="inSoratTable">
                          <Title className="fd"></Title>
                          <FunSoratTable
                            childRef ={ref => (this.FunSoratTable = ref)}  
                            winAmount={this.state.winAmount}
                            TotalBetAmouont={this.state.Total_Bet_Amount}
                            balance={this.props.balance}
                            totalBet={this.state.nmArray}
                            chipMoeny={this.state.selectedChipVal}
                            betString={this.state.betString}
                            action={this.benohandle.bind(this)}
                            ref={this.soratTableRef}
                            infoTxt={this.state.infoText}
                            fst_minBet={this.props.fst_minBet}
                            fst_maxBet={this.props.fst_maxBet}
                            fst_innerLimits={this.props.fst_innerLimits}>
                          </FunSoratTable>
                          <div className="F_Sorat_ins">
                            {this.state.infoText}
                          </div>
                          <div className="fd">
                            <ul className="soratHidPid">
                              <li>
                              {this.props.language.Player_Id}: {this.props.playerId}
                              </li>
                              <li>
                              {this.props.language.Hand_Id}: {this.props.gameState.handId}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="inSoratWheel">
                          <div className="fd">
                            <RightButtonPanel
                              childRef ={ref => (this.RightButtonPanel = ref)}  
                              user={this.props.user}
                              betBtnText={this.state.betButtonText}
                              zoomButtonText={this.state.zoomButtonText}
                              btnZindex={this.state.btnZindex}
                              betBtn={this.state.showBetButton}
                              action={this.rightButtonPanelHandler.bind(this)}
                              placeBetState={this.state.placeBetState}
                              gameState={this.props.gameState}
                              ref={this.RightButtonPanel}
                              disDoubleBtn={this.state.disDoubleBtn}
                              disBetBtn={this.state.disBetBtn}
                              disZoomBtn={this.state.disZoomBtn}
                              disClearBtn={this.state.disClearBtn}
                              glowTakeBtn={this.state.glowTakeBtn}
                              bonusVal={this.bonusValue}
                              network={this.props.network}
                              timesend={this.props.timesend}
                              gameId={this.props.gameId}
                              playerId={this.props.playerId}
                            ></RightButtonPanel>
                          </div>
                          <div className="fd">
                            <Wheel
                              soratLoded={this.soratLoded.bind(this)}
                              gameState={this.props.gameState}
                              x_img={this.state.x_img}
                              ref={this.soratWheelRef}
                              zoom={this.state.wheelZoom}
                              height={this.wheelHeight}
                              width={this.wheelWidth}
                              ImageHandel={this.rightButtonPanelImageHandler.bind(this)}
                              action={this.wheelHandler.bind(this)}
                              isMobile={this.props.isMobile}
                              isIphone = {this.props.isIphone}
                              zindex={this.state.zindex}
                              lastResNum={this.props.gameState.response ? this.props.gameState.response.resultNum : ""}
                              bonusVal={this.bonusValue}
                              onFocus={this.props.onFocus}>
                            </Wheel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fd m_t_10">
                    <div className="row">
                      <div className="col-6 indSoratChips">
                        <ChipSelection
                          betAmount={this.chipVal.bind(this)}
                          disClearBtn={this.state.disClearBtn}                          
                          fst_chipValues={this.props.fst_chipValues}
                          >
                        </ChipSelection>
                      </div>
                      <div className="col-6 indSoratBtns">
                        <ButtonsList
                          childRef ={ref => (this.ButtonsList = ref)}  
                          betBtnText={this.state.betButtonText}
                          betButtonValue={this.state.betButtonValue}
                          betBtn={this.state.showBetButton}
                          actionClear={this.rightButtonPanelHandler.bind(this)}
                          action={this.rightButtonPanelHandler.bind(this)}
                          placeBetState={this.state.placeBetState}
                          disDoubleBtn={this.state.disDoubleBtn}
                          disBetBtn={this.state.disBetBtn}
                          disZoomBtn={this.state.disZoomBtn}
                          disClearBtn={this.state.disClearBtn}
                          glowTakeBtn={this.state.glowTakeBtn}
                          bonusVal={this.bonusValue}
                          network={this.props.network}
                          timesend={this.props.timesend}
                          gameId={this.props.gameId}
                          playerId={this.props.playerId}
                          gameEntry={this.props.gameEntry}>
                        </ButtonsList>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="winPop" id="winPop">
                <div className="popBorder">
                  {this.props.language.Win_Amt} <br></br>
                  <span> {winAmount} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatesToProps = (state)=>{
  return {
    language : state.languageObjs.languageObj
  }
}
export default connect(mapStatesToProps)(FunSoratTimer) 

