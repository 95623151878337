import React from "react";
import Wheel from "./UI/wheel";
import RightButtonPanel from "./UI/rightButtonPanel";
import AmericanTimerInSeconds from "./UI/american_TimerSec";
import artBg from "../../../../../assets/games/americanRouletteTimer/art_bg.png";
import "../../../../css/all_roulette_tamplate.css";
import "../../../../css/games/americanRouletteTimer/rightButtonPanel.css";
import "./UI/americanTimer.css";
import Close from "./UI/close";
import Title from "./UI/title";
import Table from "./UI/Table";
import BetChips from "./UI/betChips";
import coin from "../../../../../assets/gold_coin.svg";
import user from '../../../../../assets/login/user.svg';

import clearSound from "../../../../../assets/sounds/clear.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../assets/sounds/take.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import tickSound from "../../../../../assets/sounds/tick.mp3";
import wheelSound from "../../../../../assets/sounds/wheelStart.mp3";

import { connect } from "react-redux";

class AmericanRouletteTimer extends React.Component {
  constructor(props) {
    super(props);
    this.artWheelRef = React.createRef();
    this.AmericanTimerInSeconds = React.createRef();
    this.RightButtonPanel = React.createRef();
    this.amrTm_Ref = React.createRef();
    this.wheelWidth = window.innerHeight * 1;
    this.wheelHeight = window.innerHeight * 0.4;
    this.state = {
      previousBetArray: [],
      finalPreviousBetArray: [],
      balance: this.props.balance,
      gameId: this.props.gameId,
      playerId: this.props.playerId,
      zindex: "",
      betButtonText: this.props.language.Prev,
      betButtonValue: 0,
      zoomButtonText: this.props.language.Zoom_ON,
      zoomButtonValue: 0,
      showBetButton: false,
      wheelZoom: 1,
      stop: 0,
      betString: [],
      Total_Bet_Amount: 0,
      nmArray: [],
      betAmount: 1,
      totalBet: 0,
      betstr: "",
      bets: "",
      infoText: this.props.language.Play_Njoy,
      targetResult: props.targetResult,
      selectedChipVal: 1,
      // selectedChipLbl:"",
      wheelZoomClick: true,
      disDoubleBtn: 0,
      disBetBtn: 0,
      disClearBtn: 0,
      disZoomBtn: 0,
      glowTakeBtn: 0,
      btnActive: false,
      winAmount: 0,
      winCover: false,
      amrTmLoader: true,
    };
    this.newLimits = [];
    // this.betValid = [];
    this.resultNumber = 0;
    this.isReady = false;
    this.removeGlow = false;
    this.handId = 0;
    this.takeAmount = 0
    this.isloadImg = false;
    this.loadBg();
  }
  componentDidMount() {
    this.clearSound = new Audio(clearSound);
    this.exitSound = new Audio(exitSound);
    this.betSound = new Audio(betSound);
    this.takeSound = new Audio(takeSound);
    this.winSound = new Audio(winSound);
    this.duploseSound = new Audio(duploseSound);
    this.tickSound = new Audio(tickSound);
    this.wheelSound = new Audio(wheelSound);
    const { childRef } = this.props;
    childRef(this);

    this.preloadImgs();

    if (this.props.isIphone) {
      document.getElementById('iospopup').style.display = 'block';
    } else {
      document.getElementById('iospopup').style.display = 'none';
    }

    document.getElementById('amt_WinPop').style.display = 'none';


    this.resetBetList();

    if (this.props.art_chipValues) {
      let newChips = this.props.art_chipValues.split(",");
      console.log(newChips[0]);
      let initChipVal = newChips[0];
      let lblTxt;
      if (initChipVal >= 1000 && initChipVal <= 999999) {
        lblTxt = "K"
      } else if (initChipVal >= 1000000 && initChipVal <= 999999999) {
        lblTxt = "M"
      } else if (initChipVal > 999999999) {
        lblTxt = "B"
      }

      this.state.selectedChipVal = initChipVal;
      // this.state.selectedChipLbl = initChipVal+""+lblTxt;
    }

    if (this.props.art_innerLimits) {
      console.log(this.props.art_innerLimits)
      this.newLimits = this.props.art_innerLimits.split("|");
      console.log(this.newLimits);
      for (let t = 0; t < this.newLimits.length; t++) {
        this.newLimits[t] = this.newLimits[t].split("-");
        console.log(" *******   " + this.newLimits[t]);
      }

    }

    this.updateMessage();
    console.log("Game State: ");
    console.log(this.props.gameState);
    //alert(this.props.gameState.response.winAmount+"     ___     "+this.props.gameState.response.betAmount);
    if (this.props.gameState.handId == this.props.gameState.oldHandId && this.props.gameState.handId !== undefined) {
      document.getElementById("americanRltTimerCover").style.display = "block";
      let newStr = this.props.gameState.betRequest.betString.replace(/,/g, "|");
      console.info("newStr: " + newStr);
      let newStr1 = newStr.replace(/ /g, "0");
      console.info("newStr1: " + newStr1);
      let newStr2 = newStr1.split("|");
      console.info("newStr2: " + newStr2);
      // var newStr3 = newStr2.slice(0, -1);
      let prevBetString = [];
      for (var i = 0; i < newStr2.length; i++) {
        prevBetString.push({
          id: i,
          amount: parseInt(newStr2[i]),
        });
      }
      //console.log(prevBetString);
      this.state.betString = prevBetString.slice();
      console.log("this.state.betString");
      console.log(this.state.betString);

      for (var i = 0; i < this.state.betString.length; i++) {
        // var value = this.state.betString[i].amount;
        let preBetValue = this.state.betString[i].amount;
        if (preBetValue > 0) {
          if (preBetValue >= 1000 && preBetValue <= 999999) {
            preBetValue = preBetValue / 1000 + "K";
            console.log(preBetValue);
          } else if (preBetValue >= 1000000 && preBetValue <= 999999999) {
            preBetValue = preBetValue / 1000 + "M";
            console.log(preBetValue);
          } else if (preBetValue > 999999999) {
            preBetValue = preBetValue / 1000 + "B";
            console.log(preBetValue);
          }
          document.getElementById("id_" + i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${preBetValue} </span> </p>`
        }
 
      }

      let totBetNum = this.changeAmtlabl(this.props.gameState.betRequest.betAmount);

      this.setState({
        btnActive: false,
        // btnActive: true, 

        betButtonText: this.props.language.BetOK,
        betButtonValue: 1,
        disBetBtn: 1,
        disClearBtn: 1,
        disDoubleBtn: 1,
        Total_Bet_Amount: this.props.gameState.betRequest.betAmount,
        // infoText:this.props.language.BetAccepted+this.props.gameState.betRequest.betAmount,
        infoText: this.props.language.BetAccepted + this.props.gameState.betRequest.totBetNum,

        winCover: true,
        //zoomButtonText: "Wheel Zoom: OFF", wheelZoom: 1.5, zindex: "1000", X_top: "21.8vh", x_img: 1, winCover : true ,
      });

    } else {
      document.getElementById("americanRltTimerCover").style.display = 'none';
      if (document.getElementById("bet_take")) {
        document.getElementById("bet_take").classList.remove("active");
      }
      this.setState({
        betButtonText: this.props.language.Prev,
        betButtonValue: 0,
        disDoubleBtn: 0,
        disBetBtn: 0,
        disClearBtn: 0,
        disZoomBtn: 0,
        winCover: false,
        infoText: this.props.language.Play_Njoy
      });
      console.log(this.props.gameState);
      console.log(this.props.gameState.betRequest)
      if (this.props.gameState.betRequest == undefined) {
        this.setState({ disBetBtn: 1, disClearBtn: 1, disDoubleBtn: 1 });
      }
    }

    if (this.props.gameState.handId) {
      this.handId = this.props.gameState.handId;
    }
    document.addEventListener("keyup", this.art_handleKeyboardEvent);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.art_handleKeyboardEvent);
  }

  preloadImgs() {  
    let imgsLoad = [artBg, coin, user];
    for (let t = 0; t < imgsLoad.length; t++) {
      let art_img = new Image();
      const artImageName = imgsLoad[t];
      art_img.src = artImageName;
      // console.log(spark_img.src);      
    }
  }

  changeAmtlabl(chngAmt) {
    let newAmt = chngAmt;
    if (newAmt >= 1000 && newAmt <= 999999) {
      newAmt = newAmt / 1000 + "K";
    } else if (newAmt >= 1000000 && newAmt <= 999999999) {
      newAmt = newAmt / 1000 + "M";
    } else if (newAmt > 999999999) {
      newAmt = newAmt / 1000 + "B";
    }
    return newAmt;
  }

  art_handleKeyboardEvent = (evt) => {
    console.log(evt.code);
    switch (evt.code) {
      case "Enter":
      case "NumpadEnter":
        if (this.state.betButtonValue == 0 && this.state.disBetBtn == 0) {
          this.rightButtonPanelHandler("PREV");
        } else if (this.state.betButtonValue == 1 && this.state.disBetBtn == 0) {
          this.rightButtonPanelHandler("BET OK");
        }
        break;
      // case "Space":
      //   if (this.state.betButtonValue == 2 && this.state.disBetBtn == 0) {
      //     this.rightButtonPanelHandler("TAKE");
      //   }
      //   break;
      case "Escape":
        //this.rightButtonPanelHandler("exit");
        break;
      case "KeyC":
        if (this.state.betButtonValue == 1 && this.state.disClearBtn == 0) {
          this.rightButtonPanelHandler("clear");
        }
        break;
    }

  }

  resize = () => {
    let h;
    let w;
    if (this.props.isMobile) {
      h = window.innerWidth;
      w = window.innerHeight;
    } else {
      w = window.innerWidth;
      h = window.innerHeight;
    }
    let marg_left = (h * this.dvWid / this.dvHt - w) / 2;
    let marg_top = (w * this.dvHt / this.dvWid - h) / 2;
    let checkWid = h * this.dvWid / this.dvHt;
    if (w > h && w < this.gameBox.clientWidth && h < this.gameBox.clientHeight && w > checkWid) {
      this.gameBox.style.transform = "scale(" + h / this.dvHt + ")";
      this.gameBox.style.marginLeft = marg_left * -1 + "px";
    } else if (w < h && w < this.gameBox.clientWidth && h < this.gameBox.clientHeight) {
      this.gameBox.style.transform = "scale(" + w / this.dvWid + ")";
      this.gameBox.style.marginLeft = marg_left * 1 + "px";
      if (!this.props.isMobile) {
        this.gameBox.style.marginTop = marg_top * -1 + "px";
      }
    } else {
      this.gameBox.style.transformOrigin = "left top";
      this.gameBox.style.transform = "scale(" + w / this.dvWid + ")";
      if (!this.props.isMobile) {
        this.gameBox.style.marginTop = marg_top * -1 + "px";
      }
    }
  }


  updateGameState(gameState) {
    this.RightButtonPanel.updateHistory(); //current
  }
  resetBetList() {
    this.state.betString = [];
    for (var l = 0; l < 171; l++) {
      this.state.betString.push({
        id: l,
        amount: 0,
      });
    }
  }
  updateMessage() {
    console.log("--------- " + this.props.infoText);
    this.setState({
      infoText: this.props.infoText
    });//this.props.infoText

    console.log(this.state.totalBet)

    console.log("error_code: " + this.props.error_code);
    if (this.props.error_code == "game.bet.invalid" || this.props.error_code == "game.bet.rejected.timeOver") {
      this.setState({
        betButtonText: this.props.language.Prev,
        betButtonValue: 0,
        btnActive: false,
        zoomButtonText: this.props.language.Zoom_ON,
        zoomButtonValue: 0,
        wheelZoom: 1,
        zindex: "0",
        X_top: "14.5vh",
        disBetBtn: 0,
        disZoomBtn: 0,
        winCover: false
      });
      this.updateWinsArray(0);
    }
  }
  gameCloseHandler(game) {
    console.log("Exit Game");
    this.exitSound.play();
    this.props.action(game);
    // this.artWheelRef.current.stopWheelSound();
    this.clearSound.pause();
    this.betSound.pause();
    this.takeSound.pause();
    this.winSound.pause();
    this.duploseSound.pause();
  }

  updatePlayerInfo() {
    this.setState({ Total_Bet_Amount: 0 })
  }

  wheelHandler(position) {
    if (position) {
      this.setState({ stop: position });
    }
    this.props.gamstateHandle();
    this.glowImage();
    console.log("Wheel Handle: WinAmount: " + this.props.targetResult.winAmount);
    if (this.props.gameState.handId) {
      this.handId = this.props.gameState.handId;
    }

    if (this.props.targetResult.winAmount > 0) {
      this.setState({
        infoText: this.props.language.WonHand,
        disDoubleBtn: 1,
        disClearBtn: 1,
        disZoomBtn: 1,
        winCover: true,
      });

      setTimeout(() => {
        this.declareWin(this.props.targetResult.winAmount);
      }, 1000);
    } else {
      console.log(this.takeAmount);
      if (this.takeAmount == 0) {
        this.setState({ winAmount: 0, betButtonText: this.props.language.Prev, betButtonValue: 0, infoText: this.props.language.Play_Njoy });
        var valId = document.getElementById("bet_take");
        valId.classList.remove("active");
        this.setState({ betButtonText: this.props.language.Prev, betButtonValue: 0, btnActive: false, winCover: false, disZoomBtn: 0 });
        document.getElementById("americanRltTimerCover").style.display = 'none';

      } else {
        document.getElementById("americanRltTimerCover").style.display = 'block';
        document.getElementById("bet_take").style.zIndex = "1050";
      }
      this.setState({
        showBetButton: false,
        infoText: this.props.language.Play_Njoy,
        // balance: this.props.balance,
        totalBet: 0,
        winAmount: 0,
        disBetBtn: 0,
      });
    }
  }
  showPrevBtn() {
    this.setState({
      showBetButton: true,
      betButtonText: this.props.language.Prev,
      betButtonValue: 0,
      btnActive: false,
    });
    let valId = document.getElementById("bet_take");
    valId.classList.remove("active");
  }
  declareWin(value) {
    console.log("American Roulette Timer: DeclareWIN: value: " + value);

    // this.rightButtonPanelHandler("clear");
    for (let i = 0; i < this.state.betString.length; i++) {
      if (document.getElementById("id_" + i)) {
        document.getElementById("id_" + i).innerHTML = ``;
        this.state.betString[i].amount = 0;
      }
    }          
    this.setState({ disDoubleBtn: 1, disClearBtn: 1, disBetBtn: 0});

    if (this.props.gameState.betRequest == undefined) {
      this.setState({ disBetBtn: 1 });
    }

    if (this.props.targetResult.winAmount > 0) {
      this.setState({
        winAmount: this.props.targetResult.winAmount,
      })
      document.getElementById('amt_WinPop').style.display = 'block';
      setTimeout(() => {
        this.rightButtonPanelHandler("TAKE");
        this.getPlayerInfo();
        this.setState({
          showBetButton: false,
          infoText: this.props.language.Play_Njoy,
          // balance: this.props.balance,
          //balance: (this.state.Total_Bet_Amount + this.props.targetResult.winAmount),
          //totalBet: 0,
          disBetBtn: 0,
        });
        document.getElementById('amt_WinPop').style.display = 'none';
      }, 3000);
    }
    this.isReady = false;
  }
  rightButtonPanelHandler(action, value) {
    switch (action) {
      case "BET OK":
        this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
        if (this.state.totalBet != 0 && this.state.totalBet < this.props.minBetAmt) {
          this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
          // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});

        } else {


          //this.setState({ zoomButtonText: "Wheel Zoom: OFF", wheelZoom: 1.5, zindex: "1000", X_top: "21.8vh", x_img: 1, winCover : true });
          console.log("Bet OK: ");
          var divContain = document.getElementById('roulette');
          divContain.style.transform = "scale(1)";
          divContain.style.zIndex = "1";
          if (this.state.zoomButtonValue == 0) {
            this.setState({ zindex: "1000", winCover: true });
          }
          // document.getElementById("americanRltTimerCover").style.display = "block";//innerLimitMiniBetImplementation
          //document.getElementById("zoomBtn").disabled = true; 

          if (this.takeAmount == 0) {
            this.setState({ betstr: [], bets: [] });
            if (this.state.betString.length > 1) {
              this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
            } else {
              this.state.totalBet = 0;
            }
            console.log(this.isReady);
            if (this.isReady) {

              let betValid = [];
              this.state.bets = "";
              if (this.props.balance >= this.state.totalBet && this.state.totalBet != NaN) {
                //console.log("betString length  " + this.state.betString.length);
                let innerMin_Limit = 1;
                for (let i = 0; i < this.state.betString.length; i++) {



                  if (i <= 37) {
                    innerMin_Limit = this.newLimits[0][0];

                  } else if (i >= 38 && i <= 99) {
                    innerMin_Limit = this.newLimits[1][0];
                  } else if (i >= 100 && i <= 114) {
                    innerMin_Limit = this.newLimits[2][0];
                  } else if (i >= 115 && i <= 137) {
                    innerMin_Limit = this.newLimits[3][0];
                  } else if (i >= 138 && i <= 148) {
                    innerMin_Limit = this.newLimits[5][0];
                  } else if (i >= 154 && i <= 159) {
                    innerMin_Limit = this.newLimits[6][0];
                  } else if (i >= 165 && i <= 170) {
                    innerMin_Limit = this.newLimits[7][0];

                  }

                  if (this.state.betString[i].amount > 0) {
                    console.log(this.state.betString[i].amount)
                    console.log(innerMin_Limit)
                    if (this.state.betString[i].amount >= innerMin_Limit) {
                      console.log("OK")
                      betValid[i] = true;
                    } else {
                      betValid[i] = false;
                      console.log("dont place the bet.")
                    }
                  } else {
                    betValid[i] = true;
                  }


                  if (this.state.betString[i].id == 148 || this.state.betString[i].id == 159) {
                    this.state.bets += this.state.betString[i].amount + ",";
                  } else if (this.state.betString[i].id == 149 || this.state.betString[i].id == 150 || this.state.betString[i].id == 151 || this.state.betString[i].id == 152 || this.state.betString[i].id == 153 || this.state.betString[i].id == 160 || this.state.betString[i].id == 161 || this.state.betString[i].id == 162 || this.state.betString[i].id == 163 || this.state.betString[i].id == 164) {
                    this.state.bets += " " + ",";
                  } else if (this.state.betString[i].id == 38 || this.state.betString[i].id == 100 || this.state.betString[i].id == 115 || this.state.betString[i].id == 137 || this.state.betString[i].id == 138) {
                    this.state.bets = this.state.bets.slice(0, -1);
                    this.state.bets += "," + this.state.betString[i].amount + "|";
                  } else {
                    this.state.bets += this.state.betString[i].amount + "|";
                  }
                }

                console.info("betValid: " + betValid);
                let sendBet = true;;
                for (let u = 0; u < betValid.length; u++) {
                  if (betValid[u] == false) {
                    sendBet = false;
                    // console.log("Dont send bet reques");
                    break;
                  }
                }

                console.log(this.state.nmArray);
                console.log(this.state.betString);
                console.info(this.state.bets);
                this.state.betstr = this.state.bets.slice(0, -1);
                if (sendBet) {
                  this.setState({
                    disDoubleBtn: 1,
                    disBetBtn: 1,
                    disClearBtn: 1,
                    disZoomBtn: 1,
                    winCover: true,
                    btnActive: false
                  });
                  this.isReady = false;
                  this.placebet();
                  console.log(console.log(this.state.betstr))
                  document.getElementById("americanRltTimerCover").style.display = "block";
                } else {

                  console.log("Dont Send Bet Request.")
                  const minInrLmt = this.changeAmtlabl(innerMin_Limit)

                  this.setState({
                    infoText: this.props.language.InerLmt + minInrLmt,
                    disClearBtn: 0,
                  });


                }



                return this.state.betstr;
              } else {
                //alert('amount reduces to your balance');
              }
              //console.log("Info text on Bet Amount: " + this.state.InfoText);
            }
            if (this.state.totalBet > 0) {
              // this.setState({ infoText: this.props.infoText });
            } else {
              console.log("American Roulette Timer: Spectator Count");
              // this.props.spectatorCount(this.state.Total_Bet_Amount, this.handId);  //Check Spectator
              this.setState({ infoText: this.props.language.Play_Njoy });
            }
          }

        }


        break;
      case "TAKE":
        //this.takeSound.play();
        console.log("take amount action");
        var valId = document.getElementById("bet_take");
        // valId.classList.remove("active");

        for (var i = 0; i < this.state.betString.length; i++) {
          var valId = document.getElementById("id_" + i);
          if (valId) {
            // valId.classList.remove("active");
          }
          document.getElementById("id_" + i).innerHTML = ``;
          this.setState({ disClearBtn: 1 });
          //console.log("*****: "+this.state.betString[i].amount);
        }
        this.takeAmountResult();

        break;
      case "PREV":
        this.betSound.play();
        this.removeImgGlow();
        this.showPrevBet();
        break;
      case "ZoomON":
        if (document.getElementById('iospopup').style.display == "none") {
          this.betSound.play();
        }
        this.setState({ zoomButtonText: this.props.language.Zoom_OFF, zoomButtonValue: 1 });
        //this.setState({ zoomButtonText: "Wheel Zoom: OFF", wheelZoom: 1.5, zindex: "1000", X_top: "21.8vh", x_img: 1 });
        break;
      case "ZoomOFF":
        if (document.getElementById('iospopup').style.display == "none") {
          this.betSound.play();
        }
        this.setState({ zoomButtonText: this.props.language.Zoom_ON, zoomButtonValue: 0 });
        break;
      case "double":
        this.betSound.play();
        this.updateWinsArray(2);
        break;
      case "clear":
        this.setState({
          betButtonText: this.props.language.Prev,
          betButtonValue: 0,
          disClearBtn: 1,
          infoText: this.props.language.Play_Njoy,
          disDoubleBtn: 1,
          btnActive: false,
          disZoomBtn: 0
        });
        document.getElementById("bet_take").classList.remove("active");
        if (document.getElementById('iospopup').style.display == "none") {
          this.clearSound.play();
        }
        this.clearBetList();
        break;
      case "exit":
        this.gameCloseHandler("target");
        const body = {
          sessionId: sessionStorage.getItem('sessionId'),
          CN: "EXIT_GAME",
          object: {
            gameId: this.props.gameId,
            playerId: Number(this.props.playerId),
          },
        };
        this.props.network.sendof(body);
        break;
      case "disableBtns":
        this.removeImgGlow();
        this.setState({
          disDoubleBtn: 1,
          disBetBtn: 1,
          disClearBtn: 1,
          disZoomBtn: 1,
          winCover: true,
          btnActive: false,
        });
        this.amrTm_Ref.current.mouseUpStopCards();
        if (this.state.totalBet == 0 && this.takeAmount == 0) {
          // this.setState({ infoText: "Play and Njoy!" });
        }
        document.getElementById("americanRltTimerCover").style.display = "block";
        document.getElementById("americanRltTimerCover").style.zIndex = '1020';
        break;
      case "PlayTickSound":
        if (document.getElementById('iospopup').style.display == "none") {
          this.tickSound.play();
        }
        break;
      case "StopTickSound":
        if (document.getElementById('iospopup').style.display == "none") {
          this.tickSound.pause();
        }

        break;
      default:
        break;
    }
  }

  removeImgGlow() {
    console.log("removeImgGlow");
    for (var i = 0; i < this.state.betString.length; i++) {
      var valId = document.getElementById("id_" + i);
      if (valId) {
        valId.classList.remove("active");
      }
    }
    this.removeGlow = false;
  }

  PreviousBetMethod() {
    console.log("Previous Bet Method");
    this.updateWinsArray(0);
    var spacesremove = this.props.gameState["betRequest"]["betString"].replace(/ /g, "0");
    var preBet = spacesremove.replace(/,/g, "|");
    this.state.previousBetArray.push({
      amount: preBet.split("|"),
    });
    this.setState({ previousBetArray: this.state.previousBetArray });
    if (this.state.previousBetArray) {
      this.toCreatePreviousBetArray();
    }
    console.log(this.state.previousBetArray[0].amount[0]);
    if (this.props.gameState.handId) {
      this.handId = this.props.gameState.handId;
    }
  }
  toCreatePreviousBetArray() {
    for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
      this.state.finalPreviousBetArray.push({
        id: i,
        amount: Number(this.state.previousBetArray[0].amount[i]),
      });
    }
    this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray });
    console.log(this.state.finalPreviousBetArray);
    if (this.state.finalPreviousBetArray) {
      this.toGenerteBetString();
    }
  }
  toGenerteBetString() {
    for (let i = 0; i < this.state.betString.length; i++) {
      if (this.state.betString[i].id == this.state.finalPreviousBetArray[i].id) {
        console.log("ids" + i);
        let value = (this.state.betString[i].amount += this.state.finalPreviousBetArray[i].amount);
        if (this.state.finalPreviousBetArray[i].amount != 0) {
          document.getElementById("id_" + i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${value} </span> </p>`;
        }
      }
    }
    this.setState({ betString: this.state.betString, betButtonText: this.props.language.BetOK, betButtonValue: 1, previousBetArray: [], finalPreviousBetArray: [], btnActive: true });
    console.log(this.state.betString);
  }

  takeAmountResult() {
    this.setState({
      disDoubleBtn: 1,
      disBetBtn: 0,
      disClearBtn: 1,
      disZoomBtn: 0,
      glowTakeBtn: 0,
      btnActive: false,
      betButtonText: this.props.language.Prev,
      betButtonValue: 0,
      totalBet: 0,
      winAmount: 0,
      // Total_Bet_Amount: 0,
      btnActive: false
    });
    this.takeAmount = 0;
    var valId = document.getElementById("bet_take");
    valId.classList.remove("active");
    // document.getElementById("americanRltTimerCover").style.display = "none";
    document.getElementById("americanRltTimerCover").style.display = 'none';
    this.setState({ infoText: this.props.language.Play_Njoy, showBetButton: false, winCover: false });
    this.updateWinsArray(0);

    const body = {
      sessionId: sessionStorage.getItem('sessionId'),
      CN: "TAKE_AMOUNT",
      object: {
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
      },
    };
    // this.props.network.sendof(body);
    this.body = {};

    if (this.props.isPlayerBanned) {
      this.gameCloseHandler("target");
      this.props.player_banned();
    }

    console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
    if (this.props.gameUnderMaintanance) {
      this.gameCloseHandler("target");
      this.props.game_maintanance("Game under maintenance, reach to admin.");
    }

    console.log("deviceBlocked " + this.props.isdeviceBlocked);
    if (this.props.isdeviceBlocked) {
      this.gameCloseHandler("target");
      this.props.device_blocked();
    }
  }

  clearBetList() {
    console.log("clearBetList");
    // document.getElementById("americanRltTimerCover").style.display = 'none';
    for (var i = 0; i < this.state.betString.length; i++) {
      if (document.getElementById("id_" + i)) {
        document.getElementById("id_" + i).innerHTML = ``;
        this.state.betString[i].amount = 0;
      }
    }
    //this.setState({ Total_Bet_Amount: 0 });
    this.setState({ disDoubleBtn: 1, disClearBtn: 1, disBetBtn: 0, Total_Bet_Amount: 0 });

    if (this.props.gameState.betRequest == undefined) {
      this.setState({ disBetBtn: 1 });
    }
  }
  updateWinsArray(num) {
    console.info("Update Bet:::::::::::::::::::::: " + num);
    let totBet = 0;
    if (num == 2) {
      if (this.state.Total_Bet_Amount * 2 > this.props.maxBetAmt) {
        // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
        this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });

      } else if (this.state.Total_Bet_Amount * 2 <= this.props.balance) {
        let innerMaxLimit = 1;
        for (let k = 0; k < this.state.betString.length; k++) {

          if (k <= 37) {
            innerMaxLimit = this.newLimits[0][1];

          } else if (k >= 38 && k <= 99) {
            innerMaxLimit = this.newLimits[1][1];
          } else if (k >= 100 && k <= 114) {
            innerMaxLimit = this.newLimits[2][1];
          } else if (k >= 115 && k <= 137) {
            innerMaxLimit = this.newLimits[3][1];
          } else if (k >= 138 && k <= 148) {
            innerMaxLimit = this.newLimits[5][1];
          } else if (k >= 154 && k <= 159) {
            innerMaxLimit = this.newLimits[6][1];
          } else if (k >= 165 && k <= 170) {
            innerMaxLimit = this.newLimits[7][1];

          }


          if (this.state.betString[k].amount > 0 && this.state.betString[k].amount <= innerMaxLimit && totBet <= this.props.balance) {
            if (this.state.betString[k].amount * num <= innerMaxLimit) {
              if (this.state.Total_Bet_Amount + this.state.betString[k].amount <= this.props.balance) {
                this.state.Total_Bet_Amount = this.state.Total_Bet_Amount + this.state.betString[k].amount;
                this.state.betString[k].amount = this.state.betString[k].amount * num;
                let doubleBetValue = this.state.betString[k].amount;
                if (doubleBetValue >= 1000 && doubleBetValue <= 999999) {
                  doubleBetValue = doubleBetValue / 1000 + "K";
                  console.log(doubleBetValue);
                } else if (doubleBetValue >= 1000000 && doubleBetValue <= 999999999) {
                  doubleBetValue = doubleBetValue / 1000 + "M";
                  console.log(doubleBetValue);
                } else if (doubleBetValue > 999999999) {
                  doubleBetValue = doubleBetValue / 1000 + "B";
                  console.log(doubleBetValue);
                }
                document.getElementById("id_" + k).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${doubleBetValue} </span> </p>`;
              }
            } else {
              console.log(innerMaxLimit);
              let maxBetMount = this.changeAmtlabl(innerMaxLimit)

              this.setState({ infoText: this.props.language.MaxInrBetAmt + " " + maxBetMount });


            }
            totBet += this.state.betString[k].amount;
            this.setState({
              Total_Bet_Amount: totBet,
            });
          }
        }
        if (totBet <= this.props.balance) {
          this.setState({
            betString: this.state.betString,
            disDoubleBtn: 0,
            disClearBtn: 0,
            disZoomBtn: 0,
            //infoText: this.props.language.Play_Njoy,
          });
        } else {
          this.isReady = false;
          this.setState({
            disDoubleBtn: 1,
            disClearBtn: 0,
            disZoomBtn: 0,
            infoText: this.props.language.LowBalance,
          });
        }
      } else {
        this.setState({
          disClearBtn: 0,
          disZoomBtn: 0,
          infoText: this.props.language.LowBalance,
        });
      }
    } else {
      for (let a = 0; a < this.state.betString.length; a++) {
        if (this.state.betString[a].amount > 0) {
          this.state.betString[a].amount = 0;
          document.getElementById("id_" + a).innerHTML = "";
        }
        totBet += this.state.betString[a].amount;
      }

      this.setState({
        betString: this.state.betString,
        disDoubleBtn: 1,
        disClearBtn: 1,
        disZoomBtn: 0,
        infoText: this.props.language.Play_Njoy,
        // Total_Bet_Amount: totBet,
      });
    }

  }

  showPrevBet() {
    console.log(this.props.gameState.betRequest.betAmount);
    console.log(this.props.balance)
    if (this.props.gameState.betRequest.betAmount <= this.props.balance) {
      if (this.props.gameState.betRequest.betAmount > this.props.maxBetAmt) {
        this.setState({ infoText: this.props.language.PreBet_MaxLmt });
      } else {

        if (this.state.Total_Bet_Amount == 0) {
          let prevBetString = [];
          console.log("Prev Bet String Length: ");
          console.log(this.props.gameState);
          console.log(this.props.gameState.betRequest.betAmount);
          console.log(this.props.balance);
          var newStr = this.props.gameState.betRequest.betString.replace(/,/g, "|");
          var newStr1 = newStr.replace(/ /g, "0");
          //console.log("newStr1 : " + newStr1);
          var newStr2 = newStr1.split("|");
          var totalBetAmt = 0;
          //console.log("newStr2 : " + newStr2);
          console.log("newStr2 length: " + newStr2.length);
          for (var i = 0; i < newStr2.length; i++) {
            if (parseInt(newStr2[i]) != NaN) {
              totalBetAmt = totalBetAmt + parseInt(newStr2[i]);
            }
            prevBetString.push({
              id: i,
              amount: parseInt(newStr2[i]),
            });
          }
          console.log("Previous Total Bet Amount: " + totalBetAmt);
          this.state.betString = prevBetString.slice();
        }

        /*const totalbal = this.state.nmArray.reduce((total, m) => m.amount + total,0)
            console.log("totalbal "+totalbal);*/
        if (totalBetAmt <= this.props.balance) {
          for (var i = 0; i < this.state.betString.length; i++) {
            // var value = this.state.betString[i].amount;
            let preBetValue = this.state.betString[i].amount;
            if (preBetValue > 0) {
              if (preBetValue >= 1000 && preBetValue <= 999999) {
                preBetValue = preBetValue / 1000 + "K";
                console.log(preBetValue);
              } else if (preBetValue >= 1000000 && preBetValue <= 999999999) {
                preBetValue = preBetValue / 1000 + "M";
                console.log(preBetValue);
              } else if (preBetValue > 999999999) {
                preBetValue = preBetValue / 1000 + "B";
                console.log(preBetValue);
              }
              document.getElementById("id_" + i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${preBetValue} </span> </p>`;
            }
          }
          if (this.state.betButtonValue == 0) {
            var valId = document.getElementById("bet_take");
            valId.classList.remove("active");
            this.setState({
              showBetButton: true,
              betButtonText: this.props.language.BetOK,
              betButtonValue: 1,
              btnActive: true
            });
            this.isReady = true;
          }
          this.setState({
            betString: this.state.betString,
            disDoubleBtn: 0,
            disClearBtn: 0,
            disZoomBtn: 0,
            Total_Bet_Amount: totalBetAmt,
          });
        }

      }

    } else {
      this.setState({ infoText: this.props.language.Bet_Amt_MaxBal })
    }
  }

  betHandler(amount) {
    this.setState({ betAmount: amount });
  }
  betPanelHandler(no) {
    this.setState({ betButtonText: this.props.language.BetOK, betButtonValue: 1, btnActive: true });
    console.log(this.state.betAmount);
    if (this.state.betAmount !== 0) {
      console.log(no);
      const x = no;
      switch (true) {
        case x < 171:
          for (let i = 0; i < this.state.betString.length; i++) {
            if (this.state.betString[i].id == no) {
              let value = (this.state.betString[i].amount += this.state.betAmount);
              document.getElementById("id_" + no).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${value} </span> </p>`;
            }
          }
          this.setState({ betString: this.state.betString });
          console.log(this.state.betString);
          break;
        default:
          //alert("none");
          break;
      }
    }
  }
  placebet() {
    console.log("American Roulette Tier: Send Bet Request");
    console.log(this.state.betstr)
    const body = {
      sessionId: sessionStorage.getItem('sessionId'),
      CN: "FUN_ROULETTE_BET",
      object: {
        betString: this.state.betstr,
        playerId: Number(this.props.playerId),
        gameId: this.props.gameId,
      },
    };
    this.props.network.sendof(body);
    this.body = {};

    // const body2 = {
    //   CN: "GET_PLAYER_INFO",
    //   object: {
    //     gameId: Number(this.state.gameId),
    //     playerId: Number(this.props.playerId),
    //   },
    //   sessionId: sessionStorage.getItem('sessionId'),
    // };
    // this.props.network.sendof(body2);
    // this.body2 = {};
  }

  getPlayerInfo() {
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.state.gameId),
        playerId: Number(this.props.playerId),
      },
      sessionId: sessionStorage.getItem('sessionId'),
    };
    this.props.network.sendof(body2);
    this.body2 = {};
  }
  americanRoulleteTimerGetwheelresp() {
    //console.log(" AmericanRoullete Result winAmount: " + this.props.targetResult.winAmount);
    document.getElementById("americanRltTimerCover").style.display = "block";
    if (this.props.targetResult) {
      this.resultNumber = this.props.targetResult.resultNum;
      if (this.state.zoomButtonValue == 0) {
        this.setState({ wheelZoom: 1.5, zindex: "1000", X_top: "21.8vh", x_img: 1 });
        document.getElementById("americanRltTimerCover").style.zIndex = '1020';
      }
      if (document.getElementById('iospopup').style.display == "none") {
        this.wheelSound.play();
      }

      this.artWheelRef.current.spinTheWheel(this.resultNumber);

    }
  }
  glowImage() {
    this.setState({ wheelZoom: 1, zindex: "0", X_top: "14.5vh", winCover: false });
    this.removeImgGlow();
    if (this.resultNumber == 37) {
      document.getElementById("id_0").classList.add("active");
    } else {
      document.getElementById("id_" + Number(this.resultNumber + 1)).classList.add("active");
    }

    if (this.props.targetResult.winAmount > 0) {
      this.takeAmount = this.props.targetResult.winAmount;
      if (document.getElementById('iospopup').style.display == "none") {
        this.winSound.play();
      }
    } else {
      if (document.getElementById('iospopup').style.display == "none") {
        this.duploseSound.play();

      }
      if (this.takeAmount == 0) {
        setTimeout(() => {
          document.getElementById("americanRltTimerCover").style.display = "none";
          // this.clearBetList();

          for (let i = 0; i < this.state.betString.length; i++) {
            if (document.getElementById("id_" + i)) {
              document.getElementById("id_" + i).innerHTML = ``;
              this.state.betString[i].amount = 0;
            }
          }
          this.setState({ disDoubleBtn: 1, disClearBtn: 1, disBetBtn: 0 });

          if (this.props.gameState.betRequest == undefined) {
            this.setState({ disBetBtn: 1 });
          }
          this.getPlayerInfo();
        }, 1000)
      }
    }
    //document.getElementById('id_'+Number(this.resultNumber)).classList.add('active');
  }

  AmericanRouletteTimerGetTime() {
    //console.log(this.props.timesend);
    if (this.props.timesend) {
      console.log("American Current Time: " + this.props.timesend);
      //console.log(this.props.timesend);
      this.AmericanTimerInSeconds.current.sendTimer(this.props.timesend);
    }
  }
  chipVal(val) {
    this.takeSound = new Audio(takeSound);
    this.takeSound.play();
    console.log("American Timer Val  " + val);
    this.state.selectedChipVal = val;
    console.log("check ::" + this.state.selectedChipVal);
    this.setState({
      selectedChipVal: val,
      //infoText: `Your Bet Amount Is: ${val} & You Are Ready To Go`
    });
  }

  benohandle(id) {
    this.betSound1 = new Audio(betSound)
    this.betSound1.play();
    this.removeImgGlow();
    console.info("benohandle: ");
    console.info("Selected Chip Value: " + this.state.selectedChipVal);
    console.info("Selected ID Value: " + id);
    if (this.state.selectedChipVal == 0) {
      this.state.betString[id].amount = 0;
      document.getElementById("id_" + id).innerHTML = ``;
    } else {
      // this.setState({
      //   disClearBtn: 0,
      //   disBetBtn: 0,
      //   disDoubleBtn: 0,
      // });
    }
    console.log("this.state.betString: ");
    console.info(this.state.betString);
    console.log("Length: " + this.state.betString.length);
    for (var i = 0; i < this.state.betString.length; i++) {
      if (this.state.betString[i].amount == "NaN" || this.state.betString[i].amount == NaN) {
        this.state.betString[i].amount = 0;
      }
      console.log("--------- " + this.state.betString[i].amount);
    }

    const totalbet = this.state.betString.reduce((total, m) => m.amount + total, parseInt(this.state.selectedChipVal));
    // const totalbal = this.state.nmArray.reduce((total, m) => m.amount + total, parseInt(this.state.selectedChipVal))
    console.log("New Bet Amount " + totalbet);
    console.log("Balance " + this.props.balance);
    if (totalbet > this.props.maxBetAmt) {
      this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
      // this.setState({infoText: "Min Bet "+this.props.minBetAmt+" and Max Bet "+this.props.maxBetAmt});
    } else if (totalbet <= this.props.balance) {

      if (this.state.betString[id] != null && this.state.betString[id].amount != null) {

        let innerLimit = 1;
        try {
          if (id <= 37) {
            innerLimit = this.newLimits[0][1];

          } else if (id >= 38 && id <= 99) {
            innerLimit = this.newLimits[1][1];
          } else if (id >= 100 && id <= 114) {
            innerLimit = this.newLimits[2][1];
          } else if (id >= 115 && id <= 137) {
            innerLimit = this.newLimits[3][1];
          } else if (id >= 138 && id <= 148) {
            innerLimit = this.newLimits[5][1];
          } else if (id >= 154 && id <= 159) {
            innerLimit = this.newLimits[6][1];
          } else if (id >= 165 && id <= 170) {
            innerLimit = this.newLimits[7][1];

          }

        } catch (err) {
          console.log("error while trying to fetch inner limits: ArayOutOfBoundsException " + err.message);
        }
        console.log("FST Inner Limit:  " + innerLimit)


        if ((this.state.betString[id].amount + parseInt(this.state.selectedChipVal)) <= innerLimit) {
          this.isReady = true;
          this.setState({
            Total_Bet_Amount: totalbet,
          });
          console.log("Total Bet Value: " + this.state.Total_Bet_Amount);
          for (var i = 0; i < this.state.betString.length; i++) {
            if (this.state.betString[i].id == id) {
              if (this.state.selectedChipVal == 0) {
                var value = (this.state.betString[i].amount *= parseInt(this.state.selectedChipVal));
                document.getElementById("id_" + i).innerHTML = ``;
              } else {
                // var value = (this.state.betString[i].amount += parseInt(this.state.selectedChipVal));

                let chipBetValue = (this.state.betString[i].amount += parseInt(this.state.selectedChipVal));

                if (Number(chipBetValue) >= 1000 && Number(chipBetValue) <= 999999) {
                  chipBetValue = chipBetValue / 1000 + "K";
                  console.log(chipBetValue);
                } else if (chipBetValue >= 1000000 && chipBetValue <= 999999999) {
                  chipBetValue = chipBetValue / 1000 + "M";
                  console.log(chipBetValue);
                } else if (chipBetValue > 999999999) {
                  chipBetValue = chipBetValue / 1000 + "B";
                  console.log(chipBetValue);
                }

                this.setState({
                  disClearBtn: 0,
                  disBetBtn: 0,
                  disDoubleBtn: 0,
                });

                document.getElementById("id_" + i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${chipBetValue} </span> </p>`;
              }
            }

            if (this.removeGlow == true) {
              //document.getElementById('id_'+i).classList.remove('active');
              var valId = document.getElementById("id_" + i);
              if (valId) {
                valId.classList.remove("active");
              }
            }
          }

          var TotalBetVaue = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
          console.log("Total Bet Amount: " + TotalBetVaue);
          if (TotalBetVaue == 0) {
            this.setState({ disDoubleBtn: 1, disClearBtn: 1, disBetBtn: 0, disZoomBtn: 0 });
            if (this.props.gameState.betRequest) {
              this.setState({ betButtonText: this.props.language.Prev, betButtonValue: 0, btnActive: false })
            } else {
              this.setState({ disBetBtn: 1 })
            }
          } else {
            this.setState({ disDoubleBtn: 0, disClearBtn: 0, showBetButton: true, betButtonText: this.props.language.BetOK, betButtonValue: 1, btnActive: true });
            this.removeGlow = false;
            if (this.state.betButtonValue == 0) {
              var valId = document.getElementById("bet_take");
              valId.classList.remove("active");
            }
          }
        } else {

          let maxBetMount = this.changeAmtlabl(innerLimit)
          this.setState({ infoText: this.props.language.MaxInrBetAmt + "" + maxBetMount })

          console.log(this.state.infoText)
        }
        if (this.state.selectedChipVal == 0 && this.state.Total_Bet_Amount == 0) {
          this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl })
          setTimeout(() => {
            this.setState({ infoText: this.props.language.Play_Njoy });
          }, 1500)
        }
        // else{
        //     this.setState({ infoText:  this.props.language.Play_Njoy });
        // }

      }

    } else {
      this.setState({ infoText: this.props.language.LowBalance });
      console.log("amount reduces to your balance");
      //alert('amount reduces to your balance');
    }

    this.setState({
      betString: this.state.betString,
    });
  }
  loadBg() {
    this.LoadImg = new window.Image()
    this.LoadImg.src = artBg;
    this.LoadImg.addEventListener('load', this.americanRouletteTimerLoded)
  }

  americanRouletteTimerLoded = () => {
    if (!this.isloadImg) {
      this.isloadImg = true;
    } else {
      this.setState({
        amrTmLoader: false
      })
    }
  }
  closeWin() {
    document.getElementById('iospopup').style.display = "none";
    this.takeSound = new Audio(takeSound);
    this.winSound = new Audio(winSound);
    this.duploseSound = new Audio(duploseSound);
    this.tickSound = new Audio(tickSound);
    this.wheelSound = new Audio(wheelSound);
    this.clearSound = new Audio(clearSound);
    this.betSound = new Audio(betSound);
  }

  render() {
    let winPopAmount = this.changeAmtlabl(this.state.winAmount)
    return (
      <React.Fragment>
        <div className="gameBox" id="gameBox">
          <div className="gamePage americanRouletteTimerMain">
            <div className="iosFetchSounds" id="iospopup" onClick={(e) => this.closeWin()}>
              <div className="popupTooLong">
                <div className=""> {this.props.language.Wel_ART}</div>
                <br></br>
                {this.props.language.OK_Btn}

                <br></br>
                <br></br>
                <div className="fd">
                  <button className="btn_iosPopup" > {this.props.language.Ok} </button>
                </div>
              </div>
            </div>

            <div className="FGAamericanRoulette">
              <div className="fd american_ht_1 p_30">
                <div className="fd">
                  <div className="row">
                    <div className="fd">
                      <div className="wheelandhis">
                        <div className="row">
                          <div className="fun_chips">
                            <BetChips
                              childRef={ref => (this.BetChips = ref)}
                              bet={this.betHandler.bind(this)}
                              winAmount={this.state.winAmount}
                              balance={this.props.balance}
                              TotalBetAmouont={this.state.Total_Bet_Amount}
                              totalBet={this.state.betString}
                              betAmount={this.chipVal.bind(this)}
                              art_chipValues={this.props.art_chipValues}
                            />
                          </div>
                          <div className="fun_routlet_table">
                            <div className="fd">
                              <Title className="fd"></Title>
                              <div className="fd" style={{ height: "450px" }}>
                                <Wheel
                                  gameState={this.props.gameState}
                                  ref={this.artWheelRef}
                                  zindex={this.state.zindex}
                                  zoom={this.state.wheelZoom}
                                  height={this.wheelHeight}
                                  width={this.wheelWidth}
                                  americanRouletteTimerLoded={this.americanRouletteTimerLoded.bind(this)}
                                  action={this.wheelHandler.bind(this)}
                                  isMobile={this.props.isMobile}
                                  showGlow={this.glowImage.bind(this)}
                                  onFocus={this.props.onFocus}>
                                </Wheel>
                              </div>
                            </div>
                          </div>
                          <div className="fun_btns">
                            <AmericanTimerInSeconds
                              winCover={this.state.winCover}
                              action={this.rightButtonPanelHandler.bind(this)}
                              playerId={this.props.playerId}
                              gameId={this.props.gameId}
                              network={this.props.network}
                              timesend={this.props.timesend}
                              ref={this.AmericanTimerInSeconds} />

                            <RightButtonPanel
                              childRef={ref => (this.RightButtonPanel = ref)}
                              betAmount={this.chipVal.bind(this)}
                              user={this.props.user}
                              betBtnText={this.state.betButtonText}
                              betButtonValue={this.state.betButtonValue}
                              gameState={this.props.gameState}
                              targetResult={this.props.targetResult}
                              zoomButtonText={this.state.zoomButtonText}
                              zoomButtonValue={this.state.zoomButtonValue}
                              betBtn={this.state.showBetButton}
                              action={this.rightButtonPanelHandler.bind(this)}
                              stop={this.state.stop}
                              disDoubleBtn={this.state.disDoubleBtn}
                              disClearBtn={this.state.disClearBtn}

                              disBetBtn={this.state.disBetBtn}
                              disZoomBtn={this.state.disZoomBtn}

                              glowTakeBtn={this.state.glowTakeBtn}
                              ref={this.RightButtonPanel}
                              network={this.props.network}
                              btnActive={this.state.btnActive}
                            ></RightButtonPanel>

                            {/* <Close action={this.gameCloseHandler.bind(this)}></Close> */}
                          </div>
                        </div>
                      </div>
                      <div className="tableAmerican">
                        <div className="fd">
                          <Table
                            betAmount={this.chipVal.bind(this)}
                            action={this.benohandle.bind(this)}
                            infoText={this.state.infoText}
                            // action={this.betPanelHandler.bind(this)}
                            ref={this.amrTm_Ref}
                            className="fd"
                          ></Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="american_ins">
                  <div className="fd">
                    <div className="row">
                      <div className="col-2">
                        <div className="userLbl">
                          <img className="user" src={user} alt={user} /> {this.props.user}
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="americanRouletteTimerIns"> {this.state.infoText} </div>
                      </div>
                      {this.props.gameEntry == 'in' ?
                        <div className="col-2">
                          <div className="fd box_clm">
                            <button className="fd ar_bt_1 z_1500" disabled={this.props.disZoomBtn == 1}
                              onClick={(e) => { e.preventDefault(); this.gameCloseHandler("target"); }}>
                              {this.props.language.Exit} </button>
                          </div>
                        </div> :
                        <div className="userLbl">
                          {this.props.language.Player_Id}: {this.props.playerId} <br></br>
                          {this.props.language.Hand_Id}: {this.props.gameState.handId} <br></br>
                        </div>}
                    </div>
                  </div>
                  <div className="americanTimerWinPop" id="amt_WinPop">
                    {this.props.language.Win_Amt} &nbsp; {winPopAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj
  }
}
export default connect(mapStatesToProps)(AmericanRouletteTimer)

