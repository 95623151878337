import React from "react";
import winningPannle from "../../../../../../assets/games/andarBaharTimer/ab_win_bg.png";
import winGlowImg from "../../../../../../assets/games/andarBaharTimer/winGlow.png";
import balancepannel from "../../../../../../assets/games/andarBaharTimer/ab_score_bg.png";
import cardDeck from "../../../../../../assets/games/andarBaharTimer/cardDeck.png";
import andarTitleAnim from "../../../../../../assets/games/andarBaharTimer/andarTitle.png";
import baharTitleAnim from "../../../../../../assets/games/andarBaharTimer/baharTitle.png";
import joker_card_anim from "../../../../../../assets/games/andarBaharTimer/joker_card_anim.gif";

import coin from "../../../../../../assets/games/andarBaharTimer/cardcoin.png"
import tickSound from "../../../../../../assets/sounds/tick.mp3";
import slotSound from "../../../../../../assets/sounds/Slot.wav";

import { connect } from "react-redux";

class TopLable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeInSec: 60,
      getTime: 60,
      isDisableSent: false,
      isSpectateCheck: false,
      isFirstRound: true,
      isTimerSent: false,

    }
    this.animateJokerCard = false;
    this.slotSound = new Audio(slotSound);
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);

    // this.adjustFontSize();

    // document.getElementById("winglow").style.display = "none";

    document.getElementById("timeSpan").style.display = "none";
    this.timerclear = null;
    this.timerclear = setInterval(this.myTimer, 1000);

    this.ab_timeout1 = setTimeout(() => {
      if (document.getElementById("timeSpan")) {
        console.log("  AB Game Time:    " + document.getElementById("timeSpan").innerHTML);

        if (document.getElementById("timeSpan").innerHTML <= 5) {
          this.setState({ isFirstRound: true });
          document.getElementById("ab_Cover").style.display = "block";
        } else {
          this.setState({ isFirstRound: false });
        }

        if (document.getElementById("timeSpan").innerHTML < 4 && document.getElementById("timeSpan").innerHTML >= 0) {
          document.getElementById("resJokerCard").src = joker_card_anim;
          document.getElementById("resJokerCard").style.opacity = 1;
          document.getElementById("resJokerCard").style.display = "block";
        }
      }

    }, 500);

  }

  adjustFontSize() {
    console.log(document.getElementById("balanceTxt").offsetWidth)
    var container = document.getElementById("balanceTxt");
    var fontSize = 40; // Initial font size
    var maxWidth = document.getElementById("balanceTxt").offsetWidth;
    console.log(maxWidth)
    // Create a dummy span element to calculate text width
    console.log(container.textContent)
    var span = document.createElement('span');
    span.style.fontSize = fontSize + 'px';
    span.textContent = container.textContent;
    document.getElementById("ab_bal").appendChild(span);
    console.log(span.offsetWidth)
    var textWidth = span.offsetWidth;
    // document.getElementById("ab_bal").removeChild(span);
    console.log(textWidth + "             " + maxWidth)
    // Reduce font size until text fits within container width
    while (textWidth > maxWidth) {
      fontSize--;
      console.log(fontSize);
      document.getElementById("balanceTxt").style.fontSize = fontSize + 'px';
      // container.style.fontSize = fontSize + 'px';
      console.log(span);
      console.log(span.innerHTML);
      console.log(span.style.fontSize);
      span.style.fontSize = fontSize + 'px';
      console.log(span.offsetWidth)
      textWidth = span.offsetWidth;
      console.log(textWidth)
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerclear);
    window.clearTimeout(this.ab_timeout1);
    if (this.slotSound) {
      this.slotSound.pause();
    }
  }

  sendTimer(tt) {
    // alert('ding')
    document.getElementById("timeSpan").style.display = "block";
    this.setState({ getTime: tt });
    this.state.getTime = tt;
    this.myTimer();
  }

  clearTimer() {
    clearInterval(this.timerclear);
  }
  updateResStatus() {
    this.animateJokerCard = false;
    if (this.slotSound) {
      this.slotSound.pause();
      this.slotSound.currentTime = 0;
    }
  }

  closeGame() {
    this.animateJokerCard = false;
    if (this.slotSound) {
      this.slotSound.pause();
      this.slotSound.currentTime = 0;
    }

    clearInterval(this.timerclear);
  }

  myTimer = () => {
    if (this.state.getTime != null && this.state.getTime != NaN && this.state.getTime >= 0) {

      let d = this.state.getTime;
      let timer = document.getElementById("timer");
      let timerGlow = document.getElementById("timerGlow");
      // timerGlow.classList.add("ab_timer_glow");

      let ab_Cover = document.getElementById("ab_Cover");
      let n = --d;
      let sec = parseInt(n % 60);
      let secS = sec.toString();
      if (String(sec).length < 2) {
        secS = "0" + sec.toString();
      }
      this.setState({ getTime: secS });
      this.setState({ timeInSec: secS });
      if (n != null && n != NaN) {
        if (n == 10 && !this.state.isTimerSent) {
          this.setState({ isTimerSent: true });
          console.log(" ------------   AB Timer -------------------")
          this.getTimer();
        }
        if (n <= 0) {
          this.setState({ getTime: 60, isBetSent: false, isFirstRound: false, isTimerSent: false });
        }

        if (n == 55) {
          this.setState({ isDisableSent: false });
        }

        if (n == 1) {
          if (!this.state.isSpectateCheck && !this.state.isFirstRound) {
            this.setState({ isSpectateCheck: true });
            this.props.check_spec();
          }
        }

        if (n < 60 && n > 3) {
          this.setState({ isSpectateCheck: false });
        }
        if (n === 5) {
          if (!this.state.isBetSent && this.props.takeAmount === 0) {
            this.setState({ isBetSent: true });
            this.state.isBetSent = true;
            this.props.auto_bet();
          }
        }

        if (n == 4) {
          if (!this.animateJokerCard) {

            this.animateJokerCard = true;

            document.getElementById("resJokerCard").src = joker_card_anim;
            document.getElementById("resJokerCard").style.opacity = 1;
            document.getElementById("resJokerCard").style.display = "block";
            if (this.slotSound) {
              this.slotSound.currentTime = 0;
              this.slotSound.play();
            }
          }
        }

        if (n <= 5 && n >= 0) {
          // ab_Cover.style.display = 'block';
          // ab_Cover.style.zIndex = "1200"          
          if (!this.state.isDisableSent) {
            this.setState({ isDisableSent: true });
            this.props.disableBtns();
          }
        } else {
          this.setState({ isBetSent: false });
        }

        if (n <= 10 && n >= 5) {
          if (timerGlow) {
            timerGlow.classList.add("ab_timer_glow");
          }
          this.tickSound = new Audio(tickSound);
          this.tickSound.play();
          this.setState({ isBetSent: false });
        } else {
          if (timerGlow && timerGlow.classList.contains("ab_timer_glow")) {
            timerGlow.classList.remove("ab_timer_glow");
            this.tickSound = {};
          }
        }
      }
    }
  };

  getTimer() {
    const body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "GET_GAME_TIMER",
      object: {
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
      },
    };
    this.props.network.sendof(body);
    this.body = {};
  }

 

  // const language = useSelector((state) => state.languageObjs.languageObj)
  // const roundNumber = props.balance - props.betAmt 
  // const betAmount = Math.round(roundNumber*100, 2)/100

  render() {
    console.log(typeof(this.props.updatedbalance))
 
    return (
      <React.Fragment>
        <div className="topLable">
          <div className="col-4">
            <div id="ab_bal" className="anbhr_balance">
              <img src={balancepannel} />
              <span className="score">{this.props.language.Score}</span>
              <span id="balanceTxt" className="scoreTxt">{this.props.updatedbalance}</span>
            </div>
            <div className="plr_dtls">
              <span >P ID:  {this.props.playerId}</span><br />
              <span >ID: {this.props.gameState.handId}</span><br />
              <span >{this.props.user}</span>
            </div>
          </div>
          <div className="col-4">
            <div className="title_layout">
              <img className="titleandar" src={andarTitleAnim} />
              <div className="timer_bg">
                <img id="timerGlow" />
                <img id="timer" src={coin} />
                <span id="timeSpan" className="timer_txt">{this.state.timeInSec}</span>
              </div>
              <img className="titlebahar" src={baharTitleAnim} />
              {/* <img className="titlebahar" src={bahartitle} /> */}
            </div>
            <img className="cardDeckImg" src={cardDeck} />
          </div>
          <div className="col-4">
            <div className="anbhr_winner">
              <img className={this.props.winGlowImg ? "winglow " : "hidewinglow"} src={this.props.winGlowImg ? winGlowImg : ""} />
              {/* <img id="winglow" className="winglow" src={winGlowImg}></img> */}
              <img src={winningPannle} />
              <span className="WINNER">{this.props.language.Winner}</span>
              <span className="winnerTxt">{this.props.updatedwinAmt}</span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj

  }
}
export default connect(mapStatesToProps)(TopLable)