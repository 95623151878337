import React from "react";
import "./UI/moneyWheel.css";
import { winImgs } from './UI/winImg';
import Wheel from "./UI/wheel";
import Close from "./UI/close";
import LeftButtonPanel from "./UI/leftButtonPanel";
import RightButtonPanel from "./UI/rightButtonPanel";
import takeSound from "../../../../../assets/sounds/take.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import clearSound from "../../../../../assets/sounds/clear.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import betoncardSound from "../../../../../assets/sounds/betoncard.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import wheelSound from "../../../../../assets/sounds/mw_wheel.mp3";
import tickSound from "../../../../../assets/sounds/tick.mp3";
//import staranimation from "../../../../../assets/games/d_up/staranimation.gif"
import mwstarsprnkle from "../../../games/sparkanim"
import { connect } from "react-redux";

class MoneyWheelTimer extends React.Component {
  constructor(props) {
    super(props);
    this.RightButtonPanel = React.createRef();
    this.betPanelRef = React.createRef();
    this.LeftButtonPanel = React.createRef();

    this.wheelDimension = window.innerHeight * 0.67;
    this.wheelDimensionWidth = window.innerWidth * 0.34;

    this.wheelString = [
      20, 1, 3, 1, 5, 1, 3, 1, 5, 1, 10,
      3, 1, 45, 1, 3, 1, 5, 1, 3, 1,
      10, 3, 1, 5, 1, 20, 1, 3, 1, 3,
      1, 5, 1, 10, 1, 3, 5, 1, 45, 1,
      3, 1, 5, 1, 3, 1, 10, 5, 1, 3, 1
    ];

    this.winImgIndex = [1, 3, 5, 10, 20, 39, 45]
    this.isSpectSent = false;

    this.state = {
      previousBetArray: [],
      finalPreviousBetArray: [],
      gameId: this.props.gameId,
      playerId: this.props.playerId,
      showBetButton: false,
      betButtonText: this.props.language.Prev,
      betButtonValue: 0,
      infoText: this.props.language.Play_Njoy,
      showWinPopUp: 0,
      betstr: "",
      bets: "",
      resultNo: 10,
      winAmount: 0,
      moneywheelResult: this.props.moneywheelResult,
      actBtn: false,
      Total_Bet_Amount: 0,
      disDoubleBtn: 1,
      disBetBtn: 1,
      disClearBtn: 1,
      winCover: false,
      timeTxt: 0,
      isBetSent: false,
      enableKeyboard: false,
      isResultUpated: false,
      balanceLbl: 0,
      selectedChipVal: 1,
      wins: [
        { id: 0, amount: 0 },
        { id: 1, amount: 0 },
        { id: 2, amount: 0 },
        { id: 3, amount: 0 },
        { id: 4, amount: 0 },
        { id: 5, amount: 0 },
        { id: 6, amount: 0 },

      ]
    };

    // this.betArray = [];
    this.winOnload = true;
    this.updatePlayerBalnce = false;
    this.previousPosition = undefined;
    this.betAmount = 1;
    this.isReady = false;
    // this.handId = 0;
    this.newLimits = [];
    this.minInnerLimit = 0;
    this.maxInnerLimit = 0;
    this.mw_takeAmt = 0;
    this.isGameActive = true;
    this.sparklemw = 1;
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.mwWheelKeyBoadEvent)
  }

  componentDidMount() {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
    console.log(this.props.gameState)
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

    this.preloadSparkAnim();
    let newChips
    if (this.props.mwt_chipValues) {
      newChips = this.props.mwt_chipValues.split(",");
      // console.log(newChips)
      // console.log(newChips[0]);
      let initChipVal = newChips[0];
      let lblTxt;
      if (initChipVal >= 1000 && initChipVal <= 999999) {
        lblTxt = "K"
      } else if (initChipVal >= 1000000 && initChipVal <= 999999999) {
        lblTxt = "M"
      } else if (initChipVal > 999999999) {
        lblTxt = "B"
      }

      this.state.selectedChipVal = initChipVal;
      // this.state.selectedChipLbl = initChipVal+""+lblTxt;
    }

    // if (this.props.maxBetAmt < 10) {
    if (this.props.maxBetAmt < Number(newChips[0])) {
      document.getElementById("chipImg_10").style.opacity = 0.5;
      document.getElementById("chipImg_10").style.pointerEvents = 'none';
      document.getElementById("chipImg_25").style.opacity = 0.5;
      document.getElementById("chipImg_25").style.pointerEvents = 'none';
      document.getElementById("chipImg_50").style.opacity = 0.5;
      document.getElementById("chipImg_50").style.pointerEvents = 'none';
      document.getElementById("chipImg_100").style.opacity = 0.5;
      document.getElementById("chipImg_100").style.pointerEvents = 'none';
      document.getElementById("chipImg_500").style.opacity = 0.5;
      document.getElementById("chipImg_500").style.pointerEvents = 'none';
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';

    // } else if (this.props.maxBetAmt < 50) {
    } else if (this.props.maxBetAmt < Number(newChips[1])) {
      document.getElementById("chipImg_50").style.opacity = 0.5;
      document.getElementById("chipImg_50").style.pointerEvents = 'none';
      document.getElementById("chipImg_100").style.opacity = 0.5;
      document.getElementById("chipImg_100").style.pointerEvents = 'none';
      document.getElementById("chipImg_500").style.opacity = 0.5;
      document.getElementById("chipImg_500").style.pointerEvents = 'none';
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';

    // } else if (this.props.maxBetAmt < 100) {
    } else if (this.props.maxBetAmt < Number(newChips[2])) {
      document.getElementById("chipImg_100").style.opacity = 0.5;
      document.getElementById("chipImg_100").style.pointerEvents = 'none';
      document.getElementById("chipImg_500").style.opacity = 0.5;
      document.getElementById("chipImg_500").style.pointerEvents = 'none';
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';

    // } else if (this.props.maxBetAmt < 500) {
    } else if (this.props.maxBetAmt < Number(newChips[3])) {
      document.getElementById("chipImg_500").style.opacity = 0.5;
      document.getElementById("chipImg_500").style.pointerEvents = 'none';
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';

    // } else if (this.props.maxBetAmt < 1000) {
    } else if (this.props.maxBetAmt < Number(newChips[4])) {
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';
    // } else if (this.props.maxBetAmt < 5000) {
    } else if (this.props.maxBetAmt < Number(newChips[5])) {
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';
    }

    this.clearSound = new Audio(clearSound);
    this.exitSound = new Audio(exitSound);
    this.betSound = new Audio(betSound);
    this.takeSound = new Audio(takeSound);
    this.winSound = new Audio(winSound);
    this.duploseSound = new Audio(duploseSound);
    this.wheelSound = new Audio(wheelSound);
    this.betoncardSound = new Audio(betoncardSound);
    this.tickSound = new Audio(tickSound);

    if (this.props.isIphone) {
      document.getElementById('iospopup').style.display = 'block';
    } else {
      document.getElementById('iospopup').style.display = 'none';
    }

    this.timeOut_1 = null;
    this.timeOut_2 = null;
    this.timeOut_3 = null;
    this.timeOut_4 = null;
    this.timeOut_5 = null;
    this.timeOut_6 = null;
    this.timeOut_7 = null;
    this.timeOut_8 = null;
    this.timeOut_9 = null;
    this.timeOut_10 = null;
    this.timeOut_11 = null;
    this.timeOut_12 = null;
    this.timeOut_13 = null;
    this.timeOut_14 = null;

    // document.getElementById("mw_Cover").style.display = "none";
    this.setState({ winCover: false })
    // document.getElementById("closeBtn").classList.add("mw_disBtn");
    // this.timeOut_12 = setTimeout(() => {
    //   document.getElementById("closeBtn").classList.remove("mw_disBtn");
    // }, 1000);

    // document.getElementById("closeBtn").addEventListener('mousedown', function onMouseDown(event) {
    //   if (!this.isMouseDown) {
    //     this.isMouseDown = true;
    //     document.getElementById("closeBtn").setAttribute('style', 'transform: scale(0.8);');
    //   }
    // });

    // document.getElementById("closeBtn").addEventListener('mouseup', function onMouseup(event) {
    //   this.isMouseDown = false;
    //   document.getElementById("closeBtn").setAttribute('style', 'transform: scale(1);');

    // });

    // document.getElementById("closeBtn").addEventListener('mouseout', function onMouseOut(event) {
    //   this.isMouseDown = false;
    //   document.getElementById("closeBtn").setAttribute('style', 'transform: scale(1);');

    // });

    console.log(this.props.gameState.history)

    if (this.props.balance > 0) {
      this.setState({ disBetBtn: 0, balanceLbl: this.props.balance });
    } else {
      //document.getElementById("mw_Cover").style.display = "block";
      this.setState({ disDoubleBtn: 1, disBetBtn: 1, disClearBtn: 1, winCover: true });
    }

    if (this.props.gameState.history && this.props.gameState.history.length == 0) {
      this.setState({ disBetBtn: 1 });
    } else {
      // this.RightButtonPanel.Gamestate(this.props.gameState);
      this.setState({ disBetBtn: 0 });
    }
    this.RightButtonPanel.updateGamestate(this.props.gameState);

    if (this.props.gameState && this.props.gameState.response && this.props.gameState.response.resultNum) {
      let wheelnumber = this.props.gameState.response.resultNum;
      console.log(wheelnumber);
      const displaywlnum = this.wheelString[wheelnumber];
      if (this.props.gameState && this.props.gameState.history && this.props.gameState.history.length > 0) {
        document.getElementById("whlmdlnum").textContent = this.wheelString[this.props.gameState.history[this.props.gameState.history.length - 1].resultNo];
      }
    } else {
      this.setState({ disBetBtn: 1 });
    }

    // if (this.props.gameState.takeAmount > 0) {

    //   // this.mw_takeAmt = this.props.gameState.takeAmount;
    //   this.setState({ winCover: true })
    //   document.getElementById("moneywheelBtn").classList.add("moneywheelbuttonglow");
    //   //document.getElementById("mw_Cover").style.display = "block";
    //   document.getElementById("betButton").style.zIndex = "350";

    //   document.getElementById("doubleButton").classList.add("mw_disBtn");
    //   document.getElementById("clearButton").classList.add("mw_disBtn");
    //   document.getElementById("betButton").classList.add("mw_disBtn");

    //   this.timeOut_10 = setTimeout(() => {
    //     this.setState({          
    //       btnActive: true,
    //     })

    //     if (document.getElementById("timeSpan").innerHTML > 5 && document.getElementById("timeSpan").innerHTML < 29 && !this.state.isResultUpated) {
    //       document.getElementById("betButton").classList.remove("mw_disBtn");
    //       this.setState({
    //         enableKeyboard: true,
    //         disBetBtn: 0,
    //       })
    //     }else{
    //       if(!this.state.isResultUpated){
    //         this.setState({ enableKeyboard: true,           
    //           betbtnValue: 2,
    //           disBetBtn: 0,
    //           }); 
    //       }
    //     }  
    //   }, 1000)

    //   this.setState({
    //     winCover: true,
    //     disClearBtn: 1,
    //     btnActive: true,
    //     betButtonText: this.props.language.TAKE,
    //     betButtonValue: 2,
    //     infoText: this.props.language.ClickTake,   //"Please Click on the TAKE",
    //     showWinPopUp: this.props.gameState.response.winAmount,
    //     winAmount: this.props.gameState.response.winAmount,
    //     Total_Bet_Amount: this.props.gameState.response.betAmount,
    //     isBetSent: true,
    //   })

    //   let mwWinImg = document.getElementById('mwWinImg');

    //   if (mwWinImg) {
    //     document.getElementById('mwWinImg').style.display = 'block';
    //     console.log(this.props.gameState.response.resultNo);
    //     console.log(this.winImgIndex[this.props.gameState.response.resultNo]);

    //     if (this.props.gameState.response.resultNum == 39) {
    //       mwWinImg.innerHTML = `<img src=${winImgs[5]} />`
    //     } else if (this.props.gameState.response.resultNum == 13) {
    //       mwWinImg.innerHTML = `<img src=${winImgs[6]} />`
    //     } else {
    //       mwWinImg.innerHTML = `<img src=${winImgs[this.winImgIndex.indexOf(this.props.gameState.response.resultNo)]} />`
    //     }
    //   }

    //   let prevBet = this.props.gameState.betRequest.betString;
    //   this.state.previousBetArray.push({
    //     amount: prevBet.split(",")
    //   })
    //   console.log("previousBetArray")
    //   console.log(this.state.previousBetArray)

    //   if (this.state.previousBetArray) {
    //     this.isReady = true;
    //     for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
    //       this.state.finalPreviousBetArray.push({
    //         id: i,
    //         amount: Number(this.state.previousBetArray[0].amount[i]),
    //       })
    //     }
    //   }

    //   this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray })

    //   if (this.state.finalPreviousBetArray) {
    //     for (let i = 0; i < this.state.wins.length; i++) {
    //       if (this.state.wins[i].id == this.state.finalPreviousBetArray[i].id) {
    //         this.state.wins[i].amount += this.state.finalPreviousBetArray[i].amount;
    //         this.betPanelRef.current.updateBetAmt(i, this.state.wins[i].amount);
    //       }
    //     }
    //   }
    //   console.log(this.state.wins)
    // } else 

    if (this.props.gameState.handId == this.props.gameState.oldHandId && this.props.gameState.takeAmount != undefined) {
      let prevBet = this.props.gameState.betRequest.betString;
      this.setState({ enableKeyboard: false });

      document.getElementById("betButton").classList.add("mw_disBtn");
      document.getElementById("doubleButton").classList.add("mw_disBtn");
      document.getElementById("clearButton").classList.add("mw_disBtn");

      this.state.previousBetArray.push({
        amount: prevBet.split(",")
      })
      console.log("previousBetArray")
      console.log(this.state.previousBetArray)

      if (this.state.previousBetArray) {
        this.isReady = true;
        for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
          this.state.finalPreviousBetArray.push({
            id: i,
            amount: Number(this.state.previousBetArray[0].amount[i]),
          })
        }
      }

      this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray, isBetSent: true })

      if (this.state.finalPreviousBetArray) {
        for (let i = 0; i < this.state.wins.length; i++) {
          if (this.state.wins[i].id == this.state.finalPreviousBetArray[i].id) {
            this.state.wins[i].amount += this.state.finalPreviousBetArray[i].amount;
            this.betPanelRef.current.updateBetAmt(i, this.state.wins[i].amount);
          }
        }
      }
      this.setState({
        actBtn: false,
        betButtonText: this.props.language.BetOK,
        betButtonValue: 1,
        disBetBtn: 1,
        disClearBtn: 1,
        disDoubleBtn: 1,
        Total_Bet_Amount: this.props.gameState.betRequest.betAmount,
        winCover: true,
        infoText: this.props.language.BetAccepted + this.props.gameState.betRequest.betAmount,
      });
    } else {
      // console.log(this.state.betString.length);
      if (this.props.gameState.betRequest) {
        this.setState({ disBetBtn: 0 });
      } else {
        this.setState({ disBetBtn: 1 });
      }
      this.setState({
        enableKeyboard: true
      })
      console.log(this.state.previousBetArray.length)
      //document.getElementById("mw_Cover").style.display = "none";
      this.setState({ betButtonText: this.props.language.Prev, betButtonValue: 0, disDoubleBtn: 1, disClearBtn: 1, winCover: false, isBetSent: false });
    }

    this.timeOut_14 = setTimeout(() => {
      document.addEventListener("keyup", this.mwWheelKeyBoadEvent)
    }, 1000);

    if (this.props.mwt_chipValues) {
      console.log(this.props.mwt_chipValues)
      let newChips = this.props.mwt_chipValues.split(",")
      console.log(newChips)  // ['1', '5', '10', '25', '50', '100', '500', '1000']
      let intialChip = newChips[0]
      console.log(intialChip) // 1
      this.betAmount = Number(intialChip)
    }

    if (this.props.mwt_innerLimits) {
      console.log(this.props.mwt_innerLimits) //2000-10000|2000-10000|2000-10000|2000-10000|2000-10000|2000-10000|2000-10000

      this.newLimits = this.props.mwt_innerLimits.split("|");
      console.log(this.newLimits) // ['2000-10000', '2000-10000', '2000-10000', '2000-10000', '2000-10000', '2000-10000', '2000-10000']

      console.log(this.minInnerLimit)
      console.log(this.maxInnerLimit)
      for (let t = 0; t < this.newLimits.length; t++) {
        this.newLimits[t] = this.newLimits[t].split("-"); // [['2000','10000'],['2000','10000'] ,['2000','10000'] ,['2000','10000'] ,['2000','10000'] ,[ '2000','10000'],[ '2000','10000']]
        console.info("money--wheel--new--limits   :" + `${t}` + "   " + `${this.newLimits[t]}`)
      }
      console.log(this.newLimits)

    }
    console.log(this.state.betButtonValue)
    console.log(this.state.disBetBtn)


    const { childRef } = this.props;
    childRef(this);
  }

  moneyWheelTimerGetTime() {
    console.log(this.props.mw_timesend);
    console.log("*************   WM TAKE on Time Load **********************");
    console.log(this.props.gameState.takeAmount);
    console.log("*************   WM TAKE on Time Load **********************");
    if (this.props.mw_timesend) {
      console.log(this.props.mw_timesend);
      if (this.betPanelRef && this.betPanelRef.current) {
        this.betPanelRef.current.sendTimer(this.props.mw_timesend);
      }
    }
  }


  /*

   const sparklemw = `sparkanimation${this.sparklemw}`;
      //console.log(sparklemw)
      const starssprinklemw = mwstarsprnkle[sparklemw];
      //console.log(starssprinklemw)
      if (document.getElementById("mw_sprkl")) {
        document.getElementById("mw_sprkl").src = starssprinklemw

  */

  preloadSparkAnim() {
    let loadedSparkImages = 0;
    // Preload each image
    for (let n = 1; n <= 100; n++) {
      let spark_img = new Image();
      if (loadedSparkImages !== 100) {
        loadedSparkImages++;
        spark_img.onload = loadedSparkImages;
        spark_img.onerror = loadedSparkImages; // Call imageLoaded even if there's an error loading the image
        const sparkImageName =  `sparkanimation${n}`;
        spark_img.src = mwstarsprnkle[sparkImageName];
        console.log(spark_img.src);
      }
    }
  }

  mwWheelKeyBoadEvent = (eve) => {

    console.log(eve)
    switch (eve.code) {
      case "Enter":
      case "NumpadEnter":
        console.log("betButtonValue: " + this.state.betButtonValue + "disbetButton   :" + this.state.disBetBtn)
        if (this.state.betButtonValue == 0 && this.state.disBetBtn == 0 && this.state.enableKeyboard) {
          this.rightButtonPanelHandler("PREV");
        } else if (this.state.betButtonValue == 1 && this.state.disBetBtn == 0 && !this.state.isBetSent && this.state.enableKeyboard) {
          this.rightButtonPanelHandler("SPIN");
        }
        break;
      case "Space":
        console.log(this.state.betButtonValue + "   " + this.state.disBetBtn + '   ' + this.state.enableKeyboard);
        if (this.state.betButtonValue == 2 && this.state.disBetBtn == 0 && this.state.enableKeyboard == true) {
          this.rightButtonPanelHandler("TAKE");
        }
        break;
      case "KeyC":
        if (this.state.betButtonValue == 1 && this.state.disClearBtn == 0 && this.state.enableKeyboard) {
          this.rightButtonPanelHandler("clear");
        }
        break;
      // case "Escape":
      //   this.rightButtonPanelHandler("exit");
      //   break;
      default:
        break;
    }
  }

  gameSessionClosed() {
    this.isGameActive = false;
    this.setState({
      enableKeyboard: false
    })
    this.exitSound.play();
    this.clearSound.pause();
    this.betSound.pause();
    this.takeSound.pause();
    this.winSound.pause();
    this.duploseSound.pause();
    this.clearSound = {};
    this.exitSound = {};
    this.betSound = {};
    this.takeSound = {};
    this.wheelSound.pause();
    this.wheelSound = {};

    window.clearTimeout(this.timeOut_1);
    window.clearTimeout(this.timeOut_2);
    window.clearTimeout(this.timeOut_3);
    window.clearTimeout(this.timeOut_4);
    window.clearTimeout(this.timeOut_5);
    window.clearTimeout(this.timeOut_6);
    window.clearTimeout(this.timeOut_7);
    window.clearTimeout(this.timeOut_8);
    window.clearTimeout(this.timeOut_9);
    window.clearTimeout(this.timeOut_10);
    window.clearTimeout(this.timeOut_11);
    window.clearTimeout(this.timeOut_12);
    window.clearTimeout(this.timeOut_13);
    window.clearTimeout(this.timeOut_14);
  }

  gameCloseHandler(game) {
    this.betPanelRef.current.closeGame();
    this.gameSessionClosed();

    this.props.action(game);
  }

  wheelHandler(position) {
    this.isSpectSent = false;

    this.props.gamstateHandle(this.state.gameId);

    if (this.props.moneywheelResult.winAmount > 0) {
      this.setState({
        winAmount: this.props.moneywheelResult.winAmount,
        infoText: this.props.language.WonHand,//"You won the hand!!!"        
        disDoubleBtn: 1,
        disBetBtn: 1,
        disClearBtn: 1,
      });
      console.log("WinAmount: " + this.state.winAmount);
    }

    console.log("MoneyWheelWinAmount   : " + this.props.moneywheelResult.winAmount)
    if (this.isGameActive) {
      this.declareWin();
    }

    if (this.updatePlayerBalnce == false) {
      this.updatePlayerBalnce = true;
    }
  }

  declareWin() {
    window.clearTimeout(this.timeOut_2);
    window.clearTimeout(this.timeOut_3);
    window.clearTimeout(this.timeOut_4);
    window.clearTimeout(this.timeOut_5);
    this.timeOut_2 = null;
    this.timeOut_3 = null;
    this.timeOut_4 = null;
    this.timeOut_5 = null;
    this.setState({ enableKeyboard: false })

    if (this.props.moneywheelResult.winAmount > 0) {
      if (this.isGameActive) {
        if (document.getElementById('iospopup').style.display == "none") {
          this.winSound.play();
        }

        let mwWinImg = document.getElementById('mwWinImg');
        if (mwWinImg) {
          document.getElementById('mwWinImg').style.display = 'block';
          console.log(this.props.moneywheelResult.resultNo);
          console.log(this.winImgIndex[this.props.moneywheelResult.resultNo]);

          if (this.props.moneywheelResult.resultNum == 39) {
            mwWinImg.innerHTML = `<img src=${winImgs[5]} />`
          } else if (this.props.moneywheelResult.resultNum == 13) {
            mwWinImg.innerHTML = `<img src=${winImgs[6]} />`
          } else {
            mwWinImg.innerHTML = `<img src=${winImgs[this.winImgIndex.indexOf(this.props.moneywheelResult.resultNo)]} />`
          }
        }
      }
      if (document.getElementById("winPop")) {
        document.getElementById("winPop").style.display = "block";
        this.mwsparkl = setInterval(this.mwsparkleanimat, 35);
      }
      this.getPlayerInfo();
      this.setState({ showWinPopUp: this.props.moneywheelResult.winAmount })

      this.timeOut_2 = setTimeout(() => {
        if (document.getElementById("winPop")) {
          document.getElementById("winPop").style.display = "none";
        }

        this.RightButtonPanel.updateGamestate(this.props.gameState);

        this.setState({
          balance: this.props.balance,
          winAmount: 0,
          showWinPopUp: 0,
          disBetBtn: 0,
          infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
        });
        if (document.getElementById("betButton")) {
          document.getElementById("betButton").style.zIndex = "360";
          if (document.getElementById("betButton").classList.contains("mw_disBtn")) {
            document.getElementById("betButton").classList.remove("mw_disBtn");
          }
        }

        for (let i = 0; i < this.state.wins.length; i++) {
          this.betPanelRef.current.updateBetAmt(i, this.state.wins[i].amount)
        }
        document.getElementById("mwWinImg").style.display = "none";

        this.updateWinsArray(0);
        this.mw_takeAmt = 0;

        this.setState({
          betButtonText: this.props.language.Prev,
          betButtonValue: 0,
          Total_Bet_Amount: 0,
          btnActive: false,
          totalBet: this.betAmount,
          totalBet: 0,
          winAmount: 0,
          actBtn: false,
          betsDone: false,
          winBet: 0,
          winCover: false,
          enableKeyboard: true,
          disDoubleBtn: 1,
          disBetBtn: 0,
          disClearBtn: 1,
          glowTakeBtn: 0,
          infoText: this.props.language.Play_Njoy,
          showBetButton: false,
        })
        console.log("WinAmount: " + this.state.winAmount);

        

        if (this.props.isPlayerBanned) {
          this.gameCloseHandler("target");
          const body2 = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "EXIT_GAME",
            object: {
              gameId: this.props.gameId,
              playerId: Number(this.state.playerId),
            }
          }
          this.props.network.sendof(body2);
          this.body2 = {};
          this.props.player_banned();
        }
        console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
        if (this.props.gameUnderMaintanance) {
          this.gameCloseHandler("target");
          this.props.game_maintanance();
        }
        console.log("deviceBlocked " + this.props.isdeviceBlocked);
        if (this.props.isdeviceBlocked) {
          console.log("---------------------------")
          this.gameCloseHandler("target");
          console.log("---------------------------")

          const body4 = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "EXIT_GAME",
            object: {
              gameId: this.props.gameId,
              playerId: Number(this.state.playerId),
            }
          }
          this.props.network.sendof(body4);
          this.body4 = {};

          this.props.device_blocked();
        }

      }, 3000);

      this.setState({
        infoText: this.props.language.WonHand,
        showWin: this.props.moneywheelResult.resultNum,
        winningAmount: this.props.moneywheelResult.winAmount
      });
      this.state.resultNo = this.props.moneywheelResult.resultNum;
    } else {
      if (this.isGameActive) {
        if (document.getElementById('iospopup').style.display == "none") {
          this.duploseSound.play();
        }
      }
      this.getPlayerInfo();
      this.timeOut_4 = setTimeout(() => {
        this.updateWinsArray(0);
        console.log(this.props.gameState.history);
        this.setState({
          Total_Bet_Amount: 0,
          balance: this.props.balance,
          totalBet: 0,
          winCover: false,
          isBetSent: false,
          enableKeyboard: true
        });

        if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount) {
          this.setState({
            showBetButton: false,
            actBtn: false,
            disBetBtn: 0,
            betButtonText: this.props.language.Prev,
            betButtonValue: 0,
          });
        }
      }, 1000);
      this.timeOut_5 = setTimeout(() => {
        if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount) {
          if (document.getElementById("betButton") && document.getElementById("betButton").classList.contains("mw_disBtn")) {
            document.getElementById("betButton").classList.remove("mw_disBtn");
          }
        }
        this.RightButtonPanel.updateGamestate(this.props.gameState);
      }, 1000)
    }
    this.isReady = false;
    console.log("this.isReady " + this.isReady);
  }

  betHandler(amount) {
    console.log("Chip Amount: " + amount);
    this.playTakeSound();
    this.betAmount = amount;
  }

  betDone() {
    this.setState({
      enableKeyboard: true,
    })
  }

  betPanelHandler(betOn) {
    this.setState({
      enableKeyboard: false,
    })
    console.log(this.props.minBetAmt)
    // console.log(this.props.maxBetAmt)
    document.getElementById("moneywheelBtn").classList.add("moneywheelbuttonglow");
    console.log("bet_based_On_Id  :" + betOn)
    // console.log(this.state.showBetButton);
    this.state.showBetButton = false; // Don't delete.
    this.setState({
      showBetButton: false,
      betButtonText: this.props.language.BetOK,
      betButtonValue: 1,
      disDoubleBtn: 0,
      disClearBtn: 0,
      disBetBtn: 0,
      actBtn: true,
      infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,

      // infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt,
    });

    this.betoncardSound1 = new Audio(betoncardSound);
    this.betoncardSound1.play();
    console.log( this.state.wins);
    // let totalBetVal = this.state.wins.reduce((prev, cur) => Number(prev) + Number(cur.amount), this.betAmount).toFixed(2);
     let totalBetVal = this.state.wins.reduce((prev, cur) => Number(prev) + Number(cur.amount), this.betAmount);
    console.log("Total Bet Amt: " + totalBetVal);
    console.log("this.state.showBetButton: " + this.state.showBetButton);
    console.log(this.betAmount);

    if (this.betAmount == 0) {
      this.state.showBetButton = false;
      this.setState({
        showBetButton: false
      });
    }
    if (!this.state.showBetButton) {
      if (Number(this.betAmount) !== 0) {
        console.log("*************************--------------------------------**********************");
        console.log(this.props.maxBetAmt)
        console.log(totalBetVal)
        console.log("*************************--------------------------------**********************");
        if (totalBetVal > this.props.maxBetAmt) {
          this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
          // this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt })
        }
        else if (totalBetVal <= this.props.balance) {
          if (this.state.wins[betOn] != null && this.state.wins[betOn].amount != null) {
            let maxInnerLimit = this.props.maxBetAmt;

            console.log("MoneyWheelMaximumInnerLimit: " + maxInnerLimit)
            if (this.state.wins[betOn].amount + Number(this.betAmount) <= Number(maxInnerLimit)) {
              console.log("newLimits: "+this.newLimits)
              console.log(this.newLimits[betOn][1])
              console.log("MoneyWheel Maximum InnerLimit " + this.newLimits[betOn][1])
              if (this.state.wins[betOn].amount + Number(this.betAmount) <= this.newLimits[betOn][1]) {
                this.setState({ Total_Bet_Amount: totalBetVal, balanceLbl: (this.props.balance - totalBetVal) });
                for (let i = 0; i < this.state.wins.length; i++) {
                  if (this.state.wins[i].id == betOn) {
                    this.state.wins[i].amount += Number(this.betAmount);
                  }
                }
                this.setState({ wins: this.state.wins, isBetSent:false});
                if (this.betPanelRef && this.betPanelRef.current) {
                  // this.betPanelRef.current.updateBetAmt(betOn, (this.state.wins[betOn].amount).toFixed(1));
                  this.betPanelRef.current.updateBetAmt(betOn, (this.state.wins[betOn].amount));


                  
                }
                this.previousPosition = betOn;
                // console.log(`${this.betAmount} rupees place on ${betOn}`);
                if (document.getElementById("doubleButton").classList.contains("mw_disBtn")) {
                  document.getElementById("doubleButton").classList.remove("mw_disBtn");

                }
                if (document.getElementById("clearButton").classList.contains("mw_disBtn")) {
                  document.getElementById("clearButton").classList.remove("mw_disBtn");
                }
                this.isReady = true;

                if (document.getElementById("betButton").classList.contains("mw_disBtn")) {
                  document.getElementById("betButton").classList.remove("mw_disBtn");
                }

                // console.log("this.isReady " + this.isReady)
                this.setState({ showBetButton: false });
              } else {
                //this.setState({ infoText: this.props.language.MaxInrBetAmt + "" + this.newLimits[betOn][1] });
              }
            } else {
              //this.setState({ infoText: this.props.language.MaxInrBetAmt + "" + this.changeAmtlabl(maxInnerLimit) });
            }
          }
        } else {
          this.setState({ infoText: this.props.language.LowBalance, actBtn: false });

          console.log("amount reduces to your balance");
        }
      } else {
        console.log(this.betAmount);
        console.log(this.state.Total_Bet_Amount);

        if (this.betAmount == 0 && this.state.Total_Bet_Amount == 0) {
          document.getElementById("moneywheelBtn").classList.remove("moneywheelbuttonglow");
          // this.setState({ infoText: this.props.language.Min_Bet + this.props.minBetAmt });
          this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });

          this.timeOut_6 = setTimeout(() => {
            this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
            // this.setState({ infoText: this.props.language.Play_Njoy + this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt });
          }, 1500)
        } else {
          this.setState({ infoText: this.props.language.Play_Njoy, betButtonText: this.props.language.BetOK, betButtonValue: 1, actBtn: true });
        }

        for (let i = 0; i < this.state.wins.length; i++) {
          if (this.state.wins[i].id == betOn) {
            this.state.wins[i].amount = 0;
          }
        }
        console.log(`awlwctedChipValue ${this.betAmount}+ "            " +${this.state.wins[betOn].amount}`)
        this.betPanelRef.current.updateBetAmt(betOn, this.state.wins[betOn].amount)
        // totalBetVal = Number(this.state.wins.reduce((prev, cur) => Number(prev) + Number(cur.amount), this.betAmount)).toFixed(2)
        totalBetVal = this.state.wins.reduce((prev, cur) => Number(prev) + Number(cur.amount), this.betAmount).toFixed(2);

        this.setState({ Total_Bet_Amount: totalBetVal })
        console.log(totalBetVal)

        if (totalBetVal == 0) {
          document.getElementById("moneywheelBtn").classList.remove("moneywheelbuttonglow");

          this.setState({
            betButtonText: this.props.language.Prev,
            betButtonValue: 0,
            actBtn: false,
            disDoubleBtn: 1,
            disBetBtn: 0,
            disClearBtn: 1,
            glowTakeBtn: 1,
            btnActive: false,
          })
        } else {
          // alert("....")
          this.setState({ disDoubleBtn: 0, disClearBtn: 0, showBetButton: true });
          if (this.state.betButtonValue == 0) {
            this.setState({
              showBetButton: true,
              betButtonText: this.props.language.BetOK,
              betButtonValue: 1,
            });
          }
        }
      }
    } else {
      //this.setState({ infoText: this.props.language.ClickTake });
    }
  }

  disableKeyBoard() {
    this.setState({
      enableKeyboard: false,
      winCover: true
    })
  }

  rightButtonPanelHandler(action) {
    switch (action) {
      case "SPIN":
        document.getElementById("mw_Cover").style.display = 'block';
        document.getElementById("mw_Cover").style.zIndex = "355";



        console.log("rorate money Wheel Bet_OK");
        console.log("Total Bet Amount: " + this.state.Total_Bet_Amount);

        if (this.state.Total_Bet_Amount != 0 && this.state.Total_Bet_Amount < this.props.minBetAmt) {
          // document.getElementById("mw_Cover").style.display = 'none';
          this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });

          // this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt, winCover: false })
          // this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
        } else {
          let wheelnum = document.getElementById("whlmdlnum")
          if (wheelnum.firstChild) {
            wheelnum.removeChild(wheelnum.firstChild);
          }

          this.setState({
            enableKeyboard: false,
            winCover: true
          })

          console.log("spin the wheel: " + this.isReady);
          console.log("this.state.totalBet: " + this.state.Total_Bet_Amount);
          this.isReady = true;
          if (this.state.Total_Bet_Amount != 0 && this.isReady) {
            this.state.betstr = "";
            this.state.bets = "";
            let betValid = [];

            console.log(this.newLimits)
            if (this.props.balance >= this.state.Total_Bet_Amount) {
              console.log(this.state.wins);
              let innerMin_Limit = this.props.minBetAmt;

              if (this.state.Total_Bet_Amount < this.props.minBetAmt || this.state.Total_Bet_Amount > this.props.maxBetAmt) {
                this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
                // this.setState({ infoText: this.props.language.Min_Bet + this.props.minBetAmt + this.props.language.Max_Bet + this.props.maxBetAmt });
              }

              for (let i = 0; i < this.state.wins.length; i++) {
                this.state.bets += this.state.wins[i].amount + ",";
                if (this.state.wins[i].amount > 0) {
                  console.log(this.state.wins[i].amount);
                  console.log(Number(this.newLimits[i][0]));

                  if (this.state.wins[i].amount < Number(this.newLimits[i][0])) {
                    // this.setState({ infoText: this.props.language.InerLmt + Number(this.newLimits[i][0]) });
                  } else if (this.state.wins[i].amount > Number(this.newLimits[i][1])) {
                    //this.setState({ infoText: this.props.language.MaxInrBetAmt + Number(this.newLimits[i][1]) });
                  }

                  // if(this.state.wins[i].amount < this.props.minBetAmt || this.state.wins[i].amount > this.props.maxBetAmt){
                  //   this.setState({ infoText: this.props.language.Min_Bet+this.props.minBetAmt+this.props.language.Max_Bet+this.props.maxBetAmt});
                  // }

                  // console.log(this.state.wins[i].amount);
                  console.log(this.props.minBetAmt);
                  console.log(this.props.maxBetAmt);
                  console.log(this.newLimits[i][0]);
                  console.log(this.newLimits[i][1]);

                  if (this.state.Total_Bet_Amount >= this.props.minBetAmt && this.state.Total_Bet_Amount <= this.props.maxBetAmt && this.state.wins[i].amount >= Number(this.newLimits[i][0]) && this.state.wins[i].amount <= Number(this.newLimits[i][1])) {
                    betValid[i] = true;
                  }
                  else {
                    betValid[i] = false;
                  }
                }
                else {
                  betValid[i] = true;
                }
              }

              this.state.betstr = this.state.bets.slice(0, -1);

              let sendBet = true;
              for (let u = 0; u < betValid.length; u++) {
                if (betValid[u] == false) {
                  sendBet = false;
                  break;
                }
              }
              console.log(sendBet)
              if (sendBet) {
                this.setState({
                  disDoubleBtn: 1,
                  disBetBtn: 1,
                  disClearBtn: 1,
                });
                document.getElementById("moneywheelBtn").classList.remove("moneywheelbuttonglow");
                this.placebet();
                this.isReady = false;
              }
              return Number(this.state.betstr).toFixed(2);

            } else {
              this.setState({ infoText: this.props.language.LowBalance, winCover: false });
              // document.getElementById("mw_Cover").style.display = 'none';
            }
          }
        };

        break;
      // case "TAKE":
      //   if (this.state.betButtonValue == 2 && this.state.disBetBtn == 0) {
      //     document.getElementById("moneywheelBtn").classList.remove("moneywheelbuttonglow");
      //     console.log("take amount action");
      //     // this.mw_takeAmt = 0;
      //     this.playTakeSound();
      //     this.setState({
      //       disBetBtn: 1, showWinPopUp: 0, betButtonText: this.props.language.Prev, betButtonValue: 0, isBetSent: false
      //     });
      //     this.takeAmountResult();
      //   }
      //   break;
      case "PREV":
        console.log("Previous Bet action");
        this.betSound.play();
        // document.getElementById("moneywheelBtn").classList.remove("moneywheelbuttonglow");
        document.getElementById("moneywheelBtn").classList.add("moneywheelbuttonglow");

        this.setState({ actBtn: false, disClearBtn: 0, disDoubleBtn: 0 });
        this.isReady = true;
        console.log("this.isReady " + this.isReady)
        this.PreviousBetMethod();
        break;
      case "double":
        this.betSound.play();
        this.updateWinsArray(2);
        break;
      case "clear":
        document.getElementById("doubleButton").classList.add("mw_disBtn");
        document.getElementById("clearButton").classList.add("mw_disBtn");

        document.getElementById("moneywheelBtn").classList.remove("moneywheelbuttonglow");
        if (document.getElementById('iospopup').style.display == "none") {
          this.clearSound.play();
        }

        this.updateWinsArray(0);

        this.setState({
          //  betButtonText: "PREV",
          infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
          // infoText: this.props.language.Play_Njoy + this.props.language.Min_Bet + this.props.minBetAmt + this.props.language.Max_Bet + this.props.maxBetAmt,
          betButtonText: this.props.language.Prev,
          betButtonValue: 0, Total_Bet_Amount: 0,
          disBetBtn: 0, disClearBtn: 1, disDoubleBtn: 1,
          balanceLbl: this.props.balance
        });

        break;
      // case "exit":
      //   this.gameCloseHandler("target");
      //   const body = {
      //     sessionId: sessionStorage.getItem("sessionId"),
      //     CN: "EXIT_GAME",
      //     object: {
      //       gameId: this.state.gameId,
      //       playerId: Number(this.state.playerId),
      //     },
      //   };
      //   this.props.network.sendof(body);
      //   this.body = {};
      //   break;
      case "disableBtns":
        this.setState({ disDoubleBtn: 1, disBetBtn: 1, disClearBtn: 1, disZoomBtn: 1, enableKeyboard: false });
        document.getElementById("doubleButton").classList.add("mw_disBtn");
        document.getElementById("clearButton").classList.add("mw_disBtn");
        document.getElementById("betButton").classList.add("mw_disBtn");
        document.getElementById("moneywheelBtn").classList.remove("moneywheelbuttonglow");
        break;
      case "PlayTickSound":
        if (document.getElementById('iospopup').style.display == "none") {
          this.tickSound.play();
        }
        break;
      case "StopTickSound":
        if (document.getElementById('iospopup').style.display == "none") {
          this.tickSound.pause();
        }

        break;
      default:
        break;
    }
  }

  clearBetAnim() {
    document.getElementById("moneywheelBtn").classList.remove("moneywheelbuttonglow");
    document.getElementById("betButton").classList.add("mw_disBtn");
    this.setState({
      disBetBtn: 1,
      enableKeyboard: false
    })
  }


  Chek_Spec() {
    console.log(" totalBetAmt  " + this.state.Total_Bet_Amount);
    console.log("TAKE Amount: " + this.mw_takeAmt);
    console.log(" this.isSpectSent: " + this.isSpectSent);

    if (this.state.Total_Bet_Amount < this.props.minBetAmt && this.isSpectSent == false) {
      this.isSpectSent = true;
      this.props.spectatorCount(0, this.props.gameState.handId);
    } else if (this.mw_takeAmt != 0 && this.isSpectSent == false) {
      this.isSpectSent = true;
      this.props.spectatorCount(0, this.props.gameState.handId);
    }
  }

  playTakeSound() {
    this.takeSound.play();
  }

  updateMessage(erroCode) {
    console.log("--------- " + this.props.infoText);
    this.setState({ infoText: this.props.infoText });

    if (erroCode == "game.bet.rejected.takeAmount") {
      this.setState({
        betButtonText: this.props.language.TAKE, betButtonValue: 2, btnActive: true, winCover: false,
      });
      if (document.getElementById("betButton")) {
        document.getElementById("betButton").style.zIndex = "350";
      }
    } else if (erroCode == "game.bet.invalid") {
      this.updateWinsArray(0);
      this.setState({
        betButtonText: this.props.language.Prev,
        betButtonValue: 0,
        btnActive: false,
        disBetBtn: 0,
        winCover: false,
      });

      if (document.getElementById('mw_Cover')) {
        //document.getElementById('mw_Cover').style.display = 'none';
      }

      this.timeOut_7 = setTimeout(() => {
        this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
        // this.setState({ infoText: this.props.language.Play_Njoy + this.props.language.Min_Bet + this.props.minBetAmt + this.props.language.Max_Bet + this.props.maxBetAmt });
      }, 1000);

    } else if (erroCode == "game.bet.accepted") {
      console.log(this.state.Total_Bet_Amount) //Don't delete
      this.setState({ isBetSent: true });
    }
    console.log("error_code: " + this.props.error_code);
  }


  PreviousBetMethod() {

    console.log(this.props.gameState.betRequest)
    console.log(this.props.gameState.betRequest.betAmount)
    console.log(this.props.balance)
    console.log(this.props.gameState.betRequest.betAmount + "  previousBetButtonFunc " + this.props.maxBetAmt)

    this.setState({
      previousBetArray: [],
      finalPreviousBetArray: [],
    })
    this.state.previousBetArray = [];
    this.state.finalPreviousBetArray = [];
    console.log(this.state.previousBetArray);

    if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount <= this.props.balance) {
      if (this.props.gameState.betRequest.betAmount > this.props.maxBetAmt) {
        this.setState({ infoText: this.props.language.PreBet_MaxLmt })

      } else {
        let prevBet = this.props.gameState.betRequest.betString;
        console.log(prevBet);

        // const str = "1,1,1,0,0,0,0";
        const preVBetNum = [...prevBet.split(",")].map(Number);

        console.log(preVBetNum);
        console.log(this.state.previousBetArray);
        for (let t = 0; t < 7; t++) {
          //console.log(preVBetNum[t])
          this.state.previousBetArray.push({
            amount: preVBetNum[t]
          })
        }

        console.log(this.state.previousBetArray);
        // this.state.previousBetArray.push({
        //   amount: arr
        // })
        //this.setState({ previousBetArray: this.state.previousBetArray });

        if (this.state.previousBetArray) {
          this.isReady = true;
          this.toCreatePreviousBetArray()
        }

        if (this.state.betButtonValue === 0) {
          // let valId10 = document.getElementById('bet_take');
          // valId10.classList.remove('ar_bt_active');
          this.setState({
            showBetButton: true,
            betButtonText: this.props.language.BetOK,
            betButtonValue: 1,
          });
          this.isReady = true;
        }
        this.setState({
          // betString: this.state.betString,
          // previousBetArray: this.state.previousBetArray,
          disClearBtn: 0,
          disDoubleBtn: 0,
          disZoomBtn: 0,
          btnActive: true,
          isBetSent:false
        })

      }
    } else {
      this.setState({ infoText: this.props.language.BetAmtMaxBal });

      document.getElementById("moneywheelBtn").classList.remove("moneywheelbuttonglow");

      this.setState({ actBtn: false, disClearBtn: 1, disDoubleBtn: 1 });
      this.isReady = false;
    }
  }


  toCreatePreviousBetArray() {
    console.log(this.state.previousBetArray.length)
    for (let i = 0; i < this.state.previousBetArray.length; i++) {
      console.log(this.state.previousBetArray[i].amount);
      this.state.finalPreviousBetArray.push({
        id: i,
        amount: Number(this.state.previousBetArray[i].amount),
      })
    }
    this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray })
    if (this.state.finalPreviousBetArray) {
      this.toGenerteBetString();
    }

  }

  toGenerteBetString() {
    for (let i = 0; i < this.state.wins.length; i++) {
      if (this.state.wins[i].id == this.state.finalPreviousBetArray[i].id) {
        this.state.wins[i].amount += this.state.finalPreviousBetArray[i].amount;
        this.betPanelRef.current.updateBetAmt(i, this.state.wins[i].amount);
        // this.betPanelRef.current.updateBetAmt(k, this.state.wins[k].amount)

      }
    }
    this.setState({
      wins: this.state.wins,
      betButtonText: this.props.language.BetOK,
      betButtonValue: 1,
      actBtn: true,
      previousBetArray: [],
      finalPreviousBetArray: [],
      Total_Bet_Amount: this.state.wins.reduce((prev, cur) => Number(prev) + Number(cur.amount), 0)
    });
    this.state.previousBetArray = [];
    this.state.finalPreviousBetArray = [];

    console.log(this.state.wins);

    if (document.getElementById("doubleButton").classList.contains("mw_disBtn")) {
      document.getElementById("doubleButton").classList.remove("mw_disBtn");
    }

    if (document.getElementById("clearButton").classList.contains("mw_disBtn")) {
      document.getElementById("clearButton").classList.remove("mw_disBtn");
    }
  }

  takeAmountResult() {
    for (let i = 0; i < this.state.wins.length; i++) {
      this.betPanelRef.current.updateBetAmt(i, this.state.wins[i].amount)
    }
    document.getElementById("mwWinImg").style.display = "none";
    console.log("TAKE Amount Function");
    console.log("Remove Img Glow: " + this.resultNumber);
    this.updateWinsArray(0);
    this.mw_takeAmt = 0;
    // document.getElementById("mw_Cover").style.display = "none";

    const body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "TAKE_AMOUNT",
      object: {
        gameId: this.state.gameId,
        playerId: Number(this.state.playerId),
      },
    };
    this.props.network.sendof(body);
    this.body = {};

    let gs_body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "GET_GAME_STATE",
      object: {
        playerId: Number(this.props.playerId),
        gameId: this.props.gameId,
      },
    };
    this.props.network.sendof(gs_body);

    this.setState({
      betButtonText: this.props.language.Prev,
      betButtonValue: 0,
      Total_Bet_Amount: 0,
      btnActive: false,
      totalBet: this.betAmount,
      totalBet: 0,
      winAmount: 0,
      actBtn: false,
      betsDone: false,
      winBet: 0,
      winCover: false,
    })

    this.timeOut_8 = setTimeout(() => {
      this.setState({
        disDoubleBtn: 1,
        disBetBtn: 0,
        disClearBtn: 1,
        glowTakeBtn: 0,
        infoText: this.props.language.Play_Njoy,
        showBetButton: false,
      })

      // if (document.getElementById('mw_Cover')) {
      //   document.getElementById('mw_Cover').style.display = 'none';
      // }
      if (document.getElementById("betButton")) {
        document.getElementById("betButton").style.zIndex = "350";
      }
    }, 800);
    console.log("WinAmount: " + this.state.winAmount);

    if (this.props.isPlayerBanned) {
      this.gameCloseHandler("target");
      const body2 = {
        sessionId: sessionStorage.getItem('sessionId'),
        CN: "EXIT_GAME",
        object: {
          gameId: this.state.gameId,
          playerId: Number(this.state.playerId),
        }
      }
      this.props.network.sendof(body2);
      this.body2 = {};
      this.props.player_banned();
    }
    console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
    if (this.props.gameUnderMaintanance) {
      this.gameCloseHandler("target");
      this.props.game_maintanance();
    }
    console.log("deviceBlocked " + this.props.isdeviceBlocked);
    if (this.props.isdeviceBlocked) {
      console.log("---------------------------")
      this.gameCloseHandler("target");
      console.log("---------------------------")

      const body4 = {
        sessionId: sessionStorage.getItem('sessionId'),
        CN: "EXIT_GAME",
        object: {
          gameId: this.state.gameId,
          playerId: Number(this.state.playerId),
        }
      }
      this.props.network.sendof(body4);
      this.body4 = {};

      this.props.device_blocked();
    }
  }

  mwsparkleanimat = () => {

    this.sparklemw++;
    if (this.sparklemw < 101) {
      const sparklemw = `sparkanimation${this.sparklemw}`;
      //console.log(sparklemw)
      const starssprinklemw = mwstarsprnkle[sparklemw];
      //console.log(starssprinklemw)
      if (document.getElementById("mw_sprkl")) {
        document.getElementById("mw_sprkl").src = starssprinklemw
      }

    }
    else {
      this.sparklemw = 1;

      clearInterval(this.mwsparkl)
      //document.getElementById("sparkID").src = ""

    }
  }

  updateWinsArray(num) {
    console.log(this.state.wins)
    let totalBet = 0;
    if (num == 2) {
      console.log(this.state.Total_Bet_Amount * 2 + "      " + this.props.maxBetAmt)
      if (this.state.Total_Bet_Amount * 2 > this.props.maxBetAmt) {
        //alert("................4")
        this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
        // this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt })
        // this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });

      } else {
        console.log(typeof (this.maxInnerLimit))
        if (this.state.Total_Bet_Amount * 2 <= this.props.balance) {
          // minBetAmt={this.state.mw_minBet}
          // maxBetAmt={this.state.mw_maxBet}


          let maxInnerLimit = this.props.maxBetAmt;
          for (let k = 0; k < this.state.wins.length; k++) {
            if (this.state.wins[k].amount > 0 && this.state.wins[k].amount <= maxInnerLimit && totalBet <= this.props.balance) {
              console.log(this.state.wins[k].amount)

              if (this.state.wins[k].amount * 2 <= maxInnerLimit) {
                if (this.state.Total_Bet_Amount + this.state.wins[k].amount * 2 <= this.props.balance && this.state.wins[k].amount * 2 <= this.newLimits[k][1]) {
                  this.state.wins[k].amount = this.state.wins[k].amount * num;
                  // this.state.Total_Bet_Amount = this.state.Total_Bet_Amount + this.state.wins[k].amount;
                  const Total_Bet_Amount = this.state.Total_Bet_Amount + this.state.wins[k].amount;
                  this.setState({ Total_Bet_Amount: Total_Bet_Amount })


                  this.betPanelRef.current.updateBetAmt(k, this.state.wins[k].amount);
                  console.log(`bet Id${k} amount placed ${this.state.wins[k].amount}`)
                } else {
                  // this.setState({ infoText: this.props.language.MaxInrBetAmt + Number(this.newLimits[k][1]) });
                }
              } else {
                // this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(maxInnerLimit) })
              }
              totalBet += Number(this.state.wins[k].amount)
            } else {

              // this.setState({infoText:this.props.language.MaxInrBetAmt+this.changeAmtlabl(maxInnerLimit) });
            }

          }
          console.log("Doube Bet: " + totalBet);
          console.log("Balacne: " + this.props.balance);
          if (totalBet <= this.props.balance) {
            this.setState({
              Total_Bet_Amount: totalBet,
              wins: this.state.wins,
              disDoubleBtn: 0,
              disClearBtn: 0,
              balanceLbl: (this.props.balance - totalBet)
            })
          } else {
            this.isReady = false;
            this.setState({
              wins: this.state.wins,
              disDoubleBtn: 1,
              disClearBtn: 0,
              infoText: this.props.language.LowBalance,
            })
          }
        } else {
          this.isReady = false;
          this.setState({
            disClearBtn: 0,
            // disZoomBtn:0,
            infoText: this.props.language.LowBalance
          })
        }
      }

    } else {
      for (let a = 0; a < this.state.wins.length; a++) {
        this.state.wins[a].amount = Number(0);
        if (this.betPanelRef.current) {
          this.betPanelRef.current.updateBetAmt(a, 0)
        }
        if (this.state.wins[a].amount > 0) {
          this.state.wins[a].amount = Number(0);
          // totalBet += Number(this.state.wins[a].amount)

        }
      }

      this.setState({
        wins: this.state.wins,
        disClearBtn: 1,
        disDoubleBtn: 1,
        infoText: this.props.language.Play_Njoy,
        //Total_Bet_Amount: totalBet
      });
    }
  }

  placebet() {
    this.setState({ isBetSent: true, winCover: true })
    const body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "MONEY_WHEEL_TIMER_BET",
      object: {
        betString: this.state.betstr,
        betAmount: this.state.Total_Bet_Amount,
        playerId: Number(this.state.playerId),
        gameId: this.state.gameId,
      },
    };
    this.props.network.sendof(body);
    this.body = {};

    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.state.gameId),
        playerId: Number(this.state.playerId),

      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};

    let totBetNum = this.changeAmtlabl(this.state.Total_Bet_Amount);
    console.log(totBetNum)
    // this.setState({ infoText: this.props.language.BetAccepted + totBetNum });
    console.log("data send");
    console.log(" ***********   3");

    //document.getElementById("mw_Cover").style.display = "block";

    document.getElementById("doubleButton").classList.add("mw_disBtn");
    document.getElementById("clearButton").classList.add("mw_disBtn");
    document.getElementById("betButton").classList.add("mw_disBtn");
  }

  getPlayerInfo() {
    this.setState({ disBetBtn: 1 });
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.state.gameId),
        playerId: Number(this.state.playerId),
      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};
  }

  moneyWheelGetwheelresp() {
    this.setState({ isResultUpated: true, winCover: true, enableKeyboard: false, })
    this.state.isResultUpated = true;
    this.state.enableKeyboard = false;
    // document.getElementById("mw_Cover").style.display = "block";
    console.log(this.props.gameState);
    console.log(this.props.moneywheelResult);

    if (this.props.moneywheelResult && this.isGameActive) {
      this.setState({
        totalBet: this.props.moneywheelResult.betAmount,
        winAmount: this.props.moneywheelResult.winAmount,
      })
      console.log(this.props.moneywheelResult.resultNum);
      if (this.betPanelRef && this.betPanelRef.current) {
        this.betPanelRef.current.spinTheWheel(this.props.moneywheelResult.resultNum);
      }

      if (document.getElementById('iospopup').style.display == "none") {
        this.wheelSound.play();
      }


      //*************************************************************** */
      if (this.state.Total_Bet_Amount == 0 && this.props.moneywheelResult.winAmount > 0) {
        this.setState({ winCover: true })
        document.getElementById("doubleButton").classList.add("mw_disBtn");
        document.getElementById("clearButton").classList.add("mw_disBtn");
        document.getElementById("betButton").classList.add("mw_disBtn");

        this.setState({
          disClearBtn: 1,
          Total_Bet_Amount: this.props.gameState.betRequest.betAmount,
          isBetSent: true,
        })

        this.setState({
          previousBetArray: [],
          finalPreviousBetArray: [],
        })
        this.state.previousBetArray = [];
        this.state.finalPreviousBetArray = [];

        let prevBet = this.props.gameState.betRequest.betString;
        this.state.previousBetArray.push({
          amount: prevBet.split(",")
        })
        console.log("previousBetArray")
        console.log(this.state.previousBetArray)

        if (this.state.previousBetArray) {
          this.isReady = true;
          for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
            this.state.finalPreviousBetArray.push({
              id: i,
              amount: Number(this.state.previousBetArray[0].amount[i]),
            })
          }
        }

        this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray })

        console.log(this.state.wins);
        console.log(this.state.finalPreviousBetArray);

        if (this.state.finalPreviousBetArray) {
          for (let i = 0; i < this.state.wins.length; i++) {
            if (this.state.wins[i].id == this.state.finalPreviousBetArray[i].id) {
              this.state.wins[i].amount = this.state.finalPreviousBetArray[i].amount;
              this.betPanelRef.current.updateBetAmt(i, this.state.wins[i].amount);
            }
          }
        }
      }
      //*************************************************************** */




    }
  }

  changeAmtlabl(labelValue) {
    return Math.abs(Number(labelValue)) >= 1.0e+9

      ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6

        ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3

          ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

          : Math.abs(Number(labelValue));
  }

  historyUpdate(event) {
    console.log(event);
    // alert('ding 2');
    this.RightButtonPanel.updateGamestate(event);
  }

  updatePlayerInfo() {

    this.setState({ balanceLbl: this.props.balance })
    // console.log(this.winOnload)
    // if (this.props.moneywheelResult.winAmount > 0 && !this.winOnload) {
    //   this.setState({ Total_Bet_Amount: 0 })
    // } else {
    //   if (this.updatePlayerBalnce == true) {
    //     this.setState({
    //       disBetBtn: 0,
    //       btnActive: false,
    //       winCover: false
    //     });
    //   } else {
    //     if (this.props.balance > 0) {
    //       this.setState({ winCover: false, disBetBtn: 0 });
    //     }
    //     if (this.props.gameState.history && this.props.gameState.history.length == 0) {
    //       this.setState({ disBetBtn: 1 });
    //     }
    //   }
    // }

    // this.state.Total_Bet_Amount = 0;
    // this.setState({ Total_Bet_Amount: 0 });
    // if (this.winOnload == true) {
    //   this.winOnload = false;
    // };
  };

  closeWin() {
    document.getElementById('iospopup').style.display = "none";
    this.takeSound = new Audio(takeSound);
    this.winSound = new Audio(winSound);
    this.duploseSound = new Audio(duploseSound);
    this.tickSound = new Audio(tickSound);
    this.wheelSound = new Audio(wheelSound);
    this.clearSound = new Audio(clearSound);
    this.betSound = new Audio(betSound);
  }
  topLable_fn(labelValue) 
  {
      // Nine Zeroes for Billions
      return Math.abs(Number(labelValue)) >= 1.0e+9
  
      ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6
  
      ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
      // Three Zeroes for Thousands
      : Math.abs(Number(labelValue)) >= 1.0e+3
  
      ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
  
      : Math.abs(Number(labelValue));
  }

  render() {
    let updateWin = this.topLable_fn(Math.floor(this.state.winAmount));
    return (
      <React.Fragment>
        <div className="fd bg_moneywheel">
          <div className="iosFetchSounds" id="iospopup" onClick={(e) => this.closeWin()}>
            <div className="popupTooLong">
              <div className=""> {this.props.language.Wel_MWT}</div>
              <br></br>
              {this.props.language.OK_Btn}

              <br></br>
              <br></br>
              <div className="fd">
                <button className="btn_iosPopup" > {this.props.language.Ok} </button>
              </div>
            </div>
          </div>
          <div className="mw_Cover" id="mw_Cover"></div>

          <LeftButtonPanel
            user={this.props.user}
            childRef={ref => (this.LeftButtonPanel = ref)}
            ref={this.LeftButtonPanel}
            balance={this.state.balanceLbl}
            totalBet={this.state.Total_Bet_Amount}
            betButtonValue={this.state.betButtonValue}
            betButtonText={this.state.betButtonText}
            winAmount={this.state.showWinPopUp}
            disBetBtn={this.state.disBetBtn}
            disDoubleBtn={this.state.disDoubleBtn}
            action={this.rightButtonPanelHandler.bind(this)}
            selectedBetChip={this.betHandler.bind(this)}
            handId={this.props.gameState.handId}
            playerId={this.props.playerId}
            mwt_chipValues={this.props.mwt_chipValues}
          />

          <Wheel
            ref={this.betPanelRef}
            winCover={this.state.winCover}
            betDone={this.betDone.bind(this)}
            betAmountAction={this.betPanelHandler.bind(this)}
            gameState={this.props.gameState}
            moneywheelResult={this.props.moneywheelResult}
            isMobile={this.props.isMobile}
            wheelHandler={this.wheelHandler.bind(this)}
            resultNo={this.state.resultNo}
            action={this.rightButtonPanelHandler.bind(this)}
            Chek_Spec={this.Chek_Spec.bind(this)}
            clearBetAnim={this.clearBetAnim.bind(this)}
            timeTxt={this.state.timeTxt}
            gameId={this.props.gameId}
            network={this.props.network}
            timesend={this.props.ftt_timesend}
            playerId={this.props.playerId}
            takeAmount={this.mw_takeAmt}
            isBetSent={this.state.isBetSent}
            disableKeyBoard={this.disableKeyBoard.bind(this)}
          />

          <RightButtonPanel
            childRef={ref => (this.RightButtonPanel = ref)}
            ref={this.RightButtonPanel}
            mwt_chipValues={this.props.mwt_chipValues}
            disClearBtn={this.state.disClearBtn}
            infoText={this.state.infoText}
            // winAmount={this.state.showWinPopUp}
            betButtonValue={this.state.betButtonValue}
            betButtonText={this.state.betButtonText}
            disBetBtn={this.state.disBetBtn}
            disDoubleBtn={this.state.disDoubleBtn}
            action={this.rightButtonPanelHandler.bind(this)}
            gameState={this.props.gameState}
            isMobile={this.props.isMobile}

          />

          {/* <Close
            action={this.gameCloseHandler.bind(this)}
            isMobile={this.props.isMobile}>
          </Close> */}

        </div>
        <div className="mwWinImg" id="mwWinImg"> </div>

        <div className="popup_back" id="winPop">

          <img id="mw_sprkl"
            className="mw_sparkAnim"
            src=""
          />
          <div className="mw_winpop">
            <span className="mw_win_txt">{updateWin}</span>
          </div>
        </div>

      </React.Fragment>

    );
  }
}
const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj
  }
}

export default connect(mapStatesToProps)(MoneyWheelTimer) 