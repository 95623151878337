import React from "react";
import "../../../../../css/games/funTarget/rightButtonPanel.css";
import "./moneyWheel.css"
import betSound from "../../../../../../assets/sounds/bet.mp3";
import betButtonBg from "../../../../../../assets/games/money_Wheel/mw_btn_green.png"
import clearBg from "../../../../../../assets/games/money_Wheel/mw_btn_red.png";
import { connect } from "react-redux";

class RightButtonPanel extends React.Component {
  constructor(props) {
    super(props);
    const { childRef } = this.props;
    childRef(this);

    setTimeout(() => {
      this.updateGamestate(this.props.gameState);
  }, 100);
  }

  componentDidMount() {
    this.wheelString = [
      20, 1, 3, 1, 5, 1, 3, 1, 5, 1,
      10, 3, 1,
      45, 1, 3, 1, 5, 1, 3, 1,
      10, 3, 1, 5, 1,
      20, 1, 3, 1, 3, 1, 5, 1,
      10, 1, 3, 5, 1,
      45, 1, 3, 1, 5, 1, 3, 1,
      10, 5, 1, 3, 1
    ];
  }

  updateGamestate(event) {
    console.log(event);

// Test Start

  //  let testHist = [
  //     {
  //         "handId": 370,
  //         "resultNo": "9"
  //     },
  //     {
  //         "handId": 384,
  //         "resultNo": "20"
  //     },
  //     {
  //         "handId": 386,
  //         "resultNo": "43"
  //     },
  //     {
  //         "handId": 400,
  //         "resultNo": "45"
  //     },
  //     {
  //         "handId": 402,
  //         "resultNo": "45"
  //     },
  //     {
  //         "handId": 416,
  //         "resultNo": "10"
  //     },
  //     {
  //         "handId": 418,
  //         "resultNo": "31"
  //     },
  //     {
  //         "handId": 432,
  //         "resultNo": "47"
  //     },
  //     {
  //         "handId": 434,
  //         "resultNo": "33"
  //     },
  //     {
  //         "handId": 448,
  //         "resultNo": "38"
  //     }
  // ]

  // if(testHist.length > 5){
  //   this.histData = testHist.slice(Math.max(testHist.length - 5, 0));        
  // }else{
  //   this.histData = testHist;

  // }

  // Testing End
  
    
    if (event.history) {
      console.log(event.history.length);
      console.log(event.history);      
      if(event.history.length > 5){
        console.log(event.history.slice(Math.max(event.history.length - 5, 0)));
        this.histData = event.history.slice(Math.max(event.history.length - 5, 0));        
      }else{        
        this.histData = event.history;
      }
    }
  }

  Gamestate(event) {
    console.log("**********************    Money Wheel History   **********************")
    //this.setState({ previousHistory: [] });
    console.log(event.history)
    console.log("**********************    Money Wheel History   **********************")
    //if (event) {
    if (event.history && event.history.length && event.history.length > 0) {
      this.histData = event.history

      console.log(this.histData);

      // if (event.history.length > 5) {
      //   for (let i = 5; i < 10; i++) {
      //    console.log(this.wheelString[Number(event.history[i].resultNo)]);
      //    console.log("---");
      //    if(!document.getElementById("hstry" + i).classList.contains("ul_container_li")){
      //      document.getElementById("hstry" + i).classList.add("ul_container_li");
      //    }
      //     document.getElementById("hst" + i).innerHTML = this.wheelString[Number(event.history[i].resultNo)];
      //   }
      // } else {
      //   for (let j = 0; j < event.history.length; j++) {
      //     console.log(event.history[j].resultNo)
      //     if(!document.getElementById("hstry" + (5+j)).classList.contains("ul_container_li")){
      //       document.getElementById("hstry" + (5+j)).classList.add("ul_container_li");
      //     }
      //     document.getElementById("hst" + (5+j)).inn = this.wheelString[Number(event.history[j].resultNo)]
      //   }
      // }
    }
    //}
  }

  render() {
    return (
      <div className="chips_clear_container">
        <div className="history_container">
          <ul className="ul_container">
            {/* <li id="hstry5"><span className="hisTxt" id="hst5"></span></li>
            <li id="hstry6"><span className="hisTxt" id="hst6"></span></li>
            <li id="hstry7"><span className="hisTxt" id="hst7"></span></li>
            <li id="hstry8"><span className="hisTxt" id="hst8"></span></li>
            <li id="hstry9"><span className="hisTxt" id="hst9"></span></li> */}

            {this.histData ? this.histData.map((value, index) => {
              return (
                <li className="ul_container_li hisTxt" key={index}>
                  {this.wheelString[Number(value.resultNo)]}
                </li>
                )
            }) :
              ''}
          </ul>
        </div>

        <div className="infotext_container">
          <h1>{this.props.infoText}</h1>
        </div>

        <div className="btn_container">

          <button className={(this.props.isMobile) ? "clear_button emptyPointer" : "clear_button addCursorPointer"}
            id="clearButton"
            disabled={this.props.disClearBtn == 1}

            onClick={(e) => {
              e.preventDefault();
              if (this.props.disClearBtn == 0) {
                this.props.action("clear")
              }
            }}
            onPointerDown={(e) => {
              e.preventDefault();
              if (this.props.disClearBtn == 0) {
                document.getElementById("clearButton").style.transform = "scale(0.85)"
              }
            }}
            onPointerUp={(e) => {
              e.preventDefault();
              if (this.props.disClearBtn == 0) {
                document.getElementById("clearButton").style.transform = "scale(1)";
              }
            }}
            onPointerOut={(e) => {
              e.preventDefault();
              if (this.props.disClearBtn == 0) {
                document.getElementById("clearButton").style.transform = "scale(1)";
              }
            }}
            onPointerCancel={(e) => {
              e.preventDefault();
              if (this.props.disClearBtn == 0) {
                document.getElementById("clearButton").style.transform = "scale(1)";
              }
            }}
          >
            <h1>  {this.props.language.Clear} </h1>
            <img className="clear_button_img" src={clearBg} alt="" />
          </button>


          <button className={(this.props.isMobile) ? "double_button emptyPointer" : "double_button addCursorPointer"}
            id="doubleButton"
            disabled={this.props.disDoubleBtn == 1}
            onClick={(e) => {
              e.preventDefault();
              if (this.props.disDoubleBtn == 0) {
                this.props.action("double")
              }
            }}
            onPointerDown={(e) => {
              e.preventDefault();
              if (this.props.disDoubleBtn == 0) {
                document.getElementById("doubleButton").style.transform = "scale(0.85)";
              }
            }}
            onPointerUp={(e) => {
              e.preventDefault();
              if (this.props.disDoubleBtn == 0) {
                document.getElementById("doubleButton").style.transform = "scale(1)";
              }
            }}
            onPointerOut={(e) => {
              e.preventDefault();
              if (this.props.disDoubleBtn == 0) {
                document.getElementById("doubleButton").style.transform = "scale(1)";
              }
            }}
            onPointerCancel={(e) => {
              e.preventDefault();
              if (this.props.disDoubleBtn == 0) {
                document.getElementById("doubleButton").style.transform = "scale(1)";
              }
            }}
          >
            <img className="bet_button_bg" src={betButtonBg} alt="" />
            <h1 className="bet_button_heading">{this.props.language.Double}</h1>
          </button>

          <button className={(this.props.isMobile) ? "bet_button emptyPointer" : "bet_button addCursorPointer"}

            id="betButton"
            disabled={this.props.disBetBtn == 1}
            name={this.props.betButtonText}
            onClick={(e) => {
              e.preventDefault();
              console.log("________" + this.props.betButtonValue)
              if (this.props.betButtonValue == 1) {
                let betSounds;
                betSounds = new Audio(betSound);
                betSounds.play();
                this.props.action("SPIN")

              } else if (this.props.betButtonValue == 0) {
                this.props.action("PREV")
              } else if (this.props.betButtonValue == 2) {
                this.props.action("TAKE")
              }
            }}
            onPointerDown={(e) => {
              e.preventDefault();
              if (this.props.disBetBtn == 0) {
                document.getElementById("betButton").style.transform = "scale(0.85)";
              }

            }}
            onPointerOut={(e) => {
              e.preventDefault();
              if (this.props.disBetBtn == 0) {
                document.getElementById("betButton").style.transform = "scale(1)";
              }
            }}
            onPointerUp={(e) => {
              e.preventDefault();
              if (this.props.disBetBtn == 0) {
                document.getElementById("betButton").style.transform = "scale(1)";
              }
            }}
            onPointerCancel={(e) => {
              e.preventDefault();
              if (this.props.disBetBtn == 0) {
                document.getElementById("betButton").style.transform = "scale(1)";
              }
            }}
          >
            <img id="moneywheelBtn" className="bet_button_bg" src={betButtonBg} alt="" />
            <h1 className="bet_button_heading">{this.props.betButtonText}</h1>
          </button>


        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj

  }
}
export default connect(mapStatesToProps)(RightButtonPanel);


