import React, { Component } from "react";
import "./UI/bingo.css";
import { connect } from "react-redux"; 
import TopButtonPanel from "./UI/TopButtonPanel";
import MiddleButtonPanel from "./UI/MiddleButtonPanel";
import BottomButtonPanel from "./UI/BottomButtonPanel";
import takeSound from "../../../../../assets/sounds/take.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import clearSound from "../../../../../assets/sounds/clear.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import betoncardSound from "../../../../../assets/sounds/betoncard.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import button_glow from "../../../../../assets/games/bingo/Button-Glow.png";
import starbngglow from "../../../../../assets/games/bingo/star1.png";
import starbng from "../../../../../assets/games/bingo/star2.png";

class BINGO extends Component {
  // LeftButtonPanel = React.createRef();

  takeSound = new Audio(takeSound);
  betSound = new Audio(betSound);
  clearSound = new Audio(clearSound);
  duploseSound = new Audio(duploseSound);
  winSound = new Audio(winSound);
  betoncardSound = new Audio(betoncardSound);
  exitSound = new Audio(exitSound);
  isbngokeyhandle = true;

  state = {
    previousBetArray: [],
    starcntbngo: [], 
    dupbnsamnt: 0, 
    bnbonusmnt_bingo: 0,
    bnsxval: 0,
    resultNu: 0,
    starcount: 0,
    winbngdup: 0,
    showDupbngo: false,
    bingo_dupcomponet: false,
    finalPreviousBetArray: [],
    gameId: this.props.gameId,
    playerId: this.props.playerId,
   initialBetAmount: this.props.minBetAmt,
    //initialBetAmount: 1,
    Total_Bet_Amount: 0,
    bingobetdeduction:0,
    infoText: this.props.language.Play_Njoy,
    winAmount: 0,
    betstr: "",
    betString: [],
    bnstrs: [],
    handId: 0,
    bets: "",
    betButtonValue: 0,
    betButtonText: this.props.language.Prev,
    disDoubleBtn: 1,
    disBetBtn: 1, 
    disClearBtn: 1,
    btnActive: false,
    bngostar: 0,
    bngokeyboardhandle: false,
    winGlowbngo: false,
    betsAmount: [
      { id: 0, amount: 0},
      { id: 1, amount: 0 },
      { id: 2, amount: 0},
      { id: 3, amount:0 },
      { id: 4, amount: 0 },
      { id: 5, amount: 0 },
    ],
    winsAmount: [
      { house: 1, amount: 0 },
      { house: 2, amount: 0 },
      { house: 3, amount: 0 },
      { house: 4, amount: 0 },
      { house: 5, amount: 0 },
      { house: 6, amount: 0 },
    ],

  };

  constructor(props) {
    super(props);
    this.innerLimits = [["1","1000"]]; 
    this.MiddleButtonPanel = React.createRef();
    this.winAmount = 0;
  }
  isReady = false;
  newLimits = [];

  componentDidMount() {
    console.log(this.props.language)
  console.log(this.props.language.Min_Bet)
  console.log(this.props.language.Max_Bet)
    if (document.getElementById("bupbingo_btn")) {
      document
        .getElementById("bupbingo_btn")
        .classList.remove("addCursorPointer_bngo");
    }
    if (document.getElementById("doubleButton")) {
      document.getElementById("doubleButton").classList.remove("addCursorPointer_bngo");
    }
    if (document.getElementById("double_bngoimg")) {
      document.getElementById("double_bngoimg").classList.remove("addCursorPointer_bngo");
    }
    if (document.getElementById("clerbngo_dupimg")) {
      document
        .getElementById("clerbngo_dupimg")
        .classList.remove("addCursorPointer_bngo");
    }
    if (document.getElementById("bngo_betbtn")) {
      document
        .getElementById("bngo_betbtn")
        .classList.remove("addCursorPointer_bngo");
    }

    if (this.props.bingo_innerLimits) {
      console.log(this.props.bingo_innerLimits)
      this.innerLimits = this.props.bingo_innerLimits.split("|");
      for (let t = 0; t < this.innerLimits.length; t++) {
        this.innerLimits[t] = this.innerLimits[t].split("-");
      }

      console.log(this.innerLimits);
    console.log(this.innerLimits[0][0]);
    console.log(this.innerLimits[0][1]);
    }

    

    this.preloadbingoimages();

    // let updatefontbingo = localStorage.getItem("myLang");

    // if (
    //   updatefontbingo == "Telugu" ||
    //   updatefontbingo == "తెలుగు" ||
    //   updatefontbingo == "বাংলা" ||
    //   updatefontbingo == "Bengali"
    // ) {

    //   document.getElementById("totalbetbingo").classList.add("totalbetbingo");
    // }

    this.bn_time1 = null;
    this.bn_time2 = null;
    this.bn_time3 = null;
    this.bn_time4 = null;
    this.bn_time5 = null;
    this.bn_time6 = null;
    this.bn_time7 = null;
    this.bn_time8 = null;

    this.setState({
      bngokeyboardhandle: true,
      disDoubleBtn: 1,
      disClearBtn: 1,
      disBetBtn: 1, 
      bingo_dupcomponet: true,
      handId: this.props.gameState.handId,
    });

    const { childRef } = this.props;
    childRef(this);
    this.betSound = new Audio(betSound);
    this.winSound = new Audio(winSound);
    if (document.getElementById("bngo_Cover")) {
      document.getElementById("bngo_Cover").style.display = "none";
    }

    const { bng_innerLimits } = this.props;
    if (bng_innerLimits) {
      const bngLimits = bng_innerLimits.split("|");
      for (let j = 0; j < bngLimits.length; j++) {
        this.newLimits[j] = bngLimits[j].split("-");
      }
    }
 

    if (this.props.gameState.handId == this.props.gameState.oldHandId && this.props.gameState.takeAmount != undefined) {
      document.getElementById("bngo_Cover").style.display = "block";
      if (this.props.gameState["betRequest"] == undefined) {
        console.log("Not detacting previous bet Take Amount");
        this.setState({ disBetBtn: 1 });
      } else {
        this.setState({ disBetBtn: 0 });
      }
      this.setState({ infoText: this.props.language.BetAccepted + this.props.gameState.betRequest.betAmount });
    } else {
      // console.log(this.state.betString.length);
      if (this.props.gameState.betRequest) {
        this.setState({ disBetBtn: 0, enableKeyboard: true });
      } else {
        this.setState({ disBetBtn: 1, disDoubleBtn: 1, disClearBtn: 1 });
      }

      this.setState({
        betButtonText: this.props.language.Prev,
        betButtonValue: 0,
        enablekeyboard: true,
        isBetSent: false,
      });
    }
    document.addEventListener("keyup", this.BingoKeyBoadEvent);
  }

  preloadbingoimages = () => {
    let bngoimagesload = [
      button_glow,
      starbngglow,
      starbng,  
    ];

    for (let i = 0; i < bngoimagesload.length; i++) {
      let bngoiamges = new Image();
      const sparkImageName = bngoimagesload[i];
      // console.log(sparkImageName);
      bngoiamges.src = sparkImageName;
    }
  };

  componentWillUnmount() {
    document.removeEventListener("keyup", this.BingoKeyBoadEvent);
  }


  BingoKeyBoadEvent = (eve) => {
    //console.log(eve);
    if (!this.state.showDupbngo) {
      switch (eve.code) {
        case "Enter":
        case "NumpadEnter":
          if (this.state.betButtonValue == 0 && this.state.disBetBtn == 0 && this.state.enablekeyboard) {
            this.rightButtonPanelHandler("PREV");
          } else if (this.state.betButtonValue == 1 && this.state.disBetBtn == 0 && this.state.enablekeyboard) {
            this.rightButtonPanelHandler("Roll");
          }
          break;
        case "Space":
          if (
            this.state.betButtonValue == 2 && this.state.disBetBtn == 0 && this.state.enablekeyboard) {
            this.rightButtonPanelHandler("TAKE");
          }
          break;
        case "KeyC":
          if (this.state.disClearBtn == 0 && this.state.enablekeyboard) {
            this.rightButtonPanelHandler("clear");
          }
          break;        
        case "Escape":
          this.rightButtonPanelHandler("EXIT");
          break;
        default:
          break;
      }
    }
  };

  changeAmtlabl(chngAmt){
    let newAmt = chngAmt;
    if(newAmt >= 1000 && newAmt <=999999){
      newAmt = newAmt / 1000+"K";      
    }else if(newAmt >= 1000000 && newAmt <=999999999){
      newAmt = newAmt / 1000+"M";    
    }else if(newAmt >999999999){
      newAmt = newAmt / 1000+"B";      
    }
    return newAmt;
}



  clearBetList() {
    for (var i = 0; i < this.state.betString.length; i++) {
      this.state.betString[i].amount = 0;
    }
    this.setState({ disDoubleBtn: 1, disClearBtn: 1 });
  }

  updateGameState(){
    
   
    // console.log("Bingo NonTimer Game State: ");
    this.setState({ handId: this.props.gameState.handId });
    console.log(this.state.handId);
  };

  gameSessionClosed() {
    this.takeSound.pause();
    this.winSound.pause();
    this.MiddleButtonPanel.clearevntsbngo();
    
    clearTimeout(this.bn_time1);
    clearTimeout(this.bn_time2);
    clearTimeout(this.bn_time3);
    clearTimeout(this.bn_time4);
    clearTimeout(this.bn_time5);
    clearTimeout(this.bn_time6);
    clearTimeout(this.bn_time7);
    clearTimeout(this.bn_time8);
  }

  gameCloseHandler = (game) => {
    this.exitSound.play();
    // Here We Need To Add The Result Sound; 
    this.takeSound.pause();
    this.winSound.pause();
    this.MiddleButtonPanel.clearevntsbngo();
    clearTimeout(this.bn_time1);
    clearTimeout(this.bn_time2);
    clearTimeout(this.bn_time3);
    clearTimeout(this.bn_time4);
    clearTimeout(this.bn_time5);
    clearTimeout(this.bn_time6);
    clearTimeout(this.bn_time7);
    clearTimeout(this.bn_time8);

    this.props.action(game);
  };

  funBingoResultRes(event) {
    if (event) {
      this.MiddleButtonPanel.bingoResultAnimation(event, event.winAmount);
    }

    if (event.winAmount > 0) {
      if (document.getElementById("bngo_Cover")) {
        document.getElementById("bngo_Cover").style.display = "block";
      }

     
      this.isReady = false;
      this.winAmount = event.winAmount
    
      this.bn_time1 = setTimeout(() => {
        this.setState({winAmount: event.winAmount});
        this.winSound.play();
        // this.setState({
        //   winAmount: this.props.BingolResult.winAmount,
        //   winGlowbngo: true,
        //   betButtonText: this.props.language.Take,
        //   betButtonValue: 2,
        //   balance: this.props.balance,
        //   infoText: this.props.language.ClickTake,
        //   Total_Bet_Amount: this.props.BingolResult.betAmount,
        // });

        // document.getElementById("bngo_betglow").src = button_glow;
        // document.getElementById("bngo_betglow").classList.add("blinkeffectbngobtns");  

     

        document.getElementById('winPop').style.display = 'block'; 
        setTimeout(() => {
          document.getElementById('winPop').style.display = 'none';
          
        }, 2000);
        // this.getPlayerInfo();
      
        setTimeout(()=>{
            this.setState({
                balance: this.props.balance,
                infoText: this.props.language.Play_Njoy,
                winBet: 0,
                btnActive: false,
                bingobetdeduction:0,
            });
         
             this.takeAmountResult();
             this.getPlayerInfo();

        },5000);
         

      }, 6500);

      this.bn_time2 = setTimeout(() => {
        this.setState({ enablekeyboard: true, disBetBtn: 0, });
        if (document.getElementById("bngo_Coverbtns")) {
          document.getElementById("bngo_Coverbtns").style.display = "none";
        } 

      }, 6800);

    } else {
       this.getPlayerInfo();
      this.props.gamstateHandle();

      this.bn_time3 = setTimeout(() => {
        this.setState({
          infoText:
            this.props.language.Play_Njoy +
            this.props.language.MinBetAmt +" " +this.props.minBetLbl +" " +this.props.language.MaxBetAmt +" " +this.props.maxBetLbl,
            // this.props.language.Min_Bet +" " +this.props.minBetLbl +" " +this.props.language.Max_Bet +" " +this.props.maxBetLbl,
          balance: this.props.balance,
          disBetBtn: 0, 
          winAmount: 0,
          disClearBtn: 0,
          disDoubleBtn: 0,
          bingobetdeduction:0
        });

        if (document.getElementById("bngo_Cover")) {
          document.getElementById("bngo_Cover").style.display = "none";
        }
        if (document.getElementById("bngo_Coverbtns")) {
          document.getElementById("bngo_Coverbtns").style.display = "none";
        }

        if (document.getElementById("bngo_betglow")) {
          document.getElementById("bngo_betglow").src = button_glow;
          document.getElementById("bngo_betglow").classList.add("blinkeffectbngobtns")
        }
      
      }, 6500);
    }
    this.isReady = false;
  }
 getPlayerInfo() {
  const { playerId, gameId, network } = this.props;
        const body2 = {
            CN: "GET_PLAYER_INFO",
            object: {
            gameId: Number(gameId),
            playerId: Number(playerId),
            },
            sessionId: sessionStorage.getItem('sessionId'),
        }
        network.sendof(body2);
        this.body2 ={};
    }
  betPanelHandler = (id) => {
    this.betoncardSound.currentTime = 0;
    this.betoncardSound.play();

    if (this.props.balance > 0) {
      if (document.getElementById("bngo_betglow") && !document.getElementById("bngo_betglow").classList.contains("blinkeffectbngobtns")) {
        document.getElementById("bngo_betglow").src = button_glow;
        document.getElementById("bngo_betglow").classList.add("blinkeffectbngobtns")
        // this.setState({infoText: this.props.language.Min_Bet +" " +this.props.minBetAmt +" " +this.props.language.Max_Bet +" " +this.props.maxBetAmt});
        this.setState({infoText:this.props.language.MinBetAmt +" " +this.props.minBetLbl +" " +this.props.language.MaxBetAmt +" " +this.props.maxBetLbl,});
      }

      const { betsAmount, initialBetAmount } = this.state;
      const { balance, maxBetAmt, minBetLbl, maxBetLbl } = this.props; 
      console.log(betsAmount);
      console.log(this.state.Total_Bet_Amount);
      if(this.state.Total_Bet_Amount == 0){
        for (let i = 0; i < betsAmount.length; i++) {
          if (betsAmount[i].amount === NaN || betsAmount[i].amount === "NaN") {
            this.setState((prevState) => {
              return {
                betsAmount: prevState.betsAmount.map((eachHouse) => {
                  if (eachHouse.id === i) {
                    return { id: i, amount: 0};
                  }
                  return eachHouse;
                }),
              };
            });
          }
        }
      }

      let totalBetAmouont = Number(betsAmount.reduce((prev, cur) => prev + cur.amount, this.state.initialBetAmount).toFixed(2));
      console.log("New Bet Amount  : " + totalBetAmouont);

      if (totalBetAmouont == this.props.minBetAmt) {
        if(!document.getElementById("doubleButton").classList.contains("addCursorPointer_bngo")){
          document.getElementById("doubleButton").classList.add("addCursorPointer_bngo");
        }

        this.setState({
          betButtonText: this.props.language.Roll,
          betButtonValue: 1,
          disClearBtn: 0,
          disDoubleBtn: 0,
          disBetBtn: 0,
          enablekeyboard: true,
        });
      }

      if (totalBetAmouont > maxBetAmt) {
        document.getElementById("bngo_Cover").style.display = "block";
        this.setState({
          // infoText: 
          // this.props.language.Play_Njoy + this.props.language.Min_Bet + this.props.minBetAmt + this.props.language.Max_Bet + this.props.maxBetAmt
          infoText:     this.props.language.MinBetAmt +" " +this.props.minBetLbl +" " +this.props.language.MaxBetAmt +" " +this.props.maxBetLbl,
        });
      } else if (totalBetAmouont <= balance) {
        if (document.getElementById("bngo_Cover")) {
          document.getElementById("bngo_Cover").style.display = "none";
        }
        if (betsAmount[id] && betsAmount[id].amount !== null && betsAmount[id].amount + initialBetAmount <= this.innerLimits[0][1]) {
          this.setState({Total_Bet_Amount: totalBetAmouont,btnActive: true});
          this.isReady = true;

          console.log(this.state.betsAmount); 

          this.state.betsAmount[id].amount = Number((this.state.betsAmount[id].amount + initialBetAmount).toFixed(2));

          // this.state.betsAmount[id].amount = this.state.betsAmount[id].amount + initialBetAmount;
          console.log(this.state.betsAmount[id].amount)

          if (this.MiddleButtonPanel && this.MiddleButtonPanel.current) {
              this.MiddleButtonPanel.betplace(id, this.state.betsAmount[id].amount);
            }


          // this.setState(
          //   (prevState) => ({
          //     betsAmount: prevState.betsAmount.map((eachBetHouse, i) => {
          //       if (eachBetHouse.id === id) {
          //         return {
          //           ...eachBetHouse,
          //           amount: eachBetHouse.amount + initialBetAmount,
          //         };
          //       }
          //       return eachBetHouse;
          //     }),
          //   }),
          //   () => {
          //     console.log("bingo.js :  " + id + "   " + this.state.betsAmount[id].amount);
          //     if (this.MiddleButtonPanel && this.MiddleButtonPanel.current) {
          //       this.MiddleButtonPanel.betplace(id, this.state.betsAmount[id].amount);
          //     }
          //   }
          // );

        } else {
          
          let maxBetMount = this.changeAmtlabl(this.innerLimits[0][1]);
          // this.setState({ infoText: this.props.language.MaxInrBetAmt + " " + this.innerLimits[0][1], });
          this.setState({ infoText: this.props.language.MaxInrBetAmt + " " + maxBetMount});
        }

      } else {
        document.getElementById("bngo_Cover").style.display = "none";
        this.setState({ infoText: this.props.language.LowBalance });
      }

      if (betsAmount[id] && betsAmount[id].amount !== null && betsAmount[id].amount ) {
       // this.MiddleButtonPanel.current.betplace(id,this.state.betsAmount[id].amount);
      }
    } else {
      this.setState({ infoText: this.props.language.LowBalance });
    }
  };

  placebet = () => {
 
    this.MiddleButtonPanel.clearbethouses();
    this.MiddleButtonPanel.disablekeyboard();
    if (document.getElementById("bngo_Cover")) {
      document.getElementById("bngo_Cover").style.display = "block";
    }

    const { betstr, Total_Bet_Amount } = this.state;
    const { playerId, gameId, network } = this.props;
    console.log(this.state.betstr)
    const body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "BINGO_BET",
      object: {
        gameId: gameId,
        betAmount: Total_Bet_Amount,
        betString: betstr,
        playerId: playerId,
      },
    };
    network.sendof(body);
    this.body = {};
    this.getPlayerInfo();
    // this.setState({bingobetdeduction:0})
    // const body2 = {
    //   CN: "GET_PLAYER_INFO",
    //   object: {
    //     playerId: Number(playerId),
    //     gameId: gameId,
    //   },
    //   sessionId: sessionStorage.getItem("sessionId"),
    // };
    // this.props.network.sendof(body2);
    // this.body2 = {};

    if (Total_Bet_Amount) {
      //  alert("..................")
      let betAmountLabel = this.changeAmtlabl(this.state.Total_Bet_Amount);
      document.getElementById("bngo_betglow").src = "";
      if (document.getElementById("bngo_betglow").classList.contains("blinkeffectbngobtns")) {
        document.getElementById("bngo_betglow").classList.remove("blinkeffectbngobtns")
      }
      this.setState({
        infoText: this.props.language.BetAccepted + " " + betAmountLabel,
        disClearBtn: 1,
        disBetBtn: 1,
        disDoubleBtn: 1,
        winAmount: 0
      });
    }
  };

  playTakeSound = () => {
    this.takeSound.play();
  };


  takeAmountResult = () => {

    this.setState({  
      disBetBtn: 1,
      disClearBtn: 1,
      disDoubleBtn: 1, 
      btnActive: false,
      // winAmount: 0,
      infoText:
        this.props.language.Play_Njoy +
        this.props.language.MinBetAmt +" " +this.props.minBetLbl +" " +this.props.language.MaxBetAmt +" " +this.props.maxBetLbl,
        // this.props.minBetAmt +
        // this.props.language.Max_Bet +
        // this.props.maxBetAmt,
      winGlowbngo: false,
    });


    for (let i = 0; i < 6; i++) {
      if (document.getElementById(`wintableglow${i}`)) {
        document.getElementById(`wintableglow${i}`).style.display = "none";
        document.getElementById(`winbngoamnt${i}`).classList.remove("blink_winamount");
        document.getElementById(`winbngoamnt${i}`).classList.remove("winblinkamount");
      }
    }

    if (document.getElementById("bngoresult_anim1")) {
      document.getElementById("bngoresult_anim1").src = "";
    }
    if (document.getElementById("bngoresult_anim2")) {
      document.getElementById("bngoresult_anim2").src = "";
    }
    if (document.getElementById("bngoresult_anim3")) {
      document.getElementById("bngoresult_anim3").src = "";
    }
    if (document.getElementById("bngoresult_anim4")) {
      document.getElementById("bngoresult_anim4").src = "";
    }
    if (document.getElementById("bngoresult_anim5")) {
      document.getElementById("bngoresult_anim5").src = "";
    }
    if (document.getElementById("bngoresult_anim6")) {
      document.getElementById("bngoresult_anim6").src = "";
    }

    if (document.getElementById("bngo_betglow")) {
      document.getElementById("bngo_betglow").src = "";
      if (document.getElementById("bngo_betglow").classList.contains("blinkeffectbngobtns")) {
        document.getElementById("bngo_betglow").classList.remove("blinkeffectbngobtns")
      }
    }
   

    const {
      gameId,
      playerId,
      network,
      isPlayerBanned,
      gameUnderMaintanance,
      isdeviceBlocked,
      player_banned,
      game_maintanance,
      device_blocked,
    } = this.props;
    // console.log("Take Amount Function");
    // this.updateWinsArray(0);
    const body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "TAKE_AMOUNT",
      object: {
        gameId: gameId,
        playerId: Number(playerId),
      },
    };
    network.sendof(body);
    this.body = {};

    const getGameStateBody = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "GET_GAME_STATE",
      object: {
        playerId: Number(this.props.playerId),
        gameId: this.props.gameId,
      },
    };
    this.props.network.sendof(getGameStateBody);

  

    this.bn_time7 = setTimeout(() => {
      this.setState({
        betButtonText: this.props.language.Roll,
        betButtonValue: 1,
        disBetBtn: 0,
        disClearBtn: 0,
        disDoubleBtn: 0, 
        btnActive: false,
        // winAmount: 0,
        infoText:
          this.props.language.Play_Njoy +
          this.props.language.MinBetAmt +" " +this.props.minBetLbl +" " +this.props.language.MaxBetAmt +" " +this.props.maxBetLbl,
          // this.props.minBetAmt +
          // this.props.language.Max_Bet +
          // this.props.maxBetAmt,
        winGlowbngo: false,
      });

      if (document.getElementById("bngo_betglow")) {
        document.getElementById("bngo_betglow").src = button_glow;
        document.getElementById("bngo_betglow").classList.add("blinkeffectbngobtns")
      }

      if (document.getElementById("bngo_Cover")) {
        document.getElementById("bngo_Cover").style.display = "none";
      }
    }, 1000);

    this.gameUnderMaintanancebngo();
  }

  gameUnderMaintanancebngo = () => {
    const {
      isPlayerBanned,
      gameUnderMaintanance,
      isdeviceBlocked,
      player_banned,
      game_maintanance,
      device_blocked,
    } = this.props;
    if (isPlayerBanned) {
      this.gameCloseHandler("bingo");
      player_banned();
    }
    // console.log("gameUnderMaintanance  : " + gameUnderMaintanance);

    if (gameUnderMaintanance) {
      this.gameCloseHandler("bingo");
      game_maintanance("Game under maintenance, reach to admin.");
    }

    // console.log("deviceBlocked " + this.props.isdeviceBlocked);
    if (isdeviceBlocked) {
      // console.log("---------------------------");
      this.gameCloseHandler("bingo");
      // console.log("---------------------------");
      device_blocked();
    }
  }

  PreviousBetMethod() {
    document.getElementById("bngo_betglow").src = button_glow;
    document.getElementById("bngo_betglow").classList.add("blinkeffectbngobtns")
    this.setState({ disClearBtn: 0, enablekeyboard: true });

    let betrqst = this.props.gameState.betRequest;
    let betamnt = this.props.gameState.betRequest.betAmount;

    if (betrqst && betamnt <= this.props.balance) {
      if (betamnt > this.props.maxBetAmt) {
        this.setState({ infoText: this.props.language.PreBetLmt });
      } else {
        let prevBet = this.props.gameState["betRequest"]["betString"];

        console.log(prevBet);
        this.state.previousBetArray.push({
          amount: prevBet.split("|"),
        });

        this.setState({ previousBetArray: this.state.previousBetArray });


        if (this.state.previousBetArray) {
          this.isReady = true;
          this.toCreatePreviousBetArray();
        }

        if (this.state.betButtonValue === 0) {
          this.setState({
            showBetButton: true,
            betButtonText: this.props.language.Roll,
            betButtonValue: 1,
          });
          this.isReady = true;
        }
        this.setState({
          disClearBtn: 0,
          disBetBtn: 0,
          disDoubleBtn: 0, 
          disZoomBtn: 0,
          btnActive: true,
        });
      }
    } else {
      this.setState({ infoText: this.props.language.BetAmtMaxBal });
    }
  }

  toCreatePreviousBetArray() {
    for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
      this.state.finalPreviousBetArray.push({
        id: i,
        amount: Number(this.state.previousBetArray[0].amount[i]),
      });
    }
    this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray });
    console.log(this.state.finalPreviousBetArray);
    if (this.state.finalPreviousBetArray) {
      this.toGenerteBetString();
    }
  }

  toGenerteBetString() {
    // console.log(this.state.betsAmount);
    for (let i = 0; i < this.state.betsAmount.length; i++) {
      if (
        this.state.betsAmount[i].id == this.state.finalPreviousBetArray[i].id
      ) {
        this.state.betsAmount[i].amount +=
          this.state.finalPreviousBetArray[i].amount;
        this.MiddleButtonPanel.current.updateBetAmt(
          i,
          this.state.betsAmount[i].amount
        );
      }
    }
    this.setState({
      wins: this.state.wins,
      betButtonText: this.props.language.Roll,
      betButtonValue: 1,
      previousBetArray: [],
      finalPreviousBetArray: [],
      Total_Bet_Amount: this.state.betsAmount.reduce(
        (prev, cur) => Number(prev) + Number(cur.amount),
        0
      ),
    });
  }

  updateWinsArray(num) {
    console.log(this.state.betsAmount);
    let totalBet = 0;
    if (num == 0) {
      console.log(this.props.gameState);
      if(this.props.gameState && this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0 ){
        this.setState({ betButtonText: this.props.language.Prev, betButtonValue: 0,winAmount:0 });
      }else{
        this.setState({disBetBtn: 1});
      }
    
    this.setState({ disDoubleBtn: 1, disClearBtn: 1, Total_Bet_Amount: 0,
      // bingobetdeduction:0, 
      betButtonText: this.props.language.Prev, betButtonValue: 0, infoText: this.props.language.Play_Njoy });

      document.getElementById("bngo_betglow").src = "";
      if (document.getElementById("bngo_betglow").classList.contains("blinkeffectbngobtns")) {
        document.getElementById("bngo_betglow").classList.remove("blinkeffectbngobtns")
      }

      if (document.getElementById("bngo_Cover")) {
        document.getElementById("bngo_Cover").style.display = "none";
      }

      this.MiddleButtonPanel.clearbethouses();
      this.MiddleButtonPanel.clearcolrbethouses();

      for (let i = 0; i < 6; i++) {
        if (document.getElementById(`betbngoamnt${i}`)) {
          document.getElementById(`betbngoamnt${i}`).classList.remove("bettextbngochnge");
        }
        this.MiddleButtonPanel.current.updateBetAmt(i, 0);
        this.state.betsAmount[i].amount = 0;
        document.getElementById("betbngoamnt"+i).innerText = 0;

        for (let j = 0; j <= 25; j++) {
          if (document.getElementById(`eachnum_${i}_${j}`) && document.getElementById(`eachnum_${i}_${j}`).classList.contains("bettextbngochnge")) {
            document.getElementById(`eachnum_${i}_${j}`).classList.remove("bettextbngochnge");
          }
        }
      }
    }
    if (num == 2) {
      let maxInnerLimit = this.innerLimits[0][1];

      for (let k = 0; k < this.state.betsAmount.length; k++) {
        if (this.state.betsAmount[k].amount > 0) {
          if ((this.state.betsAmount[k].amount * 2) <= maxInnerLimit) {
            console.log("Bingo Total Bet:  " + this.state.Total_Bet_Amount);
            console.log("Bingo Inner Bet:  " + (this.state.betsAmount[k].amount));
            if ((this.state.Total_Bet_Amount + this.state.betsAmount[k].amount) <= this.props.balance) {

              console.log("Bing Max Bet: " + this.props.maxBetAmt);

              if (this.state.Total_Bet_Amount + this.state.betsAmount[k].amount <= this.props.maxBetAmt) {
                let doublebetseachhouse=this.state.betsAmount[k].amount * 2
                let doublebets= this.changeAmtlabl(doublebetseachhouse)
               
                document.getElementById("betbngoamnt"+k).innerText = doublebets;
                const Total_Bet_Amount = this.state.Total_Bet_Amount + this.state.betsAmount[k].amount;
                // this.setState({ Total_Bet_Amount: Total_Bet_Amount });
                this.state.Total_Bet_Amount = (this.state.Total_Bet_Amount + this.state.betsAmount[k].amount);

                this.state.betsAmount[k].amount = (this.state.betsAmount[k].amount * 2);
                console.log(this.state.Total_Bet_Amount);
               
                this.MiddleButtonPanel.current.updateBetAmt(k, this.state.betsAmount[k].amount);
                this.setState({ infoText: this.props.language.Play_Njoy });
              } else {
                this.setState({
                  infoText:     this.props.language.MinBetAmt +" " +this.props.minBetLbl +" " +this.props.language.MaxBetAmt +" " +this.props.maxBetLbl,
                  
                  // infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt 
                
                });

              }
            } else {
              this.setState({
                // disDoubleBtn: 1,
                // disClearBtn: 0,
                infoText: this.props.language.LowBalance,
              });
              // document.getElementById("doubleButton").classList.add("btnsnodrop")

            }
          } else {
           
            let maxInnerbetAmount = this.changeAmtlabl(this.innerLimits[0][1]);
         
            // this.setState({ infoText: this.props.language.MaxInrBetAmt + " " + this.innerLimits[0][1], });
            this.setState({ infoText: this.props.language.MaxInrBetAmt + maxInnerbetAmount });
          }

        }
      }

      if ((this.state.Total_Bet_Amount) > this.props.maxBetAmt) {
        this.setState({ infoText:    this.props.language.MinBetAmt +" " +this.props.minBetLbl +" " +this.props.language.MaxBetAmt +" " +this.props.maxBetLbl});
        // this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt });
      }

      if ((this.state.Total_Bet_Amount) > this.props.balance) {
        console.log((this.state.Total_Bet_Amount * 2))
        console.log(this.props.balance)
        this.setState({ infoText: this.props.language.LowBalance });
      }
    }
  }

  showPrevBet() {
    console.log(this.props.gameState);

    if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0) {
      console.log(this.props.gameState.betRequest.betAmount)
      console.log(this.props.balance)

      if (this.props.gameState.betRequest.betAmount <= this.props.balance) {

        let newStr = this.props.gameState.betRequest.betString;
        let newStr1 = newStr.split("|");
        console.log("newStr1: " + newStr1);
        this.setState({
          betstr: newStr1,
          betButtonText: this.props.language.Roll,
          betButtonValue: 1,
          Total_Bet_Amount: this.props.gameState.betRequest.betAmount,
          //  bingobetdeduction:this.props.gameState.betRequest.betAmount,
          disDoubleBtn: 0,
          disClearBtn: 0
        });

        for (var i = 0; i < newStr1.length; i++) {
          this.state.betsAmount[i].amount = Number(newStr1[i]);
          if (Number(newStr1[i] > 0)) {
            for (let k = 0; k < 25; k++) {
              //console.log(document.getElementById(`eachnum_${i}_${k}`));
              if (document.getElementById(`eachnum_${i}_${k}`)) {
                document
                  .getElementById(`eachnum_${i}_${k}`)
                  .classList.add("bettextbngochnge");
              }
            }
          }
          this.MiddleButtonPanel.betplace(i, this.state.betsAmount[i].amount);

        }

        this.setState({
          disClearBtn: 0,
          enablekeyboard: true,
          disDoubleBtn: 0,
          disBetBtn: 0,
        });

        if (document.getElementById("bngo_betglow")) {
          document.getElementById("bngo_betglow").src = button_glow;
          document.getElementById("bngo_betglow").classList.add("blinkeffectbngobtns")
        }

        console.log(this.state.betsAmount);
        this.isReady = false;
      } else {
        this.setState({ infoText: this.props.language.BetAmtMaxBal });
      }
    }
  }

  rightButtonPanelHandler = (action) => {
    // alert("..................."+action)
    switch (action) {
      case "Roll":
      // this.setState({bingobetdeduction:0})
        this.betSound.play();
        // console.log(this.props.bng_innerLimits)
        const { Total_Bet_Amount, initialBetAmount, bets } = this.state;
        console.log(Total_Bet_Amount)
        console.log(typeof (Total_Bet_Amount))
        console.log(this.props.minBetAmt)
        console.log("Balance: "+this.props.balance)
        if (Total_Bet_Amount <= this.props.balance) {

          if (Total_Bet_Amount !== 0 && Total_Bet_Amount < this.props.minBetAmt) {
            if (Total_Bet_Amount !== 0 && Total_Bet_Amount < Number(this.innerLimits[0][0])) {
              this.setState({ infoText: this.props.language.InerLmt + Number(this.innerLimits[0][0]) });
            }
          } else {
            this.isReady = true;
            if (initialBetAmount != 0 && Total_Bet_Amount != 0 && this.isReady) {

              if (document.getElementById("bngo_betglow")) {
                document.getElementById("bngo_betglow").src = "";
                if (document.getElementById("bngo_betglow").classList.contains("blinkeffectbngobtns")) {
                  document.getElementById("bngo_betglow").classList.remove("blinkeffectbngobtns")
                }
              }

              this.setState({ betstr: "", bets: "", disbetBt: 1, disClearBtn: 1, disDoubleBtn: 1 });

              this.state.betstr = "";
              this.state.bets = "";
              let betValid = [];
              const { balance } = this.props;
              if (balance >= Total_Bet_Amount) {
                console.log(this.state.betsAmount);
                console.log(this.state.betsAmount.length);
                for (let i = 0; i < this.state.betsAmount.length; i++) {
                  if (this.state.betsAmount[i].amount > 0) {
                    if (this.state.betsAmount[i].amount < Number(this.innerLimits[0][0])) {


                      this.setState({ infoText: this.props.language.InerLmt + Number(this.innerLimits[0][0]), disClearBtn: 0 });
                    } else if (this.state.betsAmount[i].amount > Number(this.innerLimits[0][1])) {
                      this.setState({ infoText: this.props.language.MaxInrBetAmt + Number(this.innerLimits[0][1]) });
                    }

                    if (this.state.Total_Bet_Amount >= this.props.minBetAmt && this.state.Total_Bet_Amount <= this.props.maxBetAmt && this.state.betsAmount[i].amount >= Number(this.innerLimits[0][0]) && this.state.betsAmount[i].amount <= Number(this.innerLimits[0][1])) {
                      betValid[i] = true;
                    }
                    else {
                      betValid[i] = false;
                    }
                  }
                  else {
                    // betValid[i] = true;
                  }

                  // for(let y=1; y<26; y++){
                  //   if(document.getElementById("h"+i+"_"+y).classList.contains("hhlthouses")){
                  //     document.getElementById("h"+i+"_"+y).classList.remove("hhlthouses")
                  //   }
                     
                  // }

                }
                for (let i = 0; i < this.state.betsAmount.length; i++) {
                  this.state.bets += this.state.betsAmount[i].amount + "|";
                }

                this.setState({ betstr: this.state.bets.slice(0, -1) }, () => {
                  console.log(this.state.betstr);
                  // console.log("bets Valid : " + betValid);
                  let sendBet = true;
                  for (let k = 0; k < betValid.length; k++) {
                    if (betValid[k] === false) {
                      sendBet = false;
                      break;
                    }
                  }

                  if (sendBet) {
                    this.MiddleButtonPanel.disablekeyboradbets();
                    this.placebet();
                    this.setState({
                      disBetBtn: 1,
                      disDoubleBtn: 1,
                      disClearBtn: 1,
                      btnActive: false,
                      
                    });
                  }
                });
                console.log(this.state.betstr);
              }
              else {
                this.setState({ infoText: this.props.language.LowBalance });
              }
            }
            return this.state.betstr;
          }

        } else {          
          this.setState({ infoText: this.props.language.LowBalance });
        }
        break;
      // case "TAKE":
      //   //console.log("take amount action");
      //   this.playTakeSound();
      //   this.setState({disBetBtn: 1});
      //   this.takeAmountResult();
      //   break;

      case "PREV":
        this.betSound.play();
        this.showPrevBet();
        this.isReady = true;
        break;
      case "double":
        this.betSound.play();
        this.updateWinsArray(2);
        break;
     
      case "clear":
        this.clearSound.play();
        this.updateWinsArray(0);
        break;
      case "EXIT":
        this.gameCloseHandler("bingo");
        const { playerId, gameId, network } = this.props;
        const body = {
          sessionId: sessionStorage.getItem("sessionId"),
          CN: "EXIT_GAME",
          object: {
            gameId: gameId,
            playerId: playerId,
          },
        };
        network.sendof(body);
        this.body = {};
        break;
      case "disableBtns":
        this.setState({ disBetBtn: 1 });
        break;

      default:
        break;
    }
  };

  enablebngodup() {

    this.setState({
      betButtonText: this.props.language.Roll,
      betButtonValue: 1,
      disBetBtn: 1,
      disClearBtn: 1,
      disDoubleBtn: 1, 
      btnActive: false,
      winAmount: 0,
      infoText:
        this.props.language.Play_Njoy +
        this.props.language.MinBetAmt +" " +this.props.minBetLbl +" " +this.props.language.MaxBetAmt +" " +this.props.maxBetLbl,
      winGlowbngo: false,
      showDupbngo: true, 
    });

    this.MiddleButtonPanel.clearevntsbngo();
    this.MiddleButtonPanel.clearbethouses();


    for (let i = 0; i < 6; i++) {
      if (document.getElementById(`wintableglow${i}`)) {
        document.getElementById(`wintableglow${i}`).style.display = "none";
        document.getElementById(`winbngoamnt${i}`).classList.remove("blink_winamount");
        document.getElementById(`winbngoamnt${i}`).classList.remove("winblinkamount");
      }
    }

    if (document.getElementById("bngoresult_anim1")) {
      document.getElementById("bngoresult_anim1").src = "";
    }
    if (document.getElementById("bngoresult_anim2")) {
      document.getElementById("bngoresult_anim2").src = "";
    }
    if (document.getElementById("bngoresult_anim3")) {
      document.getElementById("bngoresult_anim3").src = "";
    }
    if (document.getElementById("bngoresult_anim4")) {
      document.getElementById("bngoresult_anim4").src = "";
    }
    if (document.getElementById("bngoresult_anim5")) {
      document.getElementById("bngoresult_anim5").src = "";
    }
    if (document.getElementById("bngoresult_anim6")) {
      document.getElementById("bngoresult_anim6").src = "";
    }

    if (document.getElementById("bngo_betglow")) {
      document.getElementById("bngo_betglow").src = "";
      if (document.getElementById("bngo_betglow").classList.contains("blinkeffectbngobtns")) {
        document.getElementById("bngo_betglow").classList.remove("blinkeffectbngobtns")
      }
    }
    
    if (document.getElementById("bngo_Cover")) {
      document.getElementById("bngo_Cover").style.display = "none";
    }


      const getPlayerInfoBody = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "GET_PLAYER_INFO",
      object: {
        playerId: Number(this.props.playerId),
      },
    };
    this.props.network.sendof(getPlayerInfoBody);
  }

  updateMessage = (updtemsg) => {
  
    // console.log(this.props.infoText);
    this.setState({ infoText: updtemsg });

    // console.log("error code :  " + this.props.error_code);
    if (this.props.error_code == "game.bet.invalid") {
      this.setState({
        btnActive: false,
        disBetBtn: 0,
      });
    }
  };

  topLable_fn(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs(Number(labelValue.toFixed(2)));
}


  render() {
  // console.log(this.state.bingobetdeduction)
  console.log(this.props.balance)
    // const roundNumber = this.props.balance - this.state.bingobetdeduction
    const roundNumber = this.props.balance 
  
  
        // const betAmount = Math.round(roundNumber*100,2)/100
        const betAmount = (roundNumber)
      
        let updatebal = this.topLable_fn(Number(betAmount))
        let updateBet = this.topLable_fn(this.state.Total_Bet_Amount)
        let winAmountlbl=this.changeAmtlabl(this.state.winAmount)
   
    return (
      <React.Fragment>
        <div className="gamePage bingo-container">
          <div id="bngo_Coverbtns" className="bngo_Coverbtns"></div>
          <div id="bngo_Cover" className="bingo_cover"></div>
          <TopButtonPanel
            childRef={(ref) => (this.TopButtonPanel = ref)}
            balance={this.props.balance}
            isMobile={this.props.isMobile}
            updatebalnace={updatebal}
            updateBetAmt={updateBet}
            ref={this.TopButtonPanel}
            playerId={this.props.playerId}
            gameId={this.state.gameId}
            winAmount={winAmountlbl}
            Total_Bet_Amount={this.state.Total_Bet_Amount}
            user={this.props.user}
            action={this.rightButtonPanelHandler}
            // rightButtonPanelHandler={this.rightButtonPanelHandler}
            bngo_payouts={this.props.bngo_payouts}
            winGlowbngo={this.state.winGlowbngo}
          />

          <MiddleButtonPanel
            childRef={(ref) => (this.MiddleButtonPanel = ref)}
            rightButtonPanelHandler={this.rightButtonPanelHandler}
            betPanelHandler={this.betPanelHandler}
            ref={this.MiddleButtonPanel}
            betPanelH={this.betPanelHan}
            bingoResultHandler={this.bingoResultHandler}
            user={this.props.user}
            betButtonValue={this.state.betButtonValue}
            betButtonText={this.state.betButtonText}
            Total_Bet_Amount={this.state.Total_Bet_Amount}
            disClearBtn={this.state.disClearBtn}
            disdoubleBt={this.state.disDoubleBtn}
            disbetBt={this.state.disBetBtn} 
            bngogamestate={this.props.gameState}
            network={this.props.network}
            playerId={this.props.playerId}
            gameId={this.state.gameId}
            isMobile={this.props.isMobile} 
            bnbonusmnt_bingo={this.state.bnbonusmnt_bingo}
            handId={this.state.handId}
            infoText={this.state.infoText}
            showDupbngo={this.state.showDupbngo}
            minbetamnt={this.props.minBetAmt}
          />
        </div>
        <div className="winPopMatka" id="winPop"> 
            <div className="popBorder">
            {this.props.language.Win_Amt}  &nbsp; <span> {winAmountlbl} </span> 
            </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj,
  };
};

export default connect(mapStatesToProps)(BINGO);
