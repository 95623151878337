import React from "react";
import "../../../../css/games/funTarget/funTarget.css";
import "./UI/funtarget.css";
import Wheel from "./UI/wheel";
import Close from "./UI/close";
import Title from "./UI/title";
import BetAmount from "./UI/betAmount";
import BalanceLabel from "./UI/balanceLabel";
import LeftButtonPanel from "./UI/leftButtonPanel";
import RightButtonPanel from "./UI/rightButtonPanel";
import BetPanel from "./UI/betPanel";
import InfoBoard from "./UI/infoBoard";
import artBg from "../../../../../assets/games/funTarget/fun-target-bg.jpg";
import takeSound from "../../../../../assets/sounds/take.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import clearSound from "../../../../../assets/sounds/clear.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import betoncardSound from "../../../../../assets/sounds/betoncard.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import wheelSound from "../../../../../assets/sounds/spinandwheelreel.mp3";

import { connect } from "react-redux";

class FunTarget extends React.Component {
  constructor(props) {
    super(props);
    this.wheelDimension = window.innerHeight * 0.67;
    this.wheelDimensionWidth = window.innerWidth * 0.34;
    this.ftWheelRef = React.createRef();
    this.ftBetAmounts = React.createRef();/**/
    this.InfoBoard = React.createRef();
    this.betPanelRef =React.createRef();

    this.state = {
      previousBetArray: [],
      finalPreviousBetArray: [],
      gameId: this.props.gameId,
      playerId: this.props.playerId,
      totalBet: 0,
      winBet: 0,
      showBetButton: false,
      betButtonText: this.props.language.Prev,
      betButtonValue:0,
      infoText: this.props.language.Play_Njoy,

      betstr: "",
      bets: "",
      resultNo: 10,
      targetResult: props.targetResult,
      actBtn: false,
      Total_Bet_Amount: 0,
      disDoubleBtn: 1,
      disBetBtn: 0,
      disClearBtn: 1,
      glowTakeBtn: 0,
      btnActive: false,
      trgtLoader: true,
      
      wins: [     
        { id: 0, amount: 0 },  
        { id: 1, amount: 0 },
        { id: 2, amount: 0 },
        { id: 3, amount: 0 },
        { id: 4, amount: 0 },
        { id: 5, amount: 0 },
        { id: 6, amount: 0 },
        { id: 7, amount: 0 },
        { id: 8, amount: 0 },
        { id: 9, amount: 0 },
        
      ]
    };
    this.betArray = [];
    this.previousPosition = undefined;
    this.betAmount = 1;
    this.isReady = false;
    this.handId = 0;
    this.isloadImg = false;
    this.loadBg(); 
    this.winOnload = true;
    this.updatePlayerBalnce = false;
    this.minInnerLimit=0 ;
    this.maxInnerLimit=0 ;
    this.minInnerLimitLbl=0 ;
    this.maxInnerLimitLbl=0 ;

  }
  componentDidMount() {  
    this.clearSound = new Audio(clearSound);
    this.exitSound = new Audio(exitSound);
    this.betSound = new Audio(betSound);
    this.takeSound = new Audio(takeSound);
    this.winSound = new Audio(winSound);
    this.duploseSound = new Audio(duploseSound);
    this.wheelSound = new Audio(wheelSound);
    this.betoncardSound = new Audio(betoncardSound);
    
    console.log(" FunTarget History: ");
    console.log(this.props.gameState)

    console.log(this.props.gameState.history)
   
    document.getElementById("funTargetNonTimerCover").style.display = "none";
 
    if (this.props.gameState.history && this.props.gameState.history.length > 0) {
      this.bonusValue = this.props.gameState.history[this.props.gameState.history.length - 1].extraInfo;
      this.ftWheelRef.current.sendJock2(this.bonusValue);
    }
    if(this.props.balance > 0){
      this.setState({disBetBtn: 0, disZoomBtn: 0 })
    }else{
        document.getElementById('funTargetNonTimerCover').style.display = 'block';
        this.setState({disDoubleBtn: 1, disBetBtn: 1, disClearBtn: 1, disZoomBtn: 1 })
    }

    if(this.props.gameState.history && this.props.gameState.history.length == 0){
        this.setState({disBetBtn: 1});
    }

    document.addEventListener("keyup", this.ft_handleKeyboardEvent);    
    document.addEventListener("keydown", this.ft_bets_handleKeyboardEvent);  
    

    if(this.props.ft_chipValues){
      let newChips = this.props.ft_chipValues.split(",");
      console.log(newChips[0]);  
      let initChipVal = newChips[0];      
      this.betAmount = initChipVal;
    }
    
    if(this.props.ft_innerLimits){
      this.minInnerLimit = this.props.ft_innerLimits.split("-")[0];      
      this.maxInnerLimit = this.props.ft_innerLimits.split("-")[1];    
      
      this.minInnerLimitLbl = this.changeAmtlabl(this.minInnerLimit);
      this.maxInnerLimitLbl = this.changeAmtlabl(this.maxInnerLimit);

      console.log("Min Inner Limit: "+this.minInnerLimit+"     "+this.minInnerLimitLbl);
      console.log("Max Inner Limit: "+this.maxInnerLimit+"     "+this.maxInnerLimitLbl);
    }


    const { childRef } = this.props;
    childRef(this);
    //console.log("history.length  "+this.props.gameState.history[this.props.gameState.history.length-1].extraInfo);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.ft_handleKeyboardEvent);
    document.removeEventListener("keydown", this.ft_bets_handleKeyboardEvent);    
  }

  
  ft_bets_handleKeyboardEvent= (evt) => {
    // if(this.state.betButtonValue != 2 && this.state.disBetBtn == 0){
    if(this.state.betButtonValue != 2 && (this.props.gameState.betRequest == undefined || this.state.disBetBtn == 0)){
    
      if(evt.keyCode >= 48 && evt.keyCode <= 57){
        console.log(evt.key);            
        this.betPanelHandler(evt.key);
      }
      if(evt.keyCode >= 96 && evt.keyCode <= 105 ){
        console.log(evt.key);      
        this.betPanelHandler(evt.key);      
      }
    }
  }

  ft_handleKeyboardEvent = (evt) => {
    console.log(evt.code);
    switch(evt.code){
      case "Enter":
      case "NumpadEnter":
        if (this.state.betButtonValue == 0 && this.state.disBetBtn == 0) {
          this.rightButtonPanelHandler("PREV");
        }else if(this.state.betButtonValue == 1 && this.state.disBetBtn == 0){
          this.rightButtonPanelHandler("SPIN");
        }
        break;
      case "Space":
        if(this.state.betButtonValue== 2 && this.state.disBetBtn == 0){
          this.rightButtonPanelHandler("TAKE");
        }
        break;
      case "Escape":
        //this.rightButtonPanelHandler("exit");
        break;
      case "KeyC":      
        if(this.state.betButtonValue == 1 && this.state.disClearBtn == 0){
          this.rightButtonPanelHandler("clear");
        }
        break;      
    }

  }
  updateGameState() {
    console.log("Target Non Timer Game State: ");
    console.log(this.props.gameState);
    if (this.props.gameState.betRequest) {
      this.setState({ disBetBtn: 0 });
    } else {
      this.setState({ disBetBtn: 1 });
    }

    this.InfoBoard.current.historyDistract();
  }
  gameCloseHandler(game) {
    this.exitSound.play();
    this.ftWheelRef.current.stopWheelSound();
    this.props.action(game);
    this.clearSound.pause();
    this.betSound.pause();
    this.takeSound.pause();
    this.winSound.pause();
    this.duploseSound.pause();
  }

  wheelHandler(position) {
   
    // this.declareWin(position);
    console.log("Win Amount: " + this.props.targetResult.winAmount);  
    var valid = document.getElementById('bet_take');
    if (valid)
      valid.classList.remove('active');
    
    this.declareWin();
    if(this.updatePlayerBalnce == false){
      this.updatePlayerBalnce = true;
    }
    
  }
  declareWin() {        
    if (this.props.targetResult.winAmount > 0) {
      this.winSound.play();
      document.getElementById('winPop').style.display = 'block';
      this.rightButtonPanelHandler('TAKE') ;
      this.getPlayerInfo();
      setTimeout(() => {
        document.getElementById('winPop').style.display = 'none';
        this.updateWinsArray(0);
        this.setState({betButtonText: this.props.language.Prev, betButtonValue:0, disBetBtn:0});
        document.getElementById("funTargetNonTimerCover").style.display = "none";
      }, 3000)
      this.setState({
          infoText: this.props.language.WonHand, 
          showWin: this.props.targetResult.resultNum,
          winningAmount: this.props.targetResult.winAmount
      });
      this.state.resultNo = this.props.targetResult.resultNum;
      //this.rightButtonPanelHandler("TAKE")
      this.state.winningAmount = this.props.targetResult.winAmount;
    } else {
      this.duploseSound.play();
      console.log("180");
      this.getPlayerInfo();
      this.setState({
          showBetButton: false,
          betButtonText: this.props.language.Prev, 
          betButtonValue:0,
          winBet: 0,
          actBtn: false,
          winCover: false,
      });
      setTimeout(() => {
        this.updateWinsArray(0);
        document.getElementById("funTargetNonTimerCover").style.display = "none";
      }, 500)
  }
  this.isReady = false;
  this.props.gamstateHandle(this.props.gameId);
  // document.getElementById("funTargetNonTimerCover").style.zIndex = "50";

  }

  betHandler(amount) {
    console.log("Chip Amount: " + amount);
    this.playTakeSound();
    this.betAmount = amount;
  }

  betPanelHandler(betOn) {
    console.log(betOn)
    console.log(this.state.showBetButton);
    this.setState({ betButtonText: this.props.language.Spin, betButtonValue:1, disDoubleBtn: 0, disClearBtn: 0, disBetBtn: 0,actBtn:true });
    this.betoncardSound1 = new Audio(betoncardSound)
    this.betoncardSound1.play();
    
    let totalBetVal = this.state.wins.reduce((prev, cur) => Number(prev) + Number(cur.amount), this.betAmount);

    console.log(" Bet Amt: " + totalBetVal);
    if (!this.state.showBetButton) {
      console.log("Selected Chipt Value: "+this.betAmount);
      if (Number(this.betAmount) !== 0) {
        if(totalBetVal > this.props.maxBetAmt){
          // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
          this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
        }else if (totalBetVal <= this.props.balance) {
          //console.log(this.maxInnerLimit);
          console.log("FT Max Inner Lmt: "+this.maxInnerLimit);
          if (this.state.wins[betOn].amount + Number(this.betAmount) <= this.maxInnerLimit) {
            this.setState({ Total_Bet_Amount: totalBetVal })
            this.state.wins[betOn].amount += Number(this.betAmount);
            if(this.betPanelRef && this.betPanelRef.current){
              this.betPanelRef.current.updateBetAmt(betOn, this.state.wins[betOn].amount);
            }
                      
            this.setState({ wins: this.state.wins, });
            this.previousPosition = betOn;
            console.log(`${this.betAmount} rupees place on ${betOn}`);

            this.isReady = true;
            console.log("this.isReady " + this.isReady)
            this.setState({ showBetButton: false });
          }else{
            this.setState({infoText: this.props.language.MaxInrBetAmt+""+this.maxInnerLimitLbl});
          }
        } else {
          this.setState({ infoText: this.props.language.LowBalance });
          console.log("amount reduces to your balance");
          //alert('amount reduces to your balance');
        }
      } else {
        if(this.betAmount == 0 && this.state.Total_Bet_Amount == 0){
          this.setState({ infoText: this.props.language.MinBetAmt+this.props.minBetLbl});
          setTimeout(()=>{
              this.setState({ infoText: this.props.language.Play_Njoy });                  
          },1500)
        }else{
            this.setState({infoText: this.props.language.Play_Njoy, betButtonText: this.props.language.BetOK, betButtonValue:1,actBtn:true});
            // this.setState({ infoText: this.props.language.Play_Njoy });
        }

        this.state.wins[betOn].amount = 0;
        console.log(" --- "+this.state.wins[betOn].amount)
        this.betPanelRef.current.updateBetAmt(betOn, this.state.wins[betOn].amount);
        totalBetVal = this.state.wins.reduce((prev, cur) => Number(prev) + Number(cur.amount), this.betAmount);
        this.setState({
          Total_Bet_Amount: totalBetVal,
        });
      }
      if (totalBetVal == 0) {
        this.setState({
          betButtonText: this.props.language.Prev,
          betButtonValue:0,
          actBtn:false,
          disDoubleBtn: 1,
          disBetBtn: 0,
          disClearBtn: 1,
          glowTakeBtn: 1,
          btnActive: false,
        })
      }
    } else {
      
      // this.setState({betButtonText:this.props.language.prev, betButtonValue:0,actBtn:false})
    }
  }
  rightButtonPanelHandler(action) {
    switch (action) {
      case "SPIN":
        if(this.state.Total_Bet_Amount != 0 && this.state.Total_Bet_Amount < this.props.minBetAmt){
          // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
          this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});

        }else{ 

          console.log("spin the wheel: " + this.isReady);
          this.isReady = true;
          
          this.winSound = new Audio(winSound);
          this.duploseSound = new Audio(duploseSound);
          this.wheelSound = new Audio(wheelSound);
  
          //this.state.totalBet = this.state.wins.reduce((prev, cur) => prev + cur.amount, 0);
          console.log("this.state.Total_Bet_Amount: " + this.state.Total_Bet_Amount);
          // alert(this.state.totalBet);
  
          if (this.state.Total_Bet_Amount != 0 && this.isReady) {
            this.state.betstr = "";
            this.state.bets = "";
            let betValid=[];
           
            console.log(this.props.ft_innerLimits)
            let innerMin_Limit = this.props.ft_innerLimits.split("-")[0];
            console.log("---------   "+innerMin_Limit);

            if (this.props.balance >= this.state.Total_Bet_Amount) {
              for (let i = 0; i < this.state.wins.length; i++) {
                this.state.bets += this.state.wins[i].amount + "|";
                console.log("FT Bet List: "+ this.state.wins[i].amount);

                if(this.state.wins[i].amount > 0){
                  if(this.state.wins[i].amount >= innerMin_Limit){
                    betValid[i] = true;
                  }else{
                    betValid[i] = false;
                  }
                }else{
                  betValid[i] = true;
                }
              }
              this.state.betstr = this.state.bets.slice(0, -1);
              console.log(this.state.betstr);

              console.info("betValid: "+betValid);
              let sendBet = true;;
              for(let u=0; u<betValid.length;u++){
                if(betValid[u] ==  false){
                  sendBet = false;
                  // console.log("Dont send bet reques");
                  break;
                }
              }
             
              if(sendBet){
                this.setState({
                  disDoubleBtn: 1,
                  disBetBtn: 1,
                  disClearBtn: 1,
                  actBtn:false,
                  btnActive:false
                });
                this.placebet();
              }else{
                console.log("Dont Send Bet Request.")
                const minInrLmt = this.changeAmtlabl(innerMin_Limit)
                this.setState({ infoText: this.props.language.InerLmt + minInrLmt });                
              }
              return this.state.betstr;
            } else {
              this.setState({ infoText: this.props.language.LowBalance });              
            }
          }
        }
        break;
      case "TAKE":
        console.log("take amount action");
        //this.playTakeSound();
        console.log("take amount action");
        this.takeAmountResult();
        break;
      case "PREV":
        console.log("Previous Bet action");
        this.betSound.play();
        this.setState({ disClearBtn: 0, disDoubleBtn: 0 });        
        this.PreviousBetMethod();
        break;
      case "double":
        this.betSound.play();
        this.updateWinsArray(2);
        break;
      case "clear":       
        this.clearSound.play();
        this.updateWinsArray(0);
        this.setState({ betButtonText: this.props.language.Prev, betButtonValue:0, disClearBtn: 1, disDoubleBtn: 1, Total_Bet_Amount: 0,actBtn:false });
        if (this.props.gameState.betRequest) {
          this.setState({ disBetBtn: 0 });
        } else {
          this.setState({ disBetBtn: 1 });
        }
        
        break;
      case "exit":
        this.gameCloseHandler("target");
        const body = {
          sessionId: sessionStorage.getItem('sessionId'),
          CN: "EXIT_GAME",
          object: {
            gameId: this.props.gameId,
            playerId: Number(this.props.playerId),
          },
        };
        this.props.network.sendof(body);
        this.body = {};
        break;
      case "disableBtns":
        this.setState({ disDoubleBtn: 1, disBetBtn: 1, disClearBtn: 1, disZoomBtn: 1 });
        break;
      default:
        break;
    }
  }

  playTakeSound() {
    this.takeSound.play();
  }
  updateMessage() {
    console.log("--------- " + this.props.infoText);
    this.setState({ infoText: this.props.infoText });

    console.log("error_code: " + this.props.error_code);
    if (this.props.error_code == "game.bet.invalid") {
      this.setState({
        betButtonText: this.props.language.Prev,
        betButtonValue:0,
        btnActive: false,
        disBetBtn: 0,
        disZoomBtn: 0,
      });
      this.updateWinsArray(0);
      document.getElementById('funTargetNonTimerCover').style.display = 'none';
    }
  }


  PreviousBetMethod() {
    //this.updateWinsArray(0);
    console.log("Show Previous Bet");
    console.log(this.state.Total_Bet_Amount);
    console.log(this.props.gameState)

    if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount <= this.props.balance) {
      if(this.props.gameState.betRequest.betAmount > this.props.maxBetAmt){
        this.setState({ infoText:this.props.language.PreBet_MaxLmt });
      }else{      
        var preBet = this.props.gameState["betRequest"]["betString"];
        this.state.previousBetArray.push({
          amount: preBet.split("|"),
        });
        this.setState({ previousBetArray: this.state.previousBetArray });
        if (this.state.previousBetArray) {
          this.isReady = true;
          this.toCreatePreviousBetArray();
        }
        // console.log(this.state.previousBetArray[0].amount[0]);
        if (this.props.gameState.handId) {
          this.handId = this.props.gameState.handId;
        }
      }
    } else {
      this.setState({ infoText: this.props.language.Bet_Amt_MaxBal });
    }
  }

  toCreatePreviousBetArray() {
    for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
      this.state.finalPreviousBetArray.push({
        id: i,
        amount: Number(this.state.previousBetArray[0].amount[i]),
      });
    }
    this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray });
    console.log(this.state.finalPreviousBetArray);
    if (this.state.finalPreviousBetArray) {
      this.toGenerteBetString();
    }
  }
  toGenerteBetString() {
    for (let i = 0; i < this.state.wins.length; i++) {
      if (this.state.wins[i].id == this.state.finalPreviousBetArray[i].id) {      
        this.state.wins[i].amount += this.state.finalPreviousBetArray[i].amount;
        this.betPanelRef.current.updateBetAmt(i, this.state.wins[i].amount);        
      }
    }
    this.setState({
      wins: this.state.wins, 
      betButtonText: this.props.language.Spin,
      actBtn:true,
      betButtonValue:1,
      previousBetArray: [], finalPreviousBetArray: [],
      Total_Bet_Amount: this.state.wins.reduce((prev, cur) => prev + cur.amount, 0)
    });
    console.log(this.state.wins);
  }
  takeAmountResult() {
    console.log("Take Amount Function");
    console.log("Remove Img Glow: " + this.resultNumber);
    this.setState({
      btnActive: false,
      winAmount: 0,
      actBtn: false,
      betsDone: false,
      infoText: this.props.language.Play_Njoy,
      showBetButton: false,
      winBet: 0,
    })        

    if (this.props.isPlayerBanned) {
      this.gameCloseHandler("target");
      this.props.player_banned();
    }
    console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
    if (this.props.gameUnderMaintanance) {
      this.gameCloseHandler("target");
      this.props.game_maintanance("Game under maintenance, reach to admin.");
    }
    console.log("deviceBlocked " + this.props.isdeviceBlocked);
    if (this.props.isdeviceBlocked) {
      console.log("---------------------------")
      this.gameCloseHandler("target");
      console.log("---------------------------")
      this.props.device_blocked();
    }
  }
  updateWinsArray(num) {
    // const array = [1,2,3,4,5,6,7,8,9,0]
    console.log(this.state.wins);
    let totBet = 0;
    if (num == 2) {
      if(this.state.Total_Bet_Amount*2 >this.props.maxBetAmt){
        this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
      }else{
        if(this.state.Total_Bet_Amount*2 <= this.props.balance){
          for (let k = 0; k < this.state.wins.length; k++) {
            if (this.state.wins[k].amount > 0 && this.state.wins[k].amount <= this.maxInnerLimit && totBet <= this.props.balance) {
              console.log(this.state.wins[k].amount);
              if (this.state.wins[k].amount * num <= this.maxInnerLimit) {
                if (this.state.Total_Bet_Amount + this.state.wins[k].amount <= this.props.balance) {
                  this.state.Total_Bet_Amount = this.state.Total_Bet_Amount + this.state.wins[k].amount;
                  this.state.wins[k].amount = this.state.wins[k].amount * num;     
                  this.betPanelRef.current.updateBetAmt(k, this.state.wins[k].amount);         
                }
              }else{
                this.setState({infoText: this.props.language.MaxInrBetAmt+this.maxInnerLimitLbl});
              }
              totBet += Number(this.state.wins[k].amount);
            }else{
              // console.log("ding 2")
              // this.setState({infoText:this.props.language.MaxInrBetAmt+this.maxInnerLimitLbl });
            }
          }
          if (totBet <= this.props.balance) {
            this.setState({
              Total_Bet_Amount: totBet,
              wins: this.state.wins,  
            });
          }else{
            this.isReady = false;
            this.setState({
              wins: this.state.wins,
              disDoubleBtn: 1,
              infoText: this.props.language.LowBalance,
            });
          }
        }else{
          this.isReady = false;
          this.setState({               
            disClearBtn: 0,
            disZoomBtn: 0,
            infoText: this.props.language.LowBalance ,
          });
        }
      }    
    }else {
      for (let a = 0; a < this.state.wins.length; a++) {
        this.state.wins[a].amount = Number(0);
        this.betPanelRef.current.updateBetAmt(a, this.state.wins[a].amount);
        if (this.state.wins[a].amount > 0) {
          this.state.wins[a].amount = Number(0);
          totBet += Number(this.state.wins[a].amount);
        }
      }

      this.setState({
        wins: this.state.wins,
        disDoubleBtn: 1,
        disClearBtn: 1,        
        infoText: this.props.language.Play_Njoy,
      });
    }
  }

  placebet() {
    const body = {
      sessionId: sessionStorage.getItem('sessionId'),
      CN: "TARGET_BET",
      object: {
        betString: this.state.betstr,
        playerId: Number(this.props.playerId),
        gameId: this.props.gameId,
      },
    };
    this.props.network.sendof(body);
    this.body = {};

    let totBetNum = this.changeAmtlabl(this.state.Total_Bet_Amount);

    this.setState({ infoText: this.props.language.BetAccepted + totBetNum });
    console.log("data send");
    document.getElementById("funTargetNonTimerCover").style.display = "block";
    
  }

  changeAmtlabl(chngAmt){
    let newAmt = chngAmt;
    if(newAmt >= 1000 && newAmt <=999999){
      newAmt = newAmt / 1000+"K";      
    }else if(newAmt >= 1000000 && newAmt <=999999999){
      newAmt = newAmt / 1000+"M";    
    }else if(newAmt >999999999){
      newAmt = newAmt / 1000+"B";      
    }
    return newAmt;
  }

  getPlayerInfo() {
   
    this.setState({disBetBtn:1})
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.state.gameId),
        playerId: Number(this.props.playerId),
      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};
  }
  funTargetGetwheelresp() {
    console.log(this.props.targetResult);
    if (this.props.targetResult) {
      console.log(this.props.targetResult.resultNum);
      this.ftWheelRef.current.spinTheWheel(this.props.targetResult.resultNum);
      this.wheelSound.play();
      this.ftWheelRef.current.sendJock(this.state.x_img);
    }
  }

  jackpotHandler() {
    this.ftWheelRef.current.sendJock();
  }

  loadBg() {
    this.LoadImg = new window.Image()
    this.LoadImg.src = artBg;
    this.LoadImg.addEventListener('load', this.trgtLoded)
  }

  trgtLoded = () => {
    if (!this.isloadImg) {
      this.isloadImg = true;
    } else {
      this.setState({
        trgtLoader: false
      })
    }
  }

  updatePlayerInfo(){        
    if(this.props.targetResult.winAmount > 0 && !this.winOnload){
      this.setState({ Total_Bet_Amount: 0})
    }else{
      if(this.updatePlayerBalnce == true){
        // alert('ding 5');
          this.setState({              
              disBetBtn: 0,
              btnActive : false,
              // betButtonText: this.props.language.Prev,
              // betButtonValue:0,
          });
          document.getElementById('funTargetNonTimerCover').style.display = 'none';
      }else{
          if(this.props.balance > 0){
              document.getElementById('funTargetNonTimerCover').style.display = 'none';
              this.setState({disBetBtn: 0,  })
          }

          if(this.props.gameState.history && this.props.gameState.history.length == 0){
              this.setState({disBetBtn: 1});
          }
      }
    }
    this.state.Total_Bet_Amount = 0;
    this.setState({Total_Bet_Amount: 0});
    //this.updateWinsArray(0)
    if(this.winOnload == true ){
      this.winOnload = false;
    }

  }


  render() {
    let winAmount = this.changeAmtlabl(this.props.targetResult.winAmount)
    return (
      <React.Fragment>
        <div className="gameBox" id="gameBox">
          <div className="gamePage funTrgtNon">
            <div className="fd">
              <div className="fd">
                <div id="funTargetNonTimerCover" className="funTargetNonTimerCover"></div>
                <div className="row">
                  <div className="sorat_chips">
                    <div className="fd p_5 clr_ff font_30">
                      {this.props.language.Player_Id} {this.props.playerId} <br />
                      {this.props.language.Hand_Id} {this.props.gameState.handId}
                    </div>
                    <BetAmount 
                      bet={this.betHandler.bind(this)} 
                      ref={this.ftBetAmounts}
                      ft_chipValues={this.props.ft_chipValues}
                    ></BetAmount>
                  </div>
                  <div className="sorat_table">
                    <div className="fd">
                      <Title></Title>
                      <Wheel
                        gameState={this.props.gameState}
                        targetResult={this.props.targetResult}
                        ref={this.ftWheelRef}
                        height={this.wheelDimension}
                        width={this.wheelDimensionWidth}
                        action={this.wheelHandler.bind(this)}
                        trgtLoded={this.trgtLoded.bind(this)}
                        isMobile={this.props.isMobile}
                        onFocus={this.props.onFocus}>
                      </Wheel>
                    </div>
                  </div>
                  <div className="sorat_btns">
                  

                    {/* <Close
                      action={this.gameCloseHandler.bind(this)} >
                    </Close> */}

                    <BalanceLabel
                      name={this.props.user}>
                    </BalanceLabel>

                    <InfoBoard
                      ref={this.InfoBoard}
                      history={this.props.gameState.history}
                      text={this.state.infoText}
                      text1={this.state.infoText1}
                      text2={this.state.infoText2}>
                    </InfoBoard>

                  </div>
                </div>
              </div>

              <div className="bottomFixFuntarget">
                <div className="fd btns-top">
                  <LeftButtonPanel
                    balance={this.props.balance}
                    totalBet={this.state.Total_Bet_Amount}
                    winning={this.state.winBet}>
                  </LeftButtonPanel>

                  <RightButtonPanel
                    actBtn={this.state.actBtn}
                    betBtnText={this.state.betButtonText}
                    betBtn={this.state.showBetButton}
                    action={this.rightButtonPanelHandler.bind(this)}
                    disDoubleBtn={this.state.disDoubleBtn}
                    disBetBtn={this.state.disBetBtn}
                    disClearBtn={this.state.disClearBtn}
                    glowTakeBtn={this.state.glowTakeBtn}
                    betButtonValue={this.state.betButtonValue}>
                  </RightButtonPanel>
                </div>
                <div className="fd m_t-30">
                  <BetPanel 
                  ref={this.betPanelRef} 
                  action={this.betPanelHandler.bind(this)}
                  resultNo={this.state.resultNo}
                  wins={this.state.wins}></BetPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="winPopTarget" id="winPop"> 
          <div className="popBorder">
            {this.props.language.Win_Amt} &nbsp; 
            <span>  {winAmount} </span> 
          </div>
      </div>
      </React.Fragment>
    );
  }
}
const mapStatesToProps = (state)=>{
  return {
      language : state.languageObjs.languageObj
  }
}
export default connect(mapStatesToProps)(FunTarget) 
