import React  from "react";
import chips from '../../../chips';

import {connect} from "react-redux"

 class BetChips extends React.Component {
    constructor(props) {
        super(props)
        const{childRef} = this.props
        childRef(this)
        this.state = {
            chipSelect : 1,
        }

        // this.chipVal = [ 
        //     { label: "1k" , val: 3000 }, 
        //     { label: "5k" , val: 5000 }, 
        //     { label: "10k" , val: 10000 }, 
        //     { label: "10k" , val: 10000 }, 
        //     { label: "50k" , val: 50000 }, 
        //     { label: "100k" , val: 100000 }, 
        //     { label: "5M" , val: 5000000 }, 
        //     { label: "1B" , val: 100000000 },
        //     { label: "" , val: 0 }
        // ];     
        this.chipVal = [ 
            { label: "1" , val: 1 }, 
            { label: "5" , val: 5}, 
            { label: "10" , val: 10 }, 
            { label: "25" , val: 25 }, 
            { label: "50" , val: 50 }, 
            { label: "100" , val: 100 }, 
            { label: "500" , val: 500}, 
            { label: "1000" , val: 1000 },
            { label: "" , val: 0 }
        ];    

        console.log( chips )
        // this.chipVal = [ "1k", "5k", "10k", "50k", "100k", "50B", "100M", "1M"]
        console.log("Selected Chip Value: "+this.props.betAmount);
    }


    componentDidMount(){
        if(this.props.er_chipValues){
            let newChips = this.props.er_chipValues.split(",");
            console.log(newChips);
            console.log(newChips.length)
            let lblTxt ="";
            for(let i =0; i<newChips.length; i++){                   
                if(Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999){
                    lblTxt = "K"
                }else if(Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999){
                    lblTxt = "M"                        
                }else if(Number(newChips[i]) > 999999999){            
                    lblTxt = "B"
                }                     
                this.chipVal[i].val = newChips[i]         
                console.log(this.chipVal[i].val, lblTxt);
                this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);
            
            }
                
        }
    }

    checkChipValue(chipValue, chpTxt, i) {       
        if(chipValue >= 1000){
            chipValue = chipValue / 1000;
            this.checkChipValue(chipValue, chpTxt, i)
        }else{
            console.log(chpTxt);
            this.showResultChipValue(chipValue, chpTxt, i);
            return chipValue
        }
    }

    showResultChipValue(chVal, chipTxt, index){
        console.log(chVal);
        console.log(chVal+""+chipTxt)
        this.chipVal[index].label = chVal+""+chipTxt;
        console.log(this.chipVal)
    }


    topLable_fn(labelValue) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9
    
        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6
    
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3
    
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
    
        : Math.abs(Number(labelValue.toFixed(2)));
    }


    // topLable_fn(betAmount){
    //     // console.log(betAmount);
    //      if(betAmount >= 1000 && betAmount <=999999){
    //          betAmount = (betAmount / 1000) +"K";      
    //         // console.log(betAmount)
    //      }else if(betAmount >= 1000000 && betAmount <=999999999){
    //        betAmount = betAmount / 1000+"M";    
    //      }else if(betAmount >999999999){
    //        betAmount = betAmount / 1000+"B";      
    //      }
 
    //      return betAmount;
    // }

    render() {
       
        const roundNumber = this.props.balance - this.props.TotalBetAmouont
        // const betAmount = Math.round(roundNumber*100,2)/100
        const betAmount = (roundNumber)
        let updatebal = this.topLable_fn(Number(betAmount));
        // console.log(this.props.balance+"_________"+this.props.TotalBetAmouont)
        // console.log(betAmount)
        // let updatebal = this.topLable_fn(betAmount);
        let updateBet = this.topLable_fn(this.props.TotalBetAmouont)

        
      
        return (
            <React.Fragment>
            <div className="fd p_5">
                <div className="fd">
                    <div className="funBal fd">
                        {/* <div className="bg_blnc1"> {this.props.balance - this.props.TotalBetAmouont} </div> */}
                        <div className="bg_blnc1"> {updatebal} </div>

                        <div className="bg_blnc2">{this.props.language.Balance}</div>
                    </div>
                    <div className="funBal fd">

                        <div className="bg_blnc1">{updateBet}</div>
                        <div className="bg_blnc2">{this.props.language.TotalBet}  </div>
                    </div>
                    {/* <div className="funBal fd">
                        <div className="bg_blnc1"> {this.props.winAmount} </div>
                        <div className="bg_blnc2"> WINNING </div>
                    </div>  */}
                </div> 

                <div className="fd chips chipsTable chipsTable_eurotable">
                        <div className="row"> 
                            <div className='col-12'>
                                {chips.map((data, i) => (
                                <div className="amChipCard am_chip" key={data.src}>
                                    <p className="chipImg_er" onClick={(e)=> {e.preventDefault(); this.props.betAmount(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`);this.setState({chipSelect : data.val})}} >
                                        <img src={data.src} alt={data.src}
                                        className={this.state.chipSelect === data.val ? "active" : "inactive"}
                                         />
                                        <div className="er_overlay-tex">{this.chipVal[i].label}</div>
                                    </p>
                                </div>))}
                            </div> 
                    </div>
                </div>


            </div>
            </React.Fragment>
        );
    }
}
const mapStatesToProps = (state) =>{

    return{
        language:state.languageObjs.languageObj
    }
}


export default connect(mapStatesToProps)(BetChips)
