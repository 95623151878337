import React from "react";
import "./bingo.css";
import greeenanim from "../../../../../../assets/games/bingo/green_anim.gif";
import pinkanim from "../../../../../../assets/games/bingo/pink_anim.gif";
import bluenanim from "../../../../../../assets/games/bingo/blue_anim.gif";
import thickbluenanim from "../../../../../../assets/games/bingo/thick_blueanim.gif";
import brownanim from "../../../../../../assets/games/bingo/brown_anim.gif";
import redanim from "../../../../../../assets/games/bingo/red_anim.gif";
import bngo_reswin1 from "../../../../../../assets/games/bingo/brown.gif";
import bngo_reswin2 from "../../../../../../assets/games/bingo/pink.gif";
import bngo_reswin3 from "../../../../../../assets/games/bingo/blue.gif";

import bngo_reswin4 from "../../../../../../assets/games/bingo/green.gif";
import bngo_reswin5 from "../../../../../../assets/games/bingo/thickblue.gif";
import bngo_reswin6 from "../../../../../../assets/games/bingo/red.gif";
import winpannelsound from "../../../../../../assets/sounds/bngo_winpannelhglt.mp3";
import bngoanim_snd from "../../../../../../assets/sounds/bngo_animres.mp3";
import betoncardSound from "../../../../../../assets/sounds/betoncard.mp3"; 
import slotnum_snd from "../../../../../../assets/sounds/slotend.mp3";
import betHouse0 from "../../../../../../assets/games/bingo/betpannel_1.png";
import betHouse1 from "../../../../../../assets/games/bingo/betpannel_2.png";
import betHouse2 from "../../../../../../assets/games/bingo/betpannel_3.png";
import betHouse3 from "../../../../../../assets/games/bingo/betpannel_4.png";
import betHouse4 from "../../../../../../assets/games/bingo/betpannel_5.png";
import betHouse5 from "../../../../../../assets/games/bingo/betpannel_6.png";
import betHouses from "../../../../../../assets/games/bingo/bingoHouse.png";
import bngInfotextAst from "../../../../../../assets/games/bingo/for.png";
// import BIGDup from "../../../../../../assets/games/bingo/dup.png";
import starbng from "../../../../../../assets/games/bingo/star2.png";
import starbngglow from "../../../../../../assets/games/bingo/star1.png";
import globalBonusAsset from "../../../../../../assets/games/bingo/global_pannel.png";
import tablewinglow from "../../../../../../assets/games/bingo/tablewinglow.png";
import { connect } from "react-redux";

import img2x from "../../../../../../assets/common_imgs/2x.svg";
import img4x from "../../../../../../assets/common_imgs/4x.svg";

const intialTableNumbers = [
  {
    id: 1,
    src: betHouse0,
    numbers: [
      5, 1, 9, 25, 3, 8, 22, 10, 19, 7, 6, 18, 16, 11, 17, 24, 21, 14, 20, 13,
      12, 23, 2, 4, 15,
    ],
  },

  {
    id: 2,
    src: betHouse1,
    numbers: [
      9, 24, 16, 4, 6, 13, 19, 14, 20, 25, 2, 18, 15, 12, 17, 1, 22, 11, 21, 8,
      10, 7, 5, 23, 3,
    ],
  },

  {
    id: 3,
    src: betHouse2,
    numbers: [
      6, 7, 3, 24, 1, 23, 14, 12, 18, 2, 5, 19, 20, 16, 22, 11, 17, 9, 15, 25,
      10, 13, 21, 4, 8,
    ],
  },

  {
    id: 4,
    src: betHouse3,
    numbers: [
      3, 7, 10, 4, 9, 24, 21, 18, 22, 8, 15, 14, 17, 11, 2, 13, 20, 12, 19, 23,
      6, 25, 16, 1, 5,
    ],
  },

  {
    id: 5,
    src: betHouse4,
    numbers: [
      4, 6, 1, 23, 5, 25, 15, 3, 17, 13, 9, 19, 21, 12, 20, 10, 18, 16, 14, 8,
      7, 24, 22, 2, 11,
    ],
  },

  {
    id: 6,
    src: betHouse5,
    numbers: [
      8, 23, 10, 13, 4, 2, 17, 16, 14, 24, 20, 12, 22, 19, 5, 25, 15, 9, 18, 11,
      1, 7, 21, 3, 6,
    ],
  },
];

const wins = [
  { id: 0, amount: 0 },
  { id: 1, amount: 0 },
  { id: 2, amount: 0 },
  { id: 3, amount: 0 },
  { id: 4, amount: 0 },
  { id: 5, amount: 0 },
  { id: 6, amount: 0 },
];
let a = [];

let b = [];

class LeftButtonPanel extends React.Component {
 
  winpannelsound = new Audio(winpannelsound);
  betoncardSound = new Audio(betoncardSound);
  
  slotnum_snd1 = new Audio(slotnum_snd);
  slotnum_snd2 = new Audio(slotnum_snd);
  slotnum_snd3 = new Audio(slotnum_snd);
  slotnum_snd4 = new Audio(slotnum_snd);
  slotnum_snd5 = new Audio(slotnum_snd);
  slotnum_snd6 = new Audio(slotnum_snd);

  isbngokeyhandle = true;
  state = {
    resultbingolist: [],
    enablekeyboard: false,
    resulst: [],
    firsthouse: [],
    secondhouse: [],
    trdhouse: [],
    frthhouse: [],
    fithhouse: [],
    sixthouse: [],
    reslstsecond: [],
    reslstsfrth: [],
    reslstsfifth: [],
    reslstsixfth: [],
    reslststrd: [],
    hltnums: [],
    intialTableNumbers: intialTableNumbers,
    Randomnumbers: [],
    displaynumonebyone: [],
    betsAmount: [
      { id: 0, amount: 0 },
      { id: 1, amount: 0 },
      { id: 2, amount: 0 },
      { id: 3, amount: 0 },
      { id: 4, amount: 0 },
      { id: 5, amount: 0 },
    ],
    winsAmount: [
      { house: 1, amount: 0 },
      { house: 2, amount: 0 },
      { house: 3, amount: 0 },
      { house: 4, amount: 0 },
      { house: 5, amount: 0 },
      { house: 6, amount: 0 },
    ],
  };

  constructor(props) {
    super(props);
    this.startInt = null;
  }

  resultBackgroundColor = "";

  componentDidMount() { 
    // console.log(this.props.bngogamestate.response.houseWin)
    this.preloadBingoImgLeft();
    const { childRef } = this.props;
    childRef(this);
    if (document.getElementById("bngo_Coverbtns")) {
      document.getElementById("bngo_Coverbtns").style.display = "none";
    }
    this.setState({ enablekeyboard: true });

    // document.addEventListener("keydown", this.bingoKeyBoadEventleft);
    // // document.addEventListener("keyup", this.mouseupevent);
    // document.addEventListener("keyup", this.removebetsglow);

    // document.addEventListener("keyup",this.mouseUpSelectHouse)

    document.addEventListener("keydown", this.BingoKeyBoadEventleft);
    document.addEventListener("keyup", this.BingoKeyUpEventleft);

    // document.addEventListener("keyup", this.BingoKeyBoadEventleft);

    setTimeout(()=>{
      document.getElementById("bigHos_1").classList.remove("hideTable")
      document.getElementById("bigHos_2").classList.remove("hideTable")
      document.getElementById("bigHos_3").classList.remove("hideTable")
      document.getElementById("bigHos_4").classList.remove("hideTable")
      document.getElementById("bigHos_5").classList.remove("hideTable")
      document.getElementById("bigHos_0").classList.remove("hideTable")
      document.getElementById("bingoHouse").style.display = "none";
    },100)


    this.bnleft1 = null;
    this.bnleft2 = null;
    this.bnleft3 = null;
    this.bnleft4 = null;
    this.bnleft5 = null;
    this.bnleft6 = null;
    this.bnleft7 = null;
    this.bnleft8 = null;
    this.bnleft9 = null;
    this.bnleft10 = null;
    this.bnleft11 = null;
    this.bnleft12 = null;
    this.bnleft13 = null;
    this.bnleft14 = null;
    this.isMouseDownSelectHouseInProgress = false;
  }

  preloadBingoImgLeft = () => {
    const bngoImagesLoadLeft = [
      betHouse0,
      betHouse1,
      betHouse2,
      betHouse3,
      betHouse4,
      betHouse5,
      bngo_reswin1,
      bngo_reswin2,
      bngo_reswin3,
      bngo_reswin4,
      bngo_reswin5,
      bngo_reswin6,
    ];
    for (let i = 0; i < bngoImagesLoadLeft.length; i++) {
      let bngoImagesLeft = new Image();
      bngoImagesLeft.src = bngoImagesLoadLeft[i];
    }
  };

  handleNumbersOneByOne = (event) => {
    const randomnumbhandle = event;
    // const randomnumbhandle = ['8','23','10','13'];
    const resultnumhandle = randomnumbhandle.map((val) => {
      return isNaN(val) ? val : parseInt(val);
    });

    for (let a = 0; a < 5; a++) {
      this.bnleft5 = setTimeout(() => {
        this.setState({ displaynumonebyone: resultnumhandle.slice(0, a + 1) });
      }, 1000 + a * 1000);

      console.log(this.state.displaynumonebyone)
    }
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.BingoKeyBoadEventleft);
    document.removeEventListener("keyup", this.BingoKeyUpEventleft);

  }
  disablebetskeyboard = () => {
    this.setState({ enablekeyboard: false });
  }

  BingoKeyUpEventleft = (e) => {
    if (e.key == "1" || e.key == "2" || e.key == "3" || e.key == "4" || e.key == "5" || e.key == "6") {
      if (document.getElementById("bngo_Coverbtns")) {
        document.getElementById("bngo_Coverbtns").style.display = "none";
      }
    }
  }

  BingoKeyBoadEventleft = (e) => {
    if (e.key == "1" || e.key == "2" || e.key == "3" || e.key == "4" || e.key == "5" || e.key == "6") {
      if (document.getElementById("bngo_Coverbtns")) {
        document.getElementById("bngo_Coverbtns").style.display = "block";
      }
      this.betoncardSound = new Audio(betoncardSound);
      this.betoncardSound.currentTime = 0;
      this.betoncardSound.play();
    }
    console.log(e);

    switch (e.key) {
      case "1":
        if (this.state.enablekeyboard) {
          // this.mouseDownSelectHouse(0); //02/07/24
          this.keyDownSelectHouse(0);
     
        }

        break;
      case "2":
        if (this.state.enablekeyboard) {
          this.keyDownSelectHouse(1);
        }
        break;
      case "3":
        if (this.state.enablekeyboard) {
          this.keyDownSelectHouse(2);
        }
        break;
      case "4":
        if (this.state.enablekeyboard) {
          this.keyDownSelectHouse(3);
        }
        break;
      case "5":
        if (this.state.enablekeyboard) {
          this.keyDownSelectHouse(4);
        }
        break;
      case "6":
        if (this.state.enablekeyboard) {
          this.keyDownSelectHouse(5);
        }
        break;
      default:
        break;
    }
  };


  disablekeys = () => {
    this.setState({ enablekeyboard: false });
  };

  clearcolrbethousesleft = () => {
    for (let i = 0; i < 25; i++) {
      for (let j = 0; j < 6; j++) {
        //console.log(document.getElementById(`eachnum_${j}_${i}`));
        if (document.getElementById(`eachnum_${j}_${i}`)) {
          document
            .getElementById(`eachnum_${j}_${i}`)
            .classList.remove("bettextbngochnge");
        }
      }
      // console.log(this.state.panelids[i])
    }

    if (document.getElementById("resultimages0")) {
      document.getElementById("resultimages0").innerHTML = "";
    }
    if (document.getElementById("resultimages1")) {
      document.getElementById("resultimages1").innerHTML = "";
    }
    if (document.getElementById("resultimages2")) {
      document.getElementById("resultimages2").innerHTML = "";
    }
    if (document.getElementById("resultimages3")) {
      document.getElementById("resultimages3").innerHTML = "";
    }
    if (document.getElementById("resultimages4")) {
      document.getElementById("resultimages4").innerHTML = "";
    }
    if (document.getElementById("resultimages5")) {
      document.getElementById("resultimages5").innerHTML = "";
    }

    this.setState({
      Randomnumbers: [0, 0, 0, 0, 0],
      displaynumonebyone: [0, 0, 0, 0, 0],
      enablekeyboard: true,
    });
  };

  showswins = () => {

    this.setState({ enablekeyboard: false });
    if (this.props.bngogamestate.response.result) {
      let randomnumbers = this.props.bngogamestate.response.result;
      // console.log(randomnumbers);
      const resultnum = randomnumbers.map((val) => {
        return isNaN(val) ? val : parseInt(val);
      });
      this.setState({
        Randomnumbers: resultnum,
        //  Randomnumbers: ['8','23','10','13'],
        displaynumonebyone: resultnum,
        // displaynumonebyone: ['8','23','10','13'],
      });
      let fstnum = resultnum[0];
      let secnum = resultnum[1];
      let thrdnum = resultnum[2];
      let frthnum = resultnum[3];
      let fifthnum = resultnum[4];
      let sixnum = this.props.bngogamestate.response.bonus;
      //let sixnum ="3"
      console.log(fstnum, secnum, thrdnum, frthnum, fifthnum, sixnum);
      if (this.state.Randomnumbers) {
        let disfst = document.getElementById("resultimages0");
        let dissecond = document.getElementById("resultimages1");
        let disthird = document.getElementById("resultimages2");
        let disfrth = document.getElementById("resultimages3");
        let disfifth = document.getElementById("resultimages4");
        let disfivth = document.getElementById("resultimages5");
        if (disfst) {
          // disnum.classList.add("winpannel_num");
          disfst.innerHTML = fstnum;
          console.log(fstnum);
        }
        if (dissecond) {
          dissecond.innerHTML = secnum;
          console.log(secnum);

        }
        if (disthird) {
          // disnum.classList.add("winpannel_num");
          disthird.innerHTML = thrdnum;
          console.log(thrdnum);
        }

        if (disfrth) {
          disfrth.innerHTML = frthnum;
          console.log(frthnum);

        }
        if (disfifth) {
          // disnum.classList.add("winpannel_num");
          disfifth.innerHTML = fifthnum;
          console.log(fifthnum);

        }
        if (disfivth) {
          if (sixnum == 1) {
            //alert("...."+sixnum)
            disfivth.innerHTML = "NORMAL";
            if (disfivth) {
              disfivth.classList.add("normltext");
            }
          } else {
            if (disfivth.classList.contains("normltext")) {
              disfivth.classList.remove("normltext")
            }
            if (sixnum == 2) {
              let img = document.createElement('img');
              img.src = img2x;
              img.className = "bingo_bonusImg"

              disfivth.appendChild(img);
            }
            if (sixnum == 4) {
              let img = document.createElement('img');
              img.src = img4x;
              img.className = "bingo_bonusImg"
              disfivth.appendChild(img);
            }

            // disfivth.style.cssText =
            //   "font-size: 38px; position: absolute; top: 440px; font-family: 'poppins'; font-weight: bold; right: 101px; text-align: center; color: red; opacity: 1; height: 63px; width: 71px;";
          }
        }
      }
    }
    // if (this.props.bngogamestate.response.houseNum) {
    // console.log(this.props.bngogamestate.houseWin)
    // console.log(this.props.bngogamestate.response.houseWin)
    const resListToMatchbngo = this.props.bngogamestate.response.houseWin
    console.log(resListToMatchbngo)
    if (resListToMatchbngo) {

      let matchedHouseResultLists = [];
      let matchedhouses = [];


      resListToMatchbngo.map((item, i) => {
        if (item.matched) {
          matchedHouseResultLists.push(item.resultlist);
          matchedhouses.push(i + 1);
        }
      });

      if (matchedHouseResultLists.length > 0) {

        console.log("Matched House Numbers:" + matchedhouses);
        matchedHouseResultLists.forEach((resultList, index) => {
          console.log(`House ${index + 1}`);
          console.log("Result List:", resultList);
        });


        const matchedHouse1ResultList = matchedHouseResultLists[0];
        const matchedHouse2ResultList = matchedHouseResultLists[1];
        const matchedHouse3ResultList = matchedHouseResultLists[2];
        const matchedHouse4ResultList = matchedHouseResultLists[3];
        const matchedHouse5ResultList = matchedHouseResultLists[4];
        const matchedHouse6ResultList = matchedHouseResultLists[5];
        console.log(matchedHouse1ResultList);
        console.log(matchedHouse2ResultList);
        console.log(matchedHouse3ResultList);
        console.log(matchedHouse4ResultList);

        let intialupdatearray = [];
        let finalupdatearray = [];
        let trdhouseupdatearray = [];
        let frthupdatearray = [];
        let fivthupdatearray = [];
        let sixthupdatearray = [];
        let cmbnetwaarrays = [];

        const firsths = matchedhouses[0];
        const secondhs = matchedhouses[1];
        const thrdhs = matchedhouses[2];
        const frthhs = matchedhouses[3];
        const ffthhs = matchedhouses[4];
        const sxthhs = matchedhouses[5];

        console.log(firsths, secondhs, thrdhs, frthhs);
        this.setState({
          firsthouse: firsths,
          secondhouse: secondhs,
          trdhouse: thrdhs,
          frthhouse: frthhs,
          fithhouse: ffthhs,
          sixthouse: sxthhs,
        });

        if (matchedhouses.length == 1) {
          const updatefinalarray = matchedHouse1ResultList.flat();

          intialupdatearray.push(updatefinalarray);
          cmbnetwaarrays = intialupdatearray.concat(updatefinalarray);

          console.log(cmbnetwaarrays);
          const winresultlist = cmbnetwaarrays.map((val) => parseInt(val));

          // const winresultlist = cmbnetwaarrays.map((innerele) =>
          // innerele.map((val) => parseInt(val))

          console.log(winresultlist);
          const updatereslist = winresultlist;
          console.log(updatereslist);

          this.setState({
            resulst: updatereslist,
            hltnums: matchedhouses,
          });
        }

        if (matchedhouses.length == 2) {
          const updatefinalarray = matchedHouse1ResultList.flat();
          const updatearray = matchedHouse2ResultList.flat();

          intialupdatearray.push(updatefinalarray);
          finalupdatearray.push(updatearray);
          cmbnetwaarrays = intialupdatearray.concat(finalupdatearray);

          // trdhouseupdatearray.push(updatethirdarray);
          console.log(cmbnetwaarrays);
          // alert("....2");
          const winresultlist = cmbnetwaarrays.map((innerele) =>
            innerele.map((val) => parseInt(val))
          );

          console.log(winresultlist);
          const updatereslist = winresultlist[0];
          const second = winresultlist[1];
          console.log(updatereslist);
          console.log(second);
          this.setState({
            resulst: updatereslist,
            reslstsecond: second,
            hltnums: matchedhouses,
          });
        }

        if (matchedhouses.length == 3) {
          const updatefinalarray = matchedHouse1ResultList.flat();
          const updatearray = matchedHouse2ResultList.flat();
          // if(updatethirdarray.lenth>0){
          const updatethirdarray = matchedHouse3ResultList.flat();
          // }
          // const updatefrtharray = resListToMatch[3].flat();

          console.log(updatethirdarray);
          intialupdatearray.push(updatefinalarray);
          finalupdatearray.push(updatearray);
          trdhouseupdatearray.push(updatethirdarray);

          cmbnetwaarrays = intialupdatearray
            .concat(finalupdatearray)
            .concat(trdhouseupdatearray);
          console.log(cmbnetwaarrays);
          // Handle other cases or provide a default state
          const winresultlist = cmbnetwaarrays.map((innerele) =>
            innerele.map((val) => parseInt(val))
          );

          const updatereslist = winresultlist[0];
          const updatereslistse = winresultlist[1];
          const updatereslisttrd = winresultlist[2];
          console.log(updatereslist);
          console.log(updatereslistse);

          console.log(updatereslisttrd);

          this.setState({
            resulst: updatereslist,
            reslstsecond: updatereslistse,
            reslststrd: updatereslisttrd,
            hltnums: matchedhouses,
          });
        }

        if (matchedhouses.length == 4) {
          const updatefinalarray = matchedHouse1ResultList.flat();
          const updatearray = matchedHouse2ResultList.flat();

          const updatethirdarray = matchedHouse3ResultList.flat();
          const updatefrtharray = matchedHouse4ResultList.flat();

          intialupdatearray.push(updatefinalarray);
          finalupdatearray.push(updatearray);
          trdhouseupdatearray.push(updatethirdarray);
          frthupdatearray.push(updatefrtharray);
          cmbnetwaarrays = intialupdatearray
            .concat(finalupdatearray)
            .concat(trdhouseupdatearray)
            .concat(frthupdatearray);

          // Handle other cases or provide a default state
          const winresultlist = cmbnetwaarrays.map((innerele) =>
            innerele.map((val) => parseInt(val))
          );

          const updatereslist = winresultlist[0];
          const updatereslistse = winresultlist[1];
          const updatereslisttrd = winresultlist[2];
          const updatereslistfrth = winresultlist[3];
          // const updatereslistfifth = winresultlist[4];
          console.log(updatereslistfrth);
          this.setState({
            resulst: updatereslist,
            reslstsecond: updatereslistse,
            reslststrd: updatereslisttrd,
            reslstsfrth: updatereslistfrth,

            hltnums: matchedhouses,
          });
        }

        if (matchedhouses.length == 5) {
          const updatefinalarray = matchedHouse1ResultList.flat();
          const updatearray = matchedHouse2ResultList.flat();

          const updatethirdarray = matchedHouse3ResultList.flat();
          const updatefrtharray = matchedHouse4ResultList.flat();

          const updatefiftharray = matchedHouse5ResultList.flat();

          intialupdatearray.push(updatefinalarray);
          finalupdatearray.push(updatearray);
          trdhouseupdatearray.push(updatethirdarray);
          frthupdatearray.push(updatefrtharray);
          fivthupdatearray.push(updatefiftharray);
          cmbnetwaarrays = intialupdatearray
            .concat(finalupdatearray)
            .concat(trdhouseupdatearray)
            .concat(frthupdatearray)
            .concat(fivthupdatearray);

          // Handle other cases or provide a default state
          const winresultlist = cmbnetwaarrays.map((innerele) =>
            innerele.map((val) => parseInt(val))
          );

          const updatereslist = winresultlist[0];
          const updatereslistse = winresultlist[1];
          const updatereslisttrd = winresultlist[2];
          const updatereslistfrth = winresultlist[3];
          const updatereslistfifth = winresultlist[4];
          console.log(updatereslistfrth);
          this.setState({
            resulst: updatereslist,
            reslstsecond: updatereslistse,
            reslststrd: updatereslisttrd,
            reslstsfrth: updatereslistfrth,
            reslstsfifth: updatereslistfifth,
            hltnums: matchedhouses,
          });
        }
        if (matchedhouses.length == 6) {
          const updatefinalarray = matchedHouse1ResultList.flat();
          const updatearray = matchedHouse2ResultList.flat();

          const updatethirdarray = matchedHouse3ResultList.flat();
          const updatefrtharray = matchedHouse4ResultList.flat();

          const updatefiftharray = matchedHouse5ResultList.flat();

          const updatesixtharray = matchedHouse6ResultList.flat();

          intialupdatearray.push(updatefinalarray);
          finalupdatearray.push(updatearray);
          trdhouseupdatearray.push(updatethirdarray);
          frthupdatearray.push(updatefrtharray);
          fivthupdatearray.push(updatefiftharray);
          sixthupdatearray.push(updatesixtharray);
          cmbnetwaarrays = intialupdatearray
            .concat(finalupdatearray)
            .concat(trdhouseupdatearray)
            .concat(frthupdatearray)
            .concat(fivthupdatearray)
            .concat(sixthupdatearray);

          // Handle other cases or provide a default state
          const winresultlist = cmbnetwaarrays.map((innerele) =>
            innerele.map((val) => parseInt(val))
          );

          const updatereslist = winresultlist[0];
          const updatereslistse = winresultlist[1];
          const updatereslisttrd = winresultlist[2];
          const updatereslistfrth = winresultlist[3];
          const updatereslistfifth = winresultlist[4];
          const updatereslistsixth = winresultlist[5];
          console.log(updatereslistfrth);
          this.setState({
            resulst: updatereslist,
            reslstsecond: updatereslistse,
            reslststrd: updatereslisttrd,
            reslstsfrth: updatereslistfrth,
            reslstsfifth: updatereslistfifth,
            reslstsixfth: updatereslistsixth,
            hltnums: matchedhouses,
          });
        }
      }
    }


    if (this.props.bngogamestate.response.houseWin) {
      let houseWin = this.props.bngogamestate.response.houseWin;
      let updatedWinsAmount = [];
      for (let i = 0; i < this.state.winsAmount.length; i++) {
        let matchingResult = houseWin.find(
          (result) => result.house === this.state.winsAmount[i].house
        );
        if (matchingResult.amount > 0) {

          if (document.getElementById(`wintableglow${i}`)) {
            document.getElementById(`wintableglow${i}`).src = tablewinglow;
            document.getElementById(`wintableglow${i}`).style.display = "block";
             document.getElementById(`wintableglow${i}`).classList.add(`addglowtowintable${i}`);
          }
          document.getElementById(`winbngoamnt${i}`).classList.add("blink_winamount");
        }

        updatedWinsAmount.push({
          house: this.state.winsAmount[i].house,
          amount: matchingResult
            ? matchingResult.amount
            : this.state.winsAmount[i].amount,
        });
      }
      this.setState({ winsAmount: updatedWinsAmount });
    }
    console.log(this.props.bngogamestate.betRequest.betString)

    if (this.props.bngogamestate.betRequest.betString) {
      let betString = this.props.bngogamestate.betRequest.betString;

      for (var j = 0; j < betString.length; j++) {
        var value = parseInt(betString.split("|")[j]);
        console.log(value)

        if (this.state.betsAmount[j] && value != NaN) {
          this.state.betsAmount[j].amount = value;
        }
      }
    }
  };

  bingoRstlsshowbetpannel = (event) => {
    console.log("LBP Result Btns");
    console.log(event);

    clearTimeout(this.bnleft1);
    this.bnleft1 = null;

    clearTimeout(this.bnleft15);
    this.bnleft15 = null;

    clearTimeout(this.bnleft2);
    this.bnleft2 = null;

    clearTimeout(this.bnleft6);
    this.bnleft6 = null;

    clearTimeout(this.bnleft7);
    this.bnleft7 = null;

    clearTimeout(this.bnleft8);
    this.bnleft8 = null;

    clearTimeout(this.bnleft9);
    this.bnleft9 = null;

    clearTimeout(this.bnleft10);
    this.bnleft10 = null;

    clearTimeout(this.bnleft11);
    this.bnleft11 = null;

    clearTimeout(this.bnleft12);
    this.bnleft12 = null;

    clearTimeout(this.bnleft13);
    this.bnleft13 = null;

    this.slotnum_snd1 = null;
    this.slotnum_snd2 = null;
    this.slotnum_snd3 = null;
    this.slotnum_snd4 = null;
    this.slotnum_snd5 = null;
    this.slotnum_snd6 = null;

    if (document.getElementById("resultimages0")) {
      document.getElementById("resultimages0").innerHTML = "";
    }
    if (document.getElementById("resultimages1")) {
      document.getElementById("resultimages1").innerHTML = "";
    }
    if (document.getElementById("resultimages2")) {
      document.getElementById("resultimages2").innerHTML = "";
    }
    if (document.getElementById("resultimages3")) {
      document.getElementById("resultimages3").innerHTML = "";
    }
    if (document.getElementById("resultimages4")) {
      document.getElementById("resultimages4").innerHTML = "";
    }
    if (document.getElementById("resultimages5")) {
      document.getElementById("resultimages5").innerHTML = "";
    }

    if (document.getElementById("fstchip_spin")) {
      document.getElementById("fstchip_spin").src = brownanim;
      document.getElementById("fstchip_spin").classList.add("bingorollinggifs");
      document.getElementById("fstchip_spin").classList.add("numAnimSize");

    }
    if (document.getElementById("secondchip_spin")) {
      document.getElementById("secondchip_spin").src = pinkanim;
      document.getElementById("secondchip_spin").classList.add("bingorollinggise");
      document.getElementById("secondchip_spin").classList.add("numAnimSize");

    }
    if (document.getElementById("trdchip_spin")) {
      document.getElementById("trdchip_spin").src = bluenanim;
      document.getElementById("trdchip_spin").classList.add("bingorollinggitrd");
      document.getElementById("trdchip_spin").classList.add("numAnimSize");

    }
    if (document.getElementById("frthchip_spin")) {
      document.getElementById("frthchip_spin").src = greeenanim;
      document.getElementById("frthchip_spin").classList.add("bingorollinggifrth");
      document.getElementById("frthchip_spin").classList.add("numAnimSize");

    }
    if (document.getElementById("fithchip_spin")) {
      document.getElementById("fithchip_spin").src = thickbluenanim;
      document.getElementById("fithchip_spin").classList.add("bingorollinggifive");
      document.getElementById("fithchip_spin").classList.add("numAnimSize");
    }
    if (document.getElementById("sixthchip_spin")) {
      document.getElementById("sixthchip_spin").src = redanim;
      document.getElementById("sixthchip_spin").classList.add("bingorollinggisix");
      document.getElementById("sixthchip_spin").classList.add("numAnimSize");

    }
    this.bngoanim_snd = new Audio(bngoanim_snd);
    this.bngoanim_snd.currentTime = 0;
    this.bngoanim_snd.play();

    if (event.winAmount > 0) {
      console.log(event.houseWin);

      this.bnleft1 = setTimeout(() => {
        this.winpannelsound.play();
        if (document.getElementById("bngoresult_anim1")) {
          document.getElementById("bngoresult_anim1").src = bngo_reswin1;
        }
        if (document.getElementById("bngoresult_anim2")) {
          document.getElementById("bngoresult_anim2").src = bngo_reswin2;
        }
        if (document.getElementById("bngoresult_anim3")) {
          document.getElementById("bngoresult_anim3").src = bngo_reswin3;
        }
        if (document.getElementById("bngoresult_anim4")) {
          document.getElementById("bngoresult_anim4").src = bngo_reswin4;
        }
        if (document.getElementById("bngoresult_anim5")) {
          document.getElementById("bngoresult_anim5").src = bngo_reswin5; 
        }
        if (document.getElementById("bngoresult_anim6")) {
          document.getElementById("bngoresult_anim6").src = bngo_reswin6;
        }
   
        const resListToMatchbngo = event.houseWin
        console.log(resListToMatchbngo)

        let matchedHouseResultLists = [];
        let matchedhouses = [];

        resListToMatchbngo.map((item, i) => {
          if (item.matched) {
            matchedHouseResultLists.push(item.resultlist);
            matchedhouses.push(i + 1);
          }
        });
      

        console.log("Matched House Numbers:" + matchedhouses);
        console.log("Matched House Numbers:" + matchedHouseResultLists);

        if (matchedHouseResultLists.length > 0) {
          console.log("Matched House Numbers:" + matchedHouseResultLists);

          const matchedHouse1ResultList = matchedHouseResultLists[0];
          const matchedHouse2ResultList = matchedHouseResultLists[1];
          const matchedHouse3ResultList = matchedHouseResultLists[2];
          const matchedHouse4ResultList = matchedHouseResultLists[3];
          const matchedHouse5ResultList = matchedHouseResultLists[4];
          const matchedHouse6ResultList = matchedHouseResultLists[5];
          console.log(matchedHouse1ResultList);
          console.log(matchedHouse2ResultList);
          console.log(matchedHouse3ResultList);
          console.log(matchedHouse4ResultList);

          let intialupdatearray = [];
          let finalupdatearray = [];
          let trdhouseupdatearray = [];
          let frthupdatearray = [];
          let fivthupdatearray = [];
          let sixthupdatearray = [];
          let cmbnetwaarrays = [];

          const firsths = matchedhouses[0];
          const secondhs = matchedhouses[1];
          const thrdhs = matchedhouses[2];
          const frthhs = matchedhouses[3];
          const ffthhs = matchedhouses[4];
          const sxthhs = matchedhouses[5];

          console.log(firsths, secondhs, thrdhs, frthhs);
          this.setState({
            firsthouse: firsths,
            secondhouse: secondhs,
            trdhouse: thrdhs,
            frthhouse: frthhs,
            fithhouse: ffthhs,
            sixthouse: sxthhs,
          });

          if (matchedhouses.length == 1) {
            const updatefinalarray = matchedHouse1ResultList.flat();

            intialupdatearray.push(updatefinalarray);
            cmbnetwaarrays = intialupdatearray.concat(updatefinalarray);

            console.log(cmbnetwaarrays);
            const winresultlist = cmbnetwaarrays.map((val) => parseInt(val));

            console.log(winresultlist);
            const updatereslist = winresultlist;
            console.log(updatereslist);

            this.setState({
              resulst: updatereslist,
              hltnums: matchedhouses,
            });
          }

          if (matchedhouses.length == 2) {
            const updatefinalarray = matchedHouse1ResultList.flat();
            const updatearray = matchedHouse2ResultList.flat();

            intialupdatearray.push(updatefinalarray);
            finalupdatearray.push(updatearray);
            cmbnetwaarrays = intialupdatearray.concat(finalupdatearray);
            console.log(cmbnetwaarrays);
            const winresultlist = cmbnetwaarrays.map((innerele) =>
              innerele.map((val) => parseInt(val))
            );

            console.log(winresultlist);
            const updatereslist = winresultlist[0];
            const second = winresultlist[1];
            console.log(updatereslist);
            console.log(second);
            this.setState({
              resulst: updatereslist,
              reslstsecond: second,
              hltnums: matchedhouses,
            });
          }

          if (matchedhouses.length == 3) {
            const updatefinalarray = matchedHouse1ResultList.flat();
            const updatearray = matchedHouse2ResultList.flat();
            // if(updatethirdarray.lenth>0){
            const updatethirdarray = matchedHouse3ResultList.flat();
            // }
            // const updatefrtharray = resListToMatch[3].flat();

            console.log(updatethirdarray);
            intialupdatearray.push(updatefinalarray);
            finalupdatearray.push(updatearray);
            trdhouseupdatearray.push(updatethirdarray);

            cmbnetwaarrays = intialupdatearray
              .concat(finalupdatearray)
              .concat(trdhouseupdatearray);
            console.log(cmbnetwaarrays);
            // Handle other cases or provide a default state
            const winresultlist = cmbnetwaarrays.map((innerele) =>
              innerele.map((val) => parseInt(val))
            );

            const updatereslist = winresultlist[0];
            const updatereslistse = winresultlist[1];
            const updatereslisttrd = winresultlist[2];
            console.log(updatereslist);
            console.log(updatereslistse);

            console.log(updatereslisttrd);

            this.setState({
              resulst: updatereslist,
              reslstsecond: updatereslistse,
              reslststrd: updatereslisttrd,
              hltnums: matchedhouses,
            });
          }

          if (matchedhouses.length == 4) {
            const updatefinalarray = matchedHouse1ResultList.flat();
            const updatearray = matchedHouse2ResultList.flat();

            const updatethirdarray = matchedHouse3ResultList.flat();
            const updatefrtharray = matchedHouse4ResultList.flat();

            intialupdatearray.push(updatefinalarray);
            finalupdatearray.push(updatearray);
            trdhouseupdatearray.push(updatethirdarray);
            frthupdatearray.push(updatefrtharray);
            cmbnetwaarrays = intialupdatearray
              .concat(finalupdatearray)
              .concat(trdhouseupdatearray)
              .concat(frthupdatearray);

            // Handle other cases or provide a default state
            const winresultlist = cmbnetwaarrays.map((innerele) =>
              innerele.map((val) => parseInt(val))
            );

            const updatereslist = winresultlist[0];
            const updatereslistse = winresultlist[1];
            const updatereslisttrd = winresultlist[2];
            const updatereslistfrth = winresultlist[3];
            // const updatereslistfifth = winresultlist[4];
            console.log(updatereslistfrth);
            this.setState({
              resulst: updatereslist,
              reslstsecond: updatereslistse,
              reslststrd: updatereslisttrd,
              reslstsfrth: updatereslistfrth,
              hltnums: matchedhouses,
            });
          }

          if (matchedhouses.length == 5) {
            // alert("............11111")
            const updatefinalarray = matchedHouse1ResultList.flat();
            const updatearray = matchedHouse2ResultList.flat();

            const updatethirdarray = matchedHouse3ResultList.flat();
            const updatefrtharray = matchedHouse4ResultList.flat();
            console.log(updatefrtharray)
            const updatefiftharray = matchedHouse5ResultList.flat();

            intialupdatearray.push(updatefinalarray);
            finalupdatearray.push(updatearray);
            trdhouseupdatearray.push(updatethirdarray);
            frthupdatearray.push(updatefrtharray);
            fivthupdatearray.push(updatefiftharray);
            cmbnetwaarrays = intialupdatearray
              .concat(finalupdatearray)
              .concat(trdhouseupdatearray)
              .concat(frthupdatearray)
              .concat(fivthupdatearray);

            // Handle other cases or provide a default state
            const winresultlist = cmbnetwaarrays.map((innerele) =>
              innerele.map((val) => parseInt(val))
            );

            const updatereslist = winresultlist[0];
            const updatereslistse = winresultlist[1];
            const updatereslisttrd = winresultlist[2];
            const updatereslistfrth = winresultlist[3];
            const updatereslistfifth = winresultlist[4];
            console.log(updatereslistfrth);
            this.setState({
              resulst: updatereslist,
              reslstsecond: updatereslistse,
              reslststrd: updatereslisttrd,
              reslstsfrth: updatereslistfrth,
              reslstsfifth: updatereslistfifth,
              hltnums: matchedhouses,
            });
          }
          if (matchedhouses.length == 6) {
            const updatefinalarray = matchedHouse1ResultList.flat();
            const updatearray = matchedHouse2ResultList.flat();

            const updatethirdarray = matchedHouse3ResultList.flat();
            const updatefrtharray = matchedHouse4ResultList.flat();

            const updatefiftharray = matchedHouse5ResultList.flat();

            const updatesixtharray = matchedHouse6ResultList.flat();

            intialupdatearray.push(updatefinalarray);
            finalupdatearray.push(updatearray);
            trdhouseupdatearray.push(updatethirdarray);
            frthupdatearray.push(updatefrtharray);
            fivthupdatearray.push(updatefiftharray);
            sixthupdatearray.push(updatesixtharray);
            cmbnetwaarrays = intialupdatearray
              .concat(finalupdatearray)
              .concat(trdhouseupdatearray)
              .concat(frthupdatearray)
              .concat(fivthupdatearray)
              .concat(sixthupdatearray);

            // Handle other cases or provide a default state
            const winresultlist = cmbnetwaarrays.map((innerele) =>
              innerele.map((val) => parseInt(val))
            );

            const updatereslist = winresultlist[0];
            const updatereslistse = winresultlist[1];
            const updatereslisttrd = winresultlist[2];
            const updatereslistfrth = winresultlist[3];
            const updatereslistfifth = winresultlist[4];
            const updatereslistsixth = winresultlist[5];
            console.log(updatereslistfrth);
            this.setState({
              resulst: updatereslist,
              reslstsecond: updatereslistse,
              reslststrd: updatereslisttrd,
              reslstsfrth: updatereslistfrth,
              reslstsfifth: updatereslistfifth,
              reslstsixfth: updatereslistsixth,
              hltnums: matchedhouses,
            });
          }
        }
        // }
      }, 6500);

      this.bnleft15 = setTimeout(() => {
        if (document.getElementById("bngoresult_anim1")) {
          document.getElementById("bngoresult_anim1").src = "";
        }
        if (document.getElementById("bngoresult_anim2")) {
          document.getElementById("bngoresult_anim2").src = "";
        }
        if (document.getElementById("bngoresult_anim3")) {
          document.getElementById("bngoresult_anim3").src = "";
        }
        if (document.getElementById("bngoresult_anim4")) {
          document.getElementById("bngoresult_anim4").src = "";
        }
        if (document.getElementById("bngoresult_anim5")) {
          document.getElementById("bngoresult_anim5").src = "";
        }
        if (document.getElementById("bngoresult_anim6")) {
          document.getElementById("bngoresult_anim6").src = "";
        }
      }, 10500)

      // console.log(event.houseWin);
      if (event.houseWin) {
        this.bnleft2 = setTimeout(() => {
          let houseWin = event.houseWin;
          let updatedWinsAmount = [];
          for (let i = 0; i < this.state.winsAmount.length; i++) {
            let matchingResult = houseWin.find(
              (result) => result.house === this.state.winsAmount[i].house
            );
            console.log(matchingResult);
            if (matchingResult.amount > 0) {
              if (document.getElementById(`wintableglow${i}`)) {
                document.getElementById(`wintableglow${i}`).style.display = "block";
              }

              document.getElementById(`winbngoamnt${i}`).classList.add("blink_winamount");
              document.getElementById(`winbngoamnt${i}`).classList.add("winblinkamount");
            }
            updatedWinsAmount.push({
              house: this.state.winsAmount[i].house,
              amount: matchingResult ? matchingResult.amount : this.state.winsAmount[i].amount,
            });
          }
          this.setState({ winsAmount: updatedWinsAmount });
        }, 6200);
      }
    } else {
      this.bnleft6 = setTimeout(() => {
        this.setState({ enablekeyboard: true });
        if (event.houseWin) {
          const resListToMatchbngo = event.houseWin
          console.log(resListToMatchbngo)

          let matchedHouseResultLists = [];
          let matchedhouses = [];

          resListToMatchbngo.map((item, i) => {
            if (item.matched) {
              matchedHouseResultLists.push(item.resultlist);
              matchedhouses.push(i + 1);
            }
          });

          console.log("Matched House Numbers:" + matchedhouses);
          if (matchedHouseResultLists.length > 0) {
            matchedHouseResultLists.forEach((resultList, index) => {
              console.log(`House ${index + 1}`);
              console.log("Result List:", resultList);
            });
            // Separating matched house result lists into different variables
            const matchedHouse1ResultList = matchedHouseResultLists[0];
            const matchedHouse2ResultList = matchedHouseResultLists[1];
            const matchedHouse3ResultList = matchedHouseResultLists[2];
            const matchedHouse4ResultList = matchedHouseResultLists[3];
            const matchedHouse5ResultList = matchedHouseResultLists[4];
            const matchedHouse6ResultList = matchedHouseResultLists[5];
            console.log(matchedHouse1ResultList);
            console.log(matchedHouse2ResultList);
            console.log(matchedHouse3ResultList);
            console.log(matchedHouse4ResultList);
            let intialupdatearray = [];
            let finalupdatearray = [];
            let trdhouseupdatearray = [];
            let frthupdatearray = [];
            let fivthupdatearray = [];
            let sixthupdatearray = [];
            let cmbnetwaarrays = [];

            const firsths = matchedhouses[0];
            const secondhs = matchedhouses[1];
            const thrdhs = matchedhouses[2];
            const frthhs = matchedhouses[3];
            const ffthhs = matchedhouses[4];
            const sxthhs = matchedhouses[5];

            console.log(firsths, secondhs, thrdhs, frthhs);
            this.setState({
              firsthouse: firsths,
              secondhouse: secondhs,
              trdhouse: thrdhs,
              frthhouse: frthhs,
              fithhouse: ffthhs,
              sixthouse: sxthhs,
            });

            if (matchedhouses.length == 1) {
              const updatefinalarray = matchedHouse1ResultList.flat();

              intialupdatearray.push(updatefinalarray);
              cmbnetwaarrays = intialupdatearray.concat(updatefinalarray);

              console.log(cmbnetwaarrays);
              const winresultlist = cmbnetwaarrays.map((val) => parseInt(val));

              console.log(winresultlist);
              const updatereslist = winresultlist;
              console.log(updatereslist);

              this.setState({
                resulst: updatereslist,
                hltnums: matchedhouses,
              });
            }

            if (matchedhouses.length == 2) {
              const updatefinalarray = matchedHouse1ResultList.flat();
              const updatearray = matchedHouse2ResultList.flat();

              intialupdatearray.push(updatefinalarray);
              finalupdatearray.push(updatearray);
              cmbnetwaarrays = intialupdatearray.concat(finalupdatearray);

              console.log(cmbnetwaarrays);
              const winresultlist = cmbnetwaarrays.map((innerele) =>
                innerele.map((val) => parseInt(val))
              );

              console.log(winresultlist);
              const updatereslist = winresultlist[0];
              const second = winresultlist[1];
              console.log(updatereslist);
              console.log(second);
              this.setState({
                resulst: updatereslist,
                reslstsecond: second,
                hltnums: matchedhouses,
              });
            }

            if (matchedhouses.length == 3) {
              const updatefinalarray = matchedHouse1ResultList.flat();
              const updatearray = matchedHouse2ResultList.flat();
              const updatethirdarray = matchedHouse3ResultList.flat();

              console.log(updatethirdarray);
              intialupdatearray.push(updatefinalarray);
              finalupdatearray.push(updatearray);
              trdhouseupdatearray.push(updatethirdarray);

              cmbnetwaarrays = intialupdatearray
                .concat(finalupdatearray)
                .concat(trdhouseupdatearray);
              console.log(cmbnetwaarrays);
              // Handle other cases or provide a default state
              const winresultlist = cmbnetwaarrays.map((innerele) =>
                innerele.map((val) => parseInt(val))
              );

              const updatereslist = winresultlist[0];
              const updatereslistse = winresultlist[1];
              const updatereslisttrd = winresultlist[2];
              console.log(updatereslist);
              console.log(updatereslistse);

              console.log(updatereslisttrd);

              this.setState({
                resulst: updatereslist,
                reslstsecond: updatereslistse,
                reslststrd: updatereslisttrd,
                hltnums: matchedhouses,
              });
            }

            if (matchedhouses.length == 4) {
              const updatefinalarray = matchedHouse1ResultList.flat();
              const updatearray = matchedHouse2ResultList.flat();

              const updatethirdarray = matchedHouse3ResultList.flat();
              const updatefrtharray = matchedHouse4ResultList.flat();

              intialupdatearray.push(updatefinalarray);
              finalupdatearray.push(updatearray);
              trdhouseupdatearray.push(updatethirdarray);
              frthupdatearray.push(updatefrtharray);
              cmbnetwaarrays = intialupdatearray
                .concat(finalupdatearray)
                .concat(trdhouseupdatearray)
                .concat(frthupdatearray);

              // Handle other cases or provide a default state
              const winresultlist = cmbnetwaarrays.map((innerele) =>
                innerele.map((val) => parseInt(val))
              );

              const updatereslist = winresultlist[0];
              const updatereslistse = winresultlist[1];
              const updatereslisttrd = winresultlist[2];
              const updatereslistfrth = winresultlist[3];
              // const updatereslistfifth = winresultlist[4];
              console.log(updatereslistfrth);
              this.setState({
                resulst: updatereslist,
                reslstsecond: updatereslistse,
                reslststrd: updatereslisttrd,
                reslstsfrth: updatereslistfrth,

                hltnums: matchedhouses,
              });
            }

            if (matchedhouses.length == 5) {
              const updatefinalarray = matchedHouse1ResultList.flat();
              const updatearray = matchedHouse2ResultList.flat();

              const updatethirdarray = matchedHouse3ResultList.flat();
              const updatefrtharray = matchedHouse4ResultList.flat();

              const updatefiftharray = matchedHouse5ResultList.flat();

              intialupdatearray.push(updatefinalarray);
              finalupdatearray.push(updatearray);
              trdhouseupdatearray.push(updatethirdarray);
              frthupdatearray.push(updatefrtharray);
              fivthupdatearray.push(updatefiftharray);
              cmbnetwaarrays = intialupdatearray
                .concat(finalupdatearray)
                .concat(trdhouseupdatearray)
                .concat(frthupdatearray)
                .concat(fivthupdatearray);

              // Handle other cases or provide a default state
              const winresultlist = cmbnetwaarrays.map((innerele) =>
                innerele.map((val) => parseInt(val))
              );

              const updatereslist = winresultlist[0];
              const updatereslistse = winresultlist[1];
              const updatereslisttrd = winresultlist[2];
              const updatereslistfrth = winresultlist[3];
              const updatereslistfifth = winresultlist[4];
              console.log(updatereslistfrth);
              this.setState({
                resulst: updatereslist,
                reslstsecond: updatereslistse,
                reslststrd: updatereslisttrd,
                reslstsfrth: updatereslistfrth,
                reslstsfifth: updatereslistfifth,
                hltnums: matchedhouses,
              });
            }
            if (matchedhouses.length == 6) {
              const updatefinalarray = matchedHouse1ResultList.flat();
              const updatearray = matchedHouse2ResultList.flat();

              const updatethirdarray = matchedHouse3ResultList.flat();
              const updatefrtharray = matchedHouse4ResultList.flat();

              const updatefiftharray = matchedHouse5ResultList.flat();

              const updatesixtharray = matchedHouse6ResultList.flat();

              intialupdatearray.push(updatefinalarray);
              finalupdatearray.push(updatearray);
              trdhouseupdatearray.push(updatethirdarray);
              frthupdatearray.push(updatefrtharray);
              fivthupdatearray.push(updatefiftharray);
              sixthupdatearray.push(updatesixtharray);
              cmbnetwaarrays = intialupdatearray
                .concat(finalupdatearray)
                .concat(trdhouseupdatearray)
                .concat(frthupdatearray)
                .concat(fivthupdatearray)
                .concat(sixthupdatearray);

              // Handle other cases or provide a default state
              const winresultlist = cmbnetwaarrays.map((innerele) =>
                innerele.map((val) => parseInt(val))
              );

              const updatereslist = winresultlist[0];
              const updatereslistse = winresultlist[1];
              const updatereslisttrd = winresultlist[2];
              const updatereslistfrth = winresultlist[3];
              const updatereslistfifth = winresultlist[4];
              const updatereslistsixth = winresultlist[5];
              console.log(updatereslistfrth);
              this.setState({
                resulst: updatereslist,
                reslstsecond: updatereslistse,
                reslststrd: updatereslisttrd,
                reslstsfrth: updatereslistfrth,
                reslstsfifth: updatereslistfifth,
                reslstsixfth: updatereslistsixth,
                hltnums: matchedhouses,
              });
            }
          }
        }
      }, 6200);
    }

    let randomnumbers = event.result;
    const resultnum = randomnumbers.map((val) => {
      return isNaN(val) ? val : parseInt(val);
    });

    this.handleNumbersOneByOne(event.result);
    this.bnleft7 = setTimeout(() => {
    }, 1000);

    this.setState({ Randomnumbers: resultnum });

    if (this.state.Randomnumbers) {
      // let fstnum = this.state.Randomnumbers[0];
      // let secnum = this.state.Randomnumbers[1];
      // let thrdnum = this.state.Randomnumbers[2];
      // let frthnum = this.state.Randomnumbers[3];
      // let fifthnum = this.state.Randomnumbers[4];
      let sixnum = event.bonus;
      // let sixnum = 4;

      // let disfst = document.getElementById("resultimages0");
      // let dissecond = document.getElementById("resultimages1");
      // let disthird = document.getElementById("resultimages2");
      // let disfrth = document.getElementById("resultimages3");
      // let disfifth = document.getElementById("resultimages4");
      // let disfivth = document.getElementById("resultimages5");

      this.bnleft8 = setTimeout(() => {

        if (document.getElementById("fstchip_spin")) {
          document.getElementById("fstchip_spin").src = "";
          document.getElementById("fstchip_spin").classList.remove("bingorollinggifs");
          document.getElementById("fstchip_spin").classList.remove("numAnimSize");

        }
        if (document.getElementById("resultimages0")) {
          // disnum.classList.add("winpannel_num");
          // disfst.innerHTML = fstnum;
         

          document.getElementById("resultimages0").innerHTML = this.state.Randomnumbers[0];

          this.slotnum_snd1 = new Audio(slotnum_snd);
          this.slotnum_snd1.play();
          //console.log( disnum.innerHTML)
        }
      }, 1000);

      this.bnleft9 = setTimeout(() => {

        if (document.getElementById("secondchip_spin")) {
          document.getElementById("secondchip_spin").src = "";
          document.getElementById("secondchip_spin").classList.remove("bingorollinggise");
          document.getElementById("secondchip_spin").classList.remove("numAnimSize");

        }
        
        if (document.getElementById("resultimages1")) {
          document.getElementById("resultimages1").innerHTML = this.state.Randomnumbers[1];

          this.slotnum_snd2 = new Audio(slotnum_snd);
          this.slotnum_snd2.play();
        }
      }, 2000);

      this.bnleft10 = setTimeout(() => {
        if (document.getElementById("trdchip_spin")) {
          document.getElementById("trdchip_spin").src = "";
          document.getElementById("trdchip_spin").classList.remove("bingorollinggitrd");
          document.getElementById("trdchip_spin").classList.remove("numAnimSize");

        }

        if (document.getElementById("resultimages2")) {
          // disnum.classList.add("winpannel_num");
         

          document.getElementById("resultimages2").innerHTML = this.state.Randomnumbers[2];

          this.slotnum_snd3 = new Audio(slotnum_snd);
          this.slotnum_snd3.play(); 
        }
      }, 3000);

      this.bnleft11 = setTimeout(() => {
        if (document.getElementById("frthchip_spin")) {
          document.getElementById("frthchip_spin").src = "";
          document.getElementById("frthchip_spin").classList.remove("bingorollinggifrth");
          document.getElementById("frthchip_spin").classList.remove("numAnimSize");

        }

        if (document.getElementById("resultimages3")) {
          document.getElementById("resultimages3").innerHTML = this.state.Randomnumbers[3];
        }
        this.slotnum_snd4 = new Audio(slotnum_snd);
        this.slotnum_snd4.play();
      }, 4000);

      this.bnleft12 = setTimeout(() => {
       
        if (document.getElementById("fithchip_spin")) {
          document.getElementById("fithchip_spin").src = "";
          document.getElementById("fithchip_spin").classList.remove("bingorollinggifive");
          document.getElementById("fithchip_spin").classList.remove("numAnimSize");
        }
        if (document.getElementById("resultimages4")) {
          // disnum.classList.add("winpannel_num");
          document.getElementById("resultimages4").innerHTML = this.state.Randomnumbers[4];
          this.slotnum_snd5 = new Audio(slotnum_snd);
          this.slotnum_snd5.play();
          //console.log( disnum.innerHTML)
        }
      }, 5000);

      this.bnleft13 = setTimeout(() => {
        //  this.slotnum_snd.play()

        this.bngoanim_snd.pause();
        this.bngoanim_snd.currentTime = 0;


        if (document.getElementById("sixthchip_spin")) {
          document.getElementById("sixthchip_spin").src = "";
          document.getElementById("sixthchip_spin").classList.remove("bingorollinggisix");
          document.getElementById("sixthchip_spin").classList.remove("numAnimSize");
        }

        if (document.getElementById("resultimages5")) {
          this.slotnum_snd6 = new Audio(slotnum_snd);
          this.slotnum_snd6.play();

          if (event.bonus == 1) {
            document.getElementById("resultimages5").innerHTML = "NORMAL";
            if (document.getElementById("resultimages5")) {
              document.getElementById("resultimages5").classList.add("normltext");
              document.getElementById("resultimages5").classList.remove("bnsxval");
            }

          } else {
            if (document.getElementById("resultimages5").classList.contains("normltext")) {
              document.getElementById("resultimages5").classList.remove("normltext")
            }
                        
            let img = document.createElement('img');

            if (event.bonus == 2) {             
              img.src = img2x;             
            }else if (event.bonus == 4) {
              img.src = img4x;
            }
           
            img.className = "bingo_bonusImg";
            document.getElementById("resultimages5").appendChild(img);
          }
        }
      }, 6000);
    }
  };

  clearwinsinhouse = () => {
    this.setState({
      winsAmount: [
        { house: 1, amount: 0 },
        { house: 2, amount: 0 },
        { house: 3, amount: 0 },
        { house: 4, amount: 0 },
        { house: 5, amount: 0 },
        { house: 6, amount: 0 },
      ],
      resulst: [],
      reslstsecond: [],
      reslststrd: [],
      reslstsfrth: [],
      reslstsfifth: [],
      reslstsixfth: [],

      hltnums: [],
      Randomnumbers: [0, 0, 0, 0, 0],
      displaynumonebyone: [0, 0, 0, 0, 0],
      enablekeyboard: true,
    });

    // for (let i = 0; i < 6; i++) {
    //   document.getElementById(`wintableglow${i}`).style.display = "none";

    //   if (document.getElementById(`winbngoamnt${i}`).classList.contains(`blink_winamount`)) {
    //     document.getElementById(`winbngoamnt${i}`).classList.remove(`blink_winamount`)
    //   }

    //   this.state.winsAmount[i].amount = 0;
    // }
  };

  clearsettimeevnts = () => {  
    if(this.bngoanim_snd){
      this.bngoanim_snd.pause();
    }
    if(this.winpannelsound){
      this.winpannelsound.pause();
    }
    if(this.betoncardSound){
      this.betoncardSound.pause();
    }
    if(this.slotnum_snd1){
    this.slotnum_snd1.pause();
    }
    if(this.slotnum_snd2){
    this.slotnum_snd2.pause();
    }
    if(this.slotnum_snd3){
    this.slotnum_snd3.pause();
    }
    if(this.slotnum_snd4){
    this.slotnum_snd4.pause();
    }
    if(this.slotnum_snd5){
    this.slotnum_snd5.pause();
    }
    if(this.slotnum_snd6){
    this.slotnum_snd6.pause();
    }

    clearTimeout(this.bnleft1);
    clearTimeout(this.bnleft2);
    clearTimeout(this.bnleft3);
    clearTimeout(this.bnleft4);
    clearTimeout(this.bnleft5);
    clearTimeout(this.bnleft6);
    clearTimeout(this.bnleft7);
    clearTimeout(this.bnleft8);
    clearTimeout(this.bnleft9);
    clearTimeout(this.bnleft10);
    clearTimeout(this.bnleft11);
    clearTimeout(this.bnleft12);
    clearTimeout(this.bnleft13);
    clearTimeout(this.bnleft14);
    clearInterval(this.startInt);
  };

  mouseDownSelectHouse = (id) => {
    const { action } = this.props;
    if (this.isMouseDownSelectHouseInProgress) {
      return;
    }

    this.isMouseDownSelectHouseInProgress = true;

    clearInterval(this.startInt);
    action(id);

    this.startInt = setInterval(() => {
      action(id);
    }, 80);

    this.bnleft14 = setTimeout(() => {
      this.isMouseDownSelectHouseInProgress = false;
    }, 180);
  };

  keyDownSelectHouse(id) {
    this.props.action(id);
  }

  mouseUpSelectHouse = () => {
    clearInterval(this.startInt);
  };

  betplace = (id, amount) => {
  
    console.log("Left Button Pannel  :  " + id + "    " + amount);
    console.log(this.state.betsAmount);
    document.getElementById("betbngoamnt" + id).innerText = amount;
    this.state.betsAmount[id].amount = amount;

    for (let i = 0; i < this.state.betsAmount.length; i++) {
      // if (amount == 1) {
        if (amount ==this.props.minbetamnt ) {
        for (let i = 0; i <= 25; i++) {
          if (document.getElementById(`eachnum_${id}_${i}`) && !document.getElementById(`eachnum_${id}_${i}`).classList.contains("bettextbngochnge")) {
            document.getElementById(`eachnum_${id}_${i}`).classList.add("bettextbngochnge");
          }
        }
      }
      // this.setState({betsAmount: this.state.betsAmount});
    }
  };

  showResultInBetHouses = (resultNums) => {
    let total_indexs = [];
  };
  topLable_fn(labelValue) {
    return Math.abs(Number(labelValue));
  }

  updateBetAmts = (id, bet) => {
    console.log(id + "    " + bet);
    if (bet <= 0) {
      for (let y = 0; y < 25; y++) {
        document.getElementById(`eachnum_${id}_${y}`).classList.add("bettextbngochnge");
      }
    }
    if (this.props.Total_Bet_Amount >= this.props.balance) {
      this.startInt = setInterval(() => {
        this.props.action(id)
      }, 100);
    }
    for (let i = 0; i < this.state.betsAmount.length; i++) {
      if (this.state.betsAmount[i].id == id) {
        const totalIbetAmount = this.topLable_fn(bet);
        //console.log(totalIbetAmount);

        this.state.betsAmount[i].amount = totalIbetAmount;
        this.setState({ betsAmount: this.state.betsAmount });
      }
    }
  };

  updatebngoStarImages(starNum) {
    this.setState({ starcntbngo: starNum });
    // console.log(this.state.starcntbngo);

    const starContainer_bingo = document.getElementById("bngo_strs");
    if (starContainer_bingo) {
      while (starContainer_bingo.firstChild) {
        starContainer_bingo.removeChild(starContainer_bingo.firstChild);
      }
    }

    const numStars = 13;

    for (let index = 0; index < numStars; index++) {
      const starClassName = `bingostar-${index + 1}`;

      const div = document.createElement("div");
      div.id = index + 1;
      div.className = starClassName;

      if (starContainer_bingo) {
        starContainer_bingo.appendChild(div);
      }
      const img = document.createElement("img");
      img.src = starbng;
      img.id = index + 1;
      //let bngostarcount=6
      // console.log(this.state.starcntbngo)
      if (index < this.state.starcntbngo) {
        //if (index <bngostarcount) {
        img.style.background = `url(${starbngglow})`;
        img.classList.add("bngostarblink");
        // bngostar${i + 1} keno_starblink
        if (this.dup_starsprinklebn instanceof Audio) {
          this.dup_starsprinklebn.play();
        }

        //this.setState({isbngostaranimation:true})
      } else {
        img.style.background = `url(${starbng})`;
        img.classList.add("starClass");
      }

      div.appendChild(img);
    }
  }

  showGlow(id, state) {
   
    // console.log(id + "  ---   " + state)
    const selected_hsenum = document.getElementById(id);
    if (selected_hsenum) {
     
      if (state) {
       
        if (!selected_hsenum.classList.contains("ft_glowBetBox")) {
          selected_hsenum.classList.add("ft_glowBetBox");
        }
      } else {
        if (selected_hsenum.classList.contains("ft_glowBetBox")) {
          selected_hsenum.classList.remove("ft_glowBetBox");
        }
      }
    }
  }

  render() { 
    return (
      <React.Fragment>
        <div id="bingoHouse" >
          <img className="bingoHouseCls" src={betHouses}/>
        </div>
        <div id="bingoBetContainer" className="bet-houses-container row ">
          {this.state.intialTableNumbers.slice(0, 3).map((eachTable, j) => (
            <div
            id={`bigHos_${j}`} 
              className="hideTable col-3"
              key={`col-${j}`}

              onPointerDown={(e) => {
                e.preventDefault();
                this.mouseDownSelectHouse(j, "mouse");
              }}
              onPointerUp={(e) => {
                e.preventDefault();
                this.mouseUpSelectHouse();
              }}
              onPointerOut={(e) => {
                e.preventDefault();
                this.mouseUpSelectHouse();
              }}
              onPointerLeave={(e) => {
                e.preventDefault();
                this.mouseUpSelectHouse();
              }}
              onPointerCancel={(e) => {
                e.preventDefault();
                this.mouseUpSelectHouse();
              }}

            >

              <div id={`house_${eachTable.id}`}
                className={this.props.isMobile ? `houseBg emptyPointer_bngo` : `houseBg addCursorPointer_bngo`}
                onPointerEnter={(e) => { e.preventDefault(); this.showGlow(`house_${eachTable.id}`, true) }}
                onPointerLeave={(e) => { e.preventDefault(); this.showGlow(`house_${eachTable.id}`, false) }}
                onPointerOut={(e) => { e.preventDefault(); this.showGlow(`house_${eachTable.id}`, false) }}
                onPointerCancel={(e) => { e.preventDefault(); this.showGlow(`house_${eachTable.id}`, false) }}
              ></div>

              <div className={`bngobets${j}`}>
                <span id={`betbngoamnt${j}`}>0</span>      
              </div>
              <div className={`win-amount${j}`}>
                <span id={`winbngoamnt${j}`}>
                  {" "}
                  {this.state.winsAmount[j].amount}
                </span>
              </div>

              <img
                id={`BetHouse${eachTable.id}`}
                className={`BetHouse${eachTable.id}`}
                key={`img-${j}`}
                src={eachTable.src}
                alt={`Bet House ${eachTable.id}`}
              />

              <table id={`table${eachTable.id}`} className={this.props.isMobile ? `uniqueTableId  emptyPointer_bngo` : `uniqueTableId  addCursorPointer_bngo`}>
                <tbody>
                  <tr id={`uniqueRowId${j}`}>
                    {eachTable.numbers.map((eachNumber, i) => (
                      <td
                        key={`cell${i}`}
                        id={`eachCellSpeceficId${i}`}
                        className={`${this.state.displaynumonebyone.includes(eachNumber)
                          ? `hhlthouses`
                          : `background-color${j}`
                          }`}
                      >
                        <span
                          className="bingoNums"
                          id={`eachnum_${j}_${i}`}
                          style={{
                            animation:
                              eachTable.id === this.state.firsthouse &&
                                this.state.hltnums.includes(eachTable.id) &&
                                this.state.resulst.includes(eachNumber)
                                ? "blink-animation 1s steps(4, start) infinite"
                                : eachTable.id === this.state.secondhouse &&
                                  this.state.hltnums.includes(eachTable.id) &&
                                  this.state.reslstsecond.includes(eachNumber)
                                  ? "blink-animation 1s steps(4, start) infinite"
                                  : eachTable.id === this.state.trdhouse &&
                                    this.state.hltnums.includes(eachTable.id) &&
                                    this.state.reslststrd.includes(eachNumber)
                                    ? "blink-animation 1s steps(4, start) infinite"
                                    : eachTable.id === this.state.frthhouse &&
                                      this.state.hltnums.includes(eachTable.id) &&
                                      this.state.reslstsfrth.includes(eachNumber)
                                      ? "blink-animation 1s steps(4, start) infinite"
                                      : eachTable.id === this.state.fithhouse &&
                                        this.state.hltnums.includes(eachTable.id) &&
                                        this.state.reslstsfifth.includes(eachNumber)
                                        ? "blink-animation 1s steps(4, start) infinite"
                                        : eachTable.id === this.state.sixthouse &&
                                          this.state.hltnums.includes(eachTable.id) &&
                                          this.state.reslstsixfth.includes(eachNumber)
                                          ? "blink-animation 1s steps(4, start) infinite"
                                          : "",
                            color: this.state.displaynumonebyone.includes(
                              eachNumber
                            )
                              ? "#000000"
                              : "",
                              fontFamily: 'Bluestar-Medium',
                          }}
                        >
                          {eachNumber}
                        </span>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          ))}

          <div className="col-3">
            <img className="bottom-container-infotext-img" src={bngInfotextAst} />
            <div className="infotext-heading">{this.props.infoText}</div>


            {/* <div className="bonus-label-heading">
              {this.props.language.Global_Bonus}
            </div> */}

            {/* <img className="glblbnsimage" src={globalBonusAsset} /> */}

            {/* <span id="bingo_bnsamnt" className="bingo_bnsamnt">
              {this.props.bnbonusmnt_bingo}
            </span> */}
            <div className="bngo_pid_val"> 
              P ID: {this.props.playerId}&emsp;ID:{this.props.handId}
            </div>
          </div>

          {this.state.intialTableNumbers.slice(3, 6).map((eachTable, j) => (
            <div
              onPointerDown={(e) => {
                e.preventDefault();
                this.mouseDownSelectHouse(j + 3, "mouse");
              }}
              onPointerUp={(e) => {
                e.preventDefault();
                this.mouseUpSelectHouse(j + 3);
              }}
              onPointerOut={(e) => {
                e.preventDefault();
                this.mouseUpSelectHouse(j + 3);
              }}
              onPointerLeave={(e) => {
                e.preventDefault();
                this.mouseUpSelectHouse(j + 3);
              }}
              onPointerCancel={(e) => {
                e.preventDefault();
                this.mouseUpSelectHouse(j + 3);
              }}

               id={`bigHos_${j + 3}`} 
              className="hideTable col-3"
              key={`col-${j + 3}`}
            >
              <div id={`house_${eachTable.id}`} className={
                this.props.isMobile
                  ? `houseBg2 emptyPointer_bngo`
                  : `houseBg2 addCursorPointer_bngo`
              }
                onPointerEnter={(e) => { e.preventDefault(); this.showGlow(`house_${eachTable.id}`, true) }}
                onPointerLeave={(e) => { e.preventDefault(); this.showGlow(`house_${eachTable.id}`, false) }}
                onPointerOut={(e) => { e.preventDefault(); this.showGlow(`house_${eachTable.id}`, false) }}
                onPointerCancel={(e) => { e.preventDefault(); this.showGlow(`house_${eachTable.id}`, false) }}
              ></div>
              <table id= {`table${eachTable.id}`} className={this.props.isMobile ? `uniqueTableId  emptyPointer_bngo`: `uniqueTableId  addCursorPointer_bngo`}>
                <img id={`wintableglow${j}`} src={tablewinglow} className={`addglowtowintable${j}`} />
                <tbody>
                  <tr id={`uniqueRowId${j + 3}`}
                  // onMouseEnter={(e)=>{ e.preventDefault(); this.showGlow(`BetHouse${eachTable.id}`, true)}} 
                  // onMouseLeave={(e)=>{ e.preventDefault(); this.showGlow(`BetHouse${eachTable.id}`, false)}} 
                  >
                    {eachTable.numbers.map((eachNumber, i) => (
                      <td
                        key={`cell${i}`}
                        id={`eachCellSpeceficId${i}`}
                        className={`${this.state.displaynumonebyone.includes(eachNumber)
                          ? `hhlthouses`
                          : `background-color${j + 3}`
                          }`}
                      >
                        <span
                          className="bingoNums"
                          id={`eachnum_${j + 3}_${i}`}
                          style={{
                            animation:
                              eachTable.id === this.state.firsthouse &&
                                this.state.hltnums.includes(eachTable.id) &&
                                this.state.resulst.includes(eachNumber)
                                ? "blink-animation 1s steps(4, start) infinite"
                                : eachTable.id === this.state.secondhouse &&
                                  this.state.hltnums.includes(eachTable.id) &&
                                  this.state.reslstsecond.includes(eachNumber)
                                  ? "blink-animation 1s steps(4, start) infinite"
                                  : eachTable.id === this.state.trdhouse &&
                                    this.state.hltnums.includes(eachTable.id) &&
                                    this.state.reslststrd.includes(eachNumber)
                                    ? "blink-animation 1s steps(4, start) infinite"
                                    : eachTable.id === this.state.frthhouse &&
                                      this.state.hltnums.includes(eachTable.id) &&
                                      this.state.reslstsfrth.includes(eachNumber)
                                      ? "blink-animation 1s steps(4, start) infinite"
                                      : eachTable.id === this.state.fithhouse &&
                                        this.state.hltnums.includes(eachTable.id) &&
                                        this.state.reslstsfifth.includes(eachNumber)
                                        ? "blink-animation 1s steps(4, start) infinite"
                                        : eachTable.id === this.state.sixthouse &&
                                          this.state.hltnums.includes(eachTable.id) &&
                                          this.state.reslstsixfth.includes(eachNumber)
                                          ? "blink-animation 1s steps(4, start) infinite"
                                          : "",
                            color: this.state.displaynumonebyone.includes(eachNumber) ? "#000000" : "",
                            fontFamily: 'Bluestar-Medium',
                          }}
                        >
                          {eachNumber}
                        </span>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
              <div className={`bngobets${j + 3}`}>
                <span id={`betbngoamnt${j + 3}`}>0</span>                
              </div>
              <div className={`win-amount${j + 3}`}>
                <span id={`winbngoamnt${j + 3}`}>
                  {" "}
                  {this.state.winsAmount[j + 3].amount}
                </span>
              </div>
              <img id={`wintableglow${j + 3}`} src={tablewinglow} className={`addglowtowintable${j + 3}`} />
              <img
                id={`BetHouse${eachTable.id}`}
                className={`BetHouse${eachTable.id}`}
                key={`img-${j + 3}`}
                src={eachTable.src}
                alt={`Bet House ${eachTable.id}`}
              />
            </div>
          ))}

          <div className="col-3">
            {/* <button
              className={
                this.props.isMobile
                  ? "dupbnbtn emptyPointer_bngo"
                  : "dupbnbtn addCursorPointer_bngo"
              }
              disabled={this.props.disduptBt == 1}
              onClick={(e) => {
                e.preventDefault();
                if (this.props.disduptBt == 0) {
                  this.props.actiondup("bngodup");
                }
              }}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              onMouseUp={(e) => {
                e.preventDefault();
              }}
              onMouseOut={(e) => {
                e.preventDefault();
              }}
            >
              <img
                className={
                  this.props.isMobile
                    ? "bngo_bupbtnglow emptyPointer_bngo"
                    : "bngo_bupbtnglow addCursorPointer_bngo"
                }
                id="bngo_dup"
                src=""
              />
              <img
                id="bupbingo_btn"
                className={
                  this.props.isMobile
                    ? "bngo_bupbtn emptyPointer_bngo"
                    : "bngo_bupbtn addCursorPointer_bngo"
                }
                // src={BIGDup}
              />
              <div className="d-up-heading">
                <span id="dupbtntext" >
                  {/* {this.props.language.D_UP} */}
                {/* </span>
              </div> */} 
            {/* </button> */}

            <div id="bngo_strs" className="bing_starcontainer"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj,
  };
};
export default connect(mapStatesToProps)(LeftButtonPanel);
