import React from "react";
import { connect } from "react-redux";
import { betPlanelImgs } from './betpanelimgs';
import betoncardSound from "../../../../../../assets/sounds/betoncard.mp3";
import info_Board from "../../../../../../assets/games/dragon_tiger/DT_Info_Board.png";

class Betpanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enableBet: false
    }
        
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);


    this.betSnd = new Audio(betoncardSound);
    this.betSnd2 = new Audio(betoncardSound);

    this.intervalId = null;
    setTimeout(() => {
    if(document.getElementById("betPanel").classList.contains("disBetPanel")){
      document.getElementById("betPanel").classList.remove("disBetPanel");
    }
    },700);
  }
  clearMouseDown() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  mouseDownSelectCard(data) {
    clearInterval(this.intervalId);
    this.props.getBetBoxId(data);
    this.intervalId = setInterval(() => {
      this.props.getBetBoxId(data);
    }, 150);
  }

  mouseUpSelectCard() {
    clearInterval(this.intervalId);
    this.intervalId = null;
    this.props.stopBetSound();
  }

  render() {
    return (
      <React.Fragment>
        <div id="betPanel" className="row dt_betPanel disBetPanel">
          <div className="col-2 bet_lmt_info">
            <img className="info_Board" src={info_Board} />
            <div className="betLmtTxt" >
              <span className="info_header">{this.props.language.Bet_Limits}</span>
              <br />
              <br />
              <span className="info_text">{this.props.language.Min} {this.props.minBetAmt}</span><br />
              <span className="info_text">{this.props.language.Max} {this.props.maxBetAmt}</span><br />
            </div>

          </div>
          <div className="col-8 row">
            <div className="col-2">
              <div className="row">
                <div id="dbig_btn" className={(this.props.isMobile) ? "betDTbox" : "betDTbox addCursorPointer"}

                  onPointerUp={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dbig_btn").style.transform = "scale(1)"; }}
                  onPointerOut={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dbig_btn").style.transform = "scale(1)"; }}
                  onPointerCancel={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dbig_btn").style.transform = "scale(1)"; }}
                  onPointerLeave={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dbig_btn").style.transform = "scale(1)"; }}
                  onPointerDown={(e) => { e.preventDefault(); this.mouseDownSelectCard("dbig"); document.getElementById("dbig_btn").style.transform = "scale(0.9)"; }}
                >
                  <div className="betPlace" id="dbig_img">
                    <img src={betPlanelImgs[0].src} />
                  </div>
                  <div className="img_point" id="dbig"></div>
                </div>
              </div>
              <div className="row">
                <div id="dsml_btn" className={(this.props.isMobile) ? "betDTbox" : "betDTbox addCursorPointer"}
                  onPointerUp={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dsml_btn").style.transform = "scale(1)"; }}
                  onPointerOut={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dsml_btn").style.transform = "scale(1)"; }}
                  onPointerCancel={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dsml_btn").style.transform = "scale(1)"; }}
                  onPointerLeave={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dsml_btn").style.transform = "scale(1)"; }}

                  onPointerDown={(e) => { e.preventDefault(); this.mouseDownSelectCard("dsml", true); document.getElementById("dsml_btn").style.transform = "scale(0.9)"; }}

                >
                  <div className="betPlace" id="dsml_img" >
                    <img src={betPlanelImgs[1].src} />
                  </div>
                  <div className="img_point" id="dsml"></div>
                </div>
              </div>
            </div>

            <div className="col-8 drtBetBtns" >
              <div className="col-4">
                <div className={(this.props.isMobile) ? "betDTbox" : "betDTbox addCursorPointer"}
                  id="dr_btn"
                  onPointerUp={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dr_btn").style.transform = "scale(1)"; }}
                  onPointerOut={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dr_btn").style.transform = "scale(1)"; }}
                  onPointerCancel={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dr_btn").style.transform = "scale(1)"; }}
                  onPointerLeave={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("dr_btn").style.transform = "scale(1)"; }}

                  onPointerDown={(e) => { e.preventDefault(); this.mouseDownSelectCard("dr", true); document.getElementById("dr_btn").style.transform = "scale(0.9)"; }}
                >

                  <div className="betPlace topBetImg" id="dr_img" >
                    <img className="drImg" src={betPlanelImgs[4].src} />
                  </div>
                  <div className="img_point" id="dr" ></div>
                </div>
              </div>
              <div className="col-4">
                <div id="ti_btn" className={(this.props.isMobile) ? "betDTbox tieBetBox" : "betDTbox tieBetBox addCursorPointer"}

                  onPointerUp={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("ti_btn").style.transform = "scale(1)"; }}
                  onPointerOut={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("ti_btn").style.transform = "scale(1)"; }}
                  onPointerCancel={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("ti_btn").style.transform = "scale(1)"; }}
                  onPointerLeave={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("ti_btn").style.transform = "scale(1)"; }}

                  onPointerDown={(e) => { e.preventDefault(); this.mouseDownSelectCard("ti", true); document.getElementById("ti_btn").style.transform = "scale(0.9)"; }}
                >
                  <div className="betPlace topBetImg" id="ti_img" >
                    <img className="tieImg" src={betPlanelImgs[2].src} />
                  </div>
                  <div className="img_point" id="ti" ></div>
                </div>
              </div>
              <div className="col-4">
                <div id="tr_btn" className={(this.props.isMobile) ? "betDTbox" : "betDTbox addCursorPointer"}

                  onPointerUp={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tr_btn").style.transform = "scale(1)"; }}
                  onPointerOut={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tr_btn").style.transform = "scale(1)"; }}
                  onPointerCancel={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tr_btn").style.transform = "scale(1)"; }}
                  onPointerLeave={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tr_btn").style.transform = "scale(1)"; }}

                  onPointerDown={(e) => { e.preventDefault(); this.mouseDownSelectCard("tr", true); document.getElementById("tr_btn").style.transform = "scale(0.9)"; }}
                >
                  <div className="betPlace topBetImg" id="tr_img" >
                    <img className="tigerBetImg" src={betPlanelImgs[3].src} />
                  </div>
                  <div className="img_point" id="tr"></div>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div className="row fr">
                <div id="tbig_btn" className={(this.props.isMobile) ? "betDTbox" : "betDTbox addCursorPointer"}

                  onPointerUp={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tbig_btn").style.transform = "scale(1)"; }}
                  onPointerOut={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tbig_btn").style.transform = "scale(1)"; }}
                  onPointerCancel={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tbig_btn").style.transform = "scale(1)"; }}
                  onPointerLeave={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tbig_btn").style.transform = "scale(1)"; }}

                  onPointerDown={(e) => { e.preventDefault(); this.mouseDownSelectCard("tbig", true); document.getElementById("tbig_btn").style.transform = "scale(0.9)"; }}

                >
                  <div className="betPlace" id="tbig_img" >
                    <img src={betPlanelImgs[0].src} />
                  </div>
                  <div className="img_point" id="tbig"></div>
                </div>
              </div>

              <div className="row fr">
                <div id="tsml_btn" className={(this.props.isMobile) ? "betDTbox" : "betDTbox addCursorPointer"}

                  onPointerUp={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tsml_btn").style.transform = "scale(1)"; }}
                  onPointerOut={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tsml_btn").style.transform = "scale(1)"; }}
                  onPointerCancel={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tsml_btn").style.transform = "scale(1)"; }}
                  onPointerLeave={(e) => { e.preventDefault(); this.mouseUpSelectCard(); document.getElementById("tsml_btn").style.transform = "scale(1)"; }}

                  onPointerDown={(e) => { e.preventDefault(); this.mouseDownSelectCard("tsml", true); document.getElementById("tsml_btn").style.transform = "scale(0.9)"; }}

                >
                  <div className="betPlace" id="tsml_img" >
                    <img src={betPlanelImgs[1].src} />
                  </div>
                  <div className="img_point" id="tsml"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj
  }
}
export default connect(mapStatesToProps)(Betpanel)
