import React from "react";
import "../../../../css/games/funMatka/funMatka.css";
import "./UI/funMatka.css";
import Close from "./UI/close";
import Title from "./UI/title";
import Wheel from "./UI/wheel";
import MatkaRight from "./UI/matkaRight";
import MatkaLeft from "./UI/matkaLeft";
import matkanums from "./UI/matkaNumbers";
import clearSound from "../../../../../assets/sounds/clear.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../assets/sounds/take.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import betoncardSound from "../../../../../assets/sounds/betoncard.mp3";
import wheelSound from "../../../../../assets/sounds/matkareel.mp3";
import tickSound from "../../../../../assets/sounds/tick.mp3";

import fmtBg from "../../../../../assets/games/funMatkaTimer/fmt_bg.png";

import FunMatkaTimerInSeconds from "./UI/funMatka_TimerSec";

import { connect } from "react-redux";


class FunMatkaTimer extends React.Component {
    constructor(props) {
        super(props);
        const { childRef } = this.props;
        childRef(this);
        this.fmntWheelRef = React.createRef();
        this.fmTmrRight = React.createRef();
        this.FunMatkaTimerInSeconds = React.createRef();
        this.fmTmrLeft = React.createRef();
        this.wheelDimension = window.innerHeight * 0.6;
        this.state = {
            balance: this.props.balance,
            gameId: this.props.gameId,
            playerId: this.props.playerId,
            stop: "NA",
            betString: [],
            previousBetArray: [],
            finalPreviousBetArray: [],
            betAmount: 1,
            winBet: 0,
            totalBet: 0,
            betstr: "",
            bets: "",
            betButtonText: this.props.language.Prev,
            betButtonValue: 0,
            infoText: this.props.language.Play_Njoy,
            btnActive: false,
            disDoubleBtn: 1,
            disBetBtn: 0,
            disClearBtn: 1,
            winCover: false,
            eurpRlt: true,
            is_fmt_GameOn: true,
        };
        this.topRowWinNumber = "";
        this.resultNum = 0;
        this.handId = 0;
        this.takeAmount = 0;
        this.isReady = false;
        this.isloadImg = false;
        this.isLoad = false;
        this.loadBg();
        this.isMouseDown = false;
        this.isSpectSent = false;
        this.newLimits = [];
        this.ak_limits = [];
        this.sp_limits = [];
        this.dp_limits = [];
        this.tr_limits = [];
        this.winAmount = 0;
    }
    componentDidMount() {
        if (this.props.isIphone) {
            document.getElementById('iospopup').style.display = 'block';
        } else {
            document.getElementById('iospopup').style.display = 'none';
        }

        document.getElementById('matkaTmr_WinPop').style.display = 'none';

        this.clearSound = new Audio(clearSound);
        this.exitSound = new Audio(exitSound);
        this.betSound = new Audio(betSound);
        this.takeSound = new Audio(takeSound);
        this.winSound = new Audio(winSound);
        this.duploseSound = new Audio(duploseSound);
        this.betoncardSound = new Audio(betoncardSound);
        this.wheelSound = new Audio(wheelSound);
        this.tickSound = new Audio(tickSound);


        this.state.betString = [];
        for (let l = 0; l < 230; l++) {
            this.state.betString.push({
                id: l,
                amount: 0,
            });
        }

        if (this.props.fmt_chipValues) {
            let newChips = this.props.fmt_chipValues.split(",");
            try {
                console.log(newChips[0]);
                let initChipVal = Number(newChips[0]);
                let lblTxt;
                if (initChipVal >= 1000 && initChipVal <= 999999) {
                    lblTxt = "K"
                } else if (initChipVal >= 1000000 && initChipVal <= 999999999) {
                    lblTxt = "M"
                } else if (initChipVal > 999999999) {
                    lblTxt = "B"
                }
                this.state.betAmount = initChipVal;
            } catch (err) {
                console.log("Fun Matka Chip values error: " + err.message);
            }
        }

        if (this.props.fmt_innerLimits) {
            this.newLimits = this.props.fmt_innerLimits.split("|");
            console.log(this.newLimits);
            for (let t = 0; t < this.newLimits.length; t++) {
                this.newLimits[t] = this.newLimits[t].split("-");
                console.log(" *******   " + this.newLimits[t]);
                switch (t) {
                    case 0:
                        this.ak_limits[0] = Number(this.newLimits[0][0]);
                        this.ak_limits[1] = Number(this.newLimits[0][1]);
                        break;
                    case 1:
                        this.sp_limits[0] = Number(this.newLimits[1][0]);
                        this.sp_limits[1] = Number(this.newLimits[1][1]);
                        break;
                    case 2:
                        this.dp_limits[0] = Number(this.newLimits[2][0]);
                        this.dp_limits[1] = Number(this.newLimits[2][1]);
                        break;
                    case 3:
                        this.tr_limits[0] = Number(this.newLimits[3][0]);
                        this.tr_limits[1] = Number(this.newLimits[3][1]);
                        break;
                }
            }
            console.log(this.ak_limits);
            console.log(this.sp_limits);
            console.log(this.dp_limits);
            console.log(this.tr_limits);
        }


        // console.log("Fun Matka Timer GameState: "+this.props.gameState.history[this.props.gameState.history.length-1].resultNo);
        if (this.props.gameState.history && this.props.gameState.history.length > 0) {
            this.resultNum = this.props.gameState.history[this.props.gameState.history.length - 1].resultNo;
            console.log(this.resultNum);
            this.fmntWheelRef.current.updateResultNumber(this.resultNum);
        }

        if (this.props.gameState.handId == this.props.gameState.oldHandId && this.props.gameState.history) {
            this.fmntWheelRef.current.updateResultNumber(this.props.gameState.history[this.props.gameState.history.length - 1].resultNo);
            console.log("GameState BetRequest: ");
            console.log(this.props.gameState.betRequest);
            if (this.props.gameState.betRequest) {
                let newStr = this.props.gameState.betRequest.betString.replace(/,/g, "|");
                console.info("newStr: " + newStr);
                let newStr1 = newStr.replace(/ /g, "0");
                console.info("newStr1: " + newStr1);
                let newStr2 = newStr1.split('|');
                console.info("newStr2: " + newStr2);
                let prevBetString = [];
                for (let i = 0; i < newStr2.length; i++) {
                    prevBetString.push({
                        id: i,
                        amount: parseInt(newStr2[i])
                    });
                }

                this.state.betString = prevBetString.slice();
                console.log("this.state.betString");
                console.log(this.state.betString);

                for (let i = 0; i < this.state.betString.length; i++) {
                    let value = this.state.betString[i].amount;
                    if (value != 0) {
                        let id_span = document.getElementById(i).getElementsByTagName("span");
                        id_span[0].innerText = value;
                    }
                }
                this.setState({
                    btnActive: false,
                    betButtonText: this.props.language.BetOK,
                    betButtonValue: 1,
                    disBetBtn: 1,
                    disClearBtn: 1,
                    disDoubleBtn: 1,
                    infoText: this.props.language.BetAccepted + this.changeAmtlabl(this.props.gameState.betRequest.betAmount),
                    totalBet: this.props.gameState.betRequest.betAmount,
                    Total_Bet_Amount: this.props.gameState.betRequest.betAmount,
                    winCover: true,
                });
                document.getElementById('funMatkaCover').style.display = 'block';
            }
        } else {
            console.log('------------------------------------')
            console.log(this.props.gameState)
            console.log(this.props.gameState.history)
            console.log('------------------------------------')
            if (this.props.gameState.history && this.props.gameState.history.length > 0) {
                this.fmntWheelRef.current.updateResultNumber(this.props.gameState.history[this.props.gameState.history.length - 1].resultNo);
            }
            let bet_take = document.getElementById("bet_take");
            if (bet_take && bet_take.classList.contains("active")) {
                bet_take.classList.remove("active");
            }
            //    document.getElementById("funMatkaCover").style.display = "none";
            document.getElementById("winImg").style.display = "none";
            this.setState({
                betButtonText: this.props.language.Prev,
                betButtonValue: 0,
                disDoubleBtn: 1,
                disBetBtn: 0,
                disClearBtn: 1,
                winCover: false,
            });

            if (this.props.gameState['betRequest'] == undefined) {
                console.log("Disable Previous Button");
                this.setState({ disBetBtn: 1, disDoubleBtn: 1, disClearBtn: 1 });
            } else {
                // this.setState({disBetBtn:1, disDoubleBtn:1, disClearBtn:1});
            }
        }
        document.addEventListener("keyup", this.fmtt_handleKeyboardEvent);
        document.addEventListener("keydown", this.fmtt_bets_handleKeyboardEvent);
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.fmtt_handleKeyboardEvent);
        document.removeEventListener("keydown", this.fmtt_bets_handleKeyboardEvent);
    }

    fmtt_bets_handleKeyboardEvent = (evt) => {
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")

        // if(this.state.betButtonText != "TAKE" && this.state.disBetBtn == 0){
        if (this.state.betButtonValue != 2 && (this.props.gameState.betRequest == undefined || this.state.disBetBtn == 0)) {
            console.log(evt.keyCode)
            if (evt.keyCode > 48 && evt.keyCode <= 57) {
                console.log(evt.key);
                if (evt.key > 6) {
                    this.betPanelHandler(evt.key - 1, "leftClick");
                } else {
                    this.betPanelHandler(evt.key - 1, "rightClick");
                }
            }
            if (evt.keyCode > 96 && evt.keyCode <= 105) {
                console.log(evt.key);
                if (evt.key < 6) {
                    this.betPanelHandler(evt.key - 1, "leftClick");
                } else {
                    this.betPanelHandler(evt.key - 1, "rightClick");
                }
            }
            if (evt.keyCode == 48 || evt.keyCode == 96) {
                this.betPanelHandler(9, "rightClick");

            }
            if (evt.keyCode == 65) {
                this.betPanelHandler(231, "rightClick");
            }
            if (evt.keyCode == 68) {
                this.betPanelHandler(233, "rightClick");
            }
            if (evt.keyCode == 83) {
                this.betPanelHandler(232, "rightClick");
            }
            if (evt.keyCode == 84) {
                this.betPanelHandler(234, "rightClick");
            }
        }
    }

    fmtt_handleKeyboardEvent = (evt) => {
        console.log(evt.code + "    " + this.state.betButtonText);
        switch (evt.code) {
            case "Enter":
            case "NumpadEnter":
                //   if (this.state.betButtonText == "PREV" && this.state.disBetBtn == 0) {
                if (this.state.betButtonValue == 0 && this.state.disBetBtn == 0) {
                    this.rightButtonPanelHandler("PreviousBet");
                } else if (this.state.betButtonValue == 1 && this.state.disBetBtn == 0) {
                    this.rightButtonPanelHandler("betOk");
                }
                break;
            case "Space":
                if (this.state.betButtonValue == 2 && this.state.disBetBtn == 0) {
                    this.rightButtonPanelHandler("takeWinAmount");
                }
                break;
            case "Escape":
                //this.rightButtonPanelHandler("exit");
                break;
            case "KeyC":

                if (this.state.betButtonValue == 1 && this.state.disClearBtn == 0) {

                    this.rightButtonPanelHandler("clear");
                }
                break;
        }
    }


    gameCloseHandler(game) {
        this.setState({ is_fmt_GameOn: false });
        this.state.is_fmt_GameOn = false;
        this.wheelSound.pause();
        this.fmntWheelRef.current.stopWheelSound();

        this.clearSound.pause();
        this.betSound.pause();
        this.takeSound.pause();
        this.winSound.pause();
        this.duploseSound.pause();
        this.betoncardSound.pause();
        this.exitSound.play();

        this.props.action(game);
    }

    wheelHandler(position) {
        this.isSpectSent = false;
        this.fmntWheelRef.current.updateResultNumber(this.props.targetResult.resultNum);
        this.props.gamstateHandle(this.props.gameId);
        if (this.state.is_fmt_GameOn) {
            console.log(this.props.targetResult);
            if (this.props.targetResult.winAmount > 0) {
                this.winAmount = this.changeAmtlabl(this.props.targetResult.winAmount);

                this.setState({
                    winBet: this.props.targetResult.winAmount,
                    // Total_Bet_Amount : this.props.targetResult.betAmount,  
                    winAmount: this.props.targetResult.winAmount,
                    disDoubleBtn: 1,
                    disClearBtn: 1,
                });
                if (document.getElementById('iospopup').style.display == "none") {
                    this.winSound.play();
                }
                document.getElementById('matkaTmr_WinPop').style.display = 'block';
                setTimeout(() => {
                    document.getElementById('matkaTmr_WinPop').style.display = 'none';
                    this.setState({ disBetBtn: 0 });
                }, 3000);
                this.rightButtonPanelHandler('takeWinAmount');
                this.getPlayerInfo();
            } else {
                if (document.getElementById('iospopup').style.display == "none") {
                    this.duploseSound.play();
                }
                if (this.takeAmount == 0) {
                    this.getPlayerInfo();
                    let valId = document.getElementById('bet_take');
                    if (valId && valId.classList.contains("active")) {
                        valId.classList.remove('active');
                    }
                    this.setState({ betButtonText: this.props.language.Prev, betButtonValue: 0, btnActive: false, winCover: false });
                }
                // this.updateWinsArray(0);     

                if (this.props.gameState.betRequest) {
                    this.setState({ disBetBtn: 0 });
                } else {
                    this.setState({ disBetBtn: 1 });
                }
            }
            this.declareWin(this.props.targetResult.winAmount);
            if (this.props.gameState.handId) {
                this.handId = this.props.gameState.handId;
            }
        }

    }

    updatePlayerInfo() {
        this.setState({ totalBet: 0 })
    }

    declareWin() {
        console.log("MATKA TIMER Take Amount " + this.takeAmount);
        console.log("Win Amount: " + this.props.targetResult.winAmount);
        console.log("Result Number: " + this.props.targetResult.resultNum);
        this.setState({ betstr: '', bets: '', disBetBtn: 0, });
        this.winAmount = this.changeAmtlabl(this.props.targetResult.winAmount);
        this.fmntWheelRef.current.updateResultNumber(this.props.targetResult.resultNum);

        if (this.takeAmount == 0) {
            this.setState({
                betButtonText: this.props.language.Prev,
                betButtonValue: 0,
                infoText: this.props.language.Play_Njoy,
                //balance: this.props.balance,
                // totalBet: 0,
                winBet: 0,
                btnActive: false,
                winCover: false,
                disBetBtn: 0,
                disDoubleBtn: 1,
                disClearBtn: 1,
                winCover: false
            });
            this.updateWinsArray(0);
            this.fmTmrRight.removeScale();
            this.fmTmrLeft.removeScale();
            if (this.props.gameState.betRequest == undefined) {
                this.setState({ disBetBtn: 1 });
            }
            document.getElementById("funMatkaCover").style.display = "none";
            document.getElementById("winImg").style.display = "none";
            console.log("declare win fail");
        } else {
            this.setState({ disBetBtn: 0 })
        }
        this.duploseSound.play();

        setTimeout(this.removeActive, 2000);
        this.isReady = false;

        this.resultNum = [this.props.targetResult.resultNum];
        console.log(this.resultNum[0]);

        console.log("length: " + this.resultNum.length);
        for (let a = 0; a < this.resultNum.length; a++) {
            for (let k = 0; k < 230; k++) {
                let betnumber = document.getElementById(k).getAttribute("betnumber");
                if (Number(betnumber) == this.resultNum[a]) {
                    document.getElementById(k).classList.add('activeIn');
                }
            }
        }

        if (this.takeAmount == 0) {
            let rowNumber = 0;
            let f = Number(String(this.resultNum).charAt(0));
            let s = Number(String(this.resultNum).charAt(1));
            let t = Number(String(this.resultNum).charAt(2));
            rowNumber = String(f + s + t);

            if (this.resultNum == 1000) {
                this.topRowWinNumber = 10;
            } else {
                this.topRowWinNumber = rowNumber.charAt(rowNumber.length - 1);
            }
            console.log("topRowWinNumber " + this.topRowWinNumber);
            if (this.topRowWinNumber == 0) {
                document.getElementById(9).classList.add('activeIn');
            } else {
                document.getElementById(Number(this.topRowWinNumber) - 1).classList.add('activeIn');
            }
        }
    }

    rightButtonPanelHandler(action) {
        switch (action) {
            case "betOk":
                this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + Number(cur.amount), 0);
                if (this.state.totalBet != 0 && this.state.totalBet < this.props.minBetAmt) {
                    // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
                    this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });

                } else {
                    this.fmTmrRight.removeScale();
                    let validateBet = [];
                    let innerMin_Limit = 1;
                    this.setState({
                        betstr: [],
                        bets: [],
                        winCover: true,
                    });
                    //this.state.disBetBtn = 1;
                    document.getElementById('funMatkaCover').style.display = 'block';
                    console.log("Disable Bet Button: " + this.state.disBetBtn);
                    if (this.takeAmount == 0) {
                        this.setState({ btnActive: false });
                        if (this.state.betString.length > 1) {
                            this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + Number(cur.amount), 0);
                        } else {
                            this.state.totalBet = 0;
                        }

                        if (this.isReady && this.takeAmount == 0) {

                            // this.state.betstr = "";
                            this.state.bets = "";
                            console.log("balance: " + this.props.balance)
                            console.log("totalBet: " + this.state.totalBet)

                            // if (this.state.betAmount !== 0) {
                            if (this.props.balance >= this.state.totalBet && this.state.totalBet !== NaN) {
                                for (let i = 0; i < this.state.betString.length; i++) {
                                    if (this.state.betString[i].amount > 0) {
                                        if ((i >= 0 && i <= 9) || i == 231) {
                                            console.log("Row 1");
                                            innerMin_Limit = Number(this.ak_limits[0]);
                                        } else if ((i >= 10 && i <= 129) || i == 232) {
                                            console.log("Row 2");
                                            innerMin_Limit = Number(this.sp_limits[0]);
                                        } else if ((i >= 130 && i <= 219) || i == 233) {
                                            console.log("Row 3");
                                            innerMin_Limit = Number(this.dp_limits[0]);
                                        } else if ((i >= 220 && i <= 229) || i == 234) {
                                            console.log("Row 4");
                                            innerMin_Limit = Number(this.tr_limits[0]);
                                        }

                                        if (this.state.betString[i].amount >= innerMin_Limit) {
                                            validateBet[i] = true;
                                        } else {
                                            validateBet[i] = false;
                                        }
                                    } else {
                                        validateBet[i] = true;
                                    }

                                    if (this.state.betString[i].id == 10
                                        || this.state.betString[i].id == 130
                                        || this.state.betString[i].id == 220) {
                                        this.state.bets = this.state.bets.slice(0, -1);
                                        this.state.bets += ',' + this.state.betString[i].amount + '|';
                                    } else {
                                        this.state.bets += this.state.betString[i].amount + '|';
                                    }
                                }
                                this.state.betstr = this.state.bets.slice(0, -1);
                                console.log(this.state.betstr);
                                let sendBet = true;;
                                for (let u = 0; u < validateBet.length; u++) {
                                    if (validateBet[u] == false) {
                                        sendBet = false;
                                        break;
                                    }
                                }

                                if (sendBet) {
                                    this.setState({ disDoubleBtn: 1, disClearBtn: 1, disBetBtn: 1 })
                                    this.isReady = false;
                                    this.placebet();
                                    return this.state.betstr;
                                } else {
                                    document.getElementById("funMatkaCover").style.display = "none";
                                    const minInrLmt = this.changeAmtlabl(innerMin_Limit)
                                    this.setState({ infoText: this.props.language.InerLmt + minInrLmt });
                                }
                                return this.state.betstr;
                            } else {
                                //alert('amount reduces to your balance');
                            }
                            // }                   
                        }
                        if (this.state.totalBet > 0) {
                            //  this.setState({ infoText: this.props.infoText });
                        } else {
                            if (this.isSpectSent == false) {
                                this.isSpectSent = true;
                                // this.props.spectatorCount(this.state.totalBet, this.handId );  //Check Spectator
                            }
                            this.setState({ infoText: this.props.language.Play_Njoy });
                        }
                    }
                }
                break;
            case "takeWinAmount":
                if (document.getElementById('iospopup').style.display == "none") {
                    // this.takeSound.play();
                }
                console.log('take amount action');
                this.setState({ disBetBtn: 1 });
                this.takeAmountResult();
                break;
            case "PreviousBet":
                this.betSound.play();
                console.log('Previous Bet action');
                this.PreviousBetMethod();
                break;
            case "double":
                this.betSound.play();
                this.updateWinsArray(2);
                break;
            case "clear":
                if (document.getElementById('iospopup').style.display == "none") {
                    this.clearSound.play();
                }
                this.updateWinsArray(0);
                this.fmTmrRight.removeScale();
                this.fmTmrLeft.removeScale();
                this.setState({ betButtonText: this.props.language.Prev, betButtonValue: 0, disDoubleBtn: 1, disClearBtn: 1, disBetBtn: 0, btnActive: false });
                if (this.props.gameState.betRequest == undefined) {
                    this.setState({ disBetBtn: 1 });
                } else {
                    this.setState({ disBetBtn: 0 });
                }
                break;
            case "exit":
                this.gameCloseHandler("target");
                const body = {
                    sessionId: sessionStorage.getItem('sessionId'),
                    CN: "EXIT_GAME",
                    object: {
                        gameId: this.props.gameId,
                        playerId: Number(this.props.playerId),
                    },
                };
                this.props.network.sendof(body);
                break;
            case "disableBtns":
                // this.RightButtonPanel.current.mouseUpStopCards();
                // this.fmTmrLeft.current.mouseUpStopCards();
                this.fmTmrRight.mouseUpStopCards();
                this.fmTmrLeft.mouseUpStopCards();

                this.setState({ disDoubleBtn: 1, disBetBtn: 1, disClearBtn: 1, winCover: true, });
                // document.getElementById("funMatkaCover").style.display = "block";
                break;
            case "PlayTickSound":
                if (document.getElementById('iospopup').style.display == "none") {
                    this.tickSound.play();
                }
                break;
            case "StopTickSound":
                if (document.getElementById('iospopup').style.display == "none") {
                    this.tickSound.pause();
                }
                break;
            default:
                break;
        }
    }

    PreviousBetMethod() {
        console.log("Show Previous Bet");
        console.log(this.props.balance);
        if (this.props.gameState.betRequest != undefined) {
            console.log(this.props.gameState.betRequest.betAmount);

            if (this.props.gameState.betRequest.betAmount <= this.props.balance) {
                if (this.props.gameState.betRequest.betAmount > this.props.maxBetAmt) {
                    this.setState({ infoText: this.props.language.PreBet_MaxLmt });
                } else {
                    this.updateWinsArray(0);
                    let preBet = this.props.gameState['betRequest']['betString'].replace(/,/g, '|');
                    this.state.previousBetArray.push({
                        amount: preBet.split("|"),
                    });
                    this.setState({ previousBetArray: this.state.previousBetArray, btnActive: true });
                    if (this.state.previousBetArray) {
                        this.toCreatePreviousBetArray();
                        this.isReady = true;
                    }
                    //console.log(this.state.previousBetArray[0].amount[0]);
                    if (this.props.gameState.handId) {
                        this.handId = this.props.gameState.handId;
                    }
                }
            } else {
                this.setState({ infoText: this.props.language.Bet_Amt_MaxBal });
            }
        }
    }
    updateMessage() {
        console.log("--------- " + this.props.infoText);
        console.log(this.props.language);
        this.setState({ infoText: this.props.infoText });

        if (this.props.infoText == "Bet Rejected!! Time Over") {
            this.setState({ infoText: this.props.language.BetRejTimeOver });
            console.log("______________________")
            console.log(this.state.infoText);
        }
        
        console.log("error_code: " + this.props.error_code);
        if (this.props.error_code == "game.bet.invalid") {
            this.setState({
                betButtonText: this.props.language.Prev,
                betButtonValue: 0,
                btnActive: false,
                disBetBtn: 0,
                winCover: false,
            });
            // this.updateWinsArray(0);
            // document.getElementById('funMatkaCover').style.display = 'none';
        }
    }

    updateGameState() {
        console.log("Matka Timer Update GameState: ")
        if (this.props.gameState.betRequest) {
            this.setState({
                disBetBtn: 0,
            })
        } else {
            this.setState({
                disBetBtn: 1,
            })
        }
    }

    toCreatePreviousBetArray() {
        for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
            this.state.finalPreviousBetArray.push({
                id: i,
                amount: Number(this.state.previousBetArray[0].amount[i])
            });
        }
        this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray });
        console.log(this.state.finalPreviousBetArray);
        if (this.state.finalPreviousBetArray) {
            this.toGenerteBetString();
        }
    }
    toGenerteBetString() {
        for (let i = 0; i < this.state.betString.length; i++) {
            if (this.state.betString[i].id == this.state.finalPreviousBetArray[i].id) {
                this.state.betString[i].amount += this.state.finalPreviousBetArray[i].amount;
                if (this.state.betString[i].amount != 0) {
                    let preBetValue = this.changeAmtlabl(this.state.betString[i].amount);
                    let id_span = document.getElementById(i).getElementsByTagName('span');
                    // id_span[0].innerText = this.state.betString[i].amount;
                    id_span[0].innerText = preBetValue;
                }
            }
        }
        this.setState({
            betString: this.state.betString,
            betButtonText: this.props.language.BetOK,
            betButtonValue: 1,
            previousBetArray: [],
            finalPreviousBetArray: []
        });
        console.log(this.state.betString);

        this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
        if (this.state.totalBet > 0) {
            this.setState({ disClearBtn: 0, disDoubleBtn: 0 })
        }
    }
    // betPanelHandler(no, val) {
    //     console.log("Matka Timer Click on Bet Pannel");
    //     console.log(no+"   "+val);
    //     if(val == 'rightClick'){
    //         let secList_left = document.getElementsByClassName('secList_left');
    //         console.log(secList_left)
    //         for (let j = 0; j < secList_left.length; j++) {
    //         secList_left[j].classList.remove('matkaScale')
    //         }

    //     } else {
    //          let secList = document.getElementsByClassName('secList');
    //         for (let i = 0; i < secList.length; i++) {
    //             secList[i].classList.remove('matkaScale')
    //         }
    //     }
    //     console.log(no);
    //     this.betoncardSound1 = new Audio(betoncardSound)
    //     this.betoncardSound1.play();

    //     let betValue;
    //     switch(no){
    //         case 231:
    //         case 234:
    //             betValue = (Number(this.state.betAmount) * 10 )
    //             break;
    //         case 232:
    //             betValue = (Number(this.state.betAmount) * 120 )
    //             break;
    //         case 233:
    //             betValue = (Number(this.state.betAmount) * 90 )
    //             break;
    //         default:
    //             betValue = Number(this.state.betAmount)
    //             break;
    //     }


    //     console.log(betValue);

    //     this.isReady = true;
    //     let totalBetVal = (this.state.betString.reduce((prev, cur) => prev + cur.amount, 0)+ Number(betValue));
    //     console.log(totalBetVal);
    //    console.log("---->  "+this.state.betString.reduce((prev, cur) => prev + cur.amount, 0));
    //    console.log(this.state.betAmount);
    //    console.log("--->  "+this.props.balance);
    //    if(totalBetVal == 0 && this.state.betAmount == 0){
    //         this.setState({ infoText: this.props.language.MinBetAmt+ " "+this.props.minBetLbl });
    //         setTimeout(()=>{
    //             this.setState({ infoText:  this.props.language.Play_Njoy });
    //           },1500)
    //     }
    //     else if(totalBetVal > this.props.maxBetAmt){            
    //         this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
    //     }

    //     else{
    //         this.setState({betButtonText:this.props.language.BetOK, betButtonValue:1});
    //         console.log("totalBetVal :"+totalBetVal)
    //         console.log("Balance :"+this.props.balance)
    //         if (totalBetVal <= this.props.balance) {
    //             this.setState({ infoText: this.props.language.Play_Njoy });

    //             this.setState({ disBetBtn:0, betButtonText:this.props.language.BetOK, betBtttonVal: 1});
    //             if (Number(this.state.betAmount) !== 0) {
    //                 const x = no;
    //                 switch (true) {
    //                     case (x < 230):
    //                         if(x>=10 && x<130){
    //                             console.log("Chip Value: "+this.state.betAmount);
    //                             console.log("Chip Value: "+Number(this.sp_limits[1]));
    //                             if(this.state.betAmount <= Number(this.sp_limits[1])){                               
    //                                 console.log( this.state.betString[no].amount+ Number(this.state.betAmount));
    //                                 if((this.state.betString[no].amount+this.state.betAmount) <= Number(this.sp_limits[1])){
    //                                     this.showBetValue(no);
    //                                 }else{
    //                                     // this.setState({ infoText: this.props.language.InerLmt_Max }); 
    //                                     this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.sp_limits[1]))});
    //                                     console.log("Inner Limit exceeded.")
    //                                 }
    //                             }else{
    //                                 // this.setState({ infoText: this.props.language.InerLmt_Max }); 
    //                                 this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.sp_limits[1]))});
    //                                 console.log("Inner Limit exceeded.")
    //                             }
    //                         }else if(x>129 && x<231){
    //                             console.log("############  "+x);
    //                             //if(this.state.betAmount <= Number(this.tr_limits[1])){
    //                             if(x>=220 && x<=229){
    //                                 if(this.state.betAmount <= Number(this.tr_limits[1])){
    //                                     if((this.state.betString[no].amount+this.state.betAmount) <= Number(this.tr_limits[1])){
    //                                         this.showBetValue(no);
    //                                     }else{
    //                                         // this.setState({ infoText: this.props.language.InerLmt_Max }); 
    //                                         this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.tr_limits[1]))});
    //                                         console.log("Inner Limit exceeded.")
    //                                     } 
    //                                 }
    //                             }else{
    //                                 if(this.state.betAmount <= Number(this.dp_limits[1])){
    //                                     if((this.state.betString[no].amount+this.state.betAmount) <= Number(this.dp_limits[1])){
    //                                         this.showBetValue(no);
    //                                     }else{
    //                                         // this.setState({ infoText: this.props.language.InerLmt_Max }); 
    //                                         this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.dp_limits[1]))});
    //                                         console.log("Inner Limit exceeded.")
    //                                     }
    //                                 }
    //                             }                               
    //                             //}
    //                         }else {                           
    //                             if(Number(this.state.betAmount) <= Number(this.ak_limits[1])){
    //                                 console.log()
    //                                 if((Number(this.state.betString[no].amount) + Number(this.state.betAmount)) <= Number(this.ak_limits[1])){
    //                                     this.showBetValue(no);
    //                                 }else{
    //                                     // this.setState({ infoText: this.props.language.InerLmt_Max }); 
    //                                     this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.ak_limits[1]))});
    //                                     console.log("Inner Limit exceeded.")
    //                                 }
    //                             }
    //                         }
    //                     break;
    //                     case (x == 231):
    //                         if(Number(this.state.betAmount) <= Number(this.ak_limits[1]) ){
    //                             let arr1 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'topLeft');
    //                             let arr2 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'topRight');
    //                             let data = [...arr1, ...arr2];
    //                             let checkBet = 0;
    //                             console.log("--------------231-------------------------------");
    //                             console.log("data.length: "+data.length)
    //                             let checkAmt_231 = 0;
    //                             checkAmt_231 = data.length * Number(this.state.betAmount);
    //                             checkBet = checkAmt_231 + this.state.totalBet;
    //                             if (checkBet <= this.props.balance) {
    //                                 for (let aa = 0; aa < data.length; aa++) {
    //                                     if((this.state.betString[data[aa].betId].amount+this.state.betAmount) <= Number(this.ak_limits[1])){
    //                                         this.state.betString[data[aa].betId].amount += Number(this.state.betAmount);
    //                                         let id_span = document.getElementById([data[aa].betId]).getElementsByTagName("span");
    //                                         let ak_betText = this.changeAmtlabl(this.state.betString[data[aa].betId].amount);
    //                                         // id_span[0].innerText = this.state.betString[data[aa].betId].amount;
    //                                         id_span[0].innerText = Number(ak_betText);
    //                                     }else{
    //                                         // this.setState({ infoText: this.props.language.InerLmt_Max }); 
    //                                         this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.ak_limits[1]))});
    //                                         console.log("Inner Limit exceeded.")
    //                                     }
    //                                 }
    //                                 this.state.totalBet = checkBet;
    //                             } else {
    //                                 this.setState({ infoText:this.props.language.Amount_Reduce});
    //                             }
    //                             this.setState({ betString: this.state.betString });
    //                             this.fmTmrRight.removeScale();
    //                             console.log(this.state.betString);
    //                         }
    //                     break;
    //                     case (x == 232):
    //                         if(Number(this.state.betAmount) <= Number(this.sp_limits[1]) ){
    //                             let data = [...matkanums[0].sectionOne, ...matkanums[2].sectionThree];
    //                             let checkAmt_232 = 0;
    //                             let checkBet_232 = 0;
    //                             checkAmt_232 = data.length * Number(this.state.betAmount);
    //                             checkBet_232 = checkAmt_232 + this.state.totalBet;
    //                             if (checkBet_232 <= this.props.balance) {
    //                                 for (let ab = 0; ab < data.length; ab++) {
    //                                     if((this.state.betString[data[ab].betId].amount+this.state.betAmount) <= Number(this.sp_limits[1])){
    //                                         this.state.betString[data[ab].betId].amount += Number(this.state.betAmount);
    //                                         let id_span = document.getElementById(data[ab].betId).getElementsByTagName("span");
    //                                         let sp_betText = this.changeAmtlabl(this.state.betString[data[ab].betId].amount);
    //                                         id_span[0].innerText = Number(sp_betText) ;
    //                                     }else{
    //                                         // this.setState({ infoText: this.props.language.InerLmt_Max }); 
    //                                         this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.sp_limits[1]))});
    //                                         console.log("Inner Limit exceeded.")
    //                                     }
    //                                 }
    //                             } else {
    //                                 this.setState({ infoText: this.props.language.Amount_Reduce });
    //                             }
    //                             this.setState({ betString: this.state.betString });
    //                             this.fmTmrRight.removeScale();
    //                             console.log(this.state.betString);
    //                         }
    //                     break;
    //                         case (x == 233):
    //                             if(Number(this.state.betAmount) <= Number(this.tr_limits[1]) ){
    //                                 let data = [...matkanums[1].sectionTwo, ...matkanums[3].sectionFour];
    //                                 console.log("Data Length: "+data.length);
    //                                 let checkAmt_233 = 0;
    //                                 let checkBet_233 = 0;
    //                                 checkAmt_233 = data.length * Number(this.state.betAmount);
    //                                 checkBet_233 = checkAmt_233 + this.state.totalBet;
    //                                 console.log("totalBet  "+this.state.totalBet );
    //                                 if (checkBet_233 <= this.props.balance) {
    //                                     for (let ac = 0; ac < data.length; ac++) {
    //                                         if((this.state.betString[data[ac].betId].amount+this.state.betAmount) <= Number(this.dp_limits[1])){
    //                                             this.state.betString[data[ac].betId].amount += Number(this.state.betAmount);
    //                                             let id_span = document.getElementById(data[ac].betId).getElementsByTagName("span");
    //                                             let dp_betText = this.changeAmtlabl(this.state.betString[data[ac].betId].amount);
    //                                             id_span[0].innerText = Number(dp_betText);
    //                                         }else{
    //                                             // this.setState({ infoText: this.props.language.InerLmt_Max }); 
    //                                             this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.dp_limits[1]))});
    //                                             console.log("Inner Limit exceeded.")
    //                                         }
    //                                     }
    //                                 }  else {
    //                                     this.setState({ infoText: this.props.language.Amount_Reduce });
    //                                 }

    //                                 this.setState({ betString: this.state.betString });
    //                                 this.fmTmrRight.removeScale();
    //                                 console.log(this.state.betString);
    //                             }
    //                         break;
    //                     case (x == 234):
    //                         if(Number(this.state.betAmount) <=  Number(this.tr_limits[1]) ){
    //                             let arr1 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'btmLeft');
    //                             let arr2 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'btmRight');
    //                             let data = [...arr1, ...arr2]; console.log(data);
    //                             let checkAmt_234 = 0;
    //                             let checkBet_234 = 0;
    //                             checkAmt_234 = data.length * Number(this.state.betAmount);

    //                             checkBet_234 = checkAmt_234 + this.state.totalBet;
    //                             if (checkBet_234 <= this.props.balance) {
    //                                 for (let ad = 0; ad < data.length; ad++) {
    //                                     if((this.state.betString[data[ad].betId].amount+this.state.betAmount) <= Number(this.tr_limits[1])){
    //                                         this.state.betString[data[ad].betId].amount += Number(this.state.betAmount);
    //                                         let id_span = document.getElementById(data[ad].betId).getElementsByTagName("span");
    //                                         let tr_betText = this.changeAmtlabl(this.state.betString[data[ad].betId].amount);
    //                                         id_span[0].innerText = Number(tr_betText);
    //                                     }else{
    //                                         // this.setState({ infoText: this.props.language.InerLmt_Max }); 
    //                                         this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.tr_limits[1]))});
    //                                         console.log("Inner Limit exceeded.")
    //                                     }
    //                                 }
    //                             } else {
    //                                 this.setState({ infoText: this.props.language.Amount_Reduce });
    //                             }                           
    //                             this.fmTmrRight.removeScale();
    //                             this.setState({ betString: this.state.betString });
    //                             console.log(this.state.betString);
    //                         }
    //                     break;
    //                     default:
    //                     break;
    //                 }
    //                 this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
    //             } else {
    //                 console.log("Remove bet amount ")
    //                 this.state.betString[no].amount = 0;
    //                 let id_span = document.getElementById(no).getElementsByTagName('span');
    //                 id_span[0].innerText = ''
    //                 this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
    //             }
    //         }else {
    //             this.setState({ infoText: this.props.language.LowBalance  });
    //             //alert('amount reduces to your balance');
    //         }
    //         if(this.state.totalBet > 0){
    //             this.setState({disClearBtn: 0, disDoubleBtn: 0, disBetBtn:0, btnActive: true});
    //         }else{
    //             this.setState({disClearBtn: 1, disDoubleBtn: 1, disBetBtn:0});
    //             console.log(this.props.gameState);
    //             console.log(this.props.gameState.betRequest);

    //             if(this.props.gameState.betRequest){
    //                 this.setState({ betButtonText:this.props.language.Prev, betButtonValue:0, btnActive: false})
    //             }else{
    //                 this.setState({disBetBtn:1})
    //             }

    //         }
    //     }

    // }

    betPanelHandler(no, val) {
        console.log("Matka Timer Click on Bet Pannel");
        console.log(no + "   " + val);
        if (val == 'rightClick') {
            let secList_left = document.getElementsByClassName('secList_left');
            console.log(secList_left)
            for (let j = 0; j < secList_left.length; j++) {
                secList_left[j].classList.remove('matkaScale')
            }

        } else {
            let secList = document.getElementsByClassName('secList');
            for (let i = 0; i < secList.length; i++) {
                secList[i].classList.remove('matkaScale')
            }
        }
        console.log(no);
        this.betoncardSound1 = new Audio(betoncardSound)
        this.betoncardSound1.play();
        this.isReady = true;
        let totalBetVal = (this.state.betString.reduce((prev, cur) => prev + cur.amount, 0) + Number(this.state.betAmount));
        console.log(totalBetVal);
        console.log("---->  " + this.state.betString.reduce((prev, cur) => prev + cur.amount, 0));
        console.log(this.state.betAmount);
        console.log("--->  " + this.props.balance);
        if (totalBetVal == 0 && totalBetVal > this.props.maxBetAmt) {
            this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl });
            setTimeout(() => {
                this.setState({ infoText: this.props.language.Play_Njoy });
            }, 1500)
        }
        else if (totalBetVal > this.props.maxBetAmt) {
            this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
        }

        else {

            console.log("totalBetVal :" + totalBetVal)
            console.log("Balance :" + this.props.balance)
            if (totalBetVal <= this.props.balance) {
                this.setState({ infoText: this.props.language.Play_Njoy });
                this.setState({ betButtonText: this.props.language.BetOK, betButtonValue: 1 });
                this.setState({ disBetBtn: 0, betButtonText: this.props.language.BetOK, betBtttonVal: 1 });
                if (Number(this.state.betAmount) !== 0) {
                    const x = no;
                    switch (true) {
                        case (x < 230):
                            if (x >= 10 && x < 130) {
                                console.log("Chip Value: " + this.state.betAmount);
                                console.log("Chip Value: " + Number(this.sp_limits[1]));
                                if (this.state.betAmount <= Number(this.sp_limits[1])) {
                                    console.log(this.state.betString[no].amount + Number(this.state.betAmount));
                                    if ((Number(this.state.betString[no].amount) + Number(this.state.betAmount)) <= Number(this.sp_limits[1])) {
                                        this.showBetValue(no);
                                    } else {
                                        // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                        this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.sp_limits[1])) });
                                        console.log("Inner Limit exceeded.")
                                    }
                                } else {
                                    // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                    this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.sp_limits[1])) });
                                    console.log("Inner Limit exceeded.")
                                }
                            } else if (x > 129 && x < 231) {
                                console.log("############  " + x);
                                //if(this.state.betAmount <= Number(this.tr_limits[1])){
                                if (x >= 220 && x <= 229) {
                                    if (this.state.betAmount <= Number(this.tr_limits[1])) {
                                        if ((Number(this.state.betString[no].amount) + Number(this.state.betAmount)) <= Number(this.tr_limits[1])) {
                                            this.showBetValue(no);
                                        } else {
                                            // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                            this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.tr_limits[1])) });
                                            console.log("Inner Limit exceeded.")
                                        }
                                    }
                                } else {
                                    console.log(this.state.betAmount)
                                    console.log(Number(this.dp_limits[1]))
                                    console.log(this.state.betString[no].amount )
                                    if (this.state.betAmount <= Number(this.dp_limits[1])) {
                                        console.log((this.state.betString[no].amount + this.state.betAmount));
                                        if ((Number(this.state.betString[no].amount) + Number(this.state.betAmount)) <= Number(this.dp_limits[1])) {
                                            this.showBetValue(no);
                                        } else {
                                            // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                            this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.dp_limits[1])) });
                                            console.log("Inner Limit exceeded.")
                                        }
                                    }
                                }
                                //}
                            } else {
                                if (Number(this.state.betAmount) <= Number(this.ak_limits[1])) {
                                    console.log()
                                    if ((Number(this.state.betString[no].amount) + Number(this.state.betAmount)) <= Number(this.ak_limits[1])) {
                                        this.showBetValue(no);
                                    } else {
                                        // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                        this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.ak_limits[1])) });
                                        console.log("Inner Limit exceeded.")
                                    }
                                }
                            }
                            break;
                        case (x == 231):
                            if (Number(this.state.betAmount) <= Number(this.ak_limits[1])) {
                                let arr1 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'topLeft');
                                let arr2 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'topRight');
                                let data = [...arr1, ...arr2];
                                if ((this.state.totalBet + this.state.betAmount * data.length) <= this.props.maxBetAmt) {
                                    let checkBet = 0;
                                    console.log("--------------231-------------------------------");
                                    console.log("data.length: " + data.length)
                                    let checkAmt_231 = 0;
                                    checkAmt_231 = data.length * Number(this.state.betAmount);
                                    checkBet = checkAmt_231 + this.state.totalBet;
                                    if (checkBet <= this.props.balance) {
                                        for (let aa = 0; aa < data.length; aa++) {
                                            if ((this.state.betString[data[aa].betId].amount + this.state.betAmount) <= Number(this.ak_limits[1])) {
                                                this.state.betString[data[aa].betId].amount += Number(this.state.betAmount);
                                                let id_span = document.getElementById([data[aa].betId]).getElementsByTagName("span");
                                                let ak_betText = this.changeAmtlabl(this.state.betString[data[aa].betId].amount);
                                                // id_span[0].innerText = this.state.betString[data[aa].betId].amount;
                                                id_span[0].innerText = Number(ak_betText);
                                            } else {
                                                // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                                this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.ak_limits[1])) });
                                                console.log("Inner Limit exceeded.")
                                            }
                                        }
                                        this.state.totalBet = checkBet;
                                    } else {
                                        this.setState({ infoText: this.props.language.Amount_Reduce });
                                    }
                                    this.setState({ betString: this.state.betString });
                                    this.fmTmrRight.removeScale();
                                    console.log(this.state.betString);
                                }
                            }
                            break;
                        case (x == 232):
                            console.log(this.sp_limits[1]);
                            if (Number(this.state.betAmount) <= Number(this.sp_limits[1])) {
                                let data = [...matkanums[0].sectionOne, ...matkanums[2].sectionThree];
                                if ((this.state.totalBet + this.state.betAmount * data.length) <= this.props.maxBetAmt) {
                                    let checkAmt_232 = 0;
                                    let checkBet_232 = 0;
                                    checkAmt_232 = data.length * Number(this.state.betAmount);
                                    checkBet_232 = checkAmt_232 + this.state.totalBet;
                                    console.log(checkBet_232)
                                    if (checkBet_232 <= this.props.balance) {
                                        for (let ab = 0; ab < data.length; ab++) {
                                            console.log((this.state.betString[data[ab].betId].amount + Number(this.state.betAmount)));
                                            if ((Number(this.state.betString[data[ab].betId].amount) + Number(this.state.betAmount)) <= Number(this.sp_limits[1])) {
                                                this.state.betString[data[ab].betId].amount += Number(this.state.betAmount);
                                                let id_span = document.getElementById(data[ab].betId).getElementsByTagName("span");
                                                let sp_betText = this.changeAmtlabl(this.state.betString[data[ab].betId].amount);
                                                id_span[0].innerText = Number(sp_betText);
                                            } else {
                                                // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                                this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.sp_limits[1])) });
                                                console.log("Inner Limit exceeded.")
                                            }
                                        }
                                    } else {
                                        this.setState({ infoText: this.props.language.Amount_Reduce });
                                    }
                                    this.setState({ betString: this.state.betString });
                                    this.fmTmrRight.removeScale();
                                    console.log(this.state.betString);
                                } else {
                                    this.setState({ infoText: this.props.language.Min_Bet + this.props.minBetAmt + this.props.language.Max_Bet + this.props.maxBetAmt });

                                }
                            }
                            break;
                        case (x == 233):
                            if (Number(this.state.betAmount) <= Number(this.tr_limits[1])) {
                                let data = [...matkanums[1].sectionTwo, ...matkanums[3].sectionFour];
                                console.log("Data Length: " + data.length);
                                if ((this.state.totalBet + this.state.betAmount * data.length) <= this.props.maxBetAmt) {
                                    let checkAmt_233 = 0;
                                    let checkBet_233 = 0;
                                    checkAmt_233 = data.length * Number(this.state.betAmount);
                                    checkBet_233 = checkAmt_233 + this.state.totalBet;
                                    console.log("totalBet  " + this.state.totalBet);
                                    if (checkBet_233 <= this.props.balance) {
                                        for (let ac = 0; ac < data.length; ac++) {
                                            if ((this.state.betString[data[ac].betId].amount + this.state.betAmount) <= Number(this.dp_limits[1])) {
                                                this.state.betString[data[ac].betId].amount += Number(this.state.betAmount);
                                                let id_span = document.getElementById(data[ac].betId).getElementsByTagName("span");
                                                let dp_betText = this.changeAmtlabl(this.state.betString[data[ac].betId].amount);
                                                id_span[0].innerText = Number(dp_betText);
                                            } else {
                                                // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                                this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.dp_limits[1])) });
                                                console.log("Inner Limit exceeded.")
                                            }
                                        }
                                    } else {
                                        this.setState({ infoText: this.props.language.Amount_Reduce });
                                    }

                                    this.setState({ betString: this.state.betString });
                                    this.fmTmrRight.removeScale();
                                    console.log(this.state.betString);
                                } else {
                                    this.setState({ infoText: this.props.language.Min_Bet + this.props.minBetAmt + this.props.language.Max_Bet + this.props.maxBetAmt });
                                }
                            }
                            break;
                        case (x == 234):
                            if (Number(this.state.betAmount) <= Number(this.tr_limits[1])) {
                                let arr1 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'btmLeft');
                                let arr2 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'btmRight');
                                let data = [...arr1, ...arr2]; console.log(data);
                                if ((this.state.totalBet + this.state.betAmount * data.length) <= this.props.maxBetAmt) {
                                    let checkAmt_234 = 0;
                                    let checkBet_234 = 0;
                                    checkAmt_234 = data.length * Number(this.state.betAmount);

                                    checkBet_234 = checkAmt_234 + this.state.totalBet;
                                    if (checkBet_234 <= this.props.balance) {
                                        for (let ad = 0; ad < data.length; ad++) {
                                            if ((this.state.betString[data[ad].betId].amount + this.state.betAmount) <= Number(this.tr_limits[1])) {
                                                this.state.betString[data[ad].betId].amount += Number(this.state.betAmount);
                                                let id_span = document.getElementById(data[ad].betId).getElementsByTagName("span");
                                                let tr_betText = this.changeAmtlabl(this.state.betString[data[ad].betId].amount);
                                                id_span[0].innerText = Number(tr_betText);
                                            } else {
                                                // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                                this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.tr_limits[1])) });
                                                console.log("Inner Limit exceeded.")
                                            }
                                        }
                                    } else {
                                        this.setState({ infoText: this.props.language.Amount_Reduce });
                                    }
                                    this.fmTmrRight.removeScale();
                                    this.setState({ betString: this.state.betString });
                                    console.log(this.state.betString);
                                } else {
                                    this.setState({ infoText: this.props.language.Min_Bet + this.props.minBetAmt + this.props.language.Max_Bet + this.props.maxBetAmt });
                                }
                            }
                            break;
                        default:
                            break;
                    }
                    this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
                } else {
                    console.log("Remove bet amount ")
                    if (no == 231 || no == 232 || no == 233 || no == 234) {
                        return;
                    }
                    this.state.betString[no].amount = 0;
                    let id_span = document.getElementById(no).getElementsByTagName('span');
                    id_span[0].innerText = ''
                    this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
                }
            } else {
                this.setState({ infoText: this.props.language.LowBalance });
                //alert('amount reduces to your balance');
            }
            if (this.state.totalBet > 0) {
                this.setState({ disClearBtn: 0, disDoubleBtn: 0, disBetBtn: 0, btnActive: true });
            } else {
                this.setState({ disClearBtn: 1, disDoubleBtn: 1, disBetBtn: 0 });
                console.log(this.props.gameState);
                console.log(this.props.gameState.betRequest);

                if (this.props.gameState.betRequest) {
                    this.setState({ betButtonText: this.props.language.Prev, betButtonValue: 0, btnActive: false })
                } else {
                    this.setState({ disBetBtn: 1 })
                }

            }
        }

    }
    showBetValue(num) {
        console.log(num);
        for (let i = 0; i < this.state.betString.length; i++) {
            if (this.state.betString[i].id == num) {
                let id_span = document.getElementById(num).getElementsByTagName("span");
                if (this.state.betAmount == 0) {
                    this.state.betString[i].amount = 0;
                    id_span[0].innerText = "";
                } else {
                    this.state.betString[i].amount += Number(this.state.betAmount);
                    let betText = this.changeAmtlabl(this.state.betString[i].amount);
                    console.log(betText);
                    id_span[0].innerText = betText;
                }
            }
        }
        this.setState({ betString: this.state.betString });
    }

    changeAmtlabl(chngAmt) {
        let newAmt = chngAmt;
        if (newAmt >= 1000 && newAmt <= 999999) {
            newAmt = newAmt / 1000 + "K";
        } else if (newAmt >= 1000000 && newAmt <= 999999999) {
            newAmt = newAmt / 1000 + "M";
        } else if (newAmt > 999999999) {
            newAmt = newAmt / 1000 + "B";
        }
        return newAmt;
    }

    placebet() {
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "MATKA1_BET",
            object: {
                betString: this.state.betstr,
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId
            }
        }
        this.props.network.sendof(body);

    }

    getPlayerInfo() {
        const body2 = {
            CN: "GET_PLAYER_INFO",
            object: {
                gameId: Number(this.state.gameId),
                playerId: Number(this.props.playerId),
            },
            sessionId: sessionStorage.getItem('sessionId'),
        }
        this.props.network.sendof(body2);
        this.body2 = {};
    }

    takeAmountResult() {
        this.setState({
            disDoubleBtn: 0,
            disBetBtn: 0,
            disClearBtn: 0,
            glowTakeBtn: 0,
            btnActive: false,
            betButtonText: this.props.language.Prev,
            betButtonValue: 0,
            // totalBet: 0,
            winAmount: 0,
            winBet: 0
        });
        this.takeAmount = 0;
        this.removeActive();
        this.setState({ infoText: this.props.language.Play_Njoy, showBetButton: false });
        // this.updateWinsArray(0);

        for (let k = 0; k < 230; k++) {
            var id_span = document.getElementById(k).getElementsByTagName("span");
            id_span[0].innerText = "";
        }
        document.getElementById('funMatkaCover').style.display = 'none';
        //this.props.gamstateHandle(this.props.gameId); 

        document.getElementById("winImg").style.display = "none";
        this.setState({ winCover: false })
        // this.rightButtonPanelHandler('clear');

        if (this.props.isPlayerBanned) {
            this.gameCloseHandler("target");
            this.props.player_banned();
        }

        console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
        if (this.props.gameUnderMaintanance) {
            this.gameCloseHandler("target");
            this.props.game_maintanance("Game under maintenance, reach to admin.");
        }

        console.log("deviceBlocked " + this.props.isdeviceBlocked);
        if (this.props.isdeviceBlocked) {
            this.gameCloseHandler("target");
            this.props.device_blocked();
        }
    }

    updateWinsArray(num) {
        console.log(this.state.totalBet);
        console.log(this.props.balance);
        if (num == 0) {
            this.isReady = false;
            console.log(this.state.betString.length);
            for (let k = 0; k < this.state.betString.length; k++) {
                let id_span_0 = document.getElementById(k).getElementsByTagName("span");
                id_span_0[0].innerText = "";
                this.state.betString[k].amount = 0;
            }
            this.state.totalBet = this.state.totalBet * num;
        } else {
            console.log(this.state.totalBet);
            console.log(this.state.totalBet * num);
            console.log(this.props.maxBetAmt)

            if (this.state.totalBet * num > this.props.maxBetAmt) {
                this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
            } else {
                if (this.state.totalBet * num <= this.props.balance) {
                    console.log(this.state.totalBet)
                    this.isReady = true;
                    for (let b = 0; b < this.state.betString.length; b++) {
                        let totBetVal = this.state.totalBet;
                        if (this.state.betString[b].amount != 0) {
                            let id_span = document.getElementById(b).getElementsByTagName("span");
                            let betIndexVal = Number(this.state.betString[b].amount);
                            if (b >= 10 && b < 130) {
                                if (this.state.betString[b].amount * num <= Number(this.sp_limits[1])) {
                                    let totBetAmt = this.state.betString[b].amount + totBetVal;
                                    console.log(totBetAmt);
                                    console.log(betIndexVal);
                                    // id_span[0].innerText = betIndexVal*num;
                                    id_span[0].innerText = this.changeAmtlabl(Number(betIndexVal * num));
                                    this.state.betString[b].amount *= num;
                                    console.log(this.state.betString[b].amount);
                                    this.setState({ totalBet: totBetAmt });
                                    this.state.totalBet = totBetAmt;
                                    console.log("Total Bet Amount " + this.state.totalBet);
                                } else {
                                    // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                    this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.sp_limits[1])) });
                                    console.log("Inner Limit exceeded.")
                                }
                            }
                            else if (b > 129 && b < 231) {
                                if (b >= 220 && b <= 229) {
                                    if (this.state.betString[b].amount * num <= Number(this.tr_limits[1])) {
                                        let totBetAmt2 = this.state.betString[b].amount + totBetVal;
                                        console.log(totBetAmt2);
                                        console.log(betIndexVal);
                                        // id_span[0].innerText = betIndexVal*num;
                                        id_span[0].innerText = this.changeAmtlabl(Number(betIndexVal * num));
                                        this.state.betString[b].amount *= num;
                                        console.log(this.state.betString[b].amount);
                                        this.setState({ totalBet: totBetAmt2 });
                                        this.state.totalBet = totBetAmt2;
                                    } else {
                                        // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                        this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.tr_limits[1])) });
                                        console.log("Inner Limit exceeded.")
                                    }
                                } else {
                                    if (this.state.betString[b].amount * num <= Number(this.dp_limits[1])) {
                                        let totBetAmt3 = this.state.betString[b].amount + totBetVal;
                                        console.log(totBetAmt3);
                                        console.log(betIndexVal);
                                        // id_span[0].innerText = betIndexVal*num;
                                        id_span[0].innerText = this.changeAmtlabl(Number(betIndexVal * num));
                                        this.state.betString[b].amount *= num;
                                        console.log(this.state.betString[b].amount);
                                        this.setState({ totalBet: totBetAmt3 });
                                        this.state.totalBet = totBetAmt3;
                                    } else {
                                        // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                        this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.dp_limits[1])) });
                                        console.log("Inner Limit exceeded.")
                                    }
                                }
                            } else {
                                if (betIndexVal * num <= Number(this.ak_limits[1])) {
                                    let totBetAmt4 = this.state.betString[b].amount + totBetVal;
                                    console.log(totBetAmt4);
                                    console.log(betIndexVal);
                                    // id_span[0].innerText = betIndexVal*num;
                                    id_span[0].innerText = this.changeAmtlabl(Number(betIndexVal * num));
                                    this.state.betString[b].amount *= num;
                                    console.log(this.state.betString[b].amount);
                                    this.setState({ totalBet: totBetAmt4 });
                                    this.state.totalBet = totBetAmt4;
                                } else {
                                    // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                    this.setState({ infoText: this.props.language.MaxInrBetAmt + this.changeAmtlabl(Number(this.ak_limits[1])) });
                                    console.log("Inner Limit exceeded.")
                                }
                            }
                        }
                    }
                } else {
                    this.setState({ infoText: this.props.language.Amount_Reduce });
                }

            }
        }
    }

    betHandler(amount) {
        console.log(amount);
        this.takeSound = new Audio(takeSound);
        this.takeSound.play();
        this.setState({ betAmount: amount });
    }

    funMatkaTimerGetwheelresp() {
        this.fmntWheelRef.current.updateResultNumber("");
        console.log(this.props.targetResult);
        if (this.props.targetResult) {
            console.log(this.props.targetResult.resultNum);
            //setTimeout(()=>{   
            if (this.props.targetResult.resultNum < 1000) {
                this.fmntWheelRef.current.spinTheWheel(this.props.targetResult.resultNum);
            } else {
                this.fmntWheelRef.current.spinTheWheel("000");
            }
            //},200)
            if (document.getElementById('iospopup').style.display == "none") {
                this.wheelSound.play();
            }
        }
    }

    removeActive() {
        for (let k = 0; k < 230; k++) {
            // console.log("--- "+document.getElementById(k));
            if (document.getElementById(k) && document.getElementById(k).classList.contains("activeIn")) {
                document.getElementById(k).classList.remove('activeIn');
            }
        }
        if (this.takeAmount == 0) {
            document.getElementById('funMatkaCover').style.display = 'none';
        }
    }

    FunMatkaTimerGetTime() {
        // console.log("Fun Matka Current Time: "+this.props.timesend);
        // console.log(this.props.timesend);
        if (this.props.timesend) {
            //console.log("FunMatkaTimerGetTime: "+this.props.timesend);
            this.FunMatkaTimerInSeconds.current.sendTimer(this.props.timesend);
        }
    }

    loadBg() {
        this.LoadImg = new window.Image()
        this.LoadImg.src = fmtBg;
        this.LoadImg.addEventListener('load', this.eurpRltLoded)
    }

    eurpRltLoded = () => {
        if (!this.isLoad) {
            this.isLoad = true;
        } else {
            this.setState({
                eurpRlt: false
            })
        }
    }
    closeWin() {
        document.getElementById('iospopup').style.display = "none";
        this.takeSound = new Audio(takeSound);
        this.winSound = new Audio(winSound);
        this.duploseSound = new Audio(duploseSound);
        this.tickSound = new Audio(tickSound);
        this.wheelSound = new Audio(wheelSound);
    }

    render() {
        return (
            <React.Fragment>
                <div className="gameBox" id="gameBox">
                    <div className="gamePage indiMatkaTimer p_30">
                        <div className="iosFetchSounds" id="iospopup" onClick={(e) => this.closeWin()}>
                            <div className="popupTooLong">
                                <div className="">{this.props.language.Wel_MT}  </div>
                                <br></br>
                                {this.props.language.OK_Btn}

                                <br></br>
                                <br></br>
                                <div className="fd">
                                    <button className="btn_iosPopup" >{this.props.language.Ok}  </button>
                                </div>
                            </div>
                        </div>
                        <div className="FGAfunMatka matkaTmr fd p_lr_10">
                            <div className="col_33">
                                <MatkaLeft
                                    childRef={ref => (this.fmTmrLeft = ref)}
                                    gameState={this.props.gameState}
                                    playerId={this.props.playerId}
                                    action={this.betPanelHandler.bind(this)}
                                    balance={this.props.balance}
                                    totalBet={this.state.totalBet}
                                    winning={this.state.winBet}
                                    ref={this.fmTmrLeft}
                                />
                            </div>
                            <div className="col_33">
                                <Title></Title>
                                <FunMatkaTimerInSeconds
                                    winCover={this.state.winCover}
                                    action={this.rightButtonPanelHandler.bind(this)}
                                    playerId={this.props.playerId}
                                    gameId={this.props.gameId}
                                    network={this.props.network}
                                    timesend={this.props.timesend}
                                    result={this.resultNum}
                                    ref={this.FunMatkaTimerInSeconds}
                                />
                                <Wheel
                                    infoText={this.state.infoText}
                                    gameState={this.props.gameState}
                                    bet={this.betHandler.bind(this)}
                                    ref={this.fmntWheelRef}
                                    height={this.wheelDimension}
                                    action={this.wheelHandler.bind(this)}
                                    isMobile={this.props.isMobile}
                                    onFocus={this.props.onFocus}
                                    winningAmt={this.state.winBet}
                                    eurpRltLoded={this.eurpRltLoded.bind(this)}
                                    fmt_chipValues={this.props.fmt_chipValues}
                                >
                                </Wheel>
                            </div>
                            <div className="col_33">

                                {/* <Close action={this.gameCloseHandler.bind(this)}></Close> */}
                                <MatkaRight
                                    childRef={ref => (this.fmTmrRight = ref)}
                                    //ref={this.fmTmrRight}
                                    btnActive={this.state.btnActive}
                                    name={this.props.user}
                                    targetResult={this.props.targetResult}
                                    betBtnText={this.state.betButtonText}
                                    betButtonValue={this.state.betButtonValue}
                                    action={this.betPanelHandler.bind(this)}
                                    btntext={this.rightButtonPanelHandler.bind(this)}
                                    disDoubleBtn={this.state.disDoubleBtn}
                                    disBetBtn={this.state.disBetBtn}
                                    disClearBtn={this.state.disClearBtn}>
                                </MatkaRight>
                            </div>
                        </div>
                        <div className="matkaTimerWinPop" id="matkaTmr_WinPop">
                            {this.props.language.Win_Amt} {this.winAmount}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        language: state.languageObjs.languageObj
    }
}

export default connect(mapStatesToProps)(FunMatkaTimer) 