import React from "react";
import Wheel from "./UI/wheel";
import RightButtonPanel from "./UI/rightButtonPanel";
import artBg from "../../../../../assets/games/europeanRoulette/ernt_bg.jpg";
import "../../../../css/all_roulette_tamplate.css";
import "./UI/european_roulette.css";
import Close from "./UI/close";
import Title from "./UI/title";
import Table from "./UI/Table";
import BetChips from './UI/betChips';
import coin from '../../../../../assets/gold_coin.svg';
import user from '../../../../../assets/login/user.svg';

import clearSound from "../../../../../assets/sounds/clear.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../assets/sounds/take.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import wheelSound from "../../../../../assets/sounds/wheelStart.mp3";

import {connect} from "react-redux"

 class EuropeanRoulette extends React.Component {
    constructor(props) {
        super(props);        
        this.artWheelRef = React.createRef();
        this.RightButtonPanel = React.createRef();
        this.BetChips = React.createRef()
        this.wheelWidth = window.innerHeight * 1;
        this.wheelHeight = window.innerHeight * 0.45;
        this.state = {
            previousBetArray: [],
            finalPreviousBetArray: [],
            balance: this.props.balance,
            gameId: this.props.gameId,
            playerId: this.props.playerId,
            zindex: '',
            betButtonText:this.props.language.Prev ,
            betBtnValue:0,
            zoomButtonText:this.props.language.Zoom_ON ,
            zoomButtonValue:0,
            showBetButton: false,
            wheelZoom: 1,
            stop: "NA",
            betString: [],
            Total_Bet_Amount: 0,
            betAmount: 1,
            totalBet: 0,
            betstr: "",
            bets: "",
            infoText: this.props.language.Play_Njoy ,
            nmArray:[],
            targetResult: props.targetResult,
            selectedChipVal:1,
            wheelZoomClick: true,
            disDoubleBtn: 1,
            disBetBtn: 0,
            disClearBtn: 1,
            disZoomBtn: 0,
            glowTakeBtn: 0,
            btnActive: false,
            winAmount:0,
            eurpRlt:true,
        };
        this.newLimits = []
        this.resultNumber = 0;
        this.isReady = false;
        this.removeGlow = false;
        this.isloadImg = false;
        this.updatePlayerBalnce = false;
        this.clearSound = new Audio(clearSound);
        this.exitSound = new Audio(exitSound);
        this.betSound = new Audio(betSound);
        this.takeSound = new Audio(takeSound);
        this.winSound = new Audio(winSound);
        this.duploseSound = new Audio(duploseSound);
        this.loadBg();  
      }
      componentDidMount() {
        const{childRef} = this.props
        childRef(this)     
        this.resetBetList();                          
        document.getElementById('rouletteTableCover').style.display = 'none';
        document.getElementById('bet_take').classList.remove('active');
        this.setState({betButtonText:this.props.language.Prev,betBtnValue:0, disDoubleBtn: 0, disBetBtn: 0, disClearBtn: 0, disZoomBtn: 0 });
                
        if(this.props.er_chipValues){
            let newChips = this.props.er_chipValues.split(",");
            console.log(newChips[0]);  
            let initChipVal = newChips[0];
            let lblTxt;
            if(initChipVal >= 1000 && initChipVal <= 999999){
                lblTxt = "K"
            }else if(initChipVal >= 1000000 && initChipVal <= 999999999){
                lblTxt = "M"                        
            }else if(initChipVal > 999999999){            
                lblTxt = "B"
            } 
            
            this.state.selectedChipVal = initChipVal;
            // this.state.selectedChipLbl = initChipVal+""+lblTxt;
          }

          if(this.props.er_innerLimits){
            this.newLimits = this.props.er_innerLimits.split("|");
            console.log(this.newLimits);
            for(let t=0; t<this.newLimits.length; t++){
              this.newLimits[t] = this.newLimits[t].split("-");
              console.log(" *******   "+this.newLimits[t]);
            }
          }

        if(this.props.balance > 0){
            this.setState({disBetBtn: 0, disZoomBtn: 0 })
        }else{
            //document.getElementById('rouletteTableCover').style.display = 'block';
            this.setState({disDoubleBtn: 1, disBetBtn: 1, disClearBtn: 1, disZoomBtn: 1 })
        }

        if(this.props.gameState.history && this.props.gameState.history.length == 0){
            this.setState({disBetBtn: 1});
        }

        if(this.props.gameState.handId){
            this.handId = this.props.gameState.handId;
        }
        document.addEventListener("keyup", this.er_handleKeyboardEvent);
    } 
    componentWillUnmount() {
        document.removeEventListener("keyup", this.er_handleKeyboardEvent);
      }

      changeAmtlabl(chngAmt){
        let newAmt = chngAmt;
        if(newAmt >= 1000 && newAmt <=999999){
          newAmt = newAmt / 1000+"K";      
        }else if(newAmt >= 1000000 && newAmt <=999999999){
          newAmt = newAmt / 1000+"M";    
        }else if(newAmt >999999999){
          newAmt = newAmt / 1000+"B";      
        }
        return newAmt;
      }

    
      er_handleKeyboardEvent = (evt) => {
        console.log(evt.code);
        switch(evt.code){
          case "Enter":
          case "NumpadEnter":
            if (this.state.betBtnValue == 0 && this.state.disBetBtn == 0) {
              this.rightButtonPanelHandler("PREV");
            }else if (this.state.betBtnValue== 1 && this.state.disBetBtn == 0) {
              this.rightButtonPanelHandler("SPIN");
            }
            break;
          case "Space":
            if(this.state.betBtnValue == 2 && this.state.disBetBtn == 0){
              this.rightButtonPanelHandler("TAKE");
            }
            break;
          case "Escape":          
            //this.rightButtonPanelHandler("exit");
            break;
          case "KeyC":      
            if(this.state.betBtnValue== 1 && this.state.disClearBtn == 0){
              this.rightButtonPanelHandler("clear");
            }
            break;      
        }
    
      }  

    updateGameState(){        
        console.log("ER Update Game History")
        this.RightButtonPanel.updateHistory();

    }
    resetBetList(){
        this.state.betString = [];
        for (var l = 0; l < 168; l++) {
            this.state.betString.push({
                id: l,
                amount: 0
            });
        }
    }
    updateMessage(){
        console.log("--------- "+this.props.infoText);
        this.setState({infoText: this.props.infoText});
        // this.setState({infoText:this.props.language.BetAccepted + this.props.totalBet})
        
        console.log("error_code: "+this.props.error_code);
        if(this.props.error_code== "game.bet.invalid"){
            this.setState({
                betButtonText: this.props.language.Prev,
                betBtnValue:0,
                btnActive : false,
                zoomButtonText: this.props.language.Zoom_ON,
                zoomButtonValue:0,
                wheelZoom: 1,
                zindex: '0',
                X_top: "14.5vh",
                disBetBtn : 0,
                disZoomBtn :0,
            });
            this.updateWinsArray(0);
            document.getElementById('rouletteTableCover').style.display = 'none';
        }
    }
    gameCloseHandler(game) {
        this.exitSound.play();
      
        this.artWheelRef.current.stopWheelSound();
        this.clearSound.pause();
        this.betSound.pause();
        this.takeSound.pause();
        this.winSound.pause();
        this.duploseSound.pause();
        //this.exitSound.pause();
        // this.clearSound = {};
        // this.exitSound = {};
        // this.betSound = {};
        // this.takeSound = {};
        // this.winSound = {};
        // this.duploseSound = {};
        this.props.action(game);
    }
    wheelHandler(position) {
        this.glowImage();
        if(position){
            this.setState({stop: position});
        }
        this.props.gamstateHandle();
        // console.log("Wheel Handle: Game State: "+this.props.gameState);
        // console.log("Wheel Handle: Game State: Take Amount: "+this.props.gameState.takeAmount);
        // console.log("Wheel Handle: WinAmount: "+this.state.winAmount);
        if(this.props.gameState.takeAmount == 0){
            if(this.state.winAmount>0){               
                console.log("Take Amount: "+this.props.gameState.takeAmount);
            }else{
                var valId = document.getElementById('bet_take');
                if(valId!=null){
                    valId.classList.remove('active');
                }
            }
        }else{
            //this.setState({disBetBtn: 0})
        }
        if(this.props.gameState.handId){
            this.handId = this.props.gameState.handId;
        }
        if(this.updatePlayerBalnce == false){
            this.updatePlayerBalnce = true;
            //this.getPlayerInfo();
        }

        if(this.props.targetResult.winAmount > 0){
            console.log("European Roulette Win Amount: "+this.props.targetResult.winAmount)
            this.setState({
                infoText: "You won the hand!!!",
               // Total_Bet_Amount : this.props.targetResult.betAmount,
            });
            //console.log("WinAmount: "+this.state.winAmount);
            document.getElementById('rouletteTableCover').style.display = 'block';
            document.getElementById('rouletteTableCover').style.zIndex = "1000";
        }        
    }
    showPrevBtn(){
        this.setState({
            showBetButton: true,
            betButtonText: this.props.language.Prev,
            betBtnValue:0,
            btnActive : false
        });
        let valId = document.getElementById('bet_take');
        if(valId!= null){
            valId.classList.remove('active');
        }
    }
    declareWin(value) { 
        console.log( this.props.gameState.history )
        console.log("declareWin")
        console.log(this.state.winAmount);
        if(this.state.winAmount == 0){
            this.updateWinsArray(0);            
        }
        this.setState({
            showBetButton: false,
            infoText: this.props.language.Play_Njoy,
            btnActive : false
        });
        this.isReady = false;
    }
    rightButtonPanelHandler(action, value) {
        switch (action) {
            case "SPIN":
                this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
                if(this.state.totalBet != 0 && this.state.totalBet < this.props.minBetAmt){
                    // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
                    this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
          
                  }else{ 
                    if(this.state.Total_Bet_Amount > 0){
                       
    
                        this.wheelSound = new Audio(wheelSound);
                        this.winSound = new Audio(winSound);
                        this.duploseSound = new Audio(duploseSound);
    
                        var divContain = document.getElementById('roulette');
                        divContain.style.transform = "scale(1)";
                        divContain.style.zIndex = "1";
                        // if(this.state.zoomButtonText == "Wheel Zoom: ON") {
                        // if(this.state.zoomButtonValue == 0) {
    
                        //     this.setState({wheelZoom: 1.5, zindex: '1000', X_top: "21.8vh", x_img: 1});
                        // }
                       
                        document.getElementById('rouletteTableCover').style.zIndex = "1000";
                        
                        document.getElementById("zoomBtn").disabled = true;
                        //console.log("Double Btn: "+document.getElementById("zoomBtn").disabled)
                        //  document.getElementById("europeanRouletteCover").style.display = 'block';
                        this.setState({ betstr: [], bets: [] });
                        if(this.state.betString.length >1){
                            this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
                        }else{
                            this.state.totalBet =0;
                        }
    
                        if (this.state.betAmount !== 0 && this.state.totalBet != 0) {
                            this.isReady = false;
                            let betValid = [];
                            // this.state.betstr = "";
                            this.state.bets = "";
                            console.log("balance: "+this.props.balance);
                            console.log("totalBet: "+this.state.totalBet);
                            console.info("Bet String: ");
                            console.info(this.state.betString);
                            if (this.props.balance >= this.state.totalBet && this.state.totalBet != NaN) {
                                console.log("betString length  "+this.state.betString.length);
                                let innerMin_Limit = 1;
                                for (let i = 0; i < this.state.betString.length; i++) {

                          
                            if(i<=36){                  
                            innerMin_Limit = this.newLimits[0][0];                  
                            
                            }else if(i >= 37 && i <= 96){
                            innerMin_Limit = this.newLimits[1][0];                      
                            }else if(i >= 97 && i <= 110){
                            innerMin_Limit = this.newLimits[2][0];                      
                            }else if(i >= 111 && i <= 133){
                            innerMin_Limit = this.newLimits[3][0];            
                            }else if(i>=135 && i <= 145){
                            innerMin_Limit = this.newLimits[5][0];
                            }else if(i>=151 && i <= 156){
                            innerMin_Limit = this.newLimits[6][0];
                            }else if(i >= 162 && i <= 167){
                            innerMin_Limit = this.newLimits[7][0];

                            }

                            if(this.state.betString[i].amount > 0){
                            console.log(this.state.betString[i].amount)
                            console.log(innerMin_Limit)
                            if(this.state.betString[i].amount >= innerMin_Limit){
                                console.log("OK")
                                betValid[i] = true;
                            }else{
                                betValid[i] = false;
                                console.log("dont place the bet.")
                            }
                            }else{
                            betValid[i] = true;
                            }



                                    if (this.state.betString[i].id == 145
                                        || this.state.betString[i].id == 156
                                        || this.state.betString[i].id == 133
                                    ) {
                                        this.state.bets += this.state.betString[i].amount + ',';
                                    } else if (this.state.betString[i].id == 134
    
                                        || this.state.betString[i].id == 146
                                        || this.state.betString[i].id == 147
                                        || this.state.betString[i].id == 148
                                        || this.state.betString[i].id == 149
                                        || this.state.betString[i].id == 150
    
                                        || this.state.betString[i].id == 157
                                        || this.state.betString[i].id == 158
                                        || this.state.betString[i].id == 159
                                        || this.state.betString[i].id == 160
                                        || this.state.betString[i].id == 161
                                        ) {
                                        this.state.bets += ' ' + ',';
    
                                    } else if (
                                        this.state.betString[i].id == 37
                                        || this.state.betString[i].id == 97
                                        || this.state.betString[i].id == 111
                                        ) {
                                        this.state.bets = this.state.bets.slice(0, -1);
                                        this.state.bets += ',' + this.state.betString[i].amount + '|';
                                    } else {
                                        this.state.bets += this.state.betString[i].amount + '|';
                                    }
                                }
                                this.state.betstr = this.state.bets.slice(0, -1);


                                let sendBet = true;;
                            for(let u=0; u<betValid.length;u++){
                              if(betValid[u] ==  false){
                                sendBet = false;
                                // console.log("Dont send bet reques");
                                break;
                              }
                            }
                            this.state.betstr = this.state.bets.slice(0, -1);
                            //console.log(this.state.betstr);
                            if(sendBet){
                                this.setState({
                                    disDoubleBtn: 1,
                                    disBetBtn: 1,
                                    disClearBtn: 1,
                                    disZoomBtn: 1,
                                    btnActive:false
                                })
                                this.placebet();
                                if(this.state.zoomButtonValue == 0) {
    
                                    this.setState({wheelZoom: 1.5, zindex: '1000', X_top: "21.8vh", x_img: 1});
                                }
                                console.log(  console.log(this.state.betstr))
                                document.getElementById('rouletteTableCover').style.display = 'block';
                              }else{
                               
                                console.log("Dont Send Bet Request.")
                                const minInrLmt = this.changeAmtlabl(innerMin_Limit) 
                                
                                  this.setState({ 
                                    infoText: this.props.language.InerLmt+minInrLmt ,
                                    disClearBtn: 0,
                                });     
                              
                                           
                              }
                                console.log("this.state.betstr: ");
                                console.log(this.state.betstr);
                              
                                return this.state.betstr;
                            } else {
                                //alert('amount reduces to your balance');
                            }
                        console.log("Info text on Bet Amount: "+this.state.InfoText)
                        }else {
                            if(this.props.infoText != undefined){
                            this.setState({infoText: this.props.infoText});
                            }
                        }
                    }
                }
                break;
            case "TAKE":
               // this.takeSound.play();
                console.log('take amount action');
                var valId = document.getElementById('bet_take');
                // valId.classList.remove('active');

                for(var i=0; i<this.state.betString.length; i++){
                    var valId = document.getElementById('id_'+i);
                    if(valId){
                        // valId.classList.remove('active');
                    }
                    document.getElementById("id_"+i).innerHTML = ``;
                }
                this.takeAmountResult();
                break;
            case "PREV":
                this.betSound.play();
                this.removeImgGlow();
                this.showPrevBet();

                break;
            case "ZoomON":
                this.betSound.play();
                this.setState({zoomButtonText:this.props.language.Zoom_OFF ,zoomButtonValue:1});
                // this.setState({ wheelZoom: 1.7, zoomButtonText: "Wheel Zoom: OFF", zindex: 1000 });
                break;
            case "ZoomOFF":
                this.betSound.play();
                this.setState({zoomButtonText: this.props.language.Zoom_ON,zoomButtonValue:0});
                // this.setState({ wheelZoom: 1, zoomButtonText: "Wheel Zoom: ON" });
                break;
            case "double":
                this.betSound.play();
                this.updateWinsArray(2);
                break;
            case "clear":
                
                if(this.props.gameState.betRequest){
                    this.setState({betButtonText: this.props.language.Prev,betBtnValue:0, btnActive : false, disZoomBtn: 0 ,disBetBtn: 0,});
                }else{
                    this.setState({disBetBtn: 1});
                }
                if(document.getElementById('bet_take')!= null){
                    document.getElementById('bet_take').classList.remove('active');
                }
                if(this.clearSound){
                    console.log(this.clearSound);
                    this.clearSound.play();
                }
                //this.updateWinsArray(0);
                this.clearBetList();
                break;
            case "exit":
                this.gameCloseHandler("target");
                const body = {
                    sessionId: sessionStorage.getItem('sessionId'),
                    CN: "EXIT_GAME",
                    object: {
                        gameId: this.props.gameId,
                        playerId: Number(this.props.playerId),
                    }
                }
                this.props.network.sendof(body);
                this.body ={};
                break;
            case "disableBtns":
                this.removeImgGlow();
                this.setState({
                    disDoubleBtn: 1,
                    disBetBtn: 1,
                    disClearBtn: 1,
                    disZoomBtn: 1,
                })
                if(this.state.totalBet == 0){
                   this.setState({infoText: this.props.language.Play_Njoy})
                }
                break;
            default:
                break;
        }
    }

    exitGame(){
        this.gameCloseHandler("target");
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "EXIT_GAME",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            }
        }
        this.props.network.sendof(body);
        this.body ={};
    }

    removeImgGlow(){
        console.log("removeImgGlow");
        for(var i=0; i<this.state.betString.length; i++){
            var valId = document.getElementById('id_'+i);
            // console.log("Remove Glow Number: "+valId);
            if(valId){
                valId.classList.remove('active');
            }
        }
        this.removeGlow = false;
    }
    PreviousBetMethod() {
        this.updateWinsArray(0);
        var spacesremove = this.props.gameState['betRequest']['betString'].replace(/ /g, "0");
        var preBet = spacesremove.replace(/,/g, '|');
        this.state.previousBetArray.push({
            amount: preBet.split("|",)
        });
        this.setState({ previousBetArray: this.state.previousBetArray });
        if (this.state.previousBetArray) {
            this.toCreatePreviousBetArray();
        }
        console.log(this.state.previousBetArray[0].amount[0]);
        if(this.props.gameState.handId){
            this.handId = this.props.gameState.handId;
        }
    }
    toCreatePreviousBetArray() {
        for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
            this.state.finalPreviousBetArray.push({
                id: i,
                amount: Number(this.state.previousBetArray[0].amount[i])
            });
        }
        this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray });
        //console.log(this.state.finalPreviousBetArray);
        if (this.state.finalPreviousBetArray) {
            this.toGenerteBetString();
        }
    }
    toGenerteBetString() {
        for (let i = 0; i < this.state.betString.length; i++) {
            if (this.state.betString[i].id == this.state.finalPreviousBetArray[i].id) {
                //console.log('ids' + i);
                let value = this.state.betString[i].amount += this.state.finalPreviousBetArray[i].amount;
                if (this.state.finalPreviousBetArray[i].amount != 0) {
                    document.getElementById("id_" + i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${value} </span> </p>`
                }
            }
        }
        this.setState({ betString: this.state.betString, betButtonText:this.props.language.Spin ,betBtnValue:1, previousBetArray: [], finalPreviousBetArray: [],btnActive:true });
        //console.log(this.state.betString);
    }
    takeAmountResult() {
        //console.log("Take Amount Function");
       //console.log("Remove Img Glow: "+this.resultNumber);
        this.setState({
            disDoubleBtn: 1,
            //disBetBtn: 0,
            disClearBtn: 1,
            disZoomBtn: 0,
            glowTakeBtn: 0,
            btnActive : false,
            betButtonText: this.props.language.Prev,
            betBtnValue:0,
            totalBet: 0,
            winAmount:0,
            infoText: this.props.language.Play_Njoy,
            showBetButton: false,
           // Total_Bet_Amount: 0,
        })
        var valId = document.getElementById('bet_take');
        valId.classList.remove('active');
        document.getElementById('rouletteTableCover').style.display = 'none';
        this.setState({infoText: this.props.language.Play_Njoy, showBetButton: false});
        this.updateWinsArray(0);

        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "TAKE_AMOUNT",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            }
        }
        // this.props.network.sendof(body);
        this.body={};

        if(this.props.isPlayerBanned){
            this.gameCloseHandler("target");
            
            this.props.player_banned();
        }

        console.log("gameUnderMaintanance "+this.props.gameUnderMaintanance);
        if(this.props.gameUnderMaintanance){
            this.gameCloseHandler("target");
            this.props.game_maintanance("Game under maintenance, reach to admin.");
        }

        console.log("deviceBlocked "+this.props.isdeviceBlocked);
        if (this.props.isdeviceBlocked){
            this.gameCloseHandler("target");
            
            this.props.device_blocked();
        }
    }
    clearBetList(){
        //console.log("clearBetList");
        for(var i=0; i<this.state.betString.length; i++){
            if(document.getElementById("id_"+i)){
                document.getElementById("id_"+i).innerHTML = ``;
                this.state.betString[i].amount = 0;
            }
        }
        this.setState({Total_Bet_Amount: 0,infoText: this.props.language.Play_Njoy,});
        this.setState({disDoubleBtn: 1, disClearBtn:1});
    }
    updateWinsArray(num) {
        console.info("Update Bet:::::::::::::::::::::: "+ num);
        console.log(this.state.Total_Bet_Amount)
        console.log(this.props.balance)
        console.log(this.state.Total_Bet_Amount*2)
    let totBet = 0;
        if(num == 2){    

            if(this.state.Total_Bet_Amount*2 > this.props.maxBetAmt){
                this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
            }else{
                if(this.state.Total_Bet_Amount*2 <= this.props.balance){
                            let innerMaxLimit = 1
                    for(let k=0; k<this.state.betString.length; k++){

                        if(k<=36){                  
                            innerMaxLimit = this.newLimits[0][1];                  
                        
                        }else if(k >= 37 && k <= 96){
                            innerMaxLimit = this.newLimits[1][1];                      
                        }else if(k >= 97 && k <= 110){
                            innerMaxLimit = this.newLimits[2][1];                      
                        }else if(k >= 111 && k <= 133){
                            innerMaxLimit = this.newLimits[3][1];            
                        }else if(k>=135 && k <= 145){
                            innerMaxLimit = this.newLimits[5][1];
                        }else if(k>=151 && k <= 156){
                            innerMaxLimit = this.newLimits[6][1];
                        }else if(k >= 162 && k <= 167){
                            innerMaxLimit = this.newLimits[7][1];

                        }
        
                        if(this.state.betString[k].amount > 0 && this.state.betString[k].amount <= innerMaxLimit && totBet <= this.props.balance  ){          
                            if(this.state.betString[k].amount * num <= innerMaxLimit){  
        
                                if(this.state.Total_Bet_Amount + this.state.betString[k].amount <= this.props.balance){   
                                    this.state.Total_Bet_Amount = this.state.Total_Bet_Amount + this.state.betString[k].amount;             
                                    this.state.betString[k].amount = this.state.betString[k].amount * num;
                                    
                                    let doubleBetValue = this.state.betString[k].amount;
                                    if(doubleBetValue >= 1000 && doubleBetValue <=999999){
                                    doubleBetValue = doubleBetValue / 1000+"K";
                                    console.log(doubleBetValue);
                                    }else if(doubleBetValue >= 1000000 && doubleBetValue <=999999999){
                                    doubleBetValue = doubleBetValue / 1000+"M";
                                    console.log(doubleBetValue);
                                    }else if(doubleBetValue >999999999){
                                    doubleBetValue = doubleBetValue / 1000+"B";
                                    console.log(doubleBetValue);
                                }    
                                    document.getElementById("id_" + k).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${doubleBetValue} </span> </p>`;
                                }
        
                            }else{
                                let maxBetMount = this.changeAmtlabl(innerMaxLimit)
                              
                                this.setState({infoText: this.props.language.MaxInrBetAmt+" "+maxBetMount});

                            }
                            totBet += this.state.betString[k].amount;
                            this.setState({
                                Total_Bet_Amount: totBet,
                            });
                        }
                    }
        
                    if(totBet <= this.props.balance){
                                           
                        this.setState({
                        betString: this.state.betString,
                        disDoubleBtn: 0,     
                        disClearBtn: 0,
                        disZoomBtn: 0,
                        // infoText:this.props.language.Play_Njoy,
                        });
        
                    }else {
                        this.isReady = false;
                        this.setState({         
                        disDoubleBtn: 1,     
                        disClearBtn: 0,
                        disZoomBtn: 0,
                        infoText: this.props.language.Amount_Reduce  ,
                        });
                    
                    }
                }  else{
                    this.isReady = false;
                    this.setState({         
                    infoText: this.props.language.LowBalance   ,
                    });
        
                }
                
            }
          
          
        }else{
          for(let a=0; a<this.state.betString.length ; a++){
            if(this.state.betString[a].amount > 0 ){
              this.state.betString[a].amount = 0;
              document.getElementById("id_" + a).innerHTML = "";
            }
           // totBet += this.state.betString[a].amount;
          }
    
          this.setState({
            betString: this.state.betString,
            disDoubleBtn: 1,     
            disClearBtn: 1,
           // disZoomBtn: 0,
            infoText: this.props.language.Play_Njoy ,
           // Total_Bet_Amount: totBet,
          });
        }        
    }

    showPrevBet(){
        console.log("Show Previous Bet");
        console.log(this.state.Total_Bet_Amount);
        if(this.props.gameState.betRequest.betAmount <= this.props.balance){  

            if(this.props.gameState.betRequest.betAmount >= this.props.maxBetAmt){
                this.setState({ infoText:this.props.language.PreBet_MaxLmt });
            }else{

                if(this.state.Total_Bet_Amount == 0){
                    let prevBetString=[];
                    //console.log("Prev Bet String Length: ");
                    console.log(this.props.gameState);
    
                    if(this.props.gameState.betRequest.betString){
                        var newStr = this.props.gameState.betRequest.betString.replace(/,/g, "|");
                        console.log("newStr : "+newStr)
                        //var newStr0 = newStr.slice(0, -1);
                        // console.log("newStr0 : "+newStr0)
                        var newStr1 = newStr.replace(/ /g, "0");
                        console.log("newStr1 : "+newStr1);
                        var newStr2 = newStr1.split('|');
                        var totalBetAmt = 0;
                        console.log("newStr2 : "+newStr2);
                        console.log("newStr2 length: "+newStr2.length);
                        for(var i=0; i<newStr2.length; i++){
                            if(parseInt(newStr2[i]) != NaN){
                                totalBetAmt = totalBetAmt+parseInt(newStr2[i]);
                            }
                            prevBetString.push({
                                id: i,
                                amount: parseInt(newStr2[i])
                            });                        
                        }               
                        this.state.betString = prevBetString.slice();                
                    }           
                }
    
                //const totalbal = this.state.nmArray.reduce((total, m) => m.amount + total, 0)
                // console.log("totalbal "+totalbal);
                console.log("Previous Bet Amount: "+totalBetAmt);
                console.log("Player Balance: "+this.props.balance);
                if(totalBetAmt <= this.props.balance){
                    this.setState({
                        Total_Bet_Amount : totalBetAmt
                    })
    
            for(var i=0; i<this.state.betString.length; i++){
                // var value  = this.state.betString[i].amount;
    
                let preBetValue = this.state.betString[i].amount;
            if (preBetValue > 0) {
              //console.log("Bet Value: 488: " + value);
              if(preBetValue >= 1000 && preBetValue <=999999){
                preBetValue = preBetValue / 1000+"K";
                console.log(preBetValue);
              }else if(preBetValue >= 1000000 && preBetValue <=999999999){
                preBetValue = preBetValue / 1000+"M";
                console.log(preBetValue);
              }else if(preBetValue >999999999){
                preBetValue = preBetValue / 1000+"B";
                console.log(preBetValue);
              }
              document.getElementById("id_"+i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${preBetValue} </span> </p>`
            }
    
    
    
                // if(value > 0){
                //     document.getElementById("id_"+i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${preBetValue} </span> </p>`
                // }
            }
            // if(this.state.betButtonText == "PREV"){
            if(this.state.betBtnValue == 0){
    
                var valId = document.getElementById('bet_take');
                valId.classList.remove('active');
                this.setState({
                    showBetButton: true,
                    betButtonText: this.props.language.Spin,
                    betBtnValue:1,
                    btnActive:true
                });
    
                        this.isReady = true;
                    }
                    this.setState({
                        betString: this.state.betString,
                        disClearBtn: 0,
                        disDoubleBtn: 0,
                        disZoomBtn: 0,
                    })
                }

            }
          
        }else{
            this.setState({infoText: this.props.language.Bet_Amt_MaxBal})
        }
    }
    betHandler(amount) {
        console.log(amount);
        this.setState({ betAmount: amount });
    }
    betPanelHandler(no) {
        this.setState({ betButtonText: this.props.language.Spin ,betBtnValue:1,btnActive:true});
        //console.log(this.state.betAmount);
        if (this.state.betAmount !== 0) {
            //console.log(no);
            const x = no;
            switch (true) {
                case (x < 168):
                    for (let i = 0; i < this.state.betString.length; i++) {
                        if (this.state.betString[i].id == no) {
                            // let value = this.state.betString[i].amount += this.state.betAmount;
                            let chipBetValue = this.state.betString[i].amount += this.state.betAmount;;
                            console.log("Bet Value: 699: " + chipBetValue);
                            console.log("Bet Value: 699: " + typeof(chipBetValue));
                            if(Number(chipBetValue) >= 1000 && Number(chipBetValue) <=999999){
                              chipBetValue = chipBetValue / 1000+"K";
                              console.log(chipBetValue);
                            }else if(chipBetValue >= 1000000 && chipBetValue <=999999999){
                              chipBetValue = chipBetValue / 1000+"M";
                              console.log(chipBetValue);
                            }else if(chipBetValue >999999999){
                              chipBetValue = chipBetValue / 1000+"B";
                              console.log(chipBetValue);
                            }
                            document.getElementById("id_" + no).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${chipBetValue} </span> </p>`
                        }
                    }
                    this.setState({ betString: this.state.betString });
                    //console.log(this.state.betString);
                    break;
                default:
                    //alert("none");
                    break;
            }
        }
    }
    placebet() {
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "EUROPEAN_ROULETTE_BET",
            object: {
                betString: this.state.betstr,
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId
            }
        }
        this.props.network.sendof(body);
        this.body={};
        let betAmountLabel;
        if(this.state.Total_Bet_Amount){
            betAmountLabel = this.changeAmtlabl(this.state.Total_Bet_Amount)
        }
        // this.setState({infoText: this.props.language.BetAccepted +this.state.Total_Bet_Amount});
        this.setState({infoText: this.props.language.BetAccepted +" "+betAmountLabel});


        //this.getPlayerInfo();
    }

    getPlayerInfo() {
        const body2 = {
            CN: "GET_PLAYER_INFO",
            object: {
            gameId: Number(this.state.gameId),
            playerId: Number(this.props.playerId),
            },
            sessionId: sessionStorage.getItem('sessionId'),
        }
        this.props.network.sendof(body2);
        this.body2 ={};
    }

    europeanRoulleteGetwheelresp() {
        console.log(this.props.targetResult.resultNum + " European Roullete Result winAmount: "+this.props.targetResult.winAmount);
        //this.setState({winAmount: this.props.targetResult.winAmount});
        if (this.props.targetResult) {
            this.resultNumber = this.props.targetResult.resultNum;
            // if(this.state.zoomButtonText == "Wheel Zoom: ON"){
            if(this.state.zoomButtonValue == 0){
                this.setState({wheelZoom: 1.5, zindex: '1000', X_top: "21.8vh", x_img:1 });               
            }
          
            console.log("---> "+this.resultNumber)
            if(this.resultNumber){
                this.artWheelRef.current.spinTheWheel(this.resultNumber); 
            }
        }
        this.wheelSound.play()
    }

    glowImage(){
        console.log('glowImage');
        console.log("Glow Win Image: "+ this.resultNumber);
        this.setState({winAmount: this.props.targetResult.winAmount});
  
        if(document.getElementById('id_'+Number(this.resultNumber))!= null){
            document.getElementById('id_'+Number(this.resultNumber)).classList.add('active');
        }

        this.declareWin(this.props.targetResult.winAmount);        
        if(this.props.targetResult.winAmount > 0){
            document.getElementById('winPop').style.display = 'block';
            this.getPlayerInfo();
            setTimeout(() =>{
                document.getElementById('winPop').style.display = 'none';
                for(var i=0; i<this.state.betString.length; i++){
                    document.getElementById("id_"+i).innerHTML = ``;            
                    this.state.betString[i].amount = 0;
                }
            },2000)
            if(this.winSound){
                this.winSound.play();
            }
        }else{
            if(this.duploseSound){
                this.duploseSound.play();
                for(var i=0; i<this.state.betString.length; i++){
                    document.getElementById("id_"+i).innerHTML = ``;            
                    this.state.betString[i].amount = 0;
                }
                this.getPlayerInfo();
                setTimeout(() =>{
                    // this.state.Total_Bet_Amount = 0;
                    // this.setState({Total_Bet_Amount: 0});
                    document.getElementById('rouletteTableCover').style.display = 'none';
                },1000)
            }
        }
        this.setState({wheelZoom: 1, zindex: '0', X_top: "14.5vh", });
    }
    EuropeanRouletteTimerGetTime(){
        //console.log(this.props.timesend);
        if (this.props.timesend) {
            //console.log(this.props.timesend);
            this.EuropeanTimerInSeconds.current.sendTimer(this.props.timesend);
        }
    }
    chipVal(val){
        if(this.takeSound){
            this.takeSound.play();
        }
        this.state.selectedChipVal = val;
        console.log( "check ::" + this.state.selectedChipVal );
        this.setState({
            selectedChipVal: val,
            //infoText: `Your Bet Amount Is: ${val} & You Are Ready To Go`
        });
    }
    benohandle(id){
        this.betSound1 = new Audio(betSound)
        this.betSound1.play();
        this.removeImgGlow();
        console.info("Selected Chip Value: "+this.state.selectedChipVal);
        console.info("Selected ID Value: "+ id);
        if(this.state.selectedChipVal == 0){
            this.state.betString[id].amount = 0;
            document.getElementById("id_"+id).innerHTML = ``
        }
        // console.log("this.state.betString: ")
        // console.info(this.state.betString);
        // console.log("Length: "+this.state.betString.length)
        for(var i =0; i<this.state.betString.length; i++){
            if(this.state.betString[i].amount == "NaN" || this.state.betString[i].amount == NaN) {
               this.state.betString[i].amount = 0;
            }
            //  console.log("--------- "+this.state.betString[i].amount);
        }

        const totalbal = this.state.betString.reduce((total, m) => m.amount + total, parseInt(this.state.selectedChipVal))
       // const totalbal = this.state.nmArray.reduce((total, m) => m.amount + total, parseInt(this.state.selectedChipVal))
        console.log("New Bet Amount "+totalbal);
        if(totalbal > this.props.maxBetAmt){
            // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
            this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
          }else if(totalbal <= this.props.balance ){

            if (this.state.betString[id] != null && this.state.betString[id].amount != null) {

                let innerLimit = 1;
                try{
                    if(id<=36){                  
                        innerLimit = this.newLimits[0][1];                  
                        }else if(id >= 37 && id <= 96){
                            innerLimit = this.newLimits[1][1];                      
                        }else if(id >= 97 && id <= 110){
                            innerLimit = this.newLimits[2][1];                      
                        }else if(id >= 111 && id <= 133){
                            innerLimit = this.newLimits[3][1];            
                        }else if(id>=135 && id <= 145){
                            innerLimit = this.newLimits[5][1];
                        }else if(id>=151 && id <= 156){
                            innerLimit = this.newLimits[6][1];
                        }else if(id >= 162 && id <= 167){
                            innerLimit = this.newLimits[7][1];

                        }
                        //console.log("FST Inner Limit:  "+innerLimit)
          
                }catch (err)
                {
                  console.log("error while trying to fetch inner limits: ArayOutOfBoundsException "+ err.message);
                }
                //console.log("FST Inner Limit:  "+innerLimit)


                if((this.state.betString[id].amount+parseInt(this.state.selectedChipVal)) <= innerLimit){
                    this.isReady = true;
                    this.setState({
                        Total_Bet_Amount : totalbal,
    
                    })
                    console.log("Total Bet Value: "+this.state.Total_Bet_Amount);
                    for(var i=0; i<this.state.betString.length; i++){
                        if(this.state.betString[i].id == id){
                            if(this.state.selectedChipVal == 0){
                                var value  = this.state.betString[i].amount *= parseInt(this.state.selectedChipVal);
                                document.getElementById("id_"+i).innerHTML = ``
                            }else{
                                // var value  = this.state.betString[i].amount += parseInt(this.state.selectedChipVal);
    
                                let chipBetValue = this.state.betString[i].amount += parseInt(this.state.selectedChipVal);
                                // console.log("Bet Value: 699: " + chipBetValue);
                                // console.log("Bet Value: 699: " + typeof(chipBetValue));
                                if(Number(chipBetValue) >= 1000 && Number(chipBetValue) <=999999){
                                  chipBetValue = chipBetValue / 1000+"K";
                                  console.log(chipBetValue);
                                }else if(chipBetValue >= 1000000 && chipBetValue <=999999999){
                                  chipBetValue = chipBetValue / 1000+"M";
                                  console.log(chipBetValue);
                                }else if(chipBetValue >999999999){
                                  chipBetValue = chipBetValue / 1000+"B";
                                  console.log(chipBetValue);
                                }

                                this.setState({
                                    disClearBtn: 0,
                                    disBetBtn: 0,
                                    disDoubleBtn: 0,
                                });

                                document.getElementById("id_"+i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${chipBetValue} </span> </p>`

                                
                            }
                        }
    
                        if( this.removeGlow == true){
                            //document.getElementById('id_'+i).classList.remove('active');
                            var valId = document.getElementById('id_'+i);
                            if(valId){
                                valId.classList.remove('active');
                            }
                        }
                    }
    
                    var TotalBetVaue = this.state.betString.reduce((prev, cur) => prev + cur.amount, 0);
                    // console.log("Total Bet Amount: "+TotalBetVaue );
                    if(TotalBetVaue == 0){
                        this.setState({disDoubleBtn: 1, disClearBtn: 1, disZoomBtn:0});
                        if(this.props.gameState.betRequest){
                            this.setState({betButtonText: this.props.language.Prev,betBtnValue:0,btnActive:false});
                        }else{
                            this.setState({disBetBtn: 1});
                        }
                    }else{
                        this.setState({disDoubleBtn: 0, disClearBtn: 0, showBetButton: true});
                        // if(this.state.betButtonText == "PREV"){
                        if(this.state.betBtnValue == 0){
    
                            this.setState({
                                showBetButton: true,
                                betButtonText: this.props.language.Spin,
                                btnActive:true,
                                betBtnValue:1
                            });
                            var valId = document.getElementById('bet_take');
                            valId.classList.remove('active');
                        }
                    }
                }else{
    
                    let maxBetMount = this.changeAmtlabl(innerLimit)
                    this.setState({infoText: this.props.language.MaxInrBetAmt+""+maxBetMount}) 
                   
                     console.log(this.state.infoText)
                  }
                if(this.state.selectedChipVal == 0 && this.state.Total_Bet_Amount ==0){
                    this.setState({infoText:this.props.language.MinBetAmt + " " + this.props.minBetLbl})
                    setTimeout(()=>{
                        this.setState({ infoText:  this.props.language.Play_Njoy });
                      },1500)
                }
                // else{
                //     this.setState({ infoText:  this.props.language.Play_Njoy });
                // }

            }


            
        }else{
           this.setState({infoText: this.props.language.Amount_Reduce})
            console.log("amount reduces to your balance")
            //alert('amount reduces to your balance');
        }
        this.removeGlow = false;
       
        this.setState({
            betString: this.state.betString
        })
    }


    loadBg(){
        this.LoadImg = new window.Image()
        this.LoadImg.src = artBg; 
        this.LoadImg.addEventListener('load', this.eurpRltLoded)
    }

    eurpRltLoded = () => {
        if(!this.isloadImg){
            this.isloadImg = true; 
        } else{ 
            this.setState({
                eurpRlt:false
            })
        }
    }
    updatePlayerInfo(){
        if(this.props.targetResult.winAmount > 0){
            this.setState({ Total_Bet_Amount: 0})
            // document.getElementById('winPop').style.display = 'block';
            setTimeout(() => {
                document.getElementById('winPop').style.display = 'none'
                if(this.updatePlayerBalnce == true){
                    this.setState({
                        disZoomBtn: 0,
                        disBetBtn: 0,
                        btnActive : false,
                        betButtonText: this.props.language.Prev, 
                        betBtnValue:0,
                       
                    });                    
                    document.getElementById('rouletteTableCover').style.display = 'none';
                }
            }, 2500);
        }else{
            if(this.updatePlayerBalnce == true){
                this.setState({
                    disZoomBtn: 0,
                    disBetBtn: 0,
                    btnActive : false,
                    betButtonText: this.props.language.Prev, 
                    Total_Bet_Amount: 0,
                    betBtnValue:0
                });                
                //document.getElementById('rouletteTableCover').style.display = 'none';
            }else{
                if(this.props.balance > 0){
                    document.getElementById('rouletteTableCover').style.display = 'none';
                    this.setState({disBetBtn: 0, disZoomBtn: 0 })
                }

                if(this.props.gameState.history && this.props.gameState.history.length == 0){
                    this.setState({disBetBtn: 1});
                }
            }
        }
    }
    
 
    render() {
        let winAmount = this.changeAmtlabl(this.props.targetResult.winAmount)
        return (
            <React.Fragment> 
                <div className="gameBox" id="gameBox">
                <div className="gamePage"
                    style={{
                        backgroundImage: `url(${this.LoadImg.src})`
                    }}
                >
            <div className="FGAEuropeanRoulette">
                <div className="american_ht_1 fd p_30">
                    <div className="fd">
                    <div className="row">
                        <div className="fd">
                            <div className="wheelandhis">
                                <div className="row">
                                    <div className="indEuroulette_chips">
                                        <BetChips
                                            er_chipValues = {this.props.er_chipValues}
                                            childRef = {ref =>(this.BetChips = ref)}
                                            bet={this.betHandler.bind(this)}
                                            winAmount={this.state.winAmount}
                                            balance={this.props.balance}
                                            TotalBetAmouont={this.state.Total_Bet_Amount}
                                            totalBet={this.state.betString}
                                            betAmount={this.chipVal.bind(this)}
                                            disDoubleBtn ={this.state.disDoubleBtn}
                                            disClearBtn = {this.state.disClearBtn}
                                            action={this.rightButtonPanelHandler.bind(this)}/>
                                    </div>
                                    <div className="fun_routlet_table">
                                        <div className="fd">
                                            <Title className="fd"></Title>
                                            <div className="fd" style={{height:'40vh'}}>
                                                <Wheel
                                                    gameState={this.props.gameState}
                                                    ref={this.artWheelRef}
                                                    zindex={this.state.zindex}
                                                    zoom={this.state.wheelZoom}
                                                    height={this.wheelHeight}
                                                    width={this.wheelWidth}
                                                    eurpRltLoded={this.eurpRltLoded.bind(this)}
                                                    action={this.wheelHandler.bind(this)}
                                                    isMobile={this.props.isMobile}
                                                    onFocus={this.props.onFocus}>                                                        
                                                </Wheel>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="fun_btns">
                                        <RightButtonPanel
                                            
                                            childRef={ref => (this.RightButtonPanel = ref)}
                                            user={this.props.user}
                                            betBtnText={this.state.betButtonText}
                                            betBtnValue = {this.state.betBtnValue}
                                            gameState={this.props.gameState}
                                            targetResult={this.props.targetResult}
                                            zoomButtonText={this.state.zoomButtonText}
                                            zoomButtonValue={this.state.zoomButtonValue}
                                            betBtn={this.state.showBetButton}
                                            action={this.rightButtonPanelHandler.bind(this)}
                                            stop={this.state.stop}                                            
                                            disBetBtn ={this.state.disBetBtn}
                                            disZoomBtn = {this.state.disZoomBtn}  
                                            disDoubleBtn = {this.state.disDoubleBtn}                                     
                                            disClearBtn = {this.state.disClearBtn}                                          
                                            glowTakeBtn = {this.state.glowTakeBtn} 
                                            network={this.props.network}
                                            btnActive={this.state.btnActive}
                                            gameCloseHandler={this.gameCloseHandler.bind(this)}
                                            gameEntry={this.props.gameEntry}
                                            ref = {this.RightButtonPanel}
                                            >
                                        </RightButtonPanel>

                                        {/* <Close action={this.gameCloseHandler.bind(this)}></Close> */}
                                        {/* <Close action={this.rightButtonPanelHandler.bind(this)}></Close> */}

                                        <div id="rouletteTableCover" className="rouletteTableCover"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="table_ernt">
                                <div className="fd">
                                    <Table 
                                        action={this.benohandle.bind(this)}
                                        infoText = {this.state.infoText}
                                        className="fd">
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                <div className="american_ins"> 
                    <div className="fd">
                        <div className="row">
                            <div className="col-3 col-sm-2">
                                <div className="eur_userLbl">
                                 {this.props.user} </div> 
                            </div>
                            <div className="col-6 col-sm-8">
                                <div className="ins"> {this.state.infoText} </div>
                            </div> 
                            <div className="col-3 col-sm-2">
                                <div className="eur_userLbl"> 
                             {this.props.language.Player_Id} {this.props.playerId}  <br></br> 
                              {this.props.language.Hand_Id} {this.props.gameState.handId}
                                </div> 
                            </div>
                        </div>
                    </div> 
                </div>
                </div>
            </div>
            </div>
            </div>
            <div className="winPopEurpRlt" id="winPop" style={{zIndex: "2000"}}>  
                <div className="popBorder">
                 {this.props.language.Win_Amt}  &nbsp; 
                    <span> {winAmount} </span> 
                </div>
            </div>
            </React.Fragment>        
        );
    }
}

const mapStatesToProps = (state) =>{
    return{
        language:state.languageObjs.languageObj
    }
}

export default connect(mapStatesToProps)(EuropeanRoulette)



