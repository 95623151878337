import React from "react";
import card_items from "./cards";
import resultCards from "./resultCards";
import chips from "../../../empty_chips";
import { connect } from "react-redux";
import cardspannel from "../../../../../../assets/games/andarBaharTimer/cardspannel.png"
import pannel from "../../../../../../assets/games/andarBaharTimer/ab_pannel.png"
import line from "../../../../../../assets/games/andarBaharTimer/lineandarbahar.png"
import bottomline from "../../../../../../assets/games/andarBaharTimer/bottomline.png"
import heart from "../../../../../../assets/games/andarBaharTimer/ab_heart.png"
import spade from "../../../../../../assets/games/andarBaharTimer/ab_spade.png"
import club from "../../../../../../assets/games/andarBaharTimer/ab_club.png"
import dimond from "../../../../../../assets/games/andarBaharTimer/ab_dimond.png"
import subttleandar from "../../../../../../assets/games/andarBaharTimer/andarsubtitle.png"
import subttlebahar from "../../../../../../assets/games/andarBaharTimer/baharsub.png"
import andarBet from "../../../../../../assets/games/andarBaharTimer/andar_bet.png";
import baharBet from "../../../../../../assets/games/andarBaharTimer/bahar_bet.png";
import numBet1 from "../../../../../../assets/games/andarBaharTimer/ato6.png";
import numBet2 from "../../../../../../assets/games/andarBaharTimer/eighttok.png";
import bet_button from "../../../../../../assets/games/andarBaharTimer/ab_betBtn.png";
import glow_button from "../../../../../../assets/games/andarBaharTimer/ab_glow_btn.png";
import take_button from "../../../../../../assets/games/andarBaharTimer/ab_takeBtn.png";
import totalbet from "../../../../../../assets/games/andarBaharTimer/ab_totalbet.png";
// import ab_exitBtn from "../../../../../../assets/games/andarBaharTimer/ab_exitBtn.png";
import ab_glow from "../../../../../../assets/games/andarBaharTimer/ab_glow.png";
import one_sixGlowImg from "../../../../../../assets/games/andarBaharTimer/one_sixGlowImg.png";
import suitGlowImg from "../../../../../../assets/games/andarBaharTimer/suitGlowImg.png";
import baharHist from "../../../../../../assets/games/andarBaharTimer/baharHist.png";
import andarHist from "../../../../../../assets/games/andarBaharTimer/andarHist.png";
import cardGlow from "../../../../../../assets/games/andarBaharTimer/cardGlow.png";
import glowImg_a6 from "../../../../../../assets/games/andarBaharTimer/glow_a6.png";
import glowImg_8k from "../../../../../../assets/games/andarBaharTimer/glow_a6.png";
import abBgGlow from "../../../../../../assets/games/andarBaharTimer/abBgGlow.png";

import clearSound from "../../../../../../assets/sounds/clear.mp3";
import betSound from "../../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../../assets/sounds/take.mp3";
import cardFallSound from "../../../../../../assets/sounds/slide.mp3";
import betoncard from "../../../../../../assets/sounds/betoncard.mp3";


class ab_ChipsDealerPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.chipVal = [
      { label: "1", val: 1 },
      { label: "10", val: 10 },
      { label: "25", val: 25 },
      { label: "50", val: 50 },
      { label: "100", val: 100 },
      { label: "500", val: 500 },
      { label: "1k", val: 1000 },
      { label: "5k", val: 5000 },
      { label: "", val: 0 }
    ];

    this.state = {
      chipSelect: 1,
      index: -1,
      currentCard: 0,
      AndarCards: [],
      BaharCards: [],
      getRes: "",
      betResults: [],
      card_hover: true,
      andar_hover: false,
      bahar_hover: false,
      ato6_hover: false,
      hover_8tok: false,
      heart_hover: false,
      spade_hover: false,
      club_hover: false,
      dimond_hover: false,
      andarBg_0: false,
      andarBg_1: false,
      andarBg_2: false,
      andarBg_3: false,
      andarBgGlow: false,
      baharBgGlow: false,
    };

    this.audio = null;
    this.audioD = null;
    this.cardsInt = "";
    this.startInt = null;
    this.updateHistoryData = [];
    this.winnerNameObj = [];
   
 


    this.clearSound = new Audio(clearSound);
    this.takeSound = new Audio(takeSound);

    this.betSound = new Audio(betSound);
  }
  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);

    this.ab_ch_timeout2 = null;
    this.ab_ch_timeout3 = null;
    this.ab_ch_timeout4 = null;
    this.ab_ch_timeout5 = null;
    this.ab_ch_timeout6 = null;
    this.ab_ch_timeout7 = null;

    this.myCardAnim = null;
    this.myDlr = null;
    this.startInt = null;
    this.startIntCard = null;

    this.preloadImgs();
    this.preloadCards();

    this.clearSound = new Audio(clearSound);
    this.takeSound = new Audio(takeSound);

    this.betSound = new Audio(betSound);

    if (this.props.abt_chipValues) {
      let newChips = this.props.abt_chipValues.split(",");
      console.log(newChips);
      console.log(newChips.length)
      let lblTxt = "";

      for (let i = 0; i < newChips.length; i++) {
        if (Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999) {
          lblTxt = "K"
        } else if (Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999) {
          lblTxt = "M"
        } else if (Number(newChips[i]) > 999999999) {
          lblTxt = "B"
        }
        console.log(newChips[i])
      
        this.chipVal[i].val = newChips[i]
        this.chipVal[i].label = newChips[i]
       
        this.chipVal[i].label=this.changeAmtlabl(this.chipVal[i].val)
        
    
      
        // this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);            
      }


      // this.setState({ chipSelect: Number(this.chipVal[0].val) });
      // this.state.chipSelect = Number(this.chipVal[0].val);
      // console.log(this.chipVal[0].val);
      // console.log(this.state.chipSelect);
    }

    //this.cardanimation();
    this.startVal = 0;

    // document.getElementById("timeSpan").style.display = "none";

    this.audio = new Audio(cardFallSound);
    this.audioD = new Audio(cardFallSound);

    if (this.props.gameState.takeAmount > 0 && this.props.gameState && this.props.gameState.response) {
      let andarCard = this.props.gameState.response.andharCards[this.props.gameState.response.andharCards.length - 1];
      let baharCard = this.props.gameState.response.baharCards[this.props.gameState.response.baharCards.length - 1];

      for (let k = 0; k < resultCards.length; k++) {
        if (resultCards[k].rsc_val === this.props.gameState.response.joker) {
          console.log(resultCards[k].src);
          document.getElementById("resJokerCard").src = resultCards[k].src;
          document.getElementById("resJokerCard").style.display = "block";
          document.getElementById("resJokerCard").style.opacity = 1;
        }
        if (resultCards[k].rsc_val === andarCard) {
          console.log(resultCards[k].src);
          // document.getElementById("res_andar_Card").src = resultCards[k].src;

          document.getElementById('res_andar_Card').style.display = "block";
          let img = document.createElement('img');
          img.classList.add("ab_baharBox_load");
          img.src = resultCards[k].src;
          document.getElementById('res_andar_Card').appendChild(img);

        }
        if (resultCards[k].rsc_val === baharCard) {
          console.log(resultCards[k].src);
          // document.getElementById("res_bahar_Card").src = resultCards[k].src;

          document.getElementById('res_bahar_Card').style.display = "block";
          let img = document.createElement('img');
          img.classList.add("ab_baharBox_load");
          img.src = resultCards[k].src;
          document.getElementById('res_bahar_Card').appendChild(img);

        }

      }

      document.getElementById("resJokerCard").style.opacity = 1;
    }

    // document.getElementById("resJokerCard").src = joker_card_anim;
    // document.getElementById("resJokerCard").style.opacity = 1;


    // const animatecardsDiv = document.getElementById("animatecards");
    // animatecardsDiv.innerHTML = "";
    // card_items.map((cards, i) => {
    //   const createimage = document.createElement("img");
    //   createimage.src = cards.src;
    //   createimage.id = "animCard_" + i;
    //   createimage.style.opacity = 0.02;
    //   createimage.classList.add("jokerCard");
    //   animatecardsDiv.appendChild(createimage)
    // })


    // document.getElementById("res_andar_Card").classList.add("ab_andarBox");
  }
  changeAmtlabl(chngAmt){
    let newAmt = chngAmt;
    if(newAmt >= 1000 && newAmt <=999999){
      newAmt = newAmt / 1000+"K";      
    }else if(newAmt >= 1000000 && newAmt <=999999999){
      newAmt = newAmt / 1000+"M";    
    }else if(newAmt >999999999){
      newAmt = newAmt / 1000+"B";      
    }
    return newAmt;
}
  checkChipValue(chipValue, chpTxt, i) {
    if (chipValue >= 1000) {
      chipValue = chipValue / 1000;
      this.checkChipValue(chipValue, chpTxt, i)
    } else {
      console.log(chpTxt);
      this.showResultChipValue(chipValue, chpTxt, i);
      return chipValue
    }
  }

  showResultChipValue(chVal, chipTxt, index) {
    console.log(chVal);
    console.log(chVal + "" + chipTxt)
    this.chipVal[index].label = chVal + "" + chipTxt;
    console.log(this.chipVal)
  }

  componentWillUnmount() {
    this.audio = null;
    this.audioD = null;
    this.setState({
      AndarCards: [],
      BaharCards: [],
    })

    clearInterval(this.myCardAnim);
    clearInterval(this.myDlr);
    clearInterval(this.startInt);
    clearInterval(this.startIntCard);

    window.clearTimeout(this.ab_ch_timeout2);
    window.clearTimeout(this.ab_ch_timeout3);
    window.clearTimeout(this.ab_ch_timeout4);
    window.clearTimeout(this.ab_ch_timeout5);
    window.clearTimeout(this.ab_ch_timeout6);
    window.clearTimeout(this.ab_ch_timeout7);
  }

  disableBetOptions() {
    this.removeWinGlow();

    clearInterval(this.startIntCard);
    clearInterval(this.myCardAnim);
    clearInterval(this.myDlr);
    clearInterval(this.startInt);

    window.clearTimeout(this.ab_ch_timeout2);
    window.clearTimeout(this.ab_ch_timeout3);
    window.clearTimeout(this.ab_ch_timeout4);
    window.clearTimeout(this.ab_ch_timeout5);
    window.clearTimeout(this.ab_ch_timeout6);
    window.clearTimeout(this.ab_ch_timeout7);
  }

  preloadCards() {

    for (let n = 0; n < 13; n++) {
      let card_img = new Image();
      card_img.src = card_items[n].src;
      // console.log(card_img.src);      
    }

    for (let z = 0; z < 52; z++) {
      let rescard_img = new Image();
      rescard_img.src = resultCards[z].src;
      // console.log(rescard_img.src);      
    }
  }

  preloadImgs() {
    let loadedChips = 0;
    // Preload each image
    for (let n = 1; n <= 8; n++) {
      let chip_img = new Image();
      if (loadedChips !== 8) {
        loadedChips++;
        chip_img.onload = loadedChips;
        chip_img.onerror = loadedChips;
        const chipImageName = `chips_${n}`;
        chip_img.src = chipImageName;
        console.log(chip_img.src);
      }
    }

    let imgsLoad = [cardspannel, pannel, line, bottomline,
      heart, spade, club, dimond,
      subttleandar, subttlebahar, andarBet,
      baharBet, numBet1, numBet2, bet_button, glow_button, take_button,
      totalbet
    ]
    for (let t = 0; t < imgsLoad.length; t++) {
      let spark_img = new Image();
      const sparkImageName = imgsLoad[t];
      // console.log(sparkImageName);
      spark_img.src = sparkImageName;
      // console.log(spark_img.src);      
    }
  }
 

  showCardAnim(resCard) {
 
    console.log(this.startVal);
    console.log(card_items[this.startVal].src);
    // 
    document.getElementById("animCard_" + this.startVal).style.opacity = 1;
    document.getElementById("animCard_" + this.startVal).classList.add("animateimages" + this.startVal);
    // console.log(createimage);
    // animatecardsDiv.appendChild(createimage)
    if (this.startVal < 12) {
      console.log("   --------------------  " + this.startVal);
      this.startVal++
    } else {
      console.log("   ***************  " + this.startVal);
      clearInterval(this.myCardAnim);
      this.startVal = 0;
      //document.getElementById("animatecards").innerHTML = "";
      console.log(resCard);
      let original = resCard;
      let reversedString = [...original].reverse().join('');
      console.log(reversedString);

      document.getElementById("jokerCard").src = resultCards[reversedString].src;

      // result.joker
    }
  }

  updateResult = (result) => {
    console.log(result);

    for (let k = 0; k < resultCards.length; k++) {
      if (resultCards[k].rsc_val === result.joker && document.getElementById("resJokerCard")) {
        // console.log(resultCards[k].src);
        document.getElementById("resJokerCard").src = resultCards[k].src;
        document.getElementById("resJokerCard").style.opacity = 1;
        document.getElementById("resJokerCard").style.display = "block";
      }
    }

    this.ab_ch_timeout2 = setTimeout(() => {
      this.myCardAnim = setInterval(() => {
      }, 150);
      this.dealerCards(result);
    }, 100)

    console.log(result.joker);
  }

  dealerCards = (result) => {
    console.log("Deal AB Cards");

    this.audio = new Audio(cardFallSound);
    this.audioD = new Audio(cardFallSound);

    this.setState({
      AndarCards: [],
      BaharCards: [],
    })

    this.setState({
      AndarCards: result.andharCards,
      BaharCards: result.baharCards,
    });
    console.log(this.state.AndarCards);
    console.log(this.state.BaharCards);

    /****************DEALER CARDS SHOWING AFTER DEAL START ******************/

    this.ab_ch_timeout3 = setTimeout(() => {

      // document.getElementById("resJokerCard").src = "";
      for (let k = 0; k < resultCards.length; k++) {
        if (resultCards[k].rsc_val === result.joker && document.getElementById("resJokerCard")) {
          // console.log(resultCards[k].src);
          document.getElementById("resJokerCard").src = resultCards[k].src;
          document.getElementById("resJokerCard").style.opacity = 1;
          document.getElementById("resJokerCard").style.display = "block";
        }
      }

      let andarArray = [];
      for (let v = 0; v < this.state.AndarCards.length; v++) {
        for (let b = 0; b < resultCards.length; b++) {
          if (resultCards[b].rsc_val === this.state.AndarCards[v]) {
            andarArray.push(resultCards[b].src);
          }
        }
      }

      let baharArray = [];
      for (let pl = 0; pl < this.state.BaharCards.length; pl++) {
        for (let rs = 0; rs < resultCards.length; rs++) {
          if (resultCards[rs].rsc_val === this.state.BaharCards[pl]) {
            baharArray.push(resultCards[rs].src);
          }
        }
      }

      let totalCardsTime = andarArray.length + baharArray.length;

      let pCount = 0;
      let dCount = 0;
      const myLoop = (dPlus) => {

        if (dPlus % 2 == 0) {
          if (this.audioD) {
            this.audioD.currentTime = 0;
            this.audioD.play()
          }
          document.getElementById('res_andar_Card').style.display = "block";
          let img = document.createElement('img');
          img.classList.add("ab_andarBox");
          img.src = andarArray[dCount];
          document.getElementById('res_andar_Card').appendChild(img);

          dCount++;
        } else {
          if (this.audio) {
            this.audio.currentTime = 0;
            this.audio.play()
          }
          document.getElementById('res_bahar_Card').style.display = "block";
          let img2 = document.createElement('img');
          img2.classList.add("ab_baharBox");
          img2.src = baharArray[pCount];
          document.getElementById('res_bahar_Card').appendChild(img2);
          pCount++;
        }
      }

      let i = 0;
      const countDlr = () => {
        let dPlus = i++;
        if (dPlus < totalCardsTime) {
          myLoop(dPlus);
        }
      }

      this.myDlr = setInterval(function () {
        countDlr()
      }, 500);

      console.log("totalCardsTime " + totalCardsTime)

      if (totalCardsTime == 1) {
        totalCardsTime = 1.5;
      }

      let waittime = 600;

      if(totalCardsTime > 10){
        waittime = 550
      }

      this.ab_ch_timeout4 = setTimeout(() => {
        clearInterval(this.myDlr);
        console.log("---------------------------")
        console.log("Declare Result");

        this.props.declare_Result();
      }, (totalCardsTime * waittime));

    }, 150);
  };

  sideBets(betId) {
    console.log(this.state.chipSelect);
    console.log(betId);
    if(this.props.totalBetAmount == 0){
      this.removeWinGlow();
    }

    this.props.enableKeyboard(false);
    clearInterval(this.startInt);
    if (document.getElementById(betId)) {
      document.getElementById(betId).style.transform = "scale(0.9)";
    }
    this.props.selecteSuit(betId);
    this.betoncard = new Audio(betoncard)
    this.betoncard.play();
    this.startInt = setInterval(() => {
      this.betoncard2 = new Audio(betoncard)
      this.betoncard2.play();
      this.props.selecteSuit(betId);
    }, 120);
  }


  mouseDownSelectCard(data) {
    // this.cardanimation();
    if(this.props.totalBetAmount == 0){
      this.removeWinGlow();
    }

    clearInterval(this.startIntCard);
    document.getElementById("card_" + data).style.transform = "scale(0.9)";
    this.props.selectedCard(data)
    this.props.enableKeyboard(false);
    this.betoncard = new Audio(betoncard)
    this.betoncard.play();
    this.startIntCard = setInterval(() => {
      this.betoncard2 = new Audio(betoncard)
      this.betoncard2.play();
      this.props.selectedCard(data)
    }, 120);
  }
  mouseUpStopCards(cardNum) {
    clearInterval(this.startIntCard);
    this.ab_ch_timeout5 = setTimeout(() => {
      this.props.enableKeyboard(true);
      if (document.getElementById("card_" + cardNum)) {
        document.getElementById("card_" + cardNum).style.transform = "scale(1)";
      }
    }, 100);
  }

  mouseUpSideBets(betId) {
    clearInterval(this.startInt);
    this.setState({ onHover: false });

    switch (betId) {
      case "ander_betImg":
        if(!document.getElementById("andar_glow").classList.contains("andarbetGlowAnim") ){

        this.setState({ andar_hover: false });
        document.getElementById("andar_glow").style.display = "none";
        }
        break;
      case "bahar_bet":
        if(!document.getElementById("bahar_glow").classList.contains("andarbetGlowAnim") ){

        this.setState({ bahar_hover: false });
        document.getElementById("bahar_glow").style.display = "none";
        }
        break;
      case "one_sixImg":
        if(!document.getElementById("glow_a6").classList.contains("andarbetGlowAnim") ){

        this.setState({ ato6_hover: false });
        document.getElementById("glow_a6").style.display = "none";
        }
        break;
      case "glow_8k":
        if(!document.getElementById("glow_8k").classList.contains("andarbetGlowAnim") ){

        document.getElementById("glow_8k").style.display = "none";
        this.setState({ hover_8tok: false });
        }
        break;
      case "heart_suit":
        if(!document.getElementById("heart_glow").classList.contains("andarbetGlowAnim") ){

        document.getElementById("heart_glow").style.display = "none";          
        this.setState({ heart_hover: false });
        }
        break;
      case "spade_suit":
        if(!document.getElementById("spade_glow").classList.contains("andarbetGlowAnim") ){
        document.getElementById("spade_glow").style.display = "none";          
        this.setState({ spade_hover: false });
        }
        break;
      case "club_suit":
        if(!document.getElementById("club_glow").classList.contains("andarbetGlowAnim") ){
        this.setState({ club_hover: false });
        document.getElementById("club_glow").style.display = "none";
        }
        break;
      case "dimond_suit":
        if(!document.getElementById("dimond_glow").classList.contains("andarbetGlowAnim") ){
          this.setState({ dimond_hover: false });
          document.getElementById("dimond_glow").style.display = "none";
        }
        break;
      default:
        break;
    }
    this.ab_ch_timeout6 = setTimeout(() => {
      this.props.enableKeyboard(true);
      if (document.getElementById(betId)) {
        document.getElementById(betId).style.transition = "250ms linear";
        document.getElementById(betId).style.transform = "scale(1)";
      }
    }, 100);
  }

  mouseOverfn(betName) {
    // this.setState({onHover: true});
    console.log("**********************************  " + betName);
    // console.log(document.getElementById(betName))
    if (document.getElementById(betName)) {
      switch (betName) {
        case "ander_betImg":
          this.setState({ andar_hover: true });
          document.getElementById("andar_glow").style.display = "block";
          break;
        case "bahar_bet":
          this.setState({ bahar_hover: true });
          document.getElementById("bahar_glow").style.display = "block";
          break;
        case "one_sixImg":
          this.setState({ ato6_hover: true });
          document.getElementById("glow_a6").style.display = "block";
          break;
        case "glow_8k":
          this.setState({ hover_8tok: true });
          document.getElementById("glow_8k").style.display = "block";
          break;
        case "heart_suit":
          this.setState({ heart_hover: true });    
          document.getElementById("heart_glow").style.display = "block";          
          break;
        case "spade_suit":
          this.setState({ spade_hover: true });
          document.getElementById("spade_glow").style.display = "block";          

          break;
        case "club_suit":
          this.setState({ club_hover: true });
          document.getElementById("club_glow").style.display = "block";
          break;
        case "dimond_suit":
          this.setState({ dimond_hover: true });
          document.getElementById("dimond_glow").style.display = "block";
          break;
        // case "card":         
        //   this.setState({ card_hover: true });
        //   break;
        default:
          break;
      }

    }

  }

  updateHistory(historyData) {
    console.log(historyData)
    console.log(historyData.length)
    for (let j = 1; j <= 4; j++) {
      if (historyData.length < j) {
        document.getElementById("ab_hist_" + j).style.display = "none";
      }
    }

    if (historyData.length > 0 && historyData.length >= 4) {
      for (let a = 0; a < historyData.length; a++) {
        for (let b = 0; b < resultCards.length; b++) {
          if (resultCards[b].rsc_val === historyData[a].resultNo) {
            if (document.getElementById("hisCard_" + a)) {
              document.getElementById("hisCard_" + a).src = resultCards[b].src
            }
            console.log(historyData[a].extraInfo);
            if (a == 0) {
              if (historyData[a].extraInfo == "BAHAR") {
                this.setState({ andarBg_0: false })
              } else {
                this.setState({ andarBg_0: true })
              }
            } else if (a == 1) {
              if (historyData[a].extraInfo == "BAHAR") {
                this.setState({ andarBg_1: false })
              } else {
                this.setState({ andarBg_1: true })
              }
            } else if (a == 2) {
              if (historyData[a].extraInfo == "BAHAR") {
                this.setState({ andarBg_2: false })
              } else {
                this.setState({ andarBg_2: true })
              }
            } else if (a == 3) {
              if (historyData[a].extraInfo == "BAHAR") {
                this.setState({ andarBg_3: false })
              } else {
                this.setState({ andarBg_3: true })
              }
            }
          }
        }
      }

    }
  }

  btn_fn(betId) {
    switch (betId) {
      case "doubleBtn":
        if (this.betSound) {
          this.betSound.play();
        }
        this.props.doubleAmount();
        break;
      case "betBtn":
        if (this.props.betButtonValue == 2) {
          if (this.takeSound) {
            this.takeSound.play();
          }
        } else {
          if (this.betSound) {
            this.betSound.play();
          }
        }
        this.props.bet_fn();
        break;
      case "clearBtn":
        if (this.clearSound) {
          this.clearSound.play();
        }
        this.props.clear_fn();
        break;
      // case "exitBtn":
      //   this.props.action();
      default:
        break;
    }

  }

  glowWinImg(data) {
    console.log("--------------------***********************----------------------")
    this.removeWinGlow();
    console.log(data)
    document.getElementById("card_glow_"+data[0]).classList.add("cardbetGlow");

    if (data[0] !== 0) {
      console.log("glow rank card");      
    }
    // if (data[1] !== 0) {
      switch (data[1]) {
        case 1:
          this.setState({ club_hover: true });
          document.getElementById("club_glow").style.display = "block";
          document.getElementById("club_glow").classList.add("andarbetGlowAnim");

         
          break;
        case 2:
          this.setState({ dimond_hover: true });
          document.getElementById("dimond_glow").style.display = "block";
          document.getElementById("dimond_glow").classList.add("andarbetGlowAnim");


          break;
        case 3:
          this.setState({ heart_hover: true });
          document.getElementById("heart_glow").style.display = "block";
          document.getElementById("heart_glow").classList.add("andarbetGlowAnim");
          break;
        case 0:
          this.setState({ spade_hover: true });
          document.getElementById("spade_glow").style.display = "block";
          document.getElementById("spade_glow").classList.add("andarbetGlowAnim");
          break;
      }
    // }
    if(data[2] !== -1){
      if (data[2] == 0) {
        console.log("glow 1-6");
        this.setState({ ato6_hover: true });
        document.getElementById("glow_a6").style.display = "block";
        document.getElementById("glow_a6").classList.add("andarbetGlowAnim");
      }else if (data[2] == 1) {
        console.log("glow 8-k");
        this.setState({ hover_8tok: true });
        document.getElementById("glow_8k").style.display = "block";
        document.getElementById("glow_8k").classList.add("andarbetGlowAnim");
  
      }
    }
    if (data[3] == 0) {
      console.log("glow Andar Bet");
      this.setState({ andar_hover: true, andarBgGlow: true });
      document.getElementById("andar_glow").style.display = "block";
      document.getElementById("andar_glow").classList.add("andarbetGlowAnim")
    }else if (data[3] == 1) {
      console.log("glow Bahar Bet");
      this.setState({ bahar_hover: true, baharBgGlow: true });
      document.getElementById("bahar_glow").style.display = "block";
      document.getElementById("bahar_glow").classList.add("andarbetGlowAnim")
    }


  }
  removeWinGlow() {
    for(let i=0; i<13; i++){
      if(document.getElementById("card_glow_"+i).classList.contains("cardbetGlow")){
        document.getElementById("card_glow_"+i).classList.remove("cardbetGlow")
      }
    }
    this.setState({ heart_hover: false });
    document.getElementById("heart_glow").style.display = "none";
    if (document.getElementById("heart_glow").classList.contains("andarbetGlowAnim")) {
      document.getElementById("heart_glow").classList.remove("andarbetGlowAnim");
    } 

    this.setState({ spade_hover: false });
    document.getElementById("spade_glow").style.display = "none";
    if (document.getElementById("spade_glow").classList.contains("andarbetGlowAnim")) {
      document.getElementById("spade_glow").classList.remove("andarbetGlowAnim");
    } 

    this.setState({ club_hover: false });
    document.getElementById("club_glow").style.display = "none";
    if (document.getElementById("club_glow").classList.contains("andarbetGlowAnim")) {
      document.getElementById("club_glow").classList.remove("andarbetGlowAnim");
    } 

    this.setState({ dimond_hover: false });
    document.getElementById("dimond_glow").style.display = "none";
    if (document.getElementById("dimond_glow").classList.contains("andarbetGlowAnim")) {
      document.getElementById("dimond_glow").classList.remove("andarbetGlowAnim");
    } 

    this.setState({ ato6_hover: false });
    document.getElementById("glow_a6").style.display = "none";
    if (document.getElementById("glow_a6").classList.contains("andarbetGlowAnim")) {
      document.getElementById("glow_a6").classList.remove("andarbetGlowAnim");
    } 

    this.setState({ hover_8tok: false });
    document.getElementById("glow_8k").style.display = "none";
    if (document.getElementById("glow_8k").classList.contains("andarbetGlowAnim")) {
      document.getElementById("glow_8k").classList.remove("andarbetGlowAnim");
    } 

    this.setState({ andar_hover: false, andarBgGlow: false });
    document.getElementById("andar_glow").style.display = "none";
    if (document.getElementById("andar_glow").classList.contains("andarbetGlowAnim")) {
      document.getElementById("andar_glow").classList.remove("andarbetGlowAnim");
    }

    this.setState({ bahar_hover: false, baharBgGlow: false });
    document.getElementById("bahar_glow").style.display = "none";
    if (document.getElementById("bahar_glow").classList.contains("andarbetGlowAnim")) {
      document.getElementById("bahar_glow").classList.remove("andarbetGlowAnim");
    } 
  }

  render() {
  
    return (
      <React.Fragment>
        <div className="ab_gameArea">


          <div className="cardscontainer">
            <img className="titleline" src={line} />
            {card_items.map((data) => (
              <div
                className={(this.props.isMobile) ? "emptyPointer cardDiv" : "addCursorPointer cardDiv"}
                id={"card_" + data.c_val}
                key={data.c_val}

                onPointerDown={(e) => {
                  e.preventDefault();
                  e.currentTarget.style.transform = "scale(0.9)";
                  this.mouseDownSelectCard(data.c_val)
                  // this.props.selectedCard(data.c_val);
                }}
                onPointerUp={(e) => { e.preventDefault(); this.mouseUpStopCards(data.c_val) }}
                onPointerOut={(e) => { e.preventDefault(); this.mouseUpStopCards(data.c_val) }}
              >
                <img src={data.src} alt={data.c_val} />
                {/* <img id="card_glow" className="cardbetGlow" src={(this.state.card_hover) ? cardGlow : ""} /> */}
                <div id={"card_glow_"+ data.c_val}></div>
                <div className="ab_gldDiv, ab_goldcoin" id={"fID_" + data.c_val}>

                </div>
              </div>
            ))}

            <img className="titlelinebottom" src={bottomline} />
          </div>
          <div className="row suitBetArea">
            <div className="column colSize">
              <div className="row leftSuitRow">
                <div className={(this.props.isMobile) ? "emptyPointer heart" : "addCursorPointer heart"}

                >
                  <img id="heart_glow" className="clubbetGlow" src={(this.state.heart_hover) ? suitGlowImg : ""} />
                  <img className="suitImg" id="heart_suit" src={heart} />
                  <div className="ab_gldDiv, suit_goldcoin" id={"heart"}></div>
                  <div
                    className={(this.props.isMobile) ? "emptyPointer dimond_hit_area" : "addCursorPointer dimond_hit_area"}
                    onPointerDown={(e) => {
                      e.preventDefault();
                      this.sideBets("heart");
                      document.getElementById("heart_suit").style.transform = "scale(0.9)";
                    }}
                    onPointerOver={(e) => { e.preventDefault(); this.mouseOverfn("heart_suit") }}

                    onPointerUp={(e) => { e.preventDefault(); this.mouseUpSideBets("heart_suit") }}
                    onPointerOut={(e) => { e.preventDefault(); this.mouseUpSideBets("heart_suit") }}
                  ></div>
                </div>
                <div className={(this.props.isMobile) ? "emptyPointer spade" : "addCursorPointer spade"}
                >
                  <img id="spade_glow" className="clubbetGlow" src={(this.state.spade_hover) ? suitGlowImg : ""} />
                  <img className="suitImg" id="spade_suit" src={spade} />
                  <div className="ab_gldDiv, suit_goldcoin" id={"spade"}></div>
                  <div
                    className={(this.props.isMobile) ? "emptyPointer dimond_hit_area" : "addCursorPointer dimond_hit_area"}
                    onPointerDown={(e) => {
                      e.preventDefault();
                      this.sideBets("spade");
                      document.getElementById("spade_suit").style.transform = "scale(0.9)";
                    }}
                    onPointerOver={(e) => { e.preventDefault(); this.mouseOverfn("spade_suit") }}
                    onPointerUp={(e) => { e.preventDefault(); this.mouseUpSideBets("spade_suit") }}
                    onPointerOut={(e) => { e.preventDefault(); this.mouseUpSideBets("spade_suit") }}
                  >
                  </div>
                </div>
              </div>
              <div className="row leftHistRow">
                <div id="ab_hist_4" className="hisCard_4">
                  <img id="hisCardBg_3" src={(this.state.andarBg_3) ? andarHist : baharHist} ></img>
                  <img id="hisCard_3"></img>
                </div>
                <div id="ab_hist_3" className="hisCard_3">
                  <img id="hisCardBg_2" src={(this.state.andarBg_2) ? andarHist : baharHist} ></img>
                  <img id="hisCard_2"></img>
                </div>
              </div>
            </div>
            <div className="column colSize">
              <div className="pannelcards">

                <div id="andarCardHolder" className="fstcrd">
                  <img className= {this.state.andarBgGlow? "abBgGlow showGlow andarbetGlowAnim" : "abBgGlow hideGlow"} src={abBgGlow}></img>
                  <img className="fstcrd_bg" src={pannel} />
                  <img className="andarsubttle" src={subttleandar} />
                  <div className="resAndarCard" id="res_andar_Card" />
                  <div>
                    <img id="andar_glow" className="andarbetGlow" src={(this.state.andar_hover) ? ab_glow : ""} />
                    <img id="ander_betImg" className="andarBet" src={andarBet} />
                    <div className="andar_goldCoin" id="andar"></div>
                  </div>
                  <div className={(this.props.isMobile) ? "emptyPointer andarBetArea" : "addCursorPointer andarBetArea"}
                    onPointerDown={(e) => {
                      e.preventDefault();
                      this.sideBets("andar");
                    }}
                    onPointerOver={(e) => { e.preventDefault(); this.mouseOverfn("ander_betImg") }}

                    onPointerUp={(e) => { e.preventDefault(); this.mouseUpSideBets("ander_betImg") }}
                    onPointerOut={(e) => { e.preventDefault(); this.mouseUpSideBets("ander_betImg") }}
                  ></div>
                </div>

                <div className="sestcrd">
                  <img className="jokerCard_bg" id="jokerCard" src={cardspannel} />
                  <div className="jokerCardRuslt">
                    <img className="resJokerCard_anim" id="resJokerCard" />
                  </div>
                  <img id="glow_a6" className="a_6_glowcard" src={(this.state.ato6_hover) ? glowImg_a6 : ""} />
                  <img id="glow_8k" className="glowcard_8k" src={(this.state.hover_8tok) ? glowImg_8k : ""} />
                  <img id="one_sixImg" className="glowcard" src={numBet1} />
                  <img id="eight_KImg" className="num8_k" src={numBet2} />
                  <div className="fd a6_goldCoin" id="one_six"></div>
                  <div className={(this.props.isMobile) ? "emptyPointer one_six" : "addCursorPointer one_six"}
                    id="one_six"
                    onPointerDown={(e) => {
                      e.preventDefault();
                      this.sideBets("one_six");
                    }}
                    onPointerOver={(e) => { e.preventDefault(); this.mouseOverfn("one_sixImg") }}
                    onPointerUp={(e) => { e.preventDefault(); this.mouseUpSideBets("one_sixImg") }}
                    onPointerOut={(e) => { e.preventDefault(); this.mouseUpSideBets("one_sixImg") }}
                  >
                  </div>

                  <div className="fd bottom_num_goldCoin" id="eight_k"></div>
                  <div className={(this.props.isMobile) ? "emptyPointer eight_k" : "addCursorPointer eight_k"}
                    onPointerDown={(e) => {
                      e.preventDefault();
                      this.sideBets("eight_k");
                    }}
                    onPointerOver={(e) => { e.preventDefault(); this.mouseOverfn("glow_8k") }}
                    onPointerUp={(e) => { e.preventDefault(); this.mouseUpSideBets("glow_8k") }}
                    onPointerOut={(e) => { e.preventDefault(); this.mouseUpSideBets("glow_8k") }}
                  >


                  </div>
                </div>

                <div className="trdcrd">
                  <div> 
                  <img className= {this.state.baharBgGlow? "abBgGlow showGlow andarbetGlowAnim" : "abBgGlow hideGlow"} src={abBgGlow}></img>

                    <img className="fstcrd_bg" src={pannel} />
                    <img className="baharsubttle" src={subttlebahar} />
                    <div className="resBaharCard" id="res_bahar_Card" />
                    <div>
                      <img id="bahar_glow" className="baharbetGlow" src={(this.state.bahar_hover) ? ab_glow : ""} />
                      <img id="bahar_bet" className="baharBet" src={baharBet} />
                      <div className="bahar_goldCoin" id="bahar"></div>
                    </div>
                    <div className={(this.props.isMobile) ? "emptyPointer baharBetArea" : "addCursorPointer baharBetArea"}
                      onPointerDown={(e) => {
                        e.preventDefault();
                        this.sideBets("bahar");
                      }}
                      onPointerOver={(e) => { e.preventDefault(); this.mouseOverfn("bahar_bet") }}
                      onPointerUp={(e) => { e.preventDefault(); this.mouseUpSideBets("bahar_bet") }}
                      onPointerOut={(e) => { e.preventDefault(); this.mouseUpSideBets("bahar_bet") }}
                    ></div>
                  </div>
                </div>

              </div>
            </div>
            <div className="column colSize">
              <div className="row rightsuitRow">
                <div className={(this.props.isMobile) ? "emptyPointer club" : "addCursorPointer club"}

                >
                  <img id="club_glow" className="clubbetGlow" src={(this.state.club_hover) ? suitGlowImg : ""} />
                  <img className="suitImg" id="club_suit" src={club} />
                  <div className="ab_gldDiv, suit_goldcoin" id={"club"}></div>
                  <div
                    className={(this.props.isMobile) ? "emptyPointer dimond_hit_area" : "addCursorPointer dimond_hit_area"}
                    onPointerDown={(e) => {
                      e.preventDefault();
                      this.sideBets("club");
                      document.getElementById("club_suit").style.transform = "scale(0.9)";
                    }}

                    onPointerOver={(e) => { e.preventDefault(); this.mouseOverfn("club_suit") }}
                    onPointerUp={(e) => { e.preventDefault(); this.mouseUpSideBets("club_suit") }}
                    onPointerOut={(e) => { e.preventDefault(); this.mouseUpSideBets("club_suit") }}
                  >
                  </div>
                </div>

                <div
                  className={(this.props.isMobile) ? "emptyPointer dimond" : "addCursorPointer dimond"}

                >
                  <img className="suitImg" id="dimond_suit" src={dimond} />
                  <img id="dimond_glow" className="clubbetGlow" src={(this.state.dimond_hover) ? suitGlowImg : ""} />
                  <div className="ab_gldDiv, suit_goldcoin" id={"dimond"}></div>
                  <div
                    className={(this.props.isMobile) ? "emptyPointer dimond_hit_area" : "addCursorPointer dimond_hit_area"}
                    onPointerDown={(e) => {
                      e.preventDefault();
                      this.sideBets("dimond");
                      document.getElementById("dimond_suit").style.transform = "scale(0.9)";
                    }}
                    onPointerOver={(e) => { e.preventDefault(); this.mouseOverfn("dimond_suit") }}
                    onPointerUp={(e) => { e.preventDefault(); this.mouseUpSideBets("dimond_suit") }}
                    onPointerOut={(e) => { e.preventDefault(); this.mouseUpSideBets("dimond_suit") }}
                  >
                  </div>
                </div>
              </div>
              <div className="row rightHistRow">
                <div id="ab_hist_2" className="hisCard_2">
                  <img id="hisCardBg_1" src={(this.state.andarBg_1) ? andarHist : baharHist} ></img>
                  <img id="hisCard_1"></img>
                </div>
                <div id="ab_hist_1" className="hisCard_1">
                  <img id="hisCardBg_0" src={(this.state.andarBg_0) ? andarHist : baharHist} ></img>
                  <img id="hisCard_0"></img>
                </div>

              </div>
            </div>
          </div>
          <div className="infoBox">
            <span id="infoTxt" className="infoTxt" >{this.props.infoText}</span>
          </div>
          <div className="ab_Chps fd">
            <ul className="chips_ab_timer">
              {chips.map((chip, i) => (
                //<li key={chip.src} >
                <div
                  className={this.props.isMobile ? `chip_bg${i}` : `chip_bg${i} addCursorPointer`}
                  id={"chipImg_" + chip.val}
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("chipImg_" + chip.val).style.transform = "scale(0.9)";
                    this.ab_ch_timeout7 = setTimeout(() => {
                      document.getElementById("chipImg_" + chip.val).style.transform = "scale(1)";
                    }, 200);
                    this.props.getChipVal(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`);
                    this.setState({ chipSelect: chip.val });
                  }
                  }
                >
                  <img src={chip.src} className={this.state.chipSelect === chip.val ? "ab_active ab_chipImg" : "inactive ab_chipImg"} alt={chip.val} />
                  <div id={"chipText_" + chip.val} class="abt_overlay_text"> {this.chipVal[i].label}</div>
                </div>
                // </li>
              ))}
            </ul>
          </div>



          <div className="buttonList">
            {/* <div
              id="exitBtn"
              className={this.props.isMobile ? "exitBtn" : "exitBtn addCursorPointer"}
              onClick={(e) => {
                e.preventDefault();
                this.btn_fn("exitBtn");
              }}
            >
              <img className="ab_btn_height" src={ab_exitBtn} />
              <span className="textoverEXIT">{this.props.language.Exit}</span>
            </div> */}

            <div className={this.props.isMobile ? "clearBtn" : "clearBtn addCursorPointer"}
              id="clearBtn"
              onClick={(e) => {
                e.preventDefault();
                this.btn_fn("clearBtn");
              }}
            >
              <img className="ab_btn_height" src={take_button} />
              <div className="textovercancelbet">
                <span>{this.props.language.Clear}</span>
              </div>
            </div>

            <div className="betLbl">
              <img className="ab_btn_height totalbet" src={totalbet} />
              <div className="textovertotalbet">
                <span className="totalBetAmt">{this.props.language.TotalBet}<br /> {this.props.updatedbets}</span>
              </div>
            </div>

            <div className={this.props.isMobile ? "ab_doubleBtn" : "ab_doubleBtn addCursorPointer"}
              id="doubleBtn"
              onClick={(e) => {
                e.preventDefault();
                this.btn_fn("doubleBtn");
              }}
            >
              <img className="ab_buttondouble ab_btn_height" src={take_button} />
              <div className="textoverdouble">
                <span>{this.props.language.Double}</span>
              </div>
            </div>
            <div className={this.props.isMobile ? "ab_betok" : "ab_betok addCursorPointer"}
              id="betBtn"
              onClick={(e) => {
                e.preventDefault();
                this.btn_fn("betBtn");
              }}
            >
              <img className={this.props.startBlink ? "abbuttonglow " : "hidebuttonglow"} src={this.props.startBlink ? glow_button : ""} />
              <img className="betButton ab_btn_height" src={bet_button} />
              <div className="textoverbetok">
                <span>{this.props.betBtnTxt}</span>
              </div>
            </div>
          </div>
          <div id="ab_Cover" className="ab_Cover">
          </div>
          {/************************** DEALER & PLAYER TABLE END  ******************/}
        </div>
      </React.Fragment>
    );
  }
}
const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj

  }
}
export default connect(mapStatesToProps)(ab_ChipsDealerPlayer)