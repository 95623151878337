import React  from "react";
import chips from '../../../chips';

import { connect } from "react-redux";

 class BetChips extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            chipSelect : 1,
        }

        this.chipVal = [ 
            { label: "1k" , val: 1000 }, 
            { label: "5k" , val: 5000 }, 
            { label: "10k" , val: 10000 }, 
            { label: "10k" , val: 10000 }, 
            { label: "50k" , val: 50000 }, 
            { label: "100k" , val: 100000 }, 
            { label: "5M" , val: 5000000 }, 
            { label: "1B" , val: 100000000 },
            { label: "" , val: 0 }
        ];   
        // this.chipVal = [ "1k", "5k", "10k", "50k", "100k", "500k", "1000k", "5000k"]
        console.log("this.props.betAmount "+this.props.betAmount);
    }
    componentDidMount(){
       
        if(this.props.ert_chipValues){
            let newChips = this.props.ert_chipValues.split(",");
            console.log(newChips);
            console.log(newChips.length)
            let lblTxt ="";
            for(let i =0; i<newChips.length; i++){                   
                if(Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999){
                    lblTxt = "K"
                }else if(Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999){
                    lblTxt = "M"                        
                }else if(Number(newChips[i]) > 999999999){            
                    lblTxt = "B"
                }                     
                this.chipVal[i].val = newChips[i]         
                console.log(this.chipVal[i].val, lblTxt);
                this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);
                
            }
        }


      }

      checkChipValue(chipValue, chpTxt, i) {       
        if(chipValue >= 1000){
            chipValue = chipValue / 1000;
            this.checkChipValue(chipValue, chpTxt, i)
        }else{
            console.log(chpTxt);
            this.showResultChipValue(chipValue, chpTxt, i);
            return chipValue
        }
    }

    showResultChipValue(chVal, chipTxt, index){
        console.log(chVal);
        console.log(chVal+""+chipTxt)
        this.chipVal[index].label = chVal+""+chipTxt;
        console.log(this.chipVal)
    }


    // topLable_fn(betAmount){
    //     console.log(betAmount);
    //     console.log(typeof(betAmount));
    //     let updatedBalance = betAmount;

       

    //     if(updatedBalance >= 1000 && updatedBalance <= 999999){
    //        // alert("1");
    //         updatedBalance = (updatedBalance / 1000) +"K";      
    //         console.log(updatedBalance)
    //     }else if(updatedBalance >= 100000 && updatedBalance <=999999999){
    //        // alert("2");
    //       updatedBalance = (updatedBalance / 1000)+"M";    
    //     }else if(updatedBalance >999999999){
    //       //  alert("3");
    //       updatedBalance = (updatedBalance / 1000)+"B";      
    //     }

    //     return updatedBalance;
    //   }
    //   topLable_fn2(betAmount){
    //    // console.log(betAmount);
    //     if(betAmount >= 1000 && betAmount <=999999){
    //         betAmount = (betAmount / 1000) +"K";      
    //        // console.log(betAmount)
    //     }else if(betAmount >= 1000000 && betAmount <=999999999){
    //       betAmount = betAmount / 1000+"M";    
    //     }else if(betAmount >999999999){
    //       betAmount = betAmount / 1000+"B";      
    //     }

    //     return betAmount;
    // }

    // topLable_fn(labelValue) 
    // {
    //     // Nine Zeroes for Billions
    //     return Math.abs(Number(labelValue)) >= 1.0e+9
    
    //     ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
    //     // Six Zeroes for Millions 
    //     : Math.abs(Number(labelValue)) >= 1.0e+6
    
    //     ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    //     // Three Zeroes for Thousands
    //     : Math.abs(Number(labelValue)) >= 1.0e+3
    
    //     ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
    
    //     : Math.abs(Number(labelValue));
    // }
    topLable_fn(labelValue) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9
    
        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6
    
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3
    
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
    
        : Math.abs(Number(labelValue.toFixed(2)));
    }
    render() {
        const roundNumber = this.props.balance - this.props.TotalBetAmouont
        // const betAmount = Math.round(roundNumber*100,2)/100
        const betAmount =(roundNumber)
        // let updatebal = this.topLable_fn(Number(betAmount)).toFixed(2);
        // let updatebal = this.topLable_fn(Number(betAmount)).toFixed(2);
        let updatebal = this.topLable_fn(Number(betAmount))
        // let updatebal = this.topLable_fn(betAmount);
        let updateBet = this.topLable_fn(this.props.TotalBetAmouont)
        return (
            <React.Fragment>
                <div className="fd p_5">
                    <div className="fd">
                        <div className="funBal fd">
                            <div className=""> {updatebal} </div>
                            <div className="">{this.props.language.Balance}  </div>
                        </div>
                        <div className="funBal fd">
                            <div className="">{updateBet}</div>
                            <div className="">{this.props.language.TotalBet} </div>
                        </div>
                        {/* <div className="funBal fd">
                            <div className=""> {this.props.winAmount} </div>
                            <div className=""> WINNING </div>
                        </div> */}
                    </div> 
                    <div className="erpRltChps fd"> 
                            {chips.map((data, i) => (
                            <div className="am_chip ert_bet" key={data.src}  onClick={(e)=> {e.preventDefault(); this.props.betAmount(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`);this.setState({chipSelect : data.val})}}  >                              
                                <img src={data.src} alt={data.src}
                                    className={this.state.chipSelect === data.val ? "activeChip" : "inactive"}
                                    />
                               <div class="ert_overlay-text">
                                    {this.chipVal[i].label}
                                    
                                    
                            </div>
                               
                            </div>))}
                        </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatesToProps = (state) =>{
    return{
        language:state.languageObjs.languageObj
    }
}

export default connect(mapStatesToProps)(BetChips)
