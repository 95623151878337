import React from "react";
import "./UI/andarBahars.css";
import ChipsDealerPlayer from "./UI/AB_ChipsDealerPlayer";
import TopLable from "./UI/topLable";
import betSound from "../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../assets/sounds/take.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import close from "../../../../../assets/sounds/exit.mp3";
import betoncard from "../../../../../assets/sounds/betoncard.mp3";
import clearSound from "../../../../../assets/sounds/clear.mp3"; 
import { connect } from "react-redux";

class AndarBahar extends React.Component {
  constructor(props) {
    super(props);
    this.ChipsDealerPlayer = React.createRef();
    this.TopLable = React.createRef();
    this.state = {
      chipVal: 1,
      betAmt: 0,
      betCard: null,
      betCount: 0,
      //takeAmt: 0,
      delearFirst: true,
      infoText: this.props.language.Play_Njoy,
      cardsArray: "",
      betAmountArray: "",
      winnerArray: "",
      betButtonText: this.props.language.Prev,
      betButtonValue: 0,
      btnActive: false,
      disable: true,
      disableDealBtn: true,
      disableClearBtn: true,
      targetResult: [],
      totalBetAmount: 0,
      takeAmount: 0,
      disableBetBtn: true,
      isGameActive: true,
      isBetSent: false,
      startBlink: false,
      maxInnerValue: 0,
      enableKeyboard: false,
      winGlowImg: false
    };
    this.takeSound = new Audio(takeSound);
    this.close = new Audio(close);
    this.winSound = new Audio(winSound);
    this.betSound = new Audio(betSound);
    this.betoncard = new Audio(betoncard);
    this.clearSound = new Audio(clearSound);
    this.betPlaced = false;
    this.resultShown = false;
    this.handId = 0;
    this.isSpectSent = false;

    this.cardBetAmt = 0;
    this.cardBet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.cardBetlblchange = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.suitBet = [0, 0, 0, 0];
    this.numBet = [0, 0]; 
    this.abBet = [0, 0];
    this.ab_betString = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; 
    this.innerLimits = {}; 
  }

  componentDidMount() {
    // const { childRef } = this.props;
    // childRef(this);
    this.ab_timeout1 = null;
    this.ab_timeout2 = null;
    this.ab_timeout3 = null;
    this.ab_timeout4 = null;
    this.ab_timeout5 = null;
    this.ab_timeout6 = null;
    this.ab_timeout7 = null;
    this.ab_timeout8 = null;
    this.ab_timeout9 = null; 

    // this.preloadAssets();

    if (this.props.abt_innerLimits && this.props.abt_innerLimits.length >0) {
      this.innerLimits = this.props.abt_innerLimits.split("|");
      for (let t = 0; t < this.innerLimits.length; t++) {
        this.innerLimits[t] = this.innerLimits[t].split("-");
      }
    }

    console.log(this.innerLimits);
    console.log(this.props.maxBetAmt);


    if (this.props.maxBetAmt < 10) {
      document.getElementById("chipImg_10").style.opacity = 0.5;
      document.getElementById("chipImg_10").style.pointerEvents = 'none';
      document.getElementById("chipImg_25").style.opacity = 0.5;
      document.getElementById("chipImg_25").style.pointerEvents = 'none';
      document.getElementById("chipImg_50").style.opacity = 0.5;
      document.getElementById("chipImg_50").style.pointerEvents = 'none';
      document.getElementById("chipImg_100").style.opacity = 0.5;
      document.getElementById("chipImg_100").style.pointerEvents = 'none';
      document.getElementById("chipImg_500").style.opacity = 0.5;
      document.getElementById("chipImg_500").style.pointerEvents = 'none';
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';

    } else if (this.props.maxBetAmt < 25) {
      document.getElementById("chipImg_25").style.opacity = 0.5;
      document.getElementById("chipImg_25").style.pointerEvents = 'none';
      document.getElementById("chipImg_50").style.opacity = 0.5;
      document.getElementById("chipImg_50").style.pointerEvents = 'none';
      document.getElementById("chipImg_100").style.opacity = 0.5;
      document.getElementById("chipImg_100").style.pointerEvents = 'none';
      document.getElementById("chipImg_500").style.opacity = 0.5;
      document.getElementById("chipImg_500").style.pointerEvents = 'none';
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';
    } else if (this.props.maxBetAmt < 50) {
      document.getElementById("chipImg_50").style.opacity = 0.5;
      document.getElementById("chipImg_50").style.pointerEvents = 'none';
      document.getElementById("chipImg_100").style.opacity = 0.5;
      document.getElementById("chipImg_100").style.pointerEvents = 'none';
      document.getElementById("chipImg_500").style.opacity = 0.5;
      document.getElementById("chipImg_500").style.pointerEvents = 'none';
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';

    } else if (this.props.maxBetAmt < 100) {
      document.getElementById("chipImg_100").style.opacity = 0.5;
      document.getElementById("chipImg_100").style.pointerEvents = 'none';
      document.getElementById("chipImg_500").style.opacity = 0.5;
      document.getElementById("chipImg_500").style.pointerEvents = 'none';
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';

    } else if (this.props.maxBetAmt < 500) {
      document.getElementById("chipImg_500").style.opacity = 0.5;
      document.getElementById("chipImg_500").style.pointerEvents = 'none';
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';

    } else if (this.props.maxBetAmt < 1000) {
      document.getElementById("chipImg_1000").style.opacity = 0.5;
      document.getElementById("chipImg_1000").style.pointerEvents = 'none';
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';
    } else if (this.props.maxBetAmt < 5000) {
      document.getElementById("chipImg_5000").style.opacity = 0.5;
      document.getElementById("chipImg_5000").style.pointerEvents = 'none';
    }

    // document.getElementById("exitBtn").disabled = true;
    // this.ab_timeout1 = setTimeout(() => {
    //   if (document.getElementById("exitBtn")) {
    //     document.getElementById("exitBtn").disabled = false;
    //   }
    // }, 1000);


    let newChips = this.props.abt_chipValues.split(",");
    console.log(newChips);

    this.setState({ chipVal: Number(newChips[0]) });
    // console.log(this.chipVal[0].val);
    // console.log(this.state.chipSelect);

    this.setState({ disableDealBtn: true, maxInnerValue: 0 })

    this.disableBtn("clearBtn");
    this.disableBtn("doubleBtn");
    this.disableBtn("betBtn");

    console.log(this.props.gameState)

    this.updateHistory();

    if (this.props.gameState.takeAmount > 0) {
      document.getElementById("ab_Cover").style.display = "block";

      if (document.getElementById("betBtn").classList.contains("ab_disBtn")) {
        document.getElementById("betBtn").classList.remove("ab_disBtn");
        document.getElementById("betBtn").style.zIndex = 180;
      }

      this.setState({
        totalBetAmount: this.props.gameState.betRequest.betAmount,
        takeAmount: this.props.gameState.takeAmount,
        infoText: this.props.language.ClickTake,
        betButtonText: this.props.language.Take,
        betButtonValue: 2,
        startBlink: true,
        disableDealBtn: false,
        winGlowImg: true,
      })

      // this.showPreviousBet();

      console.log(this.props.gameState.betRequest.betString)
        let bet1 = this.props.gameState.betRequest.betString.split(",")[0];
        console.log(bet1);
        const bet1_1 = bet1.split("|");
        for (let j = 0; j < bet1_1.length; j++) {
          bet1_1[j] = Number(bet1_1[j])
          // console.log(bet1_1);
          if (Number(bet1_1[j]) <= this.innerLimits[0][1]) {
            this.cardBet[j] = Number(bet1_1[j]);
            // console.log(Number(bet1_1[j]));
            if (Number(bet1_1[j]) > 0) {
              this.setState({ betCard: j })
              this.cardBetAmt = Number(bet1_1[j]);
              document.getElementById("fID_" + j).innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet1_1[j])} </span> </p>`;

           
              
            }
          } else {
            this.setState({ infoText: this.props.language.MaxBetLmt });
          }
        }
        console.log(this.cardBet);
        console.log(typeof (this.cardBet));

        let bet2 = this.props.gameState.betRequest.betString.split(",")[1];
        const bet2_1 = bet2.split("|");
        console.log(bet2_1);
      
        for (let y = 0; y < bet2_1.length; y++) {
          // let Changebetslbl=this.changeAmtlabl(Number(bet2_1[y]))
          // bet2_1[y] = Changebetslbl
          bet2_1[y] = Number(bet2_1[y]);
          if (Number(bet2_1[y]) <= this.innerLimits[1][1]) {
            this.suitBet[y] = Number(bet2_1[y]);
            if (Number(bet2_1[y]) > 0) {
              switch (y) {
                case 0:
                  console.log(document.getElementById("spade"));
                  document.getElementById("spade").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
                  break;
                case 1:
                  console.log(document.getElementById("club"));
                  document.getElementById("club").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
                  break;
                case 2:
                  console.log(document.getElementById("dimond"));
                  document.getElementById("dimond").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
                  break;
                case 3:
                  console.log(document.getElementById("heart"));
                  document.getElementById("heart").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
                  break;
              }
            }
          } else {
            this.setState({ infoText: this.props.language.MaxBetLmt });
          }
        }
        console.log(this.suitBet);

        let bet3 = this.props.gameState.betRequest.betString.split(",")[2];
        const bet3_1 = bet3.split("|");
        console.log(bet3_1);
        console.log(bet3_1.length);
        
        for (let t = 0; t < bet3_1.length; t++) {
        
          // let lblbets=this.changeAmtlabl(Number(bet3_1[t]))
          // bet3_1[t] =lblbets;
          bet3_1[t] = Number(bet3_1[t]);
          if (Number(bet3_1[t]) <= this.innerLimits[2][1]) {
            this.numBet[t] = Number(bet3_1[t]);
            console.log(this.numBet[t]);
            if (Number(bet3_1[t]) > 0) {
              if (t == 0) {
                document.getElementById("one_six").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet3_1[t])} </span> </p>`;
              } else {
                document.getElementById("eight_k").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet3_1[t])} </span> </p>`;
              }
            }
          } else {
            this.setState({ infoText: this.props.language.MaxBetLmt });
          }

        }
        console.log(this.numBet);
        console.log(this.numBet[0]);
        console.log(this.numBet[1]);


        let bet5 = this.props.gameState.betRequest.betString.split(",")[3];
        const bet5_1 = bet5.split("|");
        console.log(bet5_1);
        for (let e = 0; e < bet5_1.length; e++) {
          // let Changebetslblamt=this.changeAmtlabl( Number(bet5_1[e]))
           bet5_1[e] = Number(bet5_1[e]);
          // bet5_1[e] =Changebetslblamt;

          if (Number(bet5_1[e]) <= this.innerLimits[3][1]) {
            this.abBet[e] = Number(bet5_1[e]);
            if (Number(bet5_1[e]) > 0) {
              if (e == 0) {
                document.getElementById("andar").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet5_1[e])} </span> </p>`;
              } else {
                document.getElementById("bahar").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet5_1[e])} </span> </p>`;
              }
            }
          } else {
            this.setState({ infoText: this.props.language.MaxBetLmt });
          }
        }
        console.log(this.abBet);
        this.setState({
          totalBetAmount: this.props.gameState.betRequest.betAmount,
          betAmt: this.props.gameState.betRequest.betAmount
        })
        

      if( this.props.gameState.response && this.props.gameState.response.winData){
        this.ChipsDealerPlayer.glowWinImg(this.props.gameState.response.winData);
      }

      this.ab_timeout2 = setTimeout(() => {
        console.log(document.getElementById("timeSpan").innerHTML);
        if (document.getElementById("timeSpan")) {
          if (document.getElementById("timeSpan").innerHTML <= 5 || document.getElementById("timeSpan").innerHTML > 58) {
            this.setState({
              enableKeyboard: false,
            });
          } else {
            if (!this.resultShown) {
              this.setState({
                enableKeyboard: true,
              });
            }
          }
        }
      }, 1000)

    } else if (this.props.gameState.handId == this.props.gameState.oldHandId && this.props.gameState.handId != undefined) {

      
      // console.log(this.props.gameState.betRequest.betString);
      this.setState({
        betButtonText: this.props.language.BetOK,
        betButtonValue: 1,
        totalBetAmount: this.props.gameState.betRequest.betAmount,
        infoText: this.props.language.BetAccepted + this.props.gameState.betRequest.betAmount,
        enableKeyboard: false,
        isBetSent: true,
      })

      document.getElementById("ab_Cover").style.display = "block";
      if (document.getElementById("betBtn")) {
        document.getElementById("betBtn").classList.add("ab_disBtn");
      }
      console.log(this.props.gameState.betRequest.betString)
      let bet1 = this.props.gameState.betRequest.betString.split(",")[0];
      console.log(bet1);
      const bet1_1 = bet1.split("|");
      console.log(bet1_1);
      for (let j = 0; j < bet1_1.length; j++) {
        // let updatebetsocard=this.changeAmtlabl(Number(bet1_1[j]))
        this.cardBet[j] = Number(bet1_1[j]);
        // this.cardBet[j] = updatebetsocard
        if (Number(bet1_1[j] > 0)) {
          document.getElementById("fID_" + j).innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet1_1[j])} </span> </p>`;
        }
      }
      console.log(this.cardBet);
      console.log(typeof (this.cardBet));

      let bet2 = this.props.gameState.betRequest.betString.split(",")[1];
      const bet2_1 = bet2.split("|");
      console.log(bet2_1);
      for (let y = 0; y < bet2_1.length; y++) {
        // let updatebetsocrd=this.changeAmtlabl(Number(bet2_1[y]))
         this.suitBet[y] = Number(bet2_1[y]);
        // this.suitBet[y] = updatebetsocrd;
        if (Number(bet2_1[y]) > 0) {
          switch (y) {
            case 0:
              console.log(document.getElementById("spade"));
              document.getElementById("spade").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
              break;
            case 1:
              console.log(document.getElementById("club"));
              document.getElementById("club").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
              break;
            case 2:
              console.log(document.getElementById("dimond"));
              document.getElementById("dimond").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
              break;
            case 3:
              console.log(document.getElementById("heart"));
              document.getElementById("heart").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
              break;
          }
        }
      }
      console.log(this.suitBet);

      let bet3 = this.props.gameState.betRequest.betString.split(",")[2];
      const bet3_1 = bet3.split("|");
      console.log(bet3_1);
      console.log(bet3_1.length);
      for (let t = 0; t < bet3_1.length; t++) {
        // let updatebts=this.changeAmtlabl(Number(bet3_1[t]))
          this.numBet[t] = Number(bet3_1[t])
        // console.log(this.numBet[t]);
        if (Number(bet3_1[t]) > 0) {
          if (t == 0) {
            document.getElementById("one_six").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet3_1[t])} </span> </p>`;
          } else {
            document.getElementById("eight_k").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet3_1[t])} </span> </p>`;
          }
        }
      }
      console.log(this.numBet);
      console.log(this.numBet[0]);
      console.log(this.numBet[1]);

      let bet5 = this.props.gameState.betRequest.betString.split(",")[3];
      const bet5_1 = bet5.split("|");
      console.log(bet5_1);
      for (let e = 0; e < bet5_1.length; e++) {
        // let bet5=this.changeAmtlabl(Number(bet5_1[e]))
        this.abBet[e] = Number(bet5_1[e]);
        // this.abBet[e] = bet5;
        if (Number(bet5_1[e]) > 0) {
          if (e == 0) {
            document.getElementById("andar").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet5_1[e])} </span> </p>`;
          } else {
            document.getElementById("bahar").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet5_1[e])} </span> </p>`;
          }
        }
      }
      console.log(this.abBet);

    } else {
      this.ab_timeout10 = setTimeout(() => {
        if(document.getElementById("timeSpan")){
          if (document.getElementById("timeSpan").innerHTML <= 5 || document.getElementById("timeSpan").innerHTML > 58) {
            this.setState({
              enableKeyboard: false,
              disableDealBtn: true,
            });
            document.getElementById("betBtn").classList.add("ab_disBtn");
          } else {
            this.setState({
              disableDealBtn: false,
              enableKeyboard: true,
            })
          }
        }
      }, 500);

      this.setState({
        betButtonText: this.props.language.Prev,
        betButtonValue: 0,
        // disableDealBtn: false,
        // enableKeyboard: true,
      })
      console.log(this.props.gameState.betRequest);
      if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0) {
        if (document.getElementById("betBtn") && document.getElementById("betBtn").classList.contains("ab_disBtn")) {
          document.getElementById("betBtn").classList.remove("ab_disBtn");
          document.getElementById("betBtn").style.zIndex = 110;
        }
      }
      
      this.ab_timeout6 = setTimeout(() => {
        if (!this.resultShown) {
          this.setState({ enableKeyboard: true });
        }
      }, 1000);

      if (document.getElementById("ab_Cover")) {
        document.getElementById("ab_Cover").style.display = "none";
      }
    }

    this.ab_timeout7 = setTimeout(() => {
      document.addEventListener("keyup", this.fc_handleKeyboardEvent);
      document.addEventListener("keydown", this.fc_bets_handleKeyboardEvent);
    }, 1000);

    // if (!this.resultShown) {
    //   this.setState({ enableKeyboard: true });
    // }
    if (this.props.gameState.handId) {
      this.handId = this.props.gameState.handId;
    }
    const { childRefAB } = this.props;
    childRefAB(this);
  }

  showPreviousBet() {
   
    console.log(this.innerLimits);
    if (this.props.gameState.betRequest != undefined) {
      if (this.props.maxBetAmt && this.props.gameState.betRequest.betAmount > this.props.maxBetAmt) {
        this.clearcardspreviuosglows();
      
        this.setState({ infoText:   this.props.language.MinBetAmt +" " +this.props.minBetLbl +" " +this.props.language.MaxBetAmt +" " +this.props.maxBetLbl, startBlink: false});
      } else if (this.props.gameState.betRequest.betAmount <= this.props.balance) {
       
        console.log(this.props.gameState.betRequest.betString)
        let bet1 = this.props.gameState.betRequest.betString.split(",")[0];
        console.log(bet1);
        const bet1_1 = bet1.split("|");
        for (let j = 0; j < bet1_1.length; j++) {
          // let bet1=this.changeAmtlabl(Number(bet1_1[j]))
          bet1_1[j] = Number(bet1_1[j])
          // bet1_1[j] =bet1
          // console.log(bet1_1);
          if (Number(bet1_1[j]) <= this.innerLimits[0][1]) {
            this.cardBet[j] = Number(bet1_1[j]);
            // console.log(Number(bet1_1[j]));
            if (Number(bet1_1[j]) > 0) {
              this.setState({ betCard: j })
              this.cardBetAmt = Number(bet1_1[j]);
              document.getElementById("fID_" + j).innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet1_1[j])} </span> </p>`;
            }
          } else {
            this.setState({ infoText: this.props.language.MaxBetLmt });
          }
        }
        console.log(this.cardBet);
        console.log(typeof (this.cardBet));

        let bet2 = this.props.gameState.betRequest.betString.split(",")[1];
        const bet2_1 = bet2.split("|");
        console.log(bet2_1);
        for (let y = 0; y < bet2_1.length; y++) {
          // let bet2=this.changeAmtlabl(Number(bet2_1[y]))
         
          // bet2_1[y] =bet2
          bet2_1[y] = Number(bet2_1[y]);
          if (Number(bet2_1[y]) <= this.innerLimits[1][1]) {
            this.suitBet[y] = Number(bet2_1[y]);
            if (Number(bet2_1[y]) > 0) {
              switch (y) {
                case 0:
                  console.log(document.getElementById("spade"));
                  document.getElementById("spade").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
                  break;
                case 1:
                  console.log(document.getElementById("club"));
                  document.getElementById("club").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
                  break;
                case 2:
                  console.log(document.getElementById("dimond"));
                  document.getElementById("dimond").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
                  break;
                case 3:
                  console.log(document.getElementById("heart"));
                  document.getElementById("heart").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet2_1[y])} </span> </p>`;
                  break;
              }
            }
          } else {
            this.setState({ infoText: this.props.language.MaxBetLmt });
          }
        }
        console.log(this.suitBet);

        let bet3 = this.props.gameState.betRequest.betString.split(",")[2];
        const bet3_1 = bet3.split("|");
        console.log(bet3_1);
        console.log(bet3_1.length);
        for (let t = 0; t < bet3_1.length; t++) {
          // let bet3=this.changeAmtlabl(Number(bet3_1[t]))
          // bet3_1[t] = bet3;
          bet3_1[t] = Number(bet3_1[t]);
          if (Number(bet3_1[t]) <= this.innerLimits[2][1]) {
            this.numBet[t] = Number(bet3_1[t]);
            console.log(this.numBet[t]);
            if (Number(bet3_1[t]) > 0) {
              if (t == 0) {
                document.getElementById("one_six").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet3_1[t])} </span> </p>`;
              } else {
                document.getElementById("eight_k").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet3_1[t])} </span> </p>`;
              }
            }
          } else {
            this.setState({ infoText: this.props.language.MaxBetLmt });
          }

        }
        console.log(this.numBet);
        console.log(this.numBet[0]);
        console.log(this.numBet[1]);


        let bet5 = this.props.gameState.betRequest.betString.split(",")[3];
        const bet5_1 = bet5.split("|");
        console.log(bet5_1);
        for (let e = 0; e < bet5_1.length; e++) {
        // let bet5=this.changeAmtlabl(Number(bet5_1[e]))
        
        // bet5_1[e] = bet5;

          bet5_1[e] = Number(bet5_1[e]);

          if (Number(bet5_1[e]) <= this.innerLimits[3][1]) {
            this.abBet[e] = Number(bet5_1[e]);
            if (Number(bet5_1[e]) > 0) {
              if (e == 0) {
                document.getElementById("andar").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet5_1[e])} </span> </p>`;
              } else {
                document.getElementById("bahar").innerHTML = `<p class="ab_goldcoin"> <span> ${Number(bet5_1[e])} </span> </p>`;
              }
            }
          } else {
            this.setState({ infoText: this.props.language.MaxBetLmt });
          }
        }
        console.log(this.abBet);
        this.setState({
          totalBetAmount: this.props.gameState.betRequest.betAmount,
          betAmt: this.props.gameState.betRequest.betAmount
        })
        if (this.props.gameState.handId) {
          this.handId = this.props.gameState.handId;
        }
      } else {
        this.setState({ infoText: this.props.language.BetAmtMaxBal });
      }
    }
  }

  disableBtn(btnId) {
    let disBtn = document.getElementById(btnId);
    if (disBtn) {
      disBtn.classList.add("ab_disBtn");
      disBtn.disabled = true;
    }
  }

  enableBtn(btnId) {
    console.log("Enable Button: " + btnId);
    let enableBtn = document.getElementById(btnId);
    console.log(enableBtn);
    if (enableBtn) {
      enableBtn.classList.remove("ab_disBtn");
      enableBtn.disabled = false;
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.fc_bets_handleKeyboardEvent);
    document.removeEventListener("keyup", this.fc_handleKeyboardEvent);
  }

  andarBaharTimer_GetTime() {
    console.log(this.props.ab_timeSend);
    if (this.props.ab_timeSend) {
      console.log(this.props.ab_timeSend);
      if (this.TopLable) {
        this.TopLable.sendTimer(this.props.ab_timeSend);
      }
    }
  }

  fc_bets_handleKeyboardEvent = (evt) => {
    console.log(this.state.betButtonValue);
    if (this.state.betButtonValue != 2 && this.state.isGameActive && this.state.enableKeyboard) {
      console.log(document.getElementById("ab_Cover"));
      console.log(document.getElementById("ab_Cover").style.display);
      if (document.getElementById("ab_Cover") && document.getElementById("ab_Cover").style.display != "block") {

        console.log(evt.keyCode);
        if (evt.keyCode >= 48 && evt.keyCode <= 57 && evt.keyCode != 49) {
          console.log(evt.key)
          if (evt.keyCode == 48) {
            this.selectedCard(9);
          } else {
            this.selectedCard(evt.key - 1);
          }
          this.betoncard = new Audio(betoncard)
          this.betoncard.play();
        }
        if (evt.keyCode >= 96 && evt.keyCode <= 105 && evt.keyCode != 97) {
          console.log(evt.key)
          if (evt.keyCode == 96) {
            this.selectedCard(9);

          } else {
            this.selectedCard(evt.key - 1);
          }
          this.betoncard = new Audio(betoncard)
          this.betoncard.play();
        }
        if (evt.keyCode == 74) {
          this.selectedCard(10);
          this.betoncard = new Audio(betoncard)
          this.betoncard.play();
        }
        if (evt.keyCode == 81) {
          this.selectedCard(11);
          this.betoncard = new Audio(betoncard)
          this.betoncard.play();
        }
        if (evt.keyCode == 75) {
          this.selectedCard(12);
          this.betoncard = new Audio(betoncard)
          this.betoncard.play();
        }
        if (evt.keyCode == 65) {
          this.selectedCard(0)
          this.betoncard = new Audio(betoncard)
          this.betoncard.play();
        }
      }
    }
  }

  fc_handleKeyboardEvent = (evt) => {
    console.log(evt);
    console.log(evt.keyCode);
    console.log(this.state.betButtonText + "      " + this.state.disableDealBtn);
    if (this.state.isGameActive) {
      switch (evt.code) {
        case "Enter":
        case "NumpadEnter":
          console.log(this.state.betButtonValue);
          console.log(this.state.disableDealBtn);
          if (this.state.betButtonValue != 2 && this.state.disableDealBtn == false && this.state.enableKeyboard) {
            console.log(this.state.betButtonText + "      " + this.state.disableDealBtn);
            this.bet_fn();
          }
          if (this.betSound) {
            this.betSound.play();
          }
          break;
        case "Space":
          console.log(this.state.betButtonText+"    "+this.state.betButtonValue+"    "+this.state.enableKeyboard+"    "+this.state.disableDealBtn);
          
          if (this.takeSound) {
            this.takeSound.play();
          }
          if (this.state.betButtonValue == 2 && this.state.disableDealBtn == false && this.state.enableKeyboard) {
            this.bet_fn();
          }
        //   break;
        // case "Escape":
        //   if (this.betSound) {
        //     this.betSound.play();
        //   }
        //   this.gameCloseHandler();
          break;
        case "KeyC":
          if (this.state.betButtonValue == 1 && this.state.disableDealBtn == false && this.state.enableKeyboard) {
            this.clear_fn();
          }
          if (this.clearSound) {
            this.clearSound.play();
          }
          break;
        case "KeyD":
          if (this.betSound) {
            this.betSound.play();
          }
          if (this.state.betButtonValue == 1 && this.state.disableDealBtn == false && this.state.totalBetAmount > 0 && this.state.enableKeyboard) {
            this.doubleAmount();
          }
          break
        case "default":
          break;
      }
    }
  }

  disableBtns() {
    
    this.setState({ enableKeyboard: false, startBlink: false });
    this.ChipsDealerPlayer.disableBetOptions();
    if (document.getElementById('ab_Cover')) {
      document.getElementById('ab_Cover').style.zIndex = 190;
      document.getElementById('ab_Cover').style.display = 'block';
    }

    if (document.getElementById("res_andar_Card")) {
      document.getElementById("res_andar_Card").style.display = "none";
    }

    if (document.getElementById("res_bahar_Card")) {
      document.getElementById("res_bahar_Card").style.display = "none";
    }
    // document.getElementById("resJokerCard").style.display = "none";

    const andhar_list = document.getElementById("res_andar_Card");
    if (andhar_list.hasChildNodes()) {
      while (andhar_list.hasChildNodes()) {
        andhar_list.removeChild(andhar_list.firstChild);
      }
    }
    const bahar_list = document.getElementById("res_bahar_Card");
    if (bahar_list.hasChildNodes()) {
      while (bahar_list.hasChildNodes()) {
        bahar_list.removeChild(bahar_list.firstChild);
      }
    }
  }

  check_spec() {
    console.log(this.state.totalBetAmount + " ----------   " + this.isSpectSent)
    console.log(" ----------   " + this.state.takeAmount);
    if (this.state.totalBetAmount < this.props.minBetAmt && !this.isSpectSent) {
      this.isSpectSent = true;
      this.props.spectatorCount(0, this.handId);
    } else if (this.state.takeAmount != 0 && this.isSpectSent == false) {
      this.isSpectSent = true;
      this.props.spectatorCount(0, this.handId);
    }
  }
  auto_bet() {
    // alert(this.state.totalBetAmount)
    if (!this.state.isBetSent && this.state.totalBetAmount > 0) {
      console.log('auto_bet')
      this.bet_fn();
    }
  }
  getPlayerInfo() {
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
      },
      sessionId: sessionStorage.getItem('sessionId'),
    };
    this.props.network.sendof(body2);
    this.body2 = {};
  }
  declare_Result() {
    console.log("Declare AB result: ");
    this.isSpectSent = false;
    if (this.state.isGameActive) {
      //console.log(this.props.andarBaharTimerResult);
      if (this.props.gameState.handId) {
        this.handId = this.props.gameState.handId;
      }

      // let winData = [0,0,0,0,1,0]
      if(this.props.andarBaharTimerResult.winData){
        this.ChipsDealerPlayer.glowWinImg(this.props.andarBaharTimerResult.winData);
      }

      if (this.props.andarBaharTimerResult.winAmount > 0) {
        document.getElementById('winPopandb').style.display = 'block'; 
        this.takeAmount();
        this.getPlayerInfo();

        if (this.winSound) {
          this.winSound.play();
        } 
        this.setState({ takeAmount: this.props.andarBaharTimerResult.winAmount, winGlowImg: true })
        
        this.ab_timeout3 = setTimeout(() => {          
          this.setState({
            // startBlink: true,
            // infoText: this.props.language.ClickTake,
           
            isBetSent: false,
            disableDealBtn: false,
          })

          this.resultShown = false;
          this.enableBtn("betBtn");

          document.getElementById("betBtn").style.zIndex = 180;
          if (document.getElementById('ab_Cover')) {
            document.getElementById('ab_Cover').style.zIndex = 150;
            document.getElementById('ab_Cover').style.display = 'block';
          }

          this.updateHistory();
          this.ab_timeout9 = setTimeout(() => {
            this.setState({ enableKeyboard: true })
          }, 500)

          this.ab_timeout8 = setTimeout(() => {
            document.getElementById('winPopandb').style.display = 'none'; 

            this.setState({ infoText: this.props.language.Play_Njoy + 
              this.props.language.MinBetAmt +" " +this.props.minBetLbl +" " +this.props.language.MaxBetAmt +" " +this.props.maxBetLbl});
          }, 1000)

        }, 500);        

      } else {
        this.getPlayerInfo();
        console.log(" Take Amount: " + this.state.takeAmount);
        
        this.ab_timeout5 = setTimeout(() => {
          this.setState({ disableDealBtn: false})
  
          if (this.state.takeAmount == 0) {
            this.setState({ isBetSent: false })
            if (document.getElementById("ab_Cover")) {
              document.getElementById("ab_Cover").style.display = "none";
            }
            this.clear_fn();
            if (this.props.gameState && this.props.gameState.betRequest && this.props.gameState.betRequest.betString) {
              this.ab_timeout12 = setTimeout(() => {
                this.enableBtn("betBtn");
                this.setState({ enableKeyboard: true });
              },500);
            }
          } else {
            this.enableBtn("betBtn");          
            document.getElementById("ab_Cover").style.zIndex = 150;
            this.ab_timeout13 = setTimeout(() => {
              this.setState({startBlink: true, enableKeyboard: true })
              document.getElementById("betBtn").style.zIndex = 180;
            },500);
          }          
          this.updateHistory();
          this.resultShown = false;
        }, 800)        
      }
    }
    this.props.gamstateHandle();
  }

  clear_fn() {
   
    this.cardBetAmt = 0;
    this.cardBet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.suitBet = [0, 0, 0, 0];
    this.numBet = [0, 0];
    this.abBet = [0, 0];

    this.setState({
      betAmt: 0,
      disable: true,
      betCard: null,
      btnActive: false,      
      disableClearBtn: true,
      infoText: this.props.language.Play_Njoy,
      totalBetAmount: 0,
      betButtonText: this.props.language.Prev,
      betButtonValue: 0,
      startBlink: false,
    });
    this.disableBtn("doubleBtn");
    this.disableBtn("clearBtn");
    console.log(this.props.gameState)
    if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0) {
      console.log(this.props.gameState.betRequest.betString);
      let preBet = (this.props.gameState.betRequest.betString).split(","[0]);
      console.log(preBet);

    } else {
      this.setState({ disableBetBtn: true });
      this.disableBtn("betBtn")
    }

    for (let m = 0; m < 13; m++) {
      if (document.getElementById("fID_" + m))
        document.getElementById("fID_" + m).innerHTML = `<p class="ab_goldcoin"/>`;
    }
    this.ab_betString = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    console.log(this.ab_betString)

    if (document.getElementById('heart')) {
      document.getElementById('heart').innerHTML = `<p class="ab_goldcoin"> </p>`;
    }
    if (document.getElementById('club')) {
      document.getElementById('club').innerHTML = `<p class="ab_goldcoin"> </p>`;
    }
    if (document.getElementById('spade')) {
      document.getElementById('spade').innerHTML = `<p class="ab_goldcoin"> </p>`;
    }
    if (document.getElementById('dimond')) {
      document.getElementById('dimond').innerHTML = `<p class="ab_goldcoin"> </p>`;
    }
    if (document.getElementById('one_six')) {
      document.getElementById('one_six').innerHTML = `<p class="ab_goldcoin"> </p>`;
    }    
    if (document.getElementById('eight_k')) {
      document.getElementById('eight_k').innerHTML = `<p class="ab_goldcoin"> </p>`;
    }
    if (document.getElementById('andar')) {
      document.getElementById('andar').innerHTML = `<p class="ab_goldcoin"> </p>`;
    }
    if (document.getElementById('bahar')) {
      document.getElementById('bahar').innerHTML = `<p class="ab_goldcoin"> </p>`;
    }
    if (document.getElementById("one_six")) {
      document.getElementById("one_six").innerHTML = `<p class="ab_goldcoin"> </p>`;
    }
    if (document.getElementById("eight_k")) {
      document.getElementById("eight_k").innerHTML = `<p class="ab_goldcoin"> </p>`;
    }
  }

  bet_fn() {
    if (this.state.isGameActive) {
      console.log(this.state.betButtonValue);

      if (this.state.betButtonValue == 0) {
        if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0) {
          if (this.props.gameState.betRequest.betAmount <= this.props.balance) {
            if (this.props.gameState.betRequest.betAmount <= this.props.maxBetAmt) {
              console.log(this.props.gameState.betRequest.betString);

              this.setState({ infoText: this.props.language.Play_Njoy });

              document.getElementById("res_andar_Card").style.display = "none";
              document.getElementById("res_bahar_Card").style.display = "none";
              document.getElementById("resJokerCard").style.display = "none";

              const andhar_list = document.getElementById("res_andar_Card");

          this.ChipsDealerPlayer.removeWinGlow();


              while (andhar_list.hasChildNodes()) {
                andhar_list.removeChild(andhar_list.firstChild);
              }

              const bahar_list = document.getElementById("res_bahar_Card");

              while (bahar_list.hasChildNodes()) {
                bahar_list.removeChild(bahar_list.firstChild);
              }

              this.showPreviousBet();

              this.setState({ betButtonValue: 1, betButtonText: this.props.language.BetOK, startBlink: true });
              this.enableBtn("clearBtn");
              this.enableBtn("doubleBtn");
            } else {
              this.setState({ infoText: this.props.language.PreBetLmt });
            }
          } else {
            this.setState({ infoText: this.props.language.BetAmtMaxBal });
          }
        }
      } else if (this.state.betButtonValue == 1) {
        console.log("AndarBahar Bet String")
        console.log(this.state.totalBetAmount);
        console.log(this.props.minBetAmt);

        if (this.state.totalBetAmount > 0 && this.state.totalBetAmount >= this.props.minBetAmt) {
          let cardBetValid = false;
          let suitBetValid = false;
          let atokBetValid = false;
          // let num7BetValid = false;
          let abBetValid = false;

          console.log(this.cardBet + "  ---  " + this.innerLimits[0][0]);
          for (let m = 0; m < this.cardBet.length; m++) {
            if (this.cardBet[m] > 0) {
              console.log(this.cardBet[m]);
              if (this.cardBet[m] >= this.innerLimits[0][0]) {
                console.log("Send Bet Request");
                cardBetValid = true;
              } else {
                cardBetValid = false;
                console.log("Dont send bet request")
                break;
              }
              // break;
            } else {
              cardBetValid = true;
            }
          }

          for (let n = 0; n < this.suitBet.length; n++) {
            if (this.suitBet[n] > 0) {
              console.log(this.suitBet[n]);
              if (this.suitBet[n] >= this.innerLimits[1][0]) {
                console.log("Send Bet Request");
                suitBetValid = true;
              } else {
                suitBetValid = false;
                console.log("Dont send bet request")
                break;
              }
            } else {
              suitBetValid = true;
            }
          }


          for (let q = 0; q < this.numBet.length; q++) {
            if (this.numBet[q] > 0) {
              console.log(this.numBet[q]);
              if (this.numBet[q] >= this.innerLimits[2][0]) {
                console.log("Send Bet Request");
                atokBetValid = true;
              } else {
                atokBetValid = false;
                console.log("Dont send bet request");
                break;
              }
            } else {
              atokBetValid = true;
            }
            if (this.abBet[q] > 0) {
              console.log(this.abBet[q]);
              if (this.abBet[q] >= this.innerLimits[3][0]) {
                console.log("Send Bet Request");
                abBetValid = true;
              } else {
                abBetValid = false;
                console.log("Dont send bet request");
                break;
              }
            } else {
              abBetValid = true;
            }
          }

          console.log("Bet Validation: " + cardBetValid + "  " + "  " + suitBetValid + "  " + atokBetValid + "  " + abBetValid)
          if (cardBetValid && suitBetValid && atokBetValid && abBetValid) {
            console.log("Send Bet Request");
            this.cardBet = this.cardBet.toString().replace(/,/g, '|');
            this.suitBet = this.suitBet.toString().replace(/,/g, '|');
            this.numBet = this.numBet.toString().replace(/,/g, '|');
            this.abBet = this.abBet.toString().replace(/,/g, '|');

            this.ab_betString = this.cardBet + "," + this.suitBet + "," + this.numBet + "," + this.abBet;

            console.log(this.ab_betString);
            this.setState({startBlink: false})
            if (document.getElementById("ab_Cover")) {
              document.getElementById("ab_Cover").style.display = "block";
            }
            this.disableBtn("betBtn");
            this.setState({ disableDealBtn: true });
            let ab_bet_body = {
              sessionId: sessionStorage.getItem("sessionId"),
              CN: "ANDAR_BAHAR_TIMER_BET",
              object: {
                betString: this.ab_betString,
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId,
                betAmount: this.state.totalBetAmount,
              },
            };
            this.props.network.sendof(ab_bet_body);
            this.ChipsDealerPlayer.removeWinGlow();
            // let pinfo_body = {
            //   CN: "GET_PLAYER_INFO",
            //   object: {
            //     playerId: Number(this.props.playerId),
            //     gameId: this.props.gameId,
            //   },
            //   sessionId: sessionStorage.getItem('sessionId'),
            // };
            // this.props.network.sendof(pinfo_body);

            document.getElementById("res_andar_Card").style.display = "none";
            document.getElementById("res_bahar_Card").style.display = "none";
            document.getElementById("resJokerCard").style.display = "none";
          } else {
            console.log("Inner Limits not matching.")
            this.setState({ infoText: this.props.language.MinBetLmt })
          }
        }else{
          this.setState({ infoText: this.props.language.MinBetLmt })

        }

      } 
      
      // else if (this.state.betButtonValue == 2) {
      //   this.disableBtn("betBtn");
      //   this.setState({ disableDealBtn: true });
      //   document.getElementById("ab_Cover").style.display = "block";
      //   // const body = {
      //   //   sessionId: sessionStorage.getItem('sessionId'),
      //   //   CN: "TAKE_AMOUNT",
      //   //   object: {
      //   //     gameId: this.props.gameId,
      //   //     playerId: Number(this.props.playerId),
      //   //   },
      //   // };
      //   // //console.log(body);
      //   // this.props.network.sendof(body);
      //   // this.body = {};

      //   this.ChipsDealerPlayer.removeWinGlow();

      //   this.clear_fn();
       
      //   document.getElementById("betBtn").style.zIndex = 80;
      //   this.setState({ btnActive: false });
      //   document.getElementById("res_andar_Card").style.display = "none";
      //   document.getElementById("res_bahar_Card").style.display = "none";
      //   document.getElementById("resJokerCard").style.display = "none";

      //   const andhar_list = document.getElementById("res_andar_Card");
        
      //   this.ab_timeout11 = setTimeout(() => {       
      //     this.setState({ 
      //       btnActive: false,
      //       takeAmount: 0,
      //       disableDealBtn: false,
      //       winGlowImg: false,
      //       betAmt: 0,
      //       betCard: null,
      //       infoText: this.props.language.Play_Njoy,
      //       startBlink: false,
      //     })
      //     this.enableBtn("betBtn"); 
      //     document.getElementById("ab_Cover").style.display = "none";
      //   },800)
      
      //   while (andhar_list.hasChildNodes()) {
      //     andhar_list.removeChild(andhar_list.firstChild);
      //   }

      //   const bahar_list = document.getElementById("res_bahar_Card");

      //   while (bahar_list.hasChildNodes()) {
      //     bahar_list.removeChild(bahar_list.firstChild);
      //   }

      //   console.log("isPlayerBanned " + this.props.isPlayerBanned);
      //   if (this.props.isPlayerBanned) {
      //     this.gameCloseHandler("target");
      //     this.props.player_banned();
      //   }

      //   console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
      //   if (this.props.gameUnderMaintanance) {
      //     this.gameCloseHandler("target");
      //     this.props.game_maintanance("Game under maintenance, reach to admin.");
      //   }

      //   console.log("deviceBlocked " + this.props.isdeviceBlocked);
      //   if (this.props.isdeviceBlocked) {
      //     this.gameCloseHandler("target");
      //     this.props.device_blocked();
      //   }

        
      // }
    }
  }






  takeAmount(){
    // this.enableBtn("clearBtn");
    this.disableBtn("betBtn");
      this.setState({ disableDealBtn: true });
      document.getElementById("ab_Cover").style.display = "block";
      const body = {
        sessionId: sessionStorage.getItem('sessionId'),
        CN: "TAKE_AMOUNT",
        object: {
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
        },
      };
      //console.log(body);
      this.props.network.sendof(body);
      this.body = {};

      // this.ChipsDealerPlayer.removeWinGlow();

      this.clear_fn();
     
      document.getElementById("betBtn").style.zIndex = 80;
      this.setState({ btnActive: false });
      // document.getElementById("res_andar_Card").style.display = "none";
      // document.getElementById("res_bahar_Card").style.display = "none";
      // document.getElementById("resJokerCard").style.display = "none";

      // const andhar_list = document.getElementById("res_andar_Card");
      
      this.ab_timeout11 = setTimeout(() => {       
        this.setState({ 
          btnActive: false,
          takeAmount: 0,
          disableDealBtn: false,
          winGlowImg: false,
          betAmt: 0,
          betCard: null,
          infoText: this.props.language.Play_Njoy,
          startBlink: false,
        })
        //  this.enableBtn("betBtn"); 
        // document.getElementById("ab_Cover").style.display = "none";
      },1500)
    
      // while (andhar_list.hasChildNodes()) {
      //   andhar_list.removeChild(andhar_list.firstChild);
      // }

      // const bahar_list = document.getElementById("res_bahar_Card");

      // while (bahar_list.hasChildNodes()) {
      //   bahar_list.removeChild(bahar_list.firstChild);
      // }

      console.log("isPlayerBanned " + this.props.isPlayerBanned);
      if (this.props.isPlayerBanned) {
        this.gameCloseHandler("target");
        this.props.player_banned();
      }

      console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
      if (this.props.gameUnderMaintanance) {
        this.gameCloseHandler("target");
        this.props.game_maintanance("Game under maintenance, reach to admin.");
      }

      console.log("deviceBlocked " + this.props.isdeviceBlocked);
      if (this.props.isdeviceBlocked) {
        this.gameCloseHandler("target");
        this.props.device_blocked();
      }

      

  }
  gameClosed() {

    window.clearTimeout(this.ab_timeout1);
    window.clearTimeout(this.ab_timeout2);
    window.clearTimeout(this.ab_timeout3);
    window.clearTimeout(this.ab_timeout4);
    window.clearTimeout(this.ab_timeout5);
    window.clearTimeout(this.ab_timeout6);
    window.clearTimeout(this.ab_timeout7);
    window.clearTimeout(this.ab_timeout8);
    window.clearTimeout(this.ab_timeout9);
 
    if (this.TopLable) {
      this.TopLable.closeGame();
    }

    this.setState({ isGameActive: false, enableKeyboard: false });

    console.log(this.winSound);
    if (this.winSound.playing) {
      this.winSound.pause();
    }
    this.betSound.pause();
    this.takeSound.pause();

  }
  gameCloseHandler(game) {
    this.close.play();
    window.clearTimeout(this.ab_timeout1);
    window.clearTimeout(this.ab_timeout2);
    window.clearTimeout(this.ab_timeout3);
    window.clearTimeout(this.ab_timeout4);
    window.clearTimeout(this.ab_timeout5);
    window.clearTimeout(this.ab_timeout6);
    window.clearTimeout(this.ab_timeout7);
    window.clearTimeout(this.ab_timeout8);
    window.clearTimeout(this.ab_timeout9);
 

    if (this.TopLable) {
      this.TopLable.closeGame();
    }

    this.setState({ isGameActive: false, enableKeyboard: false });

    console.log(this.winSound);
    if (this.winSound.playing) {
      this.winSound.pause();
    }
    this.betSound.pause();
    this.takeSound.pause();
    this.props.action(game);
  }

  getChipVal(val) {
    console.log("Selected Chip Value: " + val);
    if (this.betSound) {
      this.betSound = new Audio(betSound);
      this.betSound.play();
    }
    this.setState({
      chipVal: Number(val),
    });
  }

  selecteSuit(id) {
    console.log(id)
    // let totalBetAmt = 0;

    // let maxInnerValue = 0;
    console.log(" totalBetAmount  " + this.state.totalBetAmount);
    if (this.state.chipVal != 0) {
      let totBet = Number(this.state.chipVal + this.state.totalBetAmount);
      console.log(totBet)
      if ((this.state.chipVal + this.state.totalBetAmount) <= this.props.balance) {
        console.log("Max Bet Amount: "+this.props.maxBetAmt)
        if ((this.state.chipVal + this.state.totalBetAmount) <= this.props.maxBetAmt) {
          let suitBetAmt = 0;

          switch (id) {
            case "spade":
             // suitBetAmt=this.changeAmtlabl(Number(this.suitBet[0]) + Number(this.state.chipVal))
          
               suitBetAmt = Number(this.suitBet[0]) + Number(this.state.chipVal);
              this.setState({ maxInnerValue: this.innerLimits[1][1] });
              this.state.maxInnerValue = Number(this.innerLimits[1][1]);
              if (suitBetAmt <= Number(this.innerLimits[1][1]) && totBet <= this.props.maxBetAmt) {
                this.suitBet[0] = suitBetAmt;
              }
              break;
            case "club":
              suitBetAmt = Number(this.suitBet[1]) + Number(this.state.chipVal);
              this.setState({ maxInnerValue: this.innerLimits[1][1] });
              this.state.maxInnerValue = Number(this.innerLimits[1][1]);
              if (suitBetAmt <= Number(this.innerLimits[1][1]) && totBet <= this.props.maxBetAmt) {
                this.suitBet[1] = suitBetAmt;
              }
              break;
            case "dimond":
              console.log(Number(this.suitBet[2]));
              console.log(Number(this.state.chipVal));
              console.log(Number(this.innerLimits[1][1]));
              suitBetAmt = Number(this.suitBet[2]) + Number(this.state.chipVal);
              console.log("Dimond Bet Amount: " + suitBetAmt)
              console.log("maxBetAmt Amount: " + this.props.maxBetAmt)
              console.log("TotalBet: " + this.state.totalBetAmount);

              this.setState({ maxInnerValue: Number(this.innerLimits[1][1]) });
              this.state.maxInnerValue = Number(this.innerLimits[1][1]);

              if (suitBetAmt <= Number(this.innerLimits[1][1]) && totBet <= this.props.maxBetAmt) {
                this.suitBet[2] = suitBetAmt;
              }
              console.log(this.innerLimits[1][1])
              console.log(this.state.maxInnerValue)
              break;
            case "heart":
              suitBetAmt = Number(this.suitBet[3]) + Number(this.state.chipVal);
              this.setState({ maxInnerValue: this.innerLimits[1][1] });
              this.state.maxInnerValue = Number(this.innerLimits[1][1]);
              if (suitBetAmt <= Number(this.innerLimits[1][1]) && totBet <= this.props.maxBetAmt) {
                console.log(this.suitBet[3]);
                console.log(typeof (this.suitBet[3]));
                this.suitBet[3] = suitBetAmt;
              }
              break;
            case "andar":
              console.log(this.abBet)
              suitBetAmt = Number(this.abBet[0]) + Number(this.state.chipVal);
              this.setState({ maxInnerValue: this.innerLimits[3][1] })
              this.state.maxInnerValue = Number(this.innerLimits[3][1]);
              if (suitBetAmt <= Number(this.innerLimits[3][1]) && totBet <= this.props.maxBetAmt) {
                this.abBet[0] = suitBetAmt;
              }
              break;
            case "bahar":
              console.log(this.abBet)
              suitBetAmt = Number(this.abBet[1]) + Number(this.state.chipVal);
              this.setState({ maxInnerValue: this.innerLimits[3][1] })
              this.state.maxInnerValue = Number(this.innerLimits[3][1]);
              if (suitBetAmt <= Number(this.innerLimits[3][1]) && totBet <= this.props.maxBetAmt) {
                this.abBet[1] = suitBetAmt;
              }
              break;
            case "eight_k":
              suitBetAmt = Number(this.numBet[1]) + Number(this.state.chipVal);
              this.setState({ maxInnerValue: this.innerLimits[2][1] })
              if (suitBetAmt <= Number(this.innerLimits[2][1]) && totBet <= this.props.maxBetAmt) {
                this.numBet[1] = suitBetAmt;
              }
              this.state.maxInnerValue = Number(this.innerLimits[2][1]);
              break;            
            case "one_six":
              this.setState({ maxInnerValue: this.innerLimits[2][1] });
              this.state.maxInnerValue = Number(this.innerLimits[2][1]);
              console.log(this.numBet);
              console.log(this.numBet);
              console.log(Number(this.numBet[0]))
              console.log(Number(this.numBet[1]))
             
              console.log(Number(this.state.chipVal))

              console.log(Number(this.numBet[0]) + Number(this.numBet[1]) + Number(this.state.chipVal))

              suitBetAmt = Number(this.numBet[0]) + Number(this.state.chipVal);
             
              console.log("1-6: BetAmt: " + suitBetAmt)
              if (suitBetAmt <= Number(this.innerLimits[2][1]) && totBet <= this.props.maxBetAmt) {
                this.numBet[0] = Number(suitBetAmt);
              }
              break;
          }
          if (suitBetAmt <= this.state.maxInnerValue) {
            let suitebets=this.changeAmtlabl(suitBetAmt)
            console.log("id: " + id)
            if (id != "andar" && id != "bahar") {
              document.getElementById(id).innerHTML = `<p class="ab_goldcoin"> <span id=${id + '_cnt'}> ${suitebets} </span> </p>`;
            } else {
              document.getElementById(id).innerHTML = `<p class="ab_goldcoin"> <span id=${id + '_cnt'}> ${suitebets} </span> </p>`;
            }
            this.setState({
              totalBetAmount: this.state.totalBetAmount + this.state.chipVal,
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl 
            })
          

          } else {
            let maxInnerLimit=this.changeAmtlabl(this.state.maxInnerValue )
            this.setState({
              infoText: this.props.language.MaxInrBetAmt + maxInnerLimit            
            })
          }
        }else{
           this.setState({
            infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl 
            // infoText: this.props.language.Play_Njoy + " " + this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt
        })
        }
      } else {
        this.setState({
          infoText: this.props.language.LowBalance
        })
      }

    } else {
      let currentSuitBetAmt = 0;
      switch (id) {
        case "spade":
          currentSuitBetAmt = Number(this.suitBet[0]);
          this.suitBet[0] = 0;
          break;
        case "club":
          currentSuitBetAmt = Number(this.suitBet[1]);
          this.suitBet[1] = 0;
          break;
        case "dimond":
          currentSuitBetAmt = Number(this.suitBet[2]);
          this.suitBet[2] = 0;
          break;
        case "heart":
          console.log(Number(this.suitBet[3]));
          currentSuitBetAmt = Number(this.suitBet[3]);
          this.suitBet[3] = 0;
          break;
        case "andar":
          currentSuitBetAmt = Number(this.abBet[0]);
          this.abBet[0] = 0;
          break;
        case "bahar":
          currentSuitBetAmt = Number(this.abBet[1]);
          this.abBet[1] = 0;
          break;
        case "eight_k":
          currentSuitBetAmt = Number(this.numBet[1]);
          this.numBet[1] = 0;
          break;
        // case "bet_7":
        //   currentSuitBetAmt = Number(this.numBet7);
        //   this.numBet7 = 0;
        //   break;
        case "one_six":
          currentSuitBetAmt = Number(this.numBet[0]);
          this.numBet[0] = 0;
          break;
      }

      console.log(" currentSuitBetAmt  " + currentSuitBetAmt);
      console.log(" totalBetAmount  " + this.state.totalBetAmount);
      let updatedBal = Number(this.state.totalBetAmount - currentSuitBetAmt);
      console.log(updatedBal)
      this.state.totalBetAmount = updatedBal;
      this.setState({
        totalBetAmount: updatedBal
      })

      console.log(this.state.totalBetAmount)
      if (this.state.totalBetAmount == 0) {
     
        this.disableBtn("clearBtn");
        this.disableBtn("doubleBtn");
        console.log("Game state Bet Request: " + this.props.gameState.betRequest)
        this.setState({
          betButtonText: this.props.language.Prev,
          betButtonValue: 0,
        })
        if (this.props.gameState && this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0) {
          this.enableBtn("betBtn");
        } else {
          this.disableBtn("betBtn");
        }
      }
      document.getElementById(id).innerHTML = `<p class="ab_goldcoin"> </p>`;
    }

    if (this.state.totalBetAmount + this.state.chipVal > 0) {
      console.log(this.state.totalBetAmount)
      this.setState({ betButtonText: this.props.language.BetOK, betButtonValue: 1, startBlink: true });
      if (document.getElementById("clearBtn").classList.contains("ab_disBtn")) {
        this.enableBtn("clearBtn");
      }
      if (document.getElementById("doubleBtn").classList.contains("ab_disBtn")) {
        this.enableBtn("doubleBtn");
      }
      if (document.getElementById("betBtn").classList.contains("ab_disBtn")) {
        this.enableBtn("betBtn");
      }
    }

    // if (totalBetAmt <= this.props.balance) {
    //   if (totalBetAmt <= this.props.maxBetAmt) {
    //     console.log(suitBetAmt);
    //     // if (suitBetAmt == 0) {
    //     //   document.getElementById(id).innerHTML = `<p class="ab_goldcoin"> </p>`;
    //     // } else {
    //       console.log(suitBetAmt);
    //       console.log(typeof (suitBetAmt));
    //       console.log(this.state.maxInnerValue);
    //       console.log(typeof (this.state.maxInnerValue));

    //     // }
    //   }
    // }
  }


  clearcardspreviuosglows(){
    this.disableBtn("betBtn");
    this.setState({ disableDealBtn: true });
    document.getElementById("ab_Cover").style.display = "block";
   
   
  
    //this.clear_fn();
   
    document.getElementById("betBtn").style.zIndex = 80;
    this.setState({ btnActive: false });
    document.getElementById("res_andar_Card").style.display = "none";
    document.getElementById("res_bahar_Card").style.display = "none";
    document.getElementById("resJokerCard").style.display = "none";
  
    const andhar_list = document.getElementById("res_andar_Card");
    
    this.ab_timeout11 = setTimeout(() => {       
      this.setState({ 
        btnActive: false,
        takeAmount: 0,
        disableDealBtn: false,
        betCard: null,
        startBlink: false,
      })
      this.enableBtn("betBtn"); 
      document.getElementById("ab_Cover").style.display = "none";
    },1500)
  
    while (andhar_list.hasChildNodes()) {
      andhar_list.removeChild(andhar_list.firstChild);
    }
  
    const bahar_list = document.getElementById("res_bahar_Card");
  
    while (bahar_list.hasChildNodes()) {
      bahar_list.removeChild(bahar_list.firstChild);
    }




  }
  


  changeAmtlabl(chngAmt){
    let newAmt = chngAmt;
    if(newAmt >= 1000 && newAmt <=999999){
      newAmt = newAmt / 1000+"K";      
    }else if(newAmt >= 1000000 && newAmt <=999999999){
      newAmt = newAmt / 1000+"M";    
    }else if(newAmt >999999999){
      newAmt = newAmt / 1000+"B";      
    }
    return newAmt;
}
  handleKeyboard(resp) {
    this.setState({ enableKeyboard: resp });
  }

  selectedCard(id) {
    console.log("Selected Card Number: " + id);
    let totalBetVal = 0;
    console.log(this.state.totalBetAmount);
    if(this.state.totalBetAmount == 0){
      this.ChipsDealerPlayer.removeWinGlow();
    }

    console.log(this.state.chipVal);
    if (this.state.chipVal != 0) {
      if ((this.state.totalBetAmount + this.state.chipVal) <= this.props.maxBetAmt) {
        totalBetVal += Number(this.state.chipVal);   
    
        if ((Number(this.state.totalBetAmount) + Number(this.state.chipVal)) <= this.props.balance) {
          console.log(this.cardBet[id])
          console.log( Number(this.state.chipVal))
          console.log("--- " + (this.cardBet[id] + Number(this.state.chipVal)));
          console.log("Inner Limit: " + this.innerLimits[0][1])
          if ((Number(this.cardBet[id]) + Number(this.state.chipVal)) <= this.innerLimits[0][1]) {

        
            // this.cardBetlblchange[id] +=(Number(this.state.chipVal)); 
             console.log(this.cardBetlblchange[id] )
        
       
          
            // alert(""+this.cardBetlblchange[id])
            
          
            this.cardBetAmt += Number(this.state.chipVal);
           
            this.cardBet[id] += Number(this.state.chipVal);
            let Changebetslbl=this.changeAmtlabl(this.cardBet[id])
            this.setState({
              betCard: id,
              betAmt: this.cardBetAmt,
              disable: false,
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
              // infoText: this.props.language.Play_Njoy + " " + this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt,
              disableClearBtn: false,
              btnActive: true,
              disableDealBtn: false,
              totalBetAmount: this.state.totalBetAmount + Number(this.state.chipVal),
              betButtonText: this.props.language.BetOK,              
              betButtonValue: 1,
              startBlink: true,
            });

            if (document.getElementById("clearBtn").classList.contains("ab_disBtn")) {
              this.enableBtn("clearBtn");
            }
            if (document.getElementById("doubleBtn").classList.contains("ab_disBtn")) {
              this.enableBtn("doubleBtn");
            }
            if (document.getElementById("betBtn").classList.contains("ab_disBtn")) {
              this.enableBtn("betBtn");
            }
            // document.getElementById("fID_" + id).innerHTML = `<p class="ab_goldcoin"> <span> ${cardBetAmt} </span> </p>`;
              
              document.getElementById("fID_" + id).innerHTML = `<p class="ab_goldcoin"> <span> ${Changebetslbl} </span> </p>`;
            
          } else {
            console.log("Inner Limits Exceeded.")
            this.setState({
              infoText: this.props.language.MaxInrBetAmt + this.innerLimits[0][1]
            })
          }
        } else {
          this.setState({ infoText: this.props.language.LowBalance });
          console.log("amount reduces to your balance");
        }

        if (this.state.betAmt == 0 && this.state.chipVal == 0) {
          // this.setState({ infoText: this.props.language.Play_Njoy + " " + this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt });
          this.setState({infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl});
        }
      } else {
        // this.setState({ infoText: this.props.language.Play_Njoy + " " + this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt });
        this.setState({infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl});
      }

    } else {
      console.log(this.state.betAmt)
      console.log(Number(this.cardBet[id]))
      if (Number(this.cardBet[id]) > 0) {
        document.getElementById("fID_" + id).innerHTML = `<p class="ab_goldcoin"/>`;
        this.setState({
          //betAmt: (Number(this.state.betAmt) - Number(this.cardBet[id])),
          infoText: this.props.language.Play_Njoy,
          disable: true,
          btnActive: false,
          disableClearBtn: true,
          //totalBetAmount: Number(this.state.totalBetAmount) - Number(this.cardBet[id])
        });
        console.log(this.cardBet);
        console.log(Number(this.cardBet[id]));
        console.log(this.state.totalBetAmount);
        //console.log(this.state.betAmt);
        let updatedBal2 = Number(this.state.totalBetAmount) - Number(this.cardBet[id]);
        this.state.totalBetAmount = updatedBal2;
        console.log(this.state.totalBetAmount)
        totalBetVal = 0;
        this.cardBetAmt = (Number(this.cardBetAmt) - Number(this.cardBet[id]));
        this.cardBet[id] = 0;
        //this.cardBet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (this.state.totalBetAmount == 0) {
          this.disableBtn("clearBtn");
          this.disableBtn("doubleBtn");
          // this.disableBtn("betBtn")
          this.setState({
            betButtonText: this.props.language.Prev,
            betButtonValue: 0,
          })
          if (this.props.gameState && this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0) {
            this.enableBtn("betBtn");
          } else {
            this.disableBtn("betBtn");
          }
        }
      }
    }
  }

  doubleAmount() {
    if (this.state.isGameActive) {
      console.log("Total Bet amount: " + this.state.totalBetAmount);
      console.log(this.innerLimits[0][1]);
      console.log(this.cardBet);

      if ((this.state.totalBetAmount * 2) <= this.props.balance) {
        let totalSuitAmt = 0;
        let totalNumBet = 0;
        let totalABBet = 0;
        if ((this.state.totalBetAmount * 2) <= this.props.maxBetAmt) {

          this.cardBetAmt = 0;

          console.log("Rank Cards Inner Limit: " + this.innerLimits[0][1]);
          for (let v = 0; v < this.cardBet.length; v++) {

            console.log("Rank Cards Bet Amount: " + Number(this.cardBet[v]));

            if (Number(this.cardBet[v]) > 0) {
              let cardBet=this.changeAmtlabl(Number(this.cardBet[v] * 2))

              if ((Number(this.cardBet[v] * 2) <= this.innerLimits[0][1])) {
                //this.cardBetAmt = Number(this.cardBetAmt * 2);
                console.log(Number(this.cardBet[v]));
                // let cardBet=this.changeAmtlabl(Number(this.cardBet[v] * 2))
                this.cardBet[v] = Number(this.cardBet[v] * 2);
                // this.cardBet[v] = cardBet;
                this.cardBetAmt += this.cardBet[v];
                console.log(v);
                console.log(Number(this.cardBet[v]));
                // this.cardBet[this.state.betCard] = this.cardBetAmt;
                if (document.getElementById("fID_" + v)) {
                  document.getElementById("fID_" + v).innerHTML = `<p class="ab_goldcoin"> <span> ${cardBet} </span> </p>`;
                }
              } else {
                this.cardBetAmt += this.cardBet[v];
              }
            } else {
              // this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt })
              this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl})
            }
          }

          console.log("Card Bet Amount: " + this.cardBetAmt);

          for (let k = 0; k < this.suitBet.length; k++) {
            if (this.suitBet[k] > 0) {
              if (Number(this.suitBet[k] * 2) <= this.innerLimits[1][1]) {
                let suitbetamnt=this.changeAmtlabl(Number(this.suitBet[k] * 2))
                this.suitBet[k] = Number(this.suitBet[k] * 2);

                switch (k) {
                  case 0:
                    document.getElementById("spade").innerHTML = `<p class="ab_goldcoin"> <span> ${suitbetamnt} </span> </p>`;
                    break;
                  case 1:
                    document.getElementById("club").innerHTML = `<p class="ab_goldcoin"> <span> ${suitbetamnt} </span> </p>`;
                    break;
                  case 2:
                    document.getElementById("dimond").innerHTML = `<p class="ab_goldcoin"> <span> ${suitbetamnt} </span> </p>`;
                    break;
                  case 3:
                    document.getElementById("heart").innerHTML = `<p class="ab_goldcoin"> <span> ${suitbetamnt} </span> </p>`;
                    break;
                }
                
                totalSuitAmt += this.suitBet[k];
                
              } else {
                totalSuitAmt += this.suitBet[k];
              }
            } else {
              totalSuitAmt += this.suitBet[k];
              // this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt })
              this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl})
            }
          }

          for (let m = 0; m < this.abBet.length; m++) {
            // let abBet=this.changeAmtlabl(Number(this.abBet[m] * 2))
            if (this.abBet[m] > 0) {
              if (Number(this.abBet[m] * 2) <= this.innerLimits[3][1]) {
                let abBet=this.changeAmtlabl(Number(this.abBet[m] * 2))
                this.abBet[m] = Number(this.abBet[m] * 2);
                // this.abBet[m] = abBet;
                if (m == 0) {
                  document.getElementById("andar").innerHTML = `<p class="ab_goldcoin"> <span> ${abBet} </span> </p>`;
                } else {
                  document.getElementById("bahar").innerHTML = `<p class="ab_goldcoin"> <span> ${abBet} </span> </p>`;
                }
                totalABBet += this.abBet[m];
              } else {
                totalABBet += this.abBet[m];
              }
            } else {
              totalABBet += this.abBet[m];
              // this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt })
              this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl })

            }
          }

          for (let j = 0; j < this.numBet.length; j++) {
            
            if (this.numBet[j] > 0 && Number(this.numBet[j] * 2) <= this.innerLimits[2][1]) {
              let numBet=this.changeAmtlabl(Number(this.numBet[j] * 2))
             this.numBet[j] = Number(this.numBet[j] * 2);
            
            
              //  this.numBet[j] = numBet;
              //  console.log(this.numBet[j])
              
              if (j == 0) {
                document.getElementById("one_six").innerHTML = `<p class="ab_goldcoin"> <span> ${numBet} </span> </p>`;
              } else {
                document.getElementById("eight_k").innerHTML = `<p class="ab_goldcoin"> <span> ${numBet} </span> </p>`;
              }
              totalNumBet += this.numBet[j];
            } else {
              totalNumBet += this.numBet[j];
              // this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt });
              this.setState({infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl});
            }
          }

          console.log("Total suit amount: " + totalSuitAmt);
          console.log("Total AB amount: " + totalABBet);
          // console.log("Total 7num amount: " + this.numBet7);
          console.log("Total number amount: " + totalNumBet);
          this.setState({
             totalBetAmount: Number(totalSuitAmt + totalABBet + totalNumBet + this.cardBetAmt)
          })
        } else {
          this.setState({
            infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl
            // infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt
          })
        }
      } else {
        console.log("amount reduces to your balance");
        this.setState({ infoText: this.props.language.LowBalance, disable: true });
      }
    }
  }
  // doubleAmount() {
  //   if (this.state.isGameActive) {
  //     console.log("Total Bet amount: " + this.state.totalBetAmount);
  //     console.log(this.innerLimits[0][1]);
  //     console.log(this.cardBet);

  //     if ((this.state.totalBetAmount * 2) <= this.props.balance) {
  //       let totalSuitAmt = 0;
  //       let totalNumBet = 0;
  //       let totalABBet = 0;
  //       if ((this.state.totalBetAmount * 2) <= this.props.maxBetAmt) {

  //         this.cardBetAmt = 0;

  //         console.log("Rank Cards Inner Limit: " + this.innerLimits[0][1]);
  //         for (let v = 0; v < this.cardBet.length; v++) {

  //           console.log("Rank Cards Bet Amount: " + Number(this.cardBet[v]));

  //           if (Number(this.cardBet[v]) > 0) {
  //             if ((Number(this.cardBet[v] * 2) <= this.innerLimits[0][1])) {
  //               //this.cardBetAmt = Number(this.cardBetAmt * 2);
  //               console.log(Number(this.cardBet[v]));
  //               this.cardBet[v] = Number(this.cardBet[v] * 2);
  //               this.cardBetAmt += this.cardBet[v];
  //               console.log(v);
  //               console.log(Number(this.cardBet[v]));
  //               // this.cardBet[this.state.betCard] = this.cardBetAmt;
  //               if (document.getElementById("fID_" + v)) {
  //                 document.getElementById("fID_" + v).innerHTML = `<p class="ab_goldcoin"> <span> ${Number(this.cardBet[v])} </span> </p>`;
  //               }
  //             } else {
  //               this.cardBetAmt += this.cardBet[v];
  //             }
  //           } else {
  //             this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt })
  //           }
  //         }

  //         console.log("Card Bet Amount: " + this.cardBetAmt);

  //         for (let k = 0; k < this.suitBet.length; k++) {
  //           if (this.suitBet[k] > 0) {
  //             if (Number(this.suitBet[k] * 2) <= this.innerLimits[1][1]) {
  //               this.suitBet[k] = Number(this.suitBet[k] * 2);
  //               switch (k) {
  //                 case 0:
  //                   document.getElementById("spade").innerHTML = `<p class="ab_goldcoin"> <span> ${this.suitBet[k]} </span> </p>`;
  //                   break;
  //                 case 1:
  //                   document.getElementById("club").innerHTML = `<p class="ab_goldcoin"> <span> ${this.suitBet[k]} </span> </p>`;
  //                   break;
  //                 case 2:
  //                   document.getElementById("dimond").innerHTML = `<p class="ab_goldcoin"> <span> ${this.suitBet[k]} </span> </p>`;
  //                   break;
  //                 case 3:
  //                   document.getElementById("heart").innerHTML = `<p class="ab_goldcoin"> <span> ${this.suitBet[k]} </span> </p>`;
  //                   break;
  //               }
  //               totalSuitAmt += this.suitBet[k];
  //             } else {
  //               totalSuitAmt += this.suitBet[k];
  //             }
  //           } else {
  //             totalSuitAmt += this.suitBet[k];
  //             this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt })
  //           }
  //         }

  //         for (let m = 0; m < this.abBet.length; m++) {
  //           if (this.abBet[m] > 0) {
  //             if (Number(this.abBet[m] * 2) <= this.innerLimits[3][1]) {
  //               this.abBet[m] = Number(this.abBet[m] * 2);
  //               if (m == 0) {
  //                 document.getElementById("andar").innerHTML = `<p class="ab_goldcoin"> <span> ${this.abBet[m]} </span> </p>`;
  //               } else {
  //                 document.getElementById("bahar").innerHTML = `<p class="ab_goldcoin"> <span> ${this.abBet[m]} </span> </p>`;
  //               }
  //               totalABBet += this.abBet[m];
  //             } else {
  //               totalABBet += this.abBet[m];
  //             }
  //           } else {
  //             totalABBet += this.abBet[m];
  //             this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt })

  //           }
  //         }

  //         for (let j = 0; j < this.numBet.length; j++) {
  //           if (this.numBet[j] > 0 && Number(this.numBet[j] * 2) <= this.innerLimits[2][1]) {
  //             this.numBet[j] = Number(this.numBet[j] * 2);
  //             if (j == 0) {
  //               document.getElementById("one_six").innerHTML = `<p class="ab_goldcoin"> <span> ${this.numBet[j]} </span> </p>`;
  //             } else {
  //               document.getElementById("eight_k").innerHTML = `<p class="ab_goldcoin"> <span> ${this.numBet[j]} </span> </p>`;
  //             }
  //             totalNumBet += this.numBet[j];
  //           } else {
  //             totalNumBet += this.numBet[j];
  //             this.setState({ infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt });
  //           }
  //         }

  //         console.log("Total suit amount: " + totalSuitAmt);
  //         console.log("Total AB amount: " + totalABBet);
  //         // console.log("Total 7num amount: " + this.numBet7);
  //         console.log("Total number amount: " + totalNumBet);
  //         this.setState({
  //           totalBetAmount: Number(totalSuitAmt + totalABBet + totalNumBet + this.cardBetAmt)
  //         })
  //       } else {
  //         this.setState({
  //           infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt
  //         })
  //       }
  //     } else {
  //       console.log("amount reduces to your balance");
  //       this.setState({ infoText: this.props.language.LowBalance, disable: true });
  //     }
  //   }
  // }



  updateMessage = () => {
    console.log("--------- " + this.props.infoText);
    this.setState({ infoText: this.props.infoText });

    
    console.log("error_code: " + this.props.error_code);
    if (this.props.error_code == "game.bet.invalid") {
      this.clear_fn();
      this.setState({
        betButtonText: this.props.language.Prev,
        betButtonValue: 0,
        btnActive: false,
        disBetBtn: 0,
        isBetSent: false,
        infoText: this.props.language.InvalidBet
      });

      document.getElementById('ab_Cover').style.display = 'none';
      if (this.props.gameState && this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0) {
        this.setState({ disableDealBtn: false, })
        this.enableBtn("betBtn");
      }
    }  else if (this.props.error_code == "game.bet.accepted") {
      this.setState({ isBetSent: true, enableKeyboard: false })
    }
  }



  updateHistory(hisData) {
    this.ChipsDealerPlayer.updateHistory(this.props.gameState.history);
  }

  andarBaharTimerGetResp() {
    this.resultShown = true;
    if (document.getElementById('ab_Cover')) {
      document.getElementById('ab_Cover').style.zIndex = 190;
      document.getElementById('ab_Cover').style.display = 'block';
    }

    this.disableBtn("clearBtn");
    this.disableBtn("doubleBtn");
    this.disableBtn("betBtn");
    this.setState({ enableKeyboard: false });

    console.log(this.props.andarBaharTimerResult);
    if (this.ChipsDealerPlayer) {
      this.ChipsDealerPlayer.updateResult(this.props.andarBaharTimerResult);
    }
    if (this.TopLable) {
      this.TopLable.updateResStatus();
    }
  }
  topLable_fn(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs(Number(labelValue.toFixed(2)));
}
  render() {
    const roundNumber = this.props.balance - this.state.totalBetAmount
    const betAmount = roundNumber; 
    let updatebal = this.topLable_fn(Number(betAmount));  


    let updateBet = this.topLable_fn(this.state.totalBetAmount)
    let winAmount = this.changeAmtlabl(this.state.takeAmount)
    return (
      <React.Fragment>
        <div className="gameBox" id="gameBox">
          <div className="andarBaharbg">
            <TopLable
              childRef={ref => (this.TopLable = ref)}
              playerId={this.props.playerId}
              ref={this.TopLable}
              updatedbalance={updatebal}
              updatedwinAmt={winAmount}
         
              funCardGameState={this.props.funCardGameState}
              betAmt={this.state.betAmt}
              balance={this.props.balance}
              user={this.props.user}
              gameEntry={this.props.gameEntry}
              language={this.props.language}
              takeAmount={this.state.takeAmount}
              gameState={this.props.gameState}
              auto_bet={this.auto_bet.bind(this)}
              check_spec={this.check_spec.bind(this)}
              disableBtns={this.disableBtns.bind(this)}
              network={this.props.network}
              gameId={this.props.gameId}
              winGlowImg={this.state.winGlowImg}

            />

            <ChipsDealerPlayer
              childRef={ref => (this.ChipsDealerPlayer = ref)}
              delearFirst={this.state.delearFirst}
              updatedbets={updateBet}
              cardsArray={this.state.cardsArray}
              betAmountArray={this.state.betAmountArray}
              winnerArray={this.state.winnerArray}
              funCardGameState={this.props.funCardGameState}
              funCardResult={this.state.targetResult}
              ref={this.ChipsDealerPlayer}
              enableKeyboard={this.handleKeyboard.bind(this)}
              selectedCard={this.selectedCard.bind(this)}
              getChipVal={this.getChipVal.bind(this)}
              disableClearBtn={this.state.disableClearBtn}
              disableDealBtn={this.state.disableDealBtn}
              disable={this.state.disable}
              btnActive={this.state.btnActive}
              betBtnTxt={this.state.betButtonText}
              betButtonValue={this.state.betButtonValue}
              betCard={this.state.betCard}
              doubleAmount={this.doubleAmount.bind(this)}
              infoText={this.state.infoText}
              action={this.gameCloseHandler.bind(this)}
              bet_fn={this.bet_fn.bind(this)}
              clear_fn={this.clear_fn.bind(this)}
              selecteSuit={this.selecteSuit.bind(this)}
              totalBetAmount={this.state.totalBetAmount}
              network={this.props.network}
              disableBetBtn={this.state.disableBetBtn}
              isMobile={this.props.isMobile}
              declare_Result={this.declare_Result.bind(this)}
              startBlink={this.state.startBlink}
              gameState={this.props.gameState}
              abt_chipValues={this.props.abt_chipValues}
            />
          </div>

          <div className="winPopandb" id="winPopandb"> 
            <div className="popBorderandb">
            {this.props.language.Win_Amt}  &nbsp; <span> {winAmount} </span> 
            </div>
        </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj

  }
}
export default connect(mapStatesToProps)(AndarBahar)